import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";

import ActionItems from "./Blocks/ActionItems";
import IdrSolutionBlock from "./Blocks/IdrSolutionBlock";
import EventsGoals from "./EventsGoals";
import InvestmentAllocationCard from "./InvestmentAllocationCard";
import { SPECIAL_REPAYMENTS } from "src/interfaces";
import { getAccounts } from "src/store/account/actions";
import { fetchLivePlanProjection } from "src/store/planBuild/actions";
import {
  getStudentIncomeAndExpenseSummaries,
  loadPlanSummary,
  loadStudentPlanSummary,
} from "src/store/dashboard/actions";
import { getRefreshNeeded } from "src/store/dashboard/selector";
import {
  getLiveLifeEventProjections,
  getLiveProjection,
} from "src/store/planBuild/selector";
import {
  getLastGraduationYearMonth,
  getProfile,
  getSpouseProfile,
} from "src/store/profileBuild/selector";
import { getIsCurrentStudent } from "src/store/system/selector";
import { colors } from "src/theme";
import LatestPosts from "./Blocks/LatestPosts";
import FinancialPlanReview from "./Blocks/FinancialPlanReview";

const useStyles = makeStyles({
  eventsContainer: {
    margin: "20px 0px 0px 30px",
  },
  eventsContainerStudent: {
    margin: "20px 0px 0px 30px",
    maxWidth: 400,
  },
  executionSummary: {
    margin: "0px auto",
    maxWidth: 400,
  },
  executionSummaryStudent: {
    margin: "20px auto",
    maxWidth: 670,
  },
  executionGoals: {
    width: "100%",
  },
  overviewContainer: {
    width: 900,
    marginBottom: "60px",
  },
  explainer: {
    margin: "20px auto",
    maxWidth: 670,
  },
  tabs: {
    margin: "20px auto 0",
  },
  bottomBar: {
    position: "fixed",
    bottom: "0px",
    width: "100%",
    backgroundColor: "white",
    margin: "0px -30px",
    padding: "10px 0px",
  },
  editButton: {
    display: "block",
    margin: "0 auto",
    width: 200,
  },
  loading: {
    margin: "100px auto",
    textAlign: "center",
  },
  link: {
    textDecoration: "none",
    fontSize: "0.8em",
    color: colors.brandingBlue1,

    "&:hover": {
      textDecoration: "underline",
    },
  },
});

const nowYear = new Date().getUTCFullYear();

const HomePlan = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
  const spouseProfile = useSelector(getSpouseProfile);
  const refreshNeeded = useSelector(getRefreshNeeded);
  const livePlanEvents: any[] = useSelector(getLiveLifeEventProjections);
  const liveProjection = useSelector(getLiveProjection);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const [graduationYear, graduationMonth] = useSelector(
    getLastGraduationYearMonth
  );
  const hasIdr =
    SPECIAL_REPAYMENTS.indexOf(profile?.fed_repayment_plan || "") >= 0 ||
    SPECIAL_REPAYMENTS.indexOf(spouseProfile?.fed_repayment_plan || "") >= 0;
  const planStart = liveProjection?.start || `${nowYear}-01`;
  const startYear = +planStart.slice(0, 4);

  useEffect(() => {
    if (refreshNeeded.liveProjection) {
      dispatch(fetchLivePlanProjection(false));
    }
  }, [refreshNeeded.liveProjection]);

  const loadSummary = (year: number) => {
    const summaryArgs = { year: year || nowYear, startMonth: 0, endMonth: 0 };
    if (isCurrentStudent) {
      if (startYear === nowYear) {
        summaryArgs.startMonth = +planStart.slice(5, 7);
      }
      if (graduationYear === year) {
        summaryArgs.endMonth = graduationMonth;
      }
      dispatch(loadStudentPlanSummary(summaryArgs));
      dispatch(getStudentIncomeAndExpenseSummaries(summaryArgs));
    } else {
      dispatch(loadPlanSummary());
    }
  };

  useEffect(() => {
    loadSummary(startYear);
    dispatch(getAccounts());
  }, []);

  return (
    <>
      {!liveProjection && (
        <Box className={styles.loading}>
          <Typography className="mb-8" variant="h2" component="h2">
            Loading plan summary...
          </Typography>
          <Box style={{ margin: "0 auto", width: 200 }}>
            <CircularProgress size={150} />
          </Box>
        </Box>
      )}
      {!!liveProjection && (
        <>
          <div className="card">
            <FinancialPlanReview />
          </div>
          <div className="card">
            <ActionItems />
          </div>
          <div className="card">
            <LatestPosts />
          </div>
          {!!hasIdr && (
            <div className="card">
              <IdrSolutionBlock />
            </div>
          )}
          <div className="card">
            {!!livePlanEvents && !!livePlanEvents.length && <EventsGoals />}
          </div>
          <div className="card">
            <InvestmentAllocationCard />
          </div>
        </>
      )}
    </>
  );
};

export default HomePlan;
