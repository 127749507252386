import React from "react";

import { Breakout } from "src/interfaces";
import { formatDollarsAndCents } from "src/utils";
import { EuiText, EuiPanel, EuiFlexGroup, EuiFlexItem, EuiHorizontalRule } from "@elastic/eui";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import { Global, css } from "@emotion/react";

const incomeSummaryStyles = css`
  .value-format {
    font-weight: 600 !important;
    font-size: 16px !important;
    font-family: "Inter", sans-serif;
  }
`;

const BreakoutSummary = ({ values }: any) => {
  const incomes = values.incomes || [];
  const gross = incomes.reduce(
    (result: number, item: Breakout) => result + Math.abs(item.amount),
    0
  );
  const deductions = values.deductions || [];
  const deductionTotal = deductions.reduce(
    (result: number, item: Breakout) => result + Math.abs(item.amount),
    0
  );
  const calculatedNet = gross - deductionTotal;
  return (
    <>
      <Global styles={incomeSummaryStyles} />
      <EuiPanel paddingSize="l" className="billing-summary">
        <EuiFlexGroup justifyContent="spaceBetween" alignItems="baseline">
          <EuiFlexItem grow={false}>
            <EuiText>
              Total Income
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiText className="value-format">{formatDollarsAndCents(gross)}</EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>

        <StyledSpacer size="8px" />

        <EuiFlexGroup justifyContent="spaceBetween" alignItems="baseline">
          <EuiFlexItem grow={false}>
            <EuiText>
              Deductions
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiText className="value-format">{formatDollarsAndCents(deductionTotal)}</EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiHorizontalRule margin="m" />

        <EuiFlexGroup justifyContent="spaceBetween" alignItems="baseline">
          <EuiFlexItem grow={false}>
            <EuiText className="value-format">
              Net Income
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiText className="value-format">
              {formatDollarsAndCents(calculatedNet)}
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    </>
  );
};

export default BreakoutSummary;
