import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { usePlaidLink, PlaidLinkOptions } from "react-plaid-link";
import clsx from "clsx";
import {
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiText,
  EuiToolTip,
} from "@elastic/eui";

import { getPlaidPublicTokenApi } from "src/apiService";
import Icon from "src/components/Icon";
import {
  getProviderAccounts,
  relinkLinkedAccount,
} from "src/store/account/actions";
import { formatDollars, DOLLAR_FORMATS } from "src/utils";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import "src/pages/Dashboard/MyProfile/Accounts/components/AccountLinkPage/LinkPageStyles.css";

import useStyles from "./styles";
import FormError from "src/components/Global/FormError";

// Define the Account type
interface Account {
  id: string;
  name: string;
  balance: number;
  ignore?: boolean;
  last_updated?: string;
}

const MainContent = ({
  accounts,
  provider,
  goToNextAfterFix,
  showWaitingMessage,
  noProviders,
  polling,
}: any) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [publicToken, setPublicToken] = useState<string>("");

  useEffect(() => {
    if (provider && provider.item_id) {
      dispatch(getProviderAccounts(provider));
      getPlaidPublicTokenApi({ itemId: provider.item_id }).then((data) => {
        setPublicToken(data?.link_token || "");
      });
    }
  }, [provider]);

  const config: PlaidLinkOptions = useMemo(
    () => ({
      token: publicToken,
      onSuccess: () => {
        if (provider && provider.item_id) {
          dispatch(relinkLinkedAccount(provider.item_id));
          goToNextAfterFix(provider.item_id);
        }
      },
      onExit: () => null,
    }),
    [provider, publicToken]
  );

  const { open: relink } = usePlaidLink(config);

  const sortedAccounts = useMemo(
    () =>
      [...accounts].sort((a: Account, b: Account) => {
        if (a.balance === b.balance) {
          return a.name < b.name ? 1 : -1;
        }
        return b.balance - a.balance;
      }),
    [accounts]
  );

  const columns = [
    {
      field: "name",
      name: "Account Name",
      render: (name: string, account: Account) => (
        <div
          className={clsx(
            styles.accountName,
            account.ignore && styles.closedAccount
          )}
        >
          {name}
          {account.ignore && (
            <EuiToolTip content="This account is ignored in your plan. Contact your coach for questions.">
              <span>
                <Icon iconName="fa-eye-slash" />
              </span>
            </EuiToolTip>
          )}
        </div>
      ),
    },
    {
      field: "balance",
      name: "Balance",
      render: (balance: number) => (
        <div>{formatDollars(balance, DOLLAR_FORMATS.DOLLARS_AND_CENTS)}</div>
      ),
    },
  ];

  const lastDate = accounts?.length
    ? accounts[0].last_updated
    : provider?.update;

  return (
    <>
      <div className="ai-content-title">
        <EuiFlexGroup style={{ gap: 0 }} justifyContent="spaceBetween">
          <EuiFlexItem grow={false}>
            <h1 style={{ fontSize: "20px" }}>
              Connected {provider ? provider.name : ""} Accounts
            </h1>
          </EuiFlexItem>
          {/* <EuiFlexItem grow={false} style={{ justifyContent: 'center' }}>
            <EuiLink color="danger" style={{ fontSize: '16px' }}>Delete</EuiLink>
          </EuiFlexItem> */}
        </EuiFlexGroup>
      </div>
      <StyledSpacer size="32px" />
      {!!provider?.error && (
        <>
          <FormError
            type="callout"
            title={
              <>
                We encountered issues connecting to {provider?.name}.{" "}
                <EuiLink
                  onClick={() => relink()}
                  style={{
                    textDecoration: "underline",
                    color: "#bd271e",
                    fontWeight: "600",
                  }}
                >
                  Click here to resolve
                </EuiLink>
              </>
            }
          />
          <StyledSpacer size="32px" />
        </>
      )}
      <div className="table-accounts">
        {!!sortedAccounts.length && (
          <>
            <EuiBasicTable
              className="table-border lp-table-text"
              items={sortedAccounts}
              columns={columns}
              rowProps={(account: Account) => ({
                className: account.ignore ? "closed-account-row" : "",
              })}
              cellProps={() => ({
                textOnly: true,
              })}
            />
            <StyledSpacer size="32px" />
          </>
        )}
        <div className="secondary-text">
          {polling
            ? "It can take a minute to fetch your latest account information."
            : `Last Updated: ${new Date(lastDate).toLocaleDateString()}`}
        </div>
        <StyledSpacer size="8px" />
        {!showWaitingMessage && !!provider && !provider.error && (
          <>
            <StyledSpacer size="16px" />
            <div className="secondary-text">
              Don't see one of your accounts here?{" "}
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  relink();
                }}
              >
                Click here&nbsp;
              </a>
              to grant access.
            </div>
          </>
        )}
        {noProviders && (
          <EuiText>
            Start connecting financial institutions by clicking “Add Financial
            Institution.” We use Plaid to connect your financial accounts
            quickly and securely. Once your accounts are connected, they will
            update automatically so you have the most complete picture of your
            finances.
          </EuiText>
        )}
        {/* {!provider?.error && showWaitingMessage && (
            <Box className={clsx(styles.providerErrorHeader, "mt-8")}>
              <Typography variant="h4" className={styles.errorHeaderText}>
                Hold tight as it may take a minute to fetch your account information.
              </Typography>
            </Box>
          )} */}
      </div>
    </>
  );
};

export default MainContent;
