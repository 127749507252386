import { createAction } from "redux-actions";
import { START } from "../common";

import { Cashflow, CASHFLOW_TYPES, Plan } from "src/interfaces";

export interface AddCashflowItemPayload {
  cashflow: {
    amount: number;
    type: CASHFLOW_TYPES;
    death_benefit?: number;
    who?: string;
    inschool?: boolean;
  };
  temporaryId?: number;
}

export interface EditCashflowItemPayload {
  id: number;
  amount: number;
  death_benefit?: number;
}

export interface ReplaceCashflowItemPayload {
  id: number;
  newItem: {
    amount: number;
    type: CASHFLOW_TYPES;
    who?: string;
    inschool?: boolean;
  };
}

export const FETCH_CASHFLOW_ITEMS = "FETCH_CASHFLOW_ITEMS";
export const ADD_CASHFLOW_ITEM = "ADD_CASHFLOW_ITEM";
export const ADD_DRAFT_CASHFLOW = "ADD_DRAFT_CASHFLOW";
export const REMOVE_DRAFT_CASHFLOW = "REMOVE_DRAFT_CASHFLOW";
export const EDIT_CASHFLOW_ITEM = "EDIT_CASHFLOW_ITEM";
export const TEMPORARY_EDIT_CASHFLOW_ITEM = "TEMPORARY_EDIT_CASHFLOW_ITEM";
export const REMOVE_CASHFLOW_ITEM = "REMOVE_CASHFLOW_ITEM";
export const REPLACE_CASHFLOW_ITEM = "REPLACE_CASHFLOW_ITEM";
export const FETCH_TAXES = "FETCH_TAXES";

export const fetchCashflowItems = createAction(FETCH_CASHFLOW_ITEMS + START);
export const addCashflow = createAction<AddCashflowItemPayload>(ADD_CASHFLOW_ITEM);
export const addDraftCashflow = createAction<Cashflow>(ADD_DRAFT_CASHFLOW);
export const removeDraftCashflow = createAction(REMOVE_DRAFT_CASHFLOW);
export const temporaryEditCashflow = createAction<EditCashflowItemPayload>(
  TEMPORARY_EDIT_CASHFLOW_ITEM
);
export const editCashflow = createAction<EditCashflowItemPayload>(
  EDIT_CASHFLOW_ITEM + START
);
export const removeCashflow = createAction<number>(
  REMOVE_CASHFLOW_ITEM + START
);
export const replaceCashflow = createAction<ReplaceCashflowItemPayload>(
  REPLACE_CASHFLOW_ITEM + START
);
export const fetchTaxes = createAction<Plan | null>(FETCH_TAXES + START);
