import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forOwn } from "lodash";

import { format } from "date-fns";
import { tabLabels } from "../data";
import Tabs from "src/components/Tabs";
import { MappedCashflow, INCOME_TYPES } from "src/interfaces";
import BudgetCard from "../Components/BudgetCard";
// TODO
// import Dialog from "../Components/Dialog";
import { reportActionItemApi } from "src/apiService";
import {
  addCashflow,
  editCashflow,
  removeCashflow,
} from "src/store/cashflow/actions";
import { getIncomeSummary } from "src/store/dashboard/selector";
import { getDashboardCashflows } from "src/store/cashflow/selector";
import { livePlanIncomeTotal } from "src/store/planBuild/selector";
import { getHasPlan } from "src/store/system/selector";
import {
  EuiButtonGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
} from "@elastic/eui";
import { Global, css } from "@emotion/react";
import { useFlyout } from "src/components/Global/FlyoutContext";
import AddItemDialog from "../Components/Dialog";

const ddInsuranceStyles = css`
  .rm-insurance-block {
    .rm-secondary-header {
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      font-family: "Inter", sans-serif;
    }
    .rm-tab-group {
      min-width: 400px;
      .euiButtonGroupButton {
        background-color: #fbfcfd;
        border: 1px solid #cecece;
      }
      .euiButtonGroupButton-isSelected {
        background-color: #69707d;
      }
      span {
        font-size: 14px;
        font-family: "Inter", sans-serif;
      }
    }
  }
`;

const IncomeCard = ({ openTransactionsDialog }: any) => {
  const dispatch = useDispatch();
  const incomeSummary = useSelector(getIncomeSummary);
  const { income }: any = useSelector(getDashboardCashflows);
  const hasPlan = useSelector(getHasPlan);
  const incomeTotal = useSelector(livePlanIncomeTotal);
  const { openCustomFlyout } = useFlyout();
  const [currentTab, setCurrentTab] = useState(0);
  const [isEditing, setEditing] = useState(false);
  // TODO
  // const [addDialogVisible, setAddDialogVisible] = useState(false);

  const tabOptions = [
    {
      id: "current",
      label: "Current",
    },
    {
      id: "monthly",
      label: "Last Month",
    },
    {
      id: "annual",
      label: "Last 12 Months",
    },
  ];

  const _handleTabs = (id: any) => {
    const tabIndex = tabOptions.findIndex((tab) => tab.id === id);
    setCurrentTab(tabIndex);
  };

  const openAddDialog = () => {
    const availableTypeList = Object.entries(INCOME_TYPES).map(([value, label]) => ({
      value,
      label,
    }));

    openCustomFlyout({
      component: (props: any) => (
        <AddItemDialog
          {...props}
          title="Add Income"
          list={availableTypeList}
          onAdd={(values: { type: string; annual: string; who?: string }) => {
            const amount = +values.annual || 0;
            const cashflow = {
              amount,
              type: values.type,
              who: values.who === "spouse" ? "spouse" : undefined,
              inschool: false,
            };
            dispatch(addCashflow({ cashflow }));
          }}
        />
      ),
      showOwner: true,
    });
    
  };

  const calculateDateRange = (): string => {
    const now = new Date();
    let startDate: Date;
    const endDate = now;

    switch (currentTab) {
      case 1: // Last 30 Days
        startDate = new Date();
        startDate.setDate(now.getDate() - 30);
        break;
      case 2: // Last 12 Months
        startDate = new Date();
        startDate.setFullYear(now.getFullYear() - 1);
        break;
      case 0: // Current
      default:
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        break;
    }

    return `${format(startDate, "MMM yyyy")} - ${format(endDate, "MMM yyyy")}`;
  };

  const handleCardEditClick = (
    changes: { [stringId: string]: number },
    deletedIds: Set<number>,
    newValues: { [type: string]: number }
  ) => {
    if (isEditing) {
      let delta = 0;
      forOwn(changes, (amount: number, stringId: string) => {
        const cashflow = income.find(
          (item: MappedCashflow) => item.id === +stringId
        );
        if (cashflow) {
          const newAmount = currentTab === 2 ? amount : amount * 12;
          delta += amount - cashflow.annual;
          const update: any = { id: +stringId, amount: newAmount };
          dispatch(editCashflow(update));
        }
      });
      Array.from(deletedIds).forEach((id: number) => {
        const cashflow = income.find((item: MappedCashflow) => item.id === id);
        if (cashflow) {
          delta -= cashflow.annual;
        }
        dispatch(removeCashflow(id));
      });
      forOwn(newValues, (rawAmount: number, type: string) => {
        if (rawAmount) {
          const amount = currentTab === 2 ? rawAmount : rawAmount * 12;
          const splitType = type.split("__");
          const who = splitType?.[1] === "spouse" ? "spouse" : "applicant";
          const cashflowType = splitType[0];
          delta += amount;
          dispatch(
            addCashflow({ cashflow: { amount, type: cashflowType, who } })
          );
        }
      });
      if (hasPlan && Math.abs(delta) >= incomeTotal * 0.015) {
        reportActionItemApi("PLAN_PROFILECHG");
      }
    }
    setEditing(!isEditing);
  };

  // TODO
  // const openAddDialog = () => setAddDialogVisible(true);
  // const closeAddDialog = () => setAddDialogVisible(false);
  // const addItem = (values: { type: string; annual: string; who?: string }) => {
  //   const newCashflow: any = {
  //     type: values.type,
  //     amount: +values.annual || 0,
  //     who: values.who || "applicant",
  //   };
  //   if (isCurrentStudent && INCOME_TYPES[values.type]) {
  //     newCashflow.inschool = true;
  //   }
  //   dispatch(addCashflow({ cashflow: newCashflow }));
  //   if (hasPlan && newCashflow.amount >= incomeTotal * 0.015) {
  //     reportActionItemApi("PLAN_PROFILECHG");
  //   }
  //   closeAddDialog();
  // };

  const availableTypeList: Array<{ label: string; value: string }> = [];
  forOwn(INCOME_TYPES, (label, cashflowType) => {
    availableTypeList.push({
      label,
      value: cashflowType,
    });
  });

  const getActual = () => {
    if (currentTab === 0) {
      return incomeSummary.current;
    } else if (currentTab === 1) {
      return incomeSummary.monthly;
    }
    return incomeSummary.annual;
  };

  const timeframes = ["current", "monthly", "annual"];
  const timeframe = timeframes[currentTab];

  return (
    <>
      <Global styles={ddInsuranceStyles} />
      <div className="rm-insurance-block">
        <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
          <EuiFlexItem grow={false}>
            <EuiTitle className="rm-secondary-header">
              <h2>Income</h2>
            </EuiTitle>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonGroup
              legend="Insurance tabs"
              options={tabOptions}
              idSelected={tabOptions[currentTab].id}
              onChange={_handleTabs}
              isFullWidth
              className="rm-tab-group"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
      <BudgetCard
        buttonLabel="Add Income"
        budget={income}
        openTransactionsDialog={(key) =>
          openTransactionsDialog("income", key, timeframe)
        }
        actual={getActual()}
        onSave={handleCardEditClick}
        onAdd={openAddDialog}
        editing={isEditing}
        annual={currentTab === 2}
        addOwner
        dateRange={calculateDateRange()}
      />
    </>
  );
};

export default IncomeCard;
