import * as React from "react";

import DashboardLayout from "src/layouts/DashboardLayout";
import { Content } from "./Content";

const Budget = () => {
  return (
    <DashboardLayout pageTitle="Day-to-Day Money">
      <Content />
    </DashboardLayout>
  );
};

export default Budget;
