import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import { ViewComponent } from "src/interfaces/viewComponent.interface";
import ProfileBuilderLayout from "src/layouts/ProfileBuilderLayout";
import { fetchProfile } from "src/store/profileBuild/actions";
import {
  PROFILE_BUILD_STEPS,
  PROFILE_PROGRESS,
} from "src/store/profileBuild/constants";
import {
  getCurrentStep,
  getProfileError,
  getProfileLoaded,
  getProfileLoading,
  getProfileProgress,
} from "src/store/profileBuild/selector";
import personalInfoViews from "./Views/PersonalInfo";
import incomeAndExpensesViews from "./Views/IncomeAndExpenses";
import humanCapitalViews from "./Views/HumanCapital";
import assetsAndDebtsViews from "./Views/AssetsAndDebts";

export const ProfileBuilder = () => {
  const dispatch = useDispatch();

  const step = useSelector(getCurrentStep);
  const loaded = useSelector(getProfileLoaded);
  const loading = useSelector(getProfileLoading);
  const error = useSelector(getProfileError);
  const progress = useSelector(getProfileProgress);

  const renderFunctions: { [key in PROFILE_BUILD_STEPS]: ViewComponent } = {
    ...personalInfoViews,
    ...humanCapitalViews,
    ...incomeAndExpensesViews,
    ...assetsAndDebtsViews,
  };

  const View = renderFunctions[step];

  useEffect(() => {
    if (!loaded.profile && !loading.profile && !error.profile) {
      dispatch(fetchProfile());
    }
  }, [dispatch, loaded.profile]);

  useEffect(() => {
    if (progress >= PROFILE_PROGRESS.length) {
      dispatch(push("/dashboard"));
    }
  }, [progress]);

  return (
    <ProfileBuilderLayout pageTitle="Profile builder">
      <div className="profile-builder">
        <View key={step} render={({ component }) => <div>{component}</div>} />
      </div>
    </ProfileBuilderLayout>
  );
};

export default ProfileBuilder;
