import React from "react";
import { map, omit } from "lodash";
import {
  EuiForm,
  EuiFormRow,
  EuiSuperSelect,
  EuiText,
  EuiIcon,
  EuiFlexItem,
  EuiFlexGroup,
} from "@elastic/eui";
import { Breakout, INCOME_BREAKOUTS } from "src/interfaces";
import { DollarTextField } from "src/utils";
import BreakoutSummary from "./BreakoutSummary";
import { StyledSpacer, StyledEuiButton, StyledEuiHorizontalRule } from "src/components/Global/StyledComponents";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";

const inputIncomeStyles = css`
  .income-group {
    gap: 16px !important; 
    align-items: center !important;
    .euiFlexItem {
      flex: 0 1 auto;
    }
  }
  .income-type-field {
    .euiFormControlLayout__childrenWrapper {
      max-width: 200px;
      min-width: 200px;
    }
    .euiFormRow {
      max-width: 200px;
      min-width: 200px;
    }
  }
  .label-group {
    gap: 16px !important;
    .euiFormLabel {
      margin-bottom: 0 !important;
    }
  }
`;

const StyledEuiIcon = styled(EuiIcon)`
  width: 20px;
  height: 20px;
`;

const InputIncome = ({ formValues, handleChange, removeBreakout, transaction }: any) => {
  const incomes = formValues.incomes || [];

  const addItem = () => {
    const newIncomes = [
      ...incomes,
      { type: "", amount: "", id: Math.random() },
    ];
    handleChange({ target: { name: "incomes", value: newIncomes } });
  };

  const handleChangeItemType = (value: string, index: number) => {
    const newIncomes = [...formValues.incomes];
    newIncomes[index] = {
      ...newIncomes[index],
      type: value,
      amount: newIncomes[index].amount,
    };
    handleChange({ target: { name: "incomes", value: newIncomes } });
  };

  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    const newIncomes = [...formValues.incomes];
    newIncomes[index] = {
      ...newIncomes[index],
      type: newIncomes[index].type,
      amount: value,
    };
    handleChange({ target: { name: "incomes", value: newIncomes } });
  };

  const displayType = formValues?.type || transaction?.type || "";
  let availableBreakouts: any = INCOME_BREAKOUTS;
  if (+displayType === 7002) {
    availableBreakouts = { "1008": INCOME_BREAKOUTS["1008"] };
  } else if (+displayType === 7003) {
    availableBreakouts = { "1010": INCOME_BREAKOUTS["1010"] };
  } else {
    availableBreakouts = omit(availableBreakouts, ["1008", "1010"]);
  }

  return (
    <>
      <Global styles={inputIncomeStyles} />
      <EuiForm>
        <EuiFormRow>
          <EuiText>
            <p>Please enter your Total Income and your deductions from your paycheck. Be sure that the Net Income calculated matches the take home pay on your paycheck.</p>
            <p><b>Note:</b> For recurring transactions with a similar amount (+/- 1%) and description, you will only need to do this once and we will use this info to auto-fill your future paychecks.</p>
          </EuiText>
        </EuiFormRow>

        <StyledSpacer size="32px" />

        {incomes.length > 0 && (
          <EuiFormRow>
            <div>
              <EuiFlexGroup className="label-group">
                <EuiFlexItem className="income-type-field">
                  <EuiFormRow label="Income Type"><p></p></EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow label="Total Income"><p></p></EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
              {incomes.map((item: Breakout, index: number) => (
                <>
                  <EuiFlexGroup key={item.id} className="income-group">
                    <EuiFlexItem className="income-type-field">
                      <EuiSuperSelect
                        options={map(availableBreakouts, (label, key) => ({
                          value: key.toString(),
                          inputDisplay: label,
                        }))}
                        valueOfSelected={item.type.toString()}
                        onChange={(value) => handleChangeItemType(value, index)}
                      />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <DollarTextField
                        eui
                        inputProps={{ style: { textAlign: "right" } }}
                        variant="outlined"
                        onChange={(e: any) => handleChangeAmount(e, index)}
                        style={{ width: "120px" }}
                        value={Math.abs(item.amount)}
                      />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <StyledEuiIcon
                        type="cross"
                        onClick={() => removeBreakout("incomes", index)}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  <StyledSpacer size="16px" />
                  <StyledEuiHorizontalRule />
                  <StyledSpacer size="16px" />
                </>
              ))}
            </div>
          </EuiFormRow>
        )}

        <StyledEuiButton
          color="text"
          iconType="plus"
          onClick={addItem}
        >
          Add Income
        </StyledEuiButton>

        <StyledSpacer size="32px" />

        <EuiText>
          <BreakoutSummary values={formValues} transaction={transaction} />
        </EuiText>
      </EuiForm>
    </>
  );
};

export default InputIncome;
