import React from "react";

import { Global, css } from "@emotion/react";
import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLink,
  EuiText,
} from "@elastic/eui";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import useWindowSize from "src/hooks/useWindowSize";

const EGStyles = css`
  .card {
    max-height: 100% !important;
    .css-4ld6o8-euiCard__children {
      margin: 0;
    }
    .euiPanel {
      padding: 20px 16px 16px;
      min-height: 322px;
      max-height: 100% !important;
    }
    .eg-card {
      .eg-short-goal {
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap !important;
        .eg-wheel {
          flex: 0 1 auto !important;
          inline-size: auto !important;
        }
        .eg-info-text {
          flex: 1 1 auto !important;
          inline-size: auto !important;
          text-align: left;
        }
        .eg-short-label {
          font-size: 14px;
          font-family: "Inter", sans-serif;
          font-weight: 600;
        }
        .eg-short-text {
          font-size: 16px;
          line-height: 24px;
          font-family: "Inter", sans-serif;
          font-weight: 600;
          color: #000;
          .eg-target-amount {
            font-weight: 500;
            color: #69707d;
          }
        }
        .eg-notice-text {
          font-size: 16px;
          line-height: 24px;
          font-family: "Inter", sans-serif;
          color: #006bb8;
        }
      }
      .eg-long-goal {
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap !important;
        .eg-wheel {
          flex: 0 1 auto !important;
          inline-size: auto !important;
        }
        .eg-info-text {
          flex: 1 1 auto !important;
          inline-size: auto !important;
          text-align: left;
        }
        .eg-short-label {
          font-size: 14px;
          font-family: "Inter", sans-serif;
          font-weight: 600;
        }
        .eg-short-text {
          font-size: 16px;
          line-height: 24px;
          font-family: "Inter", sans-serif;
          font-weight: 600;
          color: #000;
          .eg-target-amount {
            font-weight: 500;
            color: #69707d;
          }
        }
        .eg-notice-text {
          font-size: 16px;
          line-height: 24px;
          font-family: "Inter", sans-serif;
          color: #8a6a0a;
        }
      }
      .progress-chart {
        flex: 0 1 auto !important;
        inline-size: auto !important;
        .CircularProgressbar-text {
          dominant-baseline: middle;
          text-anchor: middle;
          font-weight: 600;
        }
      }
      .eg-card-header {
        flex-wrap: nowrap !important;
        .eg-card-title {
          display: flex;
          justifycontent: center;
          alignitems: center;
          flex-direction: row;
          flex: 1 1 auto !important;
          inline-size: auto !important;
          h2 {
            font-size: 20px;
            line-height: 32px;
            font-family: "Poppins", sans-serif;
            font-weight: 600;
          }
          .eg-card-link {
            font-size: 16px;
            font-family: "Inter", sans-serif;
            line-height: 24px;
            text-decoration: underline;
          }
        }
        .eg-view-more {
          flex: 0 1 auto !important;
          inline-size: auto !important;
          .eg-more-link {
            font-size: 16px;
            font-family: "Inter", sans-serif;
            line-height: 24px;
            color: #69707d;
          }
        }
      }
    }
  }
`;

const LifeEventsGoals = () => {
  const { width } = useWindowSize();

  return (
    <>
      <Global styles={EGStyles} />
      <EuiCard
        className="eg-card"
        title={
          <EuiFlexGroup
            justifyContent="spaceBetween"
            alignItems="center"
            className="eg-card-header"
          >
            <EuiFlexItem grow={false} className="eg-card-title">
              {width >= 1264 && <h2>Life Events/Goal Ranking</h2>}
              {(width < 992 || width <= 1263) && <h2>Life Events/Goals</h2>}
              &nbsp;&nbsp;
              {(width >= 767 && width < 992) ||
                (width >= 1264 && (
                  <EuiLink className="eg-card-link">Goal Ranking</EuiLink>
                ))}
            </EuiFlexItem>
            <EuiFlexItem grow={false} className="eg-view-more">
              <EuiLink className="eg-more-link">View more</EuiLink>
            </EuiFlexItem>
          </EuiFlexGroup>
        }
      >
        <StyledSpacer size="24px" />
        <EuiFlexGroup className="eg-short-goal">
          <EuiFlexItem className="progress-chart">
            <div style={{ width: "60px", height: "60px" }}>
              <CircularProgressbar
                value={44}
                text={"44%"}
                styles={buildStyles({
                  textSize: "25px",
                  pathColor: "rgba(96, 146, 192, 1)",
                  textColor: "#000",
                  trailColor: "#D3DAE6",
                })}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem className="eg-info-text">
            <EuiText className="eg-short-label">SHORT-TERM GOAL</EuiText>
            <EuiText className="eg-short-text">
              Pay off student loan on Dec 2032
              <br />
              <span>$34,000</span>/
              <span className="eg-target-amount">$78,000</span>
            </EuiText>
            <EuiText className="eg-notice-text">
              <EuiIcon type="faceHappy" size="m" />
              &nbsp;&nbsp;
              <span>Good job, keep it up!</span>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
        <StyledSpacer size="24px" />
        <EuiFlexGroup className="eg-long-goal">
          <EuiFlexItem className="progress-chart">
            <div style={{ width: "60px", height: "60px" }}>
              <CircularProgressbar
                value={66}
                text={"66%"}
                styles={buildStyles({
                  textSize: "25px",
                  pathColor: "rgba(96, 146, 192, 1)",
                  textColor: "#000",
                  trailColor: "#D3DAE6",
                })}
              />
            </div>
          </EuiFlexItem>
          <EuiFlexItem className="eg-info-text">
            <EuiText className="eg-short-label">LONG-TERM GOAL</EuiText>
            <EuiText className="eg-short-text">
              Assign 30% of income to Money for Future Self
              <br />
              <span>$17,000</span>/
              <span className="eg-target-amount">$25,499</span>
            </EuiText>
            <EuiText className="eg-notice-text">
              <EuiIcon type="warning" size="m" />
              &nbsp;&nbsp;
              <span>You should be increasing your allocation</span>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiCard>
      {/* <Card className="h-full" title="Life Events/Goal Progress">
        <TableContainer className={styles.tableContainer}>
          <Table stickyHeader className={styles.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  className={`${styles.headerCell} ${styles.firstCol}`}
                ></TableCell>
                {getHeaderRow().map((col, index) => (
                  <TableCell key={index} className={styles.headerCell}>
                    {col}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {getRows().map((row, rIndex) => {
                return (
                  <>
                    <TableRow key={rIndex} className={styles.row}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={styles.cell}
                      >
                        <Box className="flex items-center relative">
                          <span className="ml-4 text-left">
                            {row.header}
                            {isMarried &&
                              (row.type === "student_loan" ||
                                row.type === "idr") &&
                              row.who !== "applicant" &&
                              ` (${row.who})`}
                          </span>
                        </Box>
                      </TableCell>
                      {row.type !== "idr" && (
                        <>
                          <TableCell className={styles.cell}>
                            {row.date}
                          </TableCell>
                          {!isCurrentStudent && (
                            <>
                              <TableCell className={styles.cell}>
                                {formatLifeEventDollars(row.currentValue)}
                              </TableCell>
                              <TableCell className={styles.cell}>
                                {formatLifeEventDollars(row.goal)}
                              </TableCell>
                            </>
                          )}
                        </>
                      )}
                      {row.type === "idr" && (
                        <>
                          <TableCell className={styles.cell}>
                            {row.date}
                          </TableCell>

                          {!isCurrentStudent && (
                            <div className={`${styles.cell} ${styles.idr}`}>
                              <AdapterLink to="/tax-bomb">
                                Click for details
                              </AdapterLink>
                            </div>
                          )}
                        </>
                      )}
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card> */}
    </>
  );
};

export default LifeEventsGoals;
