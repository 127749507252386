import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import BarChart from "src/components/BarChart";
import { getScoreRanks } from "src/store/dashboard/selector";

interface ICategories {
  name: string;
  value: number;
}

const ComparisonChart = () => {
  const { applicant, average }: any = useSelector(getScoreRanks);
  const [categories, setCategories] = useState<ICategories[]>([]);

  useEffect(() => {
    if (applicant && average) {
      if (applicant.humanCapital && !categories.length) {
        setCategories([
          { name: "You", value: applicant.humanCapital },
          { name: "Others", value: average.humanCapital },
        ]);
      }
    }
  }, [applicant]);

  return (
    <BarChart
      noNegative
      formatter={(n) => n.toFixed(0)}
      hideTooltip
      labelYOffset={20}
      dataSource={categories}
      xDomain={100}
    />
  );
};

export default ComparisonChart;
