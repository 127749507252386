import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";

import { getCurriculumOptionsApi } from "src/apiService";
import { MONTHS, YEARS_FROM_NOW } from "src/constants";
import LoginLayout from "src/layouts/LoginLayout";
import { signup } from "src/store/system/actions";
import { loginStateSelector } from "src/store/system/selector";
import { validateEmail } from "src/utils";

import {
  EuiTitle,
  EuiText,
  EuiFieldText,
  EuiButton,
  EuiImage,
  EuiShowFor,
  EuiFormRow,
  EuiFieldPassword,
  EuiLink,
  EuiIcon,
  EuiSuperSelect,
  EuiFormControlLayout,
  EuiCheckbox,
  EuiToolTip,
} from "@elastic/eui";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import default_reg from "src/assets/svgs/register_landing.svg";
import school_reg from "src/assets/svgs/school_reg.svg";
import fitbux_logo_desktop from "src/assets/svgs/fb_logo_desktop.svg";
import {
  StyledDualSelect,
  StyledSpacer,
  StyledSpan,
  LabelStyle,
} from "src/components/Global/StyledComponents";
import FormError, { InfoIcon } from "src/components/Global/FormError";

const StyledEuiText = styled(EuiText)`
  font-size: 22px;
  line-height: 2.4rem;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 2.2rem;
  }
`;
const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledEuiLink = ({ to, children, ...rest }: StyledEuiLinkProps) => (
  <Link to={to}>
    <EuiLink
      {...rest}
      style={{ textDecoration: "underline", fontWeight: "400" }}
    >
      {children}
    </EuiLink>
  </Link>
);

const PasswordRuleText = styled.span<{ isValid: boolean }>`
  color: ${({ isValid }) => (isValid ? "inherit" : "#BD271E")};
`;

interface StyledEuiLinkProps {
  to: string;
  children: React.ReactNode;
  [key: string]: any;
}

export enum REGISTRATION_VARIANTS {
  NONE = "none",
  CURRICULUM = "curriculum",
  USF = "usf",
  NEXT_LEVEL_PT = "nlpt",
  FPU = "fpu",
  STUDENT = "student",
  PAID = "paid",
}

interface MyFormValues {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  alt_email?: string;
  program?: string;
  gradmonth?: any;
  gradyear?: any;
}

interface RegisterProps {
  variant?: REGISTRATION_VARIANTS;
}

const DEGREE_NAMES: any = {
  2: "Undergrad",
  13: "MOT",
  23: "OTD",
  11: "DPT",
  3: "MBA",
  4: "JD",
  5: "MSc/MSE/MEng",
  7: "MD/DO",
  12: "DDS/DDM",
  18: "PA",
  999: "Master's",
};

const Register = ({ variant }: RegisterProps) => {
  const dispatch = useDispatch();
  const { loggedIn, loading, error } = useSelector(loginStateSelector);
  const [acceptedPolicy, setAcceptedPolicy] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [secondaryEmailError, setSecondaryEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [gradDateError, setGradDateError] = useState("");
  const [programError, setProgramError] = useState("");
  const [minLength, setMinLength] = useState(false);
  const [hasCombination, setHasCombination] = useState(false);
  const [acceptedPolicyError, setAcceptedPolicyError] = useState("");
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [hasPasswordInput, setHasPasswordInput] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [availablePrograms, setAvailablePrograms] = useState<any>([]);
  const displayError = error || emailError;
  const [formValues, setFormValues] = useState<MyFormValues>({
    password: "",
    email: "",
    alt_email: "",
    firstName: "",
    lastName: "",
    gradmonth: "",
    gradyear: "",
    program: "",
  });

  const educationVariant =
    variant === REGISTRATION_VARIANTS.USF ||
    variant === REGISTRATION_VARIANTS.FPU ||
    variant === REGISTRATION_VARIANTS.STUDENT;
  const curriculum =
    educationVariant || variant === REGISTRATION_VARIANTS.CURRICULUM;
  const paid = variant === REGISTRATION_VARIANTS.PAID;

  useEffect(() => {
    if (!curriculum) {
      return;
    }

    getCurriculumOptionsApi().then((result) => {
      setAvailablePrograms(
        result.sort((a: any, b: any) => {
          if (a.name === b.name) {
            if (DEGREE_NAMES[a.degree] > DEGREE_NAMES[b.degree]) {
              return 1;
            }
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
        })
      );
    });
  }, [curriculum]);

  if (loggedIn) {
    return <Redirect to="/home" />;
  }

  const handlePasswordChange = (e: any) => {
    const password = e.target.value;
    setFormValues((current) => ({
      ...current,
      password,
    }));

    // Check if password is at least 8 characters long
    setMinLength(password.length >= 8);

    // Check if password includes a combination of letters, numbers, and symbols
    setHasCombination(
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(password)
    );
  };

  const handleFormChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "firstName" && value) {
      setFirstNameError("");
    } else if (name === "lastName" && value) {
      setLastNameError("");
    } else if (name === "email" && value && validateEmail(value)) {
      setEmailError("");
    } else if (name === "alt_email" && value && validateEmail(value)) {
      setSecondaryEmailError("");
    } else if (name === "gradmonth" && value) {
      setGradDateError("");
    } else if (name === "gradyear" && value) {
      setGradDateError("");
    } else if (name === "program" && value) {
      setProgramError("");
    }

    setFormValues((current) => ({
      ...current,
      [name]: value,
    }));
  };

  const submit = () => {
    setSubmitAttempted(true);
    setIsPasswordFocused(true);
    setHasPasswordInput(true);

    let hasErrors = false;

    // Validate fields
    if (!formValues.firstName) {
      setFirstNameError("First name is required");
      hasErrors = true;
    } else {
      setFirstNameError("");
    }

    if (!formValues.lastName) {
      setLastNameError("Last name is required");
      hasErrors = true;
    } else {
      setLastNameError("");
    }

    if (!formValues.email) {
      setEmailError("Email is required");
      hasErrors = true;
    } else if (!validateEmail(formValues.email)) {
      setEmailError("Valid email is required");
      hasErrors = true;
    } else {
      setEmailError("");
    }

    if (curriculum) {
      if (!formValues.alt_email) {
        setSecondaryEmailError("Secondary email is required");
        hasErrors = true;
      } else if (!validateEmail(formValues.alt_email)) {
        setSecondaryEmailError("Secondary email is not valid");
        hasErrors = true;
      } else if (formValues.alt_email.endsWith(".edu")) {
        setSecondaryEmailError(
          "Secondary email address can't belong to a .edu domain."
        );
        hasErrors = true;
      } else {
        setSecondaryEmailError("");
      }
    }

    if (curriculum) {
      if (!formValues.gradmonth && !formValues.gradyear) {
        setGradDateError("Graduation month and year are required");
        hasErrors = true;
      } else if (!formValues.gradmonth) {
        setGradDateError("Graduation month is required");
        hasErrors = true;
      } else if (!formValues.gradyear) {
        setGradDateError("Graduation year is required");
        hasErrors = true;
      } else {
        setGradDateError("");
      }
    }

    if (
      curriculum &&
      variant !== REGISTRATION_VARIANTS.USF &&
      variant !== REGISTRATION_VARIANTS.STUDENT &&
      variant !== REGISTRATION_VARIANTS.FPU
    ) {
      if (!formValues.program) {
        setProgramError("Program is required");
        hasErrors = true;
      } else {
        setProgramError("");
      }
    }

    if (!formValues.password || !minLength || !hasCombination) {
      setPasswordError("Valid password is required");
      hasErrors = true;
    } else {
      setPasswordError("");
    }

    if (
      (curriculum || variant === REGISTRATION_VARIANTS.NEXT_LEVEL_PT || paid) &&
      !acceptedPolicy
    ) {
      setAcceptedPolicyError("You must accept the policy to proceed.");
      hasErrors = true;
    }

    // If there are no errors, dispatch the signup action
    if (!hasErrors) {
      let program = undefined;
      if (
        curriculum &&
        variant !== REGISTRATION_VARIANTS.USF &&
        variant !== REGISTRATION_VARIANTS.STUDENT &&
        variant !== REGISTRATION_VARIANTS.FPU
      ) {
        program = availablePrograms.find(
          (item: any) =>
            `${item.school} - ${item.degree}` === formValues.program
        );
      } else if (variant === REGISTRATION_VARIANTS.NEXT_LEVEL_PT) {
        program = "nextlevelPT";
      } else if (variant === REGISTRATION_VARIANTS.PAID) {
        program = "paid";
      }

      dispatch(signup({ ...formValues, curriculum, variant, program }));
      // dispatch(setVideo("post_signup"));
    }
  };

  const toggleAcceptedPolicy = () => {
    setAcceptedPolicyError("");
    setAcceptedPolicy((current) => !current);
  };

  const privacyPolicyLink = (
    <a
      href="https://www.fitbux.com/privacy-policy/"
      target="_blank"
      className="term-link"
    >
      Privacy Policy
    </a>
  );
  const termsOfUseLink = (
    <a
      href="https://www.fitbux.com/terms-of-use/"
      target="_blank"
      className="term-link"
    >
      Terms of Use
    </a>
  );
  const refundLink = (
    <a
      href="https://www.fitbux.com/refund-policy/"
      target="_blank"
      className="term-link"
    >
      Refund Policy
    </a>
  );
  const disclosureConsentLink = (
    <a
      href="https://www.fitbux.com/statement-of-electronic-disclosures/"
      target="_blank"
      className="term-link"
    >
      consent to Electronic Disclosures
    </a>
  );

  return (
    <LoginLayout pageTitle="Create Your Account">
      <div className={`new-container ${curriculum ? "curriculum" : ""}`}>
        <div className="half info-container blue-side">
          <div className="new-logo">
            <EuiImage
              className="desktop-logo"
              src={fitbux_logo_desktop}
              alt="FitBUX logo"
              size="original"
            />
          </div>

          <StyledSpacer size="36px" />

          <div className="info-content blue-content">
            {curriculum ? (
              <EuiImage
                src={school_reg}
                alt="school registration image"
                size="original"
              />
            ) : (
              <EuiImage
                src={default_reg}
                alt="default registration image"
                size="original"
              />
            )}

            <StyledSpacer size="36px" />

            <EuiTitle size="m">
              <h1>Reach your financial goals with FitBUX</h1>
            </EuiTitle>

            <StyledSpacer size="32px" />

            {curriculum ||
            variant === REGISTRATION_VARIANTS.NEXT_LEVEL_PT ||
            paid ? (
              <StyledEuiText className="feat-list-resp">
                <ul className="feat-list">
                  <li>Create a plan tailored to you and your goals.</li>
                  <StyledSpacer size="24px" />
                  <li>
                    Understand your finances and compare different student loan
                    payoff strategies.
                  </li>
                  <StyledSpacer size="24px" />
                  <li>
                    Get unlimited access to a financial expert to create your
                    plan.
                  </li>
                </ul>
              </StyledEuiText>
            ) : (
              <StyledEuiText className="feat-list-resp">
                <ul className="feat-list">
                  <li>
                    Start with a free 14-day trial then pay as little as
                    $15.75/month. Legacy financial planners often charge over
                    $3,000 a year.
                  </li>
                  <StyledSpacer size="24px" className="feat-block" />
                  <li>
                    Get unlimited access to a financial expert to create your
                    plan.
                  </li>
                  <StyledSpacer size="24px" className="feat-block" />
                  <li>
                    Understand your finances and compare different loan payoff
                    strategies.
                  </li>
                </ul>
              </StyledEuiText>
            )}
          </div>
        </div>
        <div className="half field-container white-side">
          <div className="field-content">
            {curriculum ? (
              <EuiTitle size="l" className="header-mobile form-header">
                <h1>Create your student account</h1>
              </EuiTitle>
            ) : variant === REGISTRATION_VARIANTS.NEXT_LEVEL_PT || paid ? (
              <EuiTitle size="l" className="header-mobile-lg form-header">
                <h1>Create your account</h1>
              </EuiTitle>
            ) : (
              <EuiTitle size="l" className="header-mobile-lg form-header">
                <h1>Start your free 14-day trial</h1>
              </EuiTitle>
            )}

            <EuiShowFor sizes={["xs", "s", "m"]}>
              <StyledSpacer size="24px" />
            </EuiShowFor>

            <EuiShowFor sizes={["xs", "s", "m"]}>
              <EuiTitle size="s" className="header-font-reg">
                <h3>Reach your financial goals with FitBUX</h3>
              </EuiTitle>

              <StyledSpacer size="24px" />

              {curriculum ||
              variant === REGISTRATION_VARIANTS.NEXT_LEVEL_PT ||
              paid ? (
                <StyledEuiText>
                  <ul className="feat-list-mobile">
                    <li>Create a plan tailored to you and your goals.</li>
                    <li>
                      Understand your finances and compare different student
                      loan payoff strategies.
                    </li>
                    <li>
                      Get unlimited access to a financial expert to create your
                      plan.
                    </li>
                  </ul>
                </StyledEuiText>
              ) : (
                <StyledEuiText>
                  <ul className="feat-list-mobile">
                    <li>
                      Start with a free 14-day trial then pay as little as
                      $15.75/month.
                    </li>
                    <li>Unlimited access to a financial expert</li>
                    <li>
                      Understand your finances and compare different loan payoff
                      strategies.
                    </li>
                  </ul>
                </StyledEuiText>
              )}
            </EuiShowFor>

            <form className="account-form">
              <StyledSpacer size={curriculum ? "24px" : "32px"} />
              <EuiFormRow
                label={<StyledSpan>First Name</StyledSpan>}
                className="input-size"
                isInvalid={!!firstNameError}
              >
                <EuiFieldText
                  name="firstName"
                  onChange={handleFormChange}
                  value={formValues.firstName}
                  isInvalid={!!firstNameError}
                />
              </EuiFormRow>

              {!!firstNameError && (
                <FormError type="text">{firstNameError}</FormError>
              )}

              <StyledSpacer size={curriculum ? "24px" : "32px"} />

              <EuiFormRow
                label={<StyledSpan>Last Name</StyledSpan>}
                className="input-size"
                isInvalid={!!lastNameError}
              >
                <EuiFieldText
                  name="lastName"
                  onChange={handleFormChange}
                  value={formValues.lastName}
                  isInvalid={!!lastNameError}
                />
              </EuiFormRow>

              {!!lastNameError && (
                <FormError type="text">{lastNameError}</FormError>
              )}

              <StyledSpacer size={curriculum ? "24px" : "32px"} />

              <EuiFormRow
                label={<StyledSpan>Email Address</StyledSpan>}
                className="input-size"
                isInvalid={!!emailError}
                error={emailError}
              >
                <EuiFieldText
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={handleFormChange}
                  value={formValues.email}
                  isInvalid={!!emailError}
                />
              </EuiFormRow>

              {paid && (
                <>
                  <StyledSpacer size={"12px"} />
                  <FormError
                    type="callout"
                    color="primary"
                    iconType={InfoIcon}
                    title="Use the same email that you used to subscribe to FitBUX."
                  />
                </>
              )}

              <div className="email-error">
                {(emailError || displayError) && (
                  <FormError type="text">
                    {emailError || displayError}
                  </FormError>
                )}
              </div>

              {curriculum && (
                <>
                  <StyledSpacer size={curriculum ? "24px" : "32px"} />
                  <EuiFormRow
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <StyledSpan>Secondary Email Address&nbsp;</StyledSpan>
                        <EuiToolTip
                          content={
                            <span style={{ fontFamily: "Inter, sans-serif" }}>
                              We ask for a second email address to make sure you
                              are receiving your assignment. Also, if you lose
                              access to your primary email address (such as an
                              .edu account), you'll still be able to access your
                              profile.
                            </span>
                          }
                          position="bottom"
                        >
                          <EuiIcon type="iInCircle" />
                        </EuiToolTip>
                      </div>
                    }
                    className="input-size"
                    isInvalid={!!secondaryEmailError}
                    error={secondaryEmailError}
                  >
                    <EuiFieldText
                      name="alt_email"
                      autoComplete="alt_email"
                      type="email"
                      onChange={handleFormChange}
                      value={formValues.alt_email}
                      isInvalid={!!secondaryEmailError}
                    />
                  </EuiFormRow>

                  <div className="email-error">
                    {secondaryEmailError && (
                      <FormError type="text">{secondaryEmailError}</FormError>
                    )}
                  </div>
                </>
              )}

              <StyledSpacer size={curriculum ? "24px" : "32px"} />

              <EuiFormRow
                label={<StyledSpan>Password</StyledSpan>}
                className={`input-size password-input ${
                  hasPasswordInput && (!minLength || !hasCombination)
                    ? "invalid"
                    : ""
                }`}
                isInvalid={!!passwordError}
                error={passwordError}
              >
                <EuiFieldPassword
                  name="password"
                  type="dual"
                  autoComplete="current-password"
                  onChange={(e) => {
                    handlePasswordChange(e);
                    setHasPasswordInput(true);
                  }}
                  value={formValues.password}
                  isInvalid={
                    hasPasswordInput && (!minLength || !hasCombination)
                  }
                  onFocus={() => setIsPasswordFocused(true)}
                  onBlur={() => setIsPasswordFocused(false)}
                />
              </EuiFormRow>

              {(isPasswordFocused || hasPasswordInput) && (
                <div className="pass-valid">
                  <p>Your password must:</p>
                  <StyledSpacer size="8px" />
                  <div className="pass-align">
                    <EuiIcon
                      type={
                        minLength ? "check" : submitAttempted ? "cross" : "dot"
                      }
                      color={
                        minLength
                          ? "success"
                          : submitAttempted
                          ? "danger"
                          : "default"
                      }
                    />
                    <PasswordRuleText isValid={minLength || !submitAttempted}>
                      Be a minimum of 8 characters
                    </PasswordRuleText>
                  </div>
                  <StyledSpacer size="8px" />
                  <div className="pass-align">
                    <EuiIcon
                      type={
                        hasCombination
                          ? "check"
                          : submitAttempted
                          ? "cross"
                          : "dot"
                      }
                      color={
                        hasCombination
                          ? "success"
                          : submitAttempted
                          ? "danger"
                          : "default"
                      }
                    />
                    <PasswordRuleText
                      isValid={hasCombination || !submitAttempted}
                    >
                      Include a combination of letters, numbers, and symbols
                    </PasswordRuleText>
                  </div>
                </div>
              )}

              {curriculum && (
                <>
                  {variant !== REGISTRATION_VARIANTS.USF &&
                    variant !== REGISTRATION_VARIANTS.STUDENT &&
                    variant !== REGISTRATION_VARIANTS.FPU && (
                      <>
                        <StyledSpacer size={curriculum ? "24px" : "32px"} />

                        <EuiFormRow
                          label={<StyledSpan>University & Program</StyledSpan>}
                          className="input-size"
                        >
                          <EuiSuperSelect
                            options={availablePrograms.map((item: any) => ({
                              value: `${item.school} - ${item.degree}`,
                              inputDisplay: `${item.name} - ${
                                DEGREE_NAMES[item.degree]
                              }`,
                            }))}
                            valueOfSelected={formValues.program}
                            onChange={(value) => {
                              handleFormChange({
                                target: { name: "program", value },
                              });
                            }}
                          />
                        </EuiFormRow>

                        {!!programError && (
                          <FormError type="text">{programError}</FormError>
                        )}
                      </>
                    )}

                  <StyledSpacer size={curriculum ? "24px" : "32px"} />

                  <EuiFormRow
                    label={<LabelStyle>Expected Graduation Date</LabelStyle>}
                  >
                    <StyledDualSelect>
                      <StyledDiv className="inputWithError">
                        <EuiFormControlLayout className="input-size-small">
                          <EuiSuperSelect
                            options={MONTHS.map((month, index) => ({
                              value: (index + 1).toString(),
                              inputDisplay: month,
                            }))}
                            valueOfSelected={formValues.gradmonth}
                            onChange={(value) => {
                              handleFormChange({
                                target: { name: "gradmonth", value },
                              });
                            }}
                          />
                        </EuiFormControlLayout>
                      </StyledDiv>

                      <StyledDiv className="inputWithError">
                        <EuiFormControlLayout className="input-size-small">
                          <EuiSuperSelect
                            options={YEARS_FROM_NOW.slice(0, 9).map((year) => ({
                              value: year.toString(),
                              inputDisplay: year.toString(),
                            }))}
                            valueOfSelected={formValues.gradyear}
                            onChange={(value) => {
                              handleFormChange({
                                target: { name: "gradyear", value },
                              });
                            }}
                          />
                        </EuiFormControlLayout>
                      </StyledDiv>
                    </StyledDualSelect>
                  </EuiFormRow>

                  {!!gradDateError && (
                    <FormError type="text">{gradDateError}</FormError>
                  )}
                </>
              )}

              {(curriculum ||
                variant === REGISTRATION_VARIANTS.NEXT_LEVEL_PT ||
                paid) && (
                <>
                  <StyledSpacer size={curriculum ? "16px" : "24px"} />
                  <EuiCheckbox
                    id="acceptPolicy"
                    label={
                      <p className="term-condition">
                        I acknowledge and agree to FitBUX's {privacyPolicyLink},{" "}
                        {termsOfUseLink}, {refundLink}, and{" "}
                        {disclosureConsentLink}.
                      </p>
                    }
                    checked={acceptedPolicy}
                    onChange={toggleAcceptedPolicy}
                  />

                  {acceptedPolicyError && (
                    <FormError type="text">{acceptedPolicyError}</FormError>
                  )}
                </>
              )}

              <StyledSpacer size={curriculum ? "24px" : "32px"} />

              <EuiButton
                type="button"
                fullWidth
                color="primary"
                fill
                onClick={submit}
                className="login-button"
                isLoading={loading}
              >
                {!loading &&
                  (curriculum ||
                  variant === REGISTRATION_VARIANTS.NEXT_LEVEL_PT ||
                  paid
                    ? "Create account"
                    : "Next: Select plan")}
              </EuiButton>

              <StyledSpacer size={curriculum ? "16px" : "24px"} />

              <EuiText size="m" className="add-text">
                <p>
                  Already have an account?&nbsp;
                  <StyledEuiLink color="text" to="/login">
                    {" "}
                    Log in{" "}
                  </StyledEuiLink>
                </p>
              </EuiText>

              <StyledSpacer size={curriculum ? "16px" : "24px"} />

              {curriculum ? (
                <EuiText size="m" className="add-text">
                  <p>
                    Already graduated?&nbsp;
                    <StyledEuiLink color="text" to="/register">
                      Sign up
                    </StyledEuiLink>
                    &nbsp;for a new account
                  </p>
                </EuiText>
              ) : (
                <EuiText size="m" className="add-text">
                  <p>
                    {" "}
                    If you or your spouse is an existing paying customer, please{" "}
                    <a href="mailto:info@fitbux.com">email us</a> so we can
                    update your account.
                  </p>
                </EuiText>
              )}

              <StyledSpacer size={curriculum ? "40px" : "48px"} />
            </form>
          </div>
        </div>
      </div>
    </LoginLayout>
  );
};

export default Register;
