import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Select,
  RadioGroup,
} from "@material-ui/core";

import CenterContainer from "../../Components/CenterContainer";
import Button from "src/components/Button";
import Radio from "src/components/Radio";
import { MAX_401K_ANNUAL_CONTRIBUTION } from "src/constants";
import {
  savePlan,
  updateCurrentPlan,
  estimateCurrentPlanTaxes,
} from "src/store/planBuild/actions";
import { EMPTY_RETIREMENT } from "src/store/planBuild/constants";
import {
  currentPlanIncomeTotal,
  getCurrentPlan,
  getMy401kEligibleIncome,
  getSpouse401kEligibleIncome,
} from "src/store/planBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import { Plan } from "src/interfaces/plan.interface";
import { PlanViewComponent } from "src/interfaces/viewComponent.interface";
import { SidebarContent } from "src/interfaces";
import {
  formatAnnually,
  formatMonthly,
  formatPercent,
  formatWholeDollars,
  PercentTextField,
} from "src/utils";
import { useStyles } from "./styles";
import { CURATED_PLAN_BUILD_STEPS } from "./common";
import ToolTipIcon from "src/components/ToolTipIcon";

type AccountTypes = "401k_value" | "roth_401k_value";

const employerBlowout: SidebarContent[] = [
  {
    header: "Retirement Savings Matching",
    body: [
      "FitBUX highly recommends taking advantage of your employer retirement match, if available.",
      "Employer matching is a contribution amount your employer makes to your retirement.",
      "For example, if you make $70,000 per year and your employer has a 3% match, if you contribute $2,100 per year to your retirement, your employer will also contribute $2,100.",
      "In short, your match is 'free' money.  That is why we recommend contributing at least the minimum amount to your retirement to trigger any company match available to you.",
    ],
  },
  {
    header: "Eligible Accounts",
    body: [
      "Below is a list of accounts and which type of employees qualify for them:",
      "1) 401k: Employees of for profit companies",
      "2) 403b: Employees of non-profit companies",
      "3) 457b: Employees of state and local governments",
      "4) TSP: Employees of the Federal government",
    ],
  },
  {
    header: "Should I do a Roth 401k?",
    body: [
      "We recommend that  you save both in a 401k and Roth 401k, if offered as they each provide different benefits. ",
    ],
  },
];

const AddOrEdit401k: PlanViewComponent = ({
  initialValues,
  onClose,
  onSave,
  render,
  // type,
  curatedIndex,
}) => {
  const curatedFlow =
    curatedIndex === CURATED_PLAN_BUILD_STEPS.EMPLOYER_RETIREMENT;
  const dispatch = useDispatch();
  const styles = useStyles();
  const plan: Plan = useSelector(getCurrentPlan);
  const isMarried = useSelector(getIsMarried);
  const myEligibleIncome = useSelector(getMy401kEligibleIncome);
  const spouseEligibleIncome = useSelector(getSpouse401kEligibleIncome);
  const totalIncome = useSelector(currentPlanIncomeTotal);
  const initialFormValues: any = {
    additional_match: "n",
    additional_limit: "",
    additional_percent: 0,
    match_limit: "",
    match_percent: 0,
    trad_contribution: "",
    who: !isMarried || curatedFlow ? "applicant" : "",
    has_401k: curatedFlow ? "n" : "y",
    has_roth_401k: "n",
    roth_401k_contribution: "",
    auto_question: "n",
    auto_match_percent: "",
  };

  if (initialValues?.who && isMarried) {
    initialFormValues.who = initialValues.who;
  }
  const itemIndex = isMarried && initialValues?.who === "spouse" ? 1 : 0;
  const retirement = plan?.retirement?.find(
    (r) => r.who === initialValues?.who
  ) || { ...EMPTY_RETIREMENT };
  const soloAllocation = plan?.allocations[0].solo[itemIndex];
  initialFormValues.has_401k = retirement.has_401k;
  initialFormValues.trad_contribution = soloAllocation["401k_value"];
  initialFormValues.roth_401k_contribution = soloAllocation.roth_401k_value;
  initialFormValues.roth_401k = retirement.has_roth_401k;
  initialFormValues.match_limit = retirement.match_limit;
  initialFormValues.match_percent = retirement.match_percent;
  initialFormValues.additional_match = retirement.additional_limit ? "y" : "n";
  initialFormValues.additional_limit = retirement.additional_limit;
  initialFormValues.additional_percent = retirement.additional_percent;
  initialFormValues.auto_question = retirement.nonelective_limit ? "y" : "n";
  initialFormValues.auto_match_percent = retirement.nonelective_limit;
  const [formValues, setFormValues] = useState<any>(initialFormValues);

  // TODO checking for income and then closing the form is not the right way, fix this
  useEffect(() => {
    const eligibleIncome =
      initialFormValues.who === "spouse"
        ? spouseEligibleIncome
        : myEligibleIncome;
    if (!eligibleIncome && curatedFlow) {
      onClose();
    }
  }, [myEligibleIncome, spouseEligibleIncome]);

  const eligibleIncome =
    formValues.who === "spouse" ? spouseEligibleIncome : myEligibleIncome;

  const totalMaxPercent = (MAX_401K_ANNUAL_CONTRIBUTION / eligibleIncome) * 100;
  const maxPercent = totalMaxPercent - (formValues.roth_401k_contribution || 0);
  const rothMaxPercent = totalMaxPercent - (formValues.trad_contribution || 0);

  const setFormValue = (e: React.ChangeEvent<any>) => {
    const field = e.target.name;
    const value = e.target.value;
    const newFormValues = { ...formValues, [field]: value };
    if (field === "who") {
      const itemIndex = value === "spouse" ? 1 : 0;
      const retirement = plan.retirement[itemIndex];
      newFormValues.trad_contribution = undefined;
      newFormValues.match_limit = retirement.match_limit;
      newFormValues.match_percent = retirement.match_percent;
      if (retirement.additional_limit) {
        newFormValues.additional_match = "y";
        newFormValues.additional_limit = retirement.additional_limit;
        newFormValues.additional_percent = retirement.additional_percent;
      }
    }
    setFormValues(newFormValues);
  };

  const spouses = formValues.who === "spouse" ? "spouse's " : "";

  const dollarContribution =
    ((formValues.trad_contribution + formValues.roth_401k_contribution) / 100) *
    (formValues.who === "spouse" ? spouseEligibleIncome : myEligibleIncome);
  const totalContribution = (dollarContribution / totalIncome || 0) * 100;

  const save = () => {
    const newRetirement = [...plan.retirement];
    const whoIndex = formValues.who === "applicant" ? 0 : 1;
    const retirementItem = {
      ...EMPTY_RETIREMENT,
      has_401k: formValues.has_401k,
      match_limit: formValues.match_limit,
      match_percent: formValues.match_percent,
      has_roth_401k: formValues.has_roth_401k,
      who: formValues.who,
    };
    if (formValues.additional_match === "y") {
      retirementItem.additional_limit = +formValues.additional_limit || 0;
      retirementItem.additional_percent = +formValues.additional_percent || 0;
    }
    if (formValues.auto_question === "y") {
      retirementItem.nonelective_limit = +formValues.auto_match_percent || 0;
      retirementItem.nonelective_traditional_limit =
        +formValues.auto_match_percent || 0;
    }
    retirementItem.traditional_limit =
      formValues.match_limit * formValues.match_percent +
      (formValues.additional_limit - formValues.match_limit) *
        formValues.additional_percent;
    newRetirement[whoIndex] = retirementItem;
    const soloAllocations = plan.allocations[0].solo;
    const newSoloAllocations = [...soloAllocations];
    newSoloAllocations[whoIndex] = {
      ...soloAllocations[whoIndex],
      ["401k_value"]: formValues.trad_contribution || 0,
      roth_401k_value: formValues.roth_401k_contribution || 0,
    };
    if (initialValues && formValues.who !== initialValues.who) {
      const otherIndex = whoIndex ? 0 : 1;
      newSoloAllocations[otherIndex] = {
        ...newSoloAllocations[otherIndex],
        ["401k_value"]: 0,
      };
    }
    const newAllocations = [...plan.allocations];
    let combinedContribution =
      ((newSoloAllocations[0]["401k_value" as AccountTypes] || 0) / 100) *
      myEligibleIncome;
    let rothCombinedContribution =
      ((newSoloAllocations[0]["roth_401k_value" as AccountTypes] || 0) / 100) *
      myEligibleIncome;
    if (isMarried) {
      combinedContribution +=
        ((newSoloAllocations[1]["401k_value" as AccountTypes] || 0) / 100) *
        spouseEligibleIncome;
      rothCombinedContribution +=
        ((newSoloAllocations[1]["roth_401k_value" as AccountTypes] || 0) /
          100) *
        spouseEligibleIncome;
    }
    newAllocations[0] = {
      ...newAllocations[0],
      solo: newSoloAllocations,
      ["401k_value"]: (combinedContribution / totalIncome) * 100,
      roth_401k_value: (rothCombinedContribution / totalIncome) * 100,
    };
    const newPlan = {
      allocations: newAllocations,
      retirement: newRetirement,
    };
    // dont save if employer offer select field is changed to no
    // this only effects the curated flow
    if (formValues.employer_offered === "y") {
      dispatch(updateCurrentPlan(newPlan));
      // Traditional 401k has been changed, check the taxes
      dispatch(estimateCurrentPlanTaxes());
    }
    dispatch(savePlan(null));
    onSave();
  };

  const setFormWho = (e: React.ChangeEvent<any>) => {
    const who = e.target.value;
    const newFormValues = { ...formValues, who };
    setFormValues(newFormValues);
  };

  return render({
    component: (
      <CenterContainer
        title="Employer Retirement"
        iconName="fb-model"
        help={employerBlowout}
        scrollable
      >
        {!formValues.who && (
          <Box className="mt-4">
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" className="mb-4">
                Whose contribution is this?
              </FormLabel>
              <Select
                variant="outlined"
                fullWidth
                value={formValues.who}
                name="who"
                placeholder="Select"
                onChange={setFormWho}
                native
              >
                <option value="applicant">Mine</option>
                <option value="spouse">My spouse's</option>
              </Select>
            </FormControl>
          </Box>
        )}

        {formValues.who && curatedFlow && (
          <Box className="mt-5">
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel className="mb-4">
                  {" "}
                  <Typography style={{ fontSize: "13px" }}>
                    Does your employer offer and do you want to contribute to
                    your 401k/403b/457b/TSP?
                  </Typography>
                </FormLabel>
                <RadioGroup
                  aria-label="has_401k"
                  name="has_401k"
                  onChange={setFormValue}
                  value={formValues.has_401k}
                  className="flex flex-row"
                >
                  <FormControlLabel value="n" control={<Radio />} label="No" />
                  <FormControlLabel value="y" control={<Radio />} label="Yes" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Box>
        )}

        {formValues.employer_offered !== "n" && (
          <>
            {!!formValues.who && (
              <>
                <Grid container spacing={2} className="mt-4">
                  <Grid item xs={6}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend" className="mb-4">
                        <Typography style={{ fontSize: "13px" }}>
                          My {spouses}eligible income for retirement
                        </Typography>
                      </FormLabel>
                      <Box className="flex items-center">
                        <Typography variant="h6">
                          {formatWholeDollars(
                            formValues.who === "spouse"
                              ? spouseEligibleIncome
                              : myEligibleIncome
                          )}
                        </Typography>
                        <ToolTipIcon
                          placement="right"
                          title="To increase/decrease this amount, return to Step 2 'Day-To-Day Money' and adjust your salary, wage, or commission income."
                        />
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend" className="mb-4">
                        <Box className="flex items-center">
                          <Typography style={{ fontSize: "13px" }}>
                            {" "}
                            My {spouses}% contributions from eligible income{" "}
                          </Typography>
                          <ToolTipIcon
                            placement="top"
                            title="This is the amount YOU will contribute to your 401k/403b based on a percenatge of your income.  See the Retirement Contribution Summary at the bottom of the screen for dollar details."
                          />
                        </Box>
                      </FormLabel>

                      <PercentTextField
                        variant="outlined"
                        fullWidth
                        name="trad_contribution"
                        value={formValues.trad_contribution}
                        placeholder="0"
                        max={maxPercent}
                        onChange={setFormValue}
                      />
                      <Typography variant="body1">
                        Max: {(Math.floor(maxPercent * 100) / 100).toFixed(2)}%
                      </Typography>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend" className="mb-4">
                        <Box className="flex items-center">
                          <Typography style={{ fontSize: "13px" }}>
                            What % of your {spouses}eligible income does your{" "}
                            {spouses}employer match?
                            <span style={{ position: "absolute" }}>
                              <ToolTipIcon
                                placement="right"
                                title="This is the percentage of income your employer matches (i.e. if your employer says they match 3% of your income, you'd enter 3% here)."
                              />
                            </span>
                          </Typography>
                        </Box>
                      </FormLabel>
                      <PercentTextField
                        variant="outlined"
                        fullWidth
                        name="match_limit"
                        value={formValues.match_limit}
                        placeholder="0"
                        onChange={setFormValue}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      component="fieldset"
                      fullWidth
                      className="h-full"
                    >
                      <FormLabel component="legend" className="mb-4">
                        <Box className="flex items-center">
                          <Typography style={{ fontSize: "13px" }}>
                            Employer match %
                          </Typography>
                          <ToolTipIcon
                            placement="top"
                            title="If your employer says they match 100%, you'd put that percentage here. "
                          />
                        </Box>
                      </FormLabel>
                      <PercentTextField
                        className="mt-auto"
                        variant="outlined"
                        fullWidth
                        name="match_percent"
                        value={formValues.match_percent}
                        placeholder="0"
                        onChange={setFormValue}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend" className="mb-4">
                        <Box className="flex items-center">
                          <Typography style={{ fontSize: "13px" }}>
                            Does your {spouses}employer offer additional match?
                          </Typography>
                          <ToolTipIcon
                            placement="right"
                            title={`Your employer may say something like, “we match an additional 50% up to 6%”. You would put 6% under ‘What additional % of your eligible income does your employer match?' and 50% under 'Employer additional match.’"`}
                          />
                        </Box>
                      </FormLabel>
                      <RadioGroup
                        aria-label="additional_match"
                        name="additional_match"
                        onChange={setFormValue}
                        value={formValues.additional_match}
                        className="flex flex-row"
                      >
                        <FormControlLabel
                          value="n"
                          control={<Radio />}
                          label="No"
                        />
                        <FormControlLabel
                          value="y"
                          control={<Radio />}
                          label="Yes"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {formValues.additional_match === "y" && (
                    <>
                      <Grid item xs={6}>
                        <FormControl component="fieldset" fullWidth>
                          <FormLabel component="legend" className="mb-4">
                            <Typography style={{ fontSize: "13px" }}>
                              What additional % of your {spouses}eligible income
                              do they match?
                            </Typography>
                          </FormLabel>
                          {/* <FormLabel component="legend" className="mb-4">My % contributions to my Roth 401k from my eligible income</FormLabel> */}
                          <PercentTextField
                            variant="outlined"
                            fullWidth
                            name="additional_limit"
                            value={formValues.additional_limit}
                            placeholder="0"
                            onChange={setFormValue}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl
                          component="fieldset"
                          fullWidth
                          className="h-full"
                        >
                          <FormLabel component="legend" className="mb-4">
                            <Typography style={{ fontSize: "13px" }}>
                              Employer match %
                            </Typography>
                          </FormLabel>
                          <PercentTextField
                            className="mt-auto"
                            variant="outlined"
                            fullWidth
                            name="additional_percent"
                            value={formValues.additional_percent}
                            placeholder="0"
                            onChange={setFormValue}
                          />
                        </FormControl>
                      </Grid>
                    </>
                  )}
                </Grid>

                <Grid item xs={12} className="mt-5">
                  <FormControl component="fieldset" fullWidth>
                    <FormLabel component="legend" className="mb-4">
                      <Box className="flex items-center">
                        <Typography style={{ fontSize: "13px" }}>
                          Does your {spouses}employer offer a Roth 401k?
                        </Typography>
                      </Box>
                    </FormLabel>
                    <RadioGroup
                      aria-label="roth_401k"
                      name="roth_401k"
                      onChange={setFormValue}
                      value={formValues.has_roth_401k}
                      className="flex flex-row mb-4"
                    >
                      <FormControlLabel
                        value="n"
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        value="y"
                        control={<Radio />}
                        label="Yes"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                {formValues.has_roth_401k === "y" && (
                  <Grid item xs={6}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend" className="mb-4">
                        <Typography style={{ fontSize: "13px" }}>
                          My % contribution to my {spouses}Roth 401k from my
                          eligible income
                        </Typography>
                      </FormLabel>
                      <PercentTextField
                        variant="outlined"
                        fullWidth
                        name="roth_401k_contribution"
                        value={formValues.roth_401k_contribution}
                        placeholder="0"
                        max={rothMaxPercent}
                        onChange={setFormValue}
                      />
                      <Typography variant="body1">
                        Max:{" "}
                        {(Math.floor(rothMaxPercent * 100) / 100).toFixed(2)}%
                      </Typography>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12} className="mt-2">
                  <FormControl component="fieldset" fullWidth>
                    <FormLabel component="legend" className="mb-4">
                      <Box className="flex items-center">
                        <Typography style={{ fontSize: "13px" }}>
                          {" "}
                          Does your {spouses}employer offer contributions to
                          your retirement without you having to contribute?{" "}
                        </Typography>
                      </Box>
                    </FormLabel>
                    <RadioGroup
                      aria-label="auto_question"
                      name="auto_question"
                      onChange={setFormValue}
                      value={formValues.auto_question}
                      className="flex flex-row mb-4"
                    >
                      <FormControlLabel
                        value="n"
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        value="y"
                        control={<Radio />}
                        label="Yes"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                {formValues.auto_question === "y" && (
                  <Grid item xs={6}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend" className="mb-4">
                        <Typography style={{ fontSize: "13px" }}>
                          Employer contributions as a % of my {spouses}eligible
                          income
                        </Typography>
                      </FormLabel>
                      <PercentTextField
                        variant="outlined"
                        fullWidth
                        name="auto_match_percent"
                        value={formValues.auto_match_percent}
                        placeholder="0"
                        onChange={setFormValue}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Box className="mb-10">
                  <Box className="w-full flex font-bold mt-4 mb-2">
                    <Box style={{ width: "40%" }} />
                    <Box className={styles.headerCell}>% of Total Income</Box>
                    <Box className={styles.headerCell}>Monthly</Box>
                    <Box className={styles.headerCell}>Annually</Box>
                  </Box>
                  <Box className="w-full flex">
                    <Box style={{ width: "50%" }}>
                      <Typography
                        style={{ fontSize: "13px" }}
                        className="font-bold"
                      >
                        Retirement Contribution Summary
                      </Typography>
                    </Box>
                    <Box style={{ width: "30%" }}>
                      {formatPercent(totalContribution)}
                    </Box>
                    <Box style={{ width: "30%" }}>
                      {formatMonthly(Math.round(dollarContribution / 12))}
                    </Box>
                    <Box style={{ width: "30%" }}>
                      {formatAnnually(dollarContribution)}
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </>
        )}

        {!curatedIndex && (
          <Button
            variant="outlined"
            fbColor="primary"
            onClick={onClose}
            className={styles.btnCancel}
          >
            Cancel
          </Button>
        )}
      </CenterContainer>
    ),
    nextLabel: "Save",
    onNext: save,
  });
};

export default AddOrEdit401k;
