import React, { useEffect, useState } from "react";
import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiText,
  EuiLink,
} from "@elastic/eui";
import {
  StyledSpacer,
  StyledEuiLink,
} from "src/components/Global/StyledComponents";
import default_image_url from "src/assets/latest_posts.jpg";
import Parser from "rss-parser";
import { Global, css } from "@emotion/react";

const LatestPostsStyles = css`
  .card {
    .css-4ld6o8-euiCard__children {
      margin: 0;
    }
    .euiPanel {
      padding: 20px 16px 16px;
      min-height: 322px;
      max-height: 322px;
    }
    .latest-posts-card {
      text-align: left;
      .post-header {
        flex-wrap: nowrap;
        .post-title {
          align-items: flex-start;
          font-size: 20px;
          line-height: 32px;
          font-family: "Poppins", sans-serif;
          font-weight: 600;
        }
        .lp-link {
          align-items: flex-end;
        }
      }
      .lp-content {
        display: flex;
        flex-direction: column;
        img {
          max-width: 75px;
          max-height: 55px;
        }
        h2 {
          font-size: 16px;
          font-family: "Inter", sans-serif;
          font-weight: 600;
        }
      }
      .lp-post {
        flex-wrap: nowrap;
        .lp-image {
          max-width: 70px;
        }
        h2 {
          margin: 0;
          line-height: 24px;
          a {
            font-size: 16px;
            font-family: "Inter", sans-serif;
            font-weight: 600;
            color: #212b36;
            &:hover {
              text-decoration: none;
            }
            svg {
              display: none;
            }
          }
        }
        @media (min-width: 992px) and (max-width: 1300px) {
          .euiFlexItem {
            justify-content: center;
          }
          p {
            display: none;
          }
        }
        @media (max-width: 576px) {
          .euiFlexItem {
            justify-content: center;
          }
          p {
            display: none;
          }
        }
      }
      .lp-link {
        font-size: 16px;
        svg {
          display: none;
        }
        a {
          font-family: "Inter", sans-serif;
          font-weight: 500;
          color: #69707d;
          text-decoration: none;
        }
      }
    }
  }
`;

const LatestPosts: React.FC = () => {
  const [posts, setPosts] = useState<Parser.Item[]>([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const parser = new Parser();

      const [blogFeed, podcastFeed] = await Promise.all([
        parser.parseURL("https://www.fitbux.com/articles/feed/"),
        parser.parseURL("https://fitbux.libsyn.com/rss"),
      ]);

      const combinedPosts = [...blogFeed.items, ...podcastFeed.items]
        .sort(
          (a, b) =>
            new Date(b.pubDate || "").getTime() -
            new Date(a.pubDate || "").getTime()
        )
        .slice(0, 3);

      setPosts(combinedPosts);
    };

    fetchPosts();
  }, []);

  const extractImageUrl = (content: string, enclosure: any) => {
    const imgRegex = /<img.*?src="(.*?)"/;
    const match = content?.match(imgRegex);
    return match ? match[1] : enclosure?.url || default_image_url;
  };

  const calculateDaysPassed = (pubDate: string) => {
    const currentDate = new Date();
    const postDate = new Date(pubDate);
    const differenceInTime = currentDate.getTime() - postDate.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

    if (differenceInDays < 7) {
      return `${differenceInDays} day${differenceInDays > 1 ? "s" : ""} ago`;
    } else if (differenceInDays < 30) {
      const weeks = Math.floor(differenceInDays / 7);
      return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
    } else {
      const months = Math.floor(differenceInDays / 30);
      return `${months} month${months > 1 ? "s" : ""} ago`;
    }
  };

  return (
    <>
      <Global styles={LatestPostsStyles} />
      <EuiCard
        className="latest-posts-card"
        title={
          <EuiFlexGroup
            justifyContent="spaceBetween"
            alignItems="center"
            className="post-header"
          >
            <EuiFlexItem grow={false} className="post-title">
              <h2>Latest Posts</h2>
            </EuiFlexItem>
            <EuiFlexItem grow={false} className="lp-link">
              <StyledEuiLink
                href="https://www.fitbux.com/articles/"
                target="_blank"
                color="text"
              >
                View more
              </StyledEuiLink>
            </EuiFlexItem>
          </EuiFlexGroup>
        }
      >
        <StyledSpacer size="24px" />
        <EuiFlexGroup gutterSize="l" className="lp-content">
          {posts.map((post, index) => (
            <EuiFlexItem key={index}>
              <EuiFlexGroup className="lp-post">
                <EuiFlexItem className="lp-image">
                  <EuiImage
                    url={extractImageUrl(post.content || "", post.enclosure)}
                    alt={post.title || ""}
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiText>
                    <h2>
                      <EuiLink href={post.link} target="_blank">
                        {post.title}
                      </EuiLink>
                    </h2>
                    <p>
                      {post.pubDate
                        ? calculateDaysPassed(post.pubDate)
                        : "Date not available"}
                    </p>
                  </EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          ))}
        </EuiFlexGroup>
      </EuiCard>
    </>
  );
};

export default LatestPosts;
