import React, { useState } from "react";
import { useSelector } from "react-redux";
import IncomeTransactionList from "src/components/IncomeTransactionList";
import { getUnconfirmedIncomeAndExpenses } from "src/store/transaction/selector";
import { EuiTitle, EuiIcon } from "@elastic/eui";
import { Transaction } from "src/interfaces";
import { StyledSpacer } from "src/components/Global/StyledComponents";

const ConfirmIncome = ({
  openConfirmDialog,
}: {
  openConfirmDialog: (transaction: Transaction) => void;
}) => {
  const { income } = useSelector(getUnconfirmedIncomeAndExpenses);
  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  if (!income || income.length === 0) {
    return null;
  }

  return (
    <div>
      <EuiTitle size="s" className="tx-table-text">
        <h1 onClick={toggleVisibility}>
          Confirm Income{" "}
          <EuiIcon color="primary" type={isVisible ? "arrowUp" : "arrowDown"} />
        </h1>
      </EuiTitle>
      <StyledSpacer size="16px" />
      {isVisible && (
        <IncomeTransactionList
          items={income}
          openConfirmDialog={openConfirmDialog}
          transactionPage={true}
        />
      )}
    </div>
  );
};

export default ConfirmIncome;
