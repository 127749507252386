import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  EuiFormControlLayout,
  EuiFormRow,
  EuiRadioGroup,
  EuiSuperSelect,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
} from "@elastic/eui";
import {
  StyledEuiButton,
  StyledEuiHorizontalRule,
  StyledSpacer,
  StyledEuiLink,
} from "src/components/Global/StyledComponents";
import useWindowSize from "src/hooks/useWindowSize";
import { MONTHS, YEARS_FROM_NOW } from "src/constants";
import { YES_NO_OPTIONS } from "src/constants/formOptions";
import { QuestionFormProps } from "src/interfaces/optimizedPlanBuild.interface";
import { savePlan } from "src/store/planBuild/actions";
import { getIsMarried, spouseSelector } from "src/store/system/selector";
import { StyledDualSelect } from "src/components/Global/StyledComponents";
import { DollarTextField } from "src/utils";
import styled from "@emotion/styled";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const IncomeIncreaseDecrease = ({
  planSetter,
  planData,
  nextClicked,
  goToNext,
  decrease,
  errors,
  setErrors,
  spouseFlag,
  setSpouseFlag,
}: QuestionFormProps & { decrease?: boolean }) => {
  const dispatch = useDispatch();
  const [nextClick] = useState(nextClicked);
  const isMarried = useSelector(getIsMarried);
  const spouse = useSelector(spouseSelector);
  const [spouseView, setSpouseView] = useState(spouseFlag);
  let key = decrease ? "income_decrease" : "income_increase";
  if (spouseView) {
    key = `s_${key}`;
  }
  const dateKey = `${key}_date`;
  const windowSize = useWindowSize();
  const [expected, setExpected] = useState(!!(planData as any)[key].length);
  const [values, setValues] = useState<number[]>([...(planData as any)[key]]);
  const [dates, setDates] = useState<string[]>([...(planData as any)[dateKey]]);
  const spouseFirstName = spouse?.first_name || "";
  const currentDate = new Date();

  useEffect(() => {
    if (spouseFlag) {
      setSpouseFlag(false);
    }
  }, [spouseFlag]);

  const handleValueChange = (value: number, index: number) => {
    const newValues = [...values];
    newValues[index] = value;
    planSetter({ ...planData, [key]: newValues });
    setValues(newValues);
    setErrors(new Set([]));
  };

  const handleDateChange = (value: string, index: number) => {
    const newDates = [...dates];
    newDates[index] = value;
    planSetter({ ...planData, [dateKey]: newDates });
    setDates(newDates);
    setErrors(new Set([]));
  };

  const goToSpouseView = () => {
    const spouseKey = `s_${key}`;
    const spouseDateKey = `s_${dateKey}`;
    dispatch(savePlan(null));
    setExpected(!!(planData as any)[spouseKey].length);
    setValues([...(planData as any)[spouseKey]]);
    setDates([...(planData as any)[spouseDateKey]]);
    setSpouseView(true);
  };

  const validate = (checkValues: number[], checkDates: string[]) => {
    let isValid = true;
    const newErrors = new Set(errors);
    for (let i = 0; i < checkValues.length; i++) {
      if (!checkValues[i] && !decrease) {
        newErrors.add(`increase_values_${i}`);
        isValid = false;
      }
      if (!checkDates[i]) {
        newErrors.add(`increase_dates_${i}`);
        isValid = false;
      }
    }
    setErrors((current) => {
      if (newErrors.size) {
        return newErrors;
      }
      return current;
    });
    return isValid;
  };

  useEffect(() => {
    if (nextClick !== nextClicked && validate(values, dates)) {
      if (isMarried && !spouseView) {
        goToSpouseView();
      } else {
        goToNext();
      }
    }
  }, [nextClicked]);

  const deleteValue = (index: number) => {
    const newValues = [...values];
    const newDates = [...dates];
    newValues.splice(index, 1);
    newDates.splice(index, 1);
    setValues(newValues);
    setDates(newDates);
    validate(newValues, newDates);
    planSetter({ ...planData, [key]: newValues, [dateKey]: newDates });
  };

  const addValue = () => {
    const newValues = [...values, 0];
    const newDates = [...dates, ""];
    setValues(newValues);
    setDates(newDates);
    planSetter({ ...planData, [key]: newValues, [dateKey]: newDates });
  };

  const doYouCaps = spouseView ? `Does ${spouseFirstName}` : "Do you";
  const doYou = spouseView ? `does ${spouseFirstName}` : "do you";
  const your = spouseView ? `${spouseFirstName}'s` : "your";
  const increaseDecrease = decrease ? "decrease" : "increase";
  const nowYear = new Date().getFullYear();

  return (
    <>
      <EuiFlexGroup justifyContent="spaceBetween" className="ai-flex-content">
        <EuiFlexItem>
          <EuiTitle className="ai-content-title">
            <h1>
              {spouseView ? `${spouseFirstName}'s ` : ""}Work Status &amp;
              Income
            </h1>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem className="flex-align">
          <h2 className="ai-secondary-text">
            {windowSize.width > 991
              ? `Section ${decrease ? "3" : "2"} of 3`
              : `(${decrease ? "3" : "2"}/3)`}
          </h2>
        </EuiFlexItem>
      </EuiFlexGroup>
      <StyledSpacer size="32px" />

      <EuiFormRow
        label={`${doYouCaps} expect to have a significant ${increaseDecrease} in income (greater than a 5% annual ${increaseDecrease})?`}
        className="full-width-ai"
      >
        <EuiRadioGroup
          idSelected={expected ? "y" : "n"}
          name="expected"
          options={YES_NO_OPTIONS}
          onChange={(id: any) => {
            setExpected(id === "y");
            if (id === "y") {
              const month = currentDate.getMonth() + 1;
              const monthString = month < 10 ? "0" + month : "" + month;
              const dateString = `${currentDate.getFullYear()}-${monthString}`;
              setValues([0]);
              setDates([dateString]);
              planSetter({ ...planData, [key]: [0], [dateKey]: [dateString] });
              setErrors(new Set([]));
            } else {
              setValues([]);
              setDates([]);
              planSetter({ ...planData, [key]: [], [dateKey]: [] });
              setErrors(new Set([]));
            }
          }}
        />
      </EuiFormRow>
      <StyledSpacer size="32px" />
      <>
        {values.map((value: number, index: number) => {
          const date = dates[index];
          const yearString = date.slice(0, 4);
          const longMonthString = date.slice(5);
          let monthString = longMonthString;
          if (monthString[0] === "0") {
            monthString = monthString.slice(1);
          }
          return (
            <div key={index}>
              <EuiFormRow
                label={`When ${doYou} expect the ${increaseDecrease}?`}
                error="This field is required."
                isInvalid={errors.has(`increase_dates_${index}`)}
              >
                <StyledDualSelect>
                  <StyledDiv className="inputWithError">
                    <EuiFormControlLayout className="input-size-small">
                      <EuiSuperSelect
                        className="input-select-dropdown"
                        options={MONTHS.map((month, index) => ({
                          value: (index + 1).toString(),
                          inputDisplay: month,
                        }))}
                        valueOfSelected={monthString}
                        onChange={(value) => {
                          const newMonthString =
                            value.length < 2 ? "0" + value : value;
                          const newDate = `${
                            yearString || nowYear
                          }-${newMonthString}`;
                          handleDateChange(newDate, index);
                        }}
                      />
                    </EuiFormControlLayout>
                  </StyledDiv>
                  <StyledDiv className="inputWithError">
                    <EuiFormControlLayout className="input-size-small">
                      <EuiSuperSelect
                        className="input-select-dropdown"
                        options={YEARS_FROM_NOW.slice(0, 9).map((year) => ({
                          value: year.toString(),
                          inputDisplay: year.toString(),
                        }))}
                        valueOfSelected={yearString}
                        onChange={(value) => {
                          const newDate = `${value}-${longMonthString}`;
                          handleDateChange(newDate, index);
                        }}
                      />
                    </EuiFormControlLayout>
                  </StyledDiv>
                </StyledDualSelect>
              </EuiFormRow>
              <StyledSpacer size="32px" />
              <EuiFormRow
                label={`How much will ${your} new total annual income be?`}
                error="This field is required."
                isInvalid={errors.has(`increase_values_${index}`)}
              >
                <DollarTextField
                  eui
                  name={`value_${index}`}
                  value={value}
                  placeholder="0"
                  onChange={(e: any) =>
                    handleValueChange(e.target.value, index)
                  }
                />
              </EuiFormRow>
              <StyledSpacer size="24px" />
              {values.length > 1 && (
                <>
                  <StyledEuiLink
                    color="primary"
                    onClick={() => deleteValue(index)}
                  >
                    Delete
                  </StyledEuiLink>
                  <StyledSpacer size="24px" />
                </>
              )}
              <StyledEuiHorizontalRule maxWidth="428px" style={{ margin: 0 }} />
              <StyledSpacer size="24px" />
            </div>
          );
        })}
        {!!values.length && (
          <StyledEuiButton color="text" iconType="plus" onClick={addValue}>
            Add Expected Income {decrease ? "Decrease" : "Increase"}
          </StyledEuiButton>
        )}
      </>
    </>
  );
};

export default IncomeIncreaseDecrease;

export const IncomeDecrease = (props: QuestionFormProps) => (
  <IncomeIncreaseDecrease {...props} decrease />
);
