import React, { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import {
  EuiText,
  EuiTimeline,
  EuiTimelineItem,
  EuiIcon,
  EuiBadge,
} from "@elastic/eui";
import styled from "@emotion/styled";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import { progressSteps, STEP_INDICES } from "src/pages/OptimizedPlan/common";
import {
  getCurrentPlan,
  isCurrentPlanImplemented,
} from "src/store/planBuild/selector";

const ProgressBarFilled = styled.div<{ height: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #0073e6;
  height: ${(props) => props.height}px;
  transition: height 0.3s ease;
  z-index: 2;
`;

interface Step {
  label: string;
  targets: number[];
}

interface Section {
  label: string;
  steps: Step[];
}

export const sections: Section[] = [
  {
    label: "Plan Setup",
    steps: [
      {
        label: "Employer Retirement & Student Loan Plans",
        targets: [
          STEP_INDICES.EmployerRetirement,
          STEP_INDICES.HSA,
          STEP_INDICES.EmployerStudentLoans,
        ],
      },
      {
        label: "Work Status & Income",
        targets: [
          STEP_INDICES.WorkStatus,
          STEP_INDICES.IncomeIncrease,
          STEP_INDICES.IncomeDecrease,
        ],
      },
      {
        label: "Marriage",
        targets: [STEP_INDICES.Marriage],
      },
      {
        label: "Children",
        targets: [STEP_INDICES.Children],
      },
      {
        label: "Invest or Pay Off Debt",
        targets: [STEP_INDICES.InvestOrPayoff],
      },
      {
        label: "Vacation",
        targets: [STEP_INDICES.Vacation],
      },
      {
        label: "Purchase a Vehicle",
        targets: [STEP_INDICES.AutoLoan],
      },
      {
        label: "Moving",
        targets: [STEP_INDICES.Moving],
      },
      {
        label: "Recap",
        targets: [STEP_INDICES.Recap],
      },
    ],
  },
  {
    label: "Overview",
    steps: [],
  },
  {
    label: "Implement",
    steps: [],
  },
];

interface LeftSidebarProps {
  stepIndex: number;
  handleClick: (index: number) => void;
}

const LeftSidebar = ({ stepIndex, handleClick }: LeftSidebarProps) => {
  const timelineContainerRef = useRef<HTMLDivElement>(null);
  const plan = useSelector(getCurrentPlan);
  const isImplemented = useSelector(isCurrentPlanImplemented);

  const progressHeight = useMemo(() => {
    if (timelineContainerRef.current) {
      const lastStepElement = Array.from(
        timelineContainerRef.current.querySelectorAll(".timeline-step")
      ).find((element) => {
        const targetIndices = (element.getAttribute("data-target") || "").split(
          ","
        );
        return targetIndices.includes("" + stepIndex);
      });
      if (lastStepElement) {
        const stepRect = lastStepElement.getBoundingClientRect();
        const timelineRect = timelineContainerRef.current?.getBoundingClientRect();
        return stepRect.top + stepRect.height / 2 - (timelineRect?.top || 0);
      }
      return 0;
    }
    return 0;
  }, [stepIndex]);

  return (
    <div className="side-bar-wrapper">
      <EuiText className="side-bar-header">
        <h2>{plan?.name || "AI Financial Plan"}</h2>
        <EuiBadge>{isImplemented ? "Implemented" : "Not Implemented"}</EuiBadge>
      </EuiText>
      <StyledSpacer size="32px" />
      <div className="relative">
        <div className="progress-bar-container">
          <ProgressBarFilled height={progressHeight} />
        </div>
        <div ref={timelineContainerRef}>
          <EuiTimeline className="timeline">
            {sections.map((section, sectionIndex) => {
              const isSectionActive = section.steps.some(
                (step) => step.targets[0] <= stepIndex
              );
              return (
                <React.Fragment key={section.label}>
                  <EuiTimelineItem
                    icon={
                      <div
                        className="step-number"
                        style={{
                          background:
                            sectionIndex === 0 ? "#0077CC" : "#98A2B3",
                        }}
                      >
                        {sectionIndex + 1}
                      </div>
                    }
                    verticalAlign="center"
                    className="section-item"
                    onClick={
                      sectionIndex === 0
                        ? () => handleClick(STEP_INDICES.Introduction)
                        : undefined
                    }
                  >
                    <EuiText
                      style={{
                        color: sectionIndex < 1 ? "#343741" : undefined,
                      }}
                      className={isSectionActive ? "active-section-label" : ""}
                    >
                      <b>{section.label}</b>
                    </EuiText>
                  </EuiTimelineItem>
                  {section.steps.map((step) => {
                    const isActive = step.targets.includes(stepIndex);
                    const isVisited =
                      (plan.progress || 0) >=
                      (progressSteps as any)[step.targets[0]];
                    return (
                      <EuiTimelineItem
                        key={step.label}
                        className={`timeline-step ${
                          isVisited ? "visited-step" : ""
                        }`}
                        icon={
                          <EuiIcon
                            type="dot"
                            color={
                              isActive || isVisited ? "#0073e6" : "#98A2B3"
                            }
                            className={isVisited ? "visited-step-icon" : ""}
                          />
                        }
                        iconAriaLabel={`Step ${step.targets[0]}`}
                        data-target={step.targets.join(",")}
                        onClick={() =>
                          isVisited && handleClick(step.targets[0])
                        }
                      >
                        <EuiText
                          className={isActive ? "visited-step" : ""}
                          style={{
                            color:
                              isActive || isVisited ? "#343741" : "#69707d",
                            fontWeight: isActive ? "bold" : "normal",
                          }}
                        >
                          {step.label}
                        </EuiText>
                      </EuiTimelineItem>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </EuiTimeline>
        </div>
      </div>
    </div>
  );
};

export default LeftSidebar;
