import * as React from "react";
import DashboardLayout from "src/layouts/DashboardLayout";
import { Content } from "./Content";

const RiskManagement = () => {
  return (
    <DashboardLayout pageTitle="Risk Management">
      <Content />
    </DashboardLayout>
  );
};

export default RiskManagement;
