import React from "react";
import { useHistory } from "react-router-dom";
import { EuiButton, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeaderTitle, EuiModalHeader, EuiIcon } from "@elastic/eui";
import { StyledEuiButton } from "../Global/StyledComponents";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";

const modalStyles = css`
  .modal-body {
    padding: 0 16px;
    p {
      font-size: 16px;
      font-family: "Inter", sans-serif;
    }
    .euiModalBody__overflow {
      padding-inline: 0;
    }
  }
`;

export const StyledEuiModalHeader = styled(EuiModalHeader)`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledEuiIcon = styled(EuiIcon)`
  width: 24px;
  height: 24px;
`;

export const cashMessages = [
    "We do not track contributions to savings and checking. Instead, we take the balance of your savings and checking accounts at the beginning of the period and compare it to the ending balance for these accounts.",
    "If your accounts are linked, be sure to visit the Accounts page to ensure the links are still connected. If you have manually added your savings and checking accounts, be sure to update the balances each month.",
    "Click below to visit your Accounts page.",
];

export const CashDialog = ({
    visible,
    title,
    confirmButton,
    cancelButton,
    onClose,
}: any) => {
    const history = useHistory();

    const handleConfirm = () => {
        history.push("/accounts");
        if (onClose) {
            onClose();
        }
    };

    return (
        <>
            <Global styles={modalStyles} />
            {visible && (
                <EuiModal
                    onClose={onClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ maxWidth: 600 }}
                >
                    <StyledEuiModalHeader>
                        <EuiModalHeaderTitle id="alert-dialog-title">{title}</EuiModalHeaderTitle>
                        <StyledEuiIcon
                            type="cross"
                            onClick={onClose}
                            style={{
                                cursor: "pointer",
                            }}
                        />
                    </StyledEuiModalHeader>
                    <EuiModalBody className="modal-body">
                        {cashMessages.map((message: string, index: number) => (
                            <p key={index} style={{ marginBottom: index < cashMessages.length - 1 ? '1em' : '0' }}>
                                {message}
                            </p>
                        ))}
                    </EuiModalBody>
                    <EuiModalFooter>
                        <StyledEuiButton color="text" onClick={onClose}>
                            {cancelButton || "Cancel"}
                        </StyledEuiButton>
                        <EuiButton fill onClick={handleConfirm}>
                            {confirmButton || "Go to Accounts Page"}
                        </EuiButton>
                    </EuiModalFooter>
                </EuiModal>
            )}
        </>
    );
};

export default CashDialog;
