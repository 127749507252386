import {
  all,
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import {
  clearActionItemApi,
  dismissPlanNotificationsApi,
  fetchActionItemsApi,
  hideActionItemApi,
  fetchCallsApi,
  fetchPlanNotificationsApi,
  getMessagesApi,
  sendMessageApi,
  markMessageReadApi,
  getEmergencyFundTargetApi,
  getExpenseSummaryApi,
  getExpenseYearSummaryApi,
  getIdrAccountsApi,
  setIdrAccountsApi,
  getIdrAssumptionsApi,
  setIdrAssumptionsApi,
  getIdrOverviewApi,
  getIncomeSummaryApi,
  getIncomeYearSummaryApi,
  // getLifeInsuranceLiabilitiesApi,
  getDealsApi,
  getEducationYearlySummaryApi,
  getScoreApi,
  getScoreRanksApi,
  GetScoreResponse,
  GetScoreRanksResponse,
  getRiskSummaryApi,
  getRiskYearSummaryApi,
  getTransactionSummaryApi,
  getTransactionYearlySummaryApi,
  getWholePlanSummaryApi,
  getMiscStateApi,
  setMiscStateApi,
} from "src/apiService";
import { getPlanNotifications as selectPlanNotifications } from "./selector";
import { fetchCashflowItems, FETCH_CASHFLOW_ITEMS } from "../cashflow/actions";
import { fetchPlans, FETCH_PLANS } from "../planBuild/actions";
import {
  fetchHousehold,
  fetchProfile,
  getProfileProgress,
} from "../profileBuild/actions";
import { getAccountProviders, getAccounts } from "../account/actions";
import { getPerformance, getPortfolio } from "../investments/actions";
import { getUnconfirmedTransactions } from "../transaction/actions";
import { FAIL, START, SUCCESS } from "../common";
import { FbAction } from "src/interfaces";
import * as actions from "./actions";

export function* loadDashboardData(): any {
  try {
    yield all([
      put(fetchHousehold()),
      put(getAccounts()),
      put(getProfileProgress()),
      put(actions.getScore()),
      put(fetchPlans()),
      put(fetchProfile()),
      put(actions.getMessages()),
      put(getUnconfirmedTransactions()),
      put(getAccountProviders()),
      put(actions.getMiscState()),
      put(actions.getCalls()),
      put(actions.getPlanNotifications()),
      put(getPortfolio()),
      put(getPerformance()),
      put(actions.fetchActionItems()),
    ]);
    yield take(FETCH_PLANS + SUCCESS);
    const [
      // idealLoanRate,
      // lifeInsuranceLiabilities,
      emergencyFundTarget,
      marketingDeals,
    ] = yield all([
      // call(getIdealLoanRateApi),
      // call(getLifeInsuranceLiabilitiesApi),
      call(getEmergencyFundTargetApi),
      call(getDealsApi),
    ]);
    yield put(fetchCashflowItems());
    yield take(FETCH_CASHFLOW_ITEMS + SUCCESS);
    yield put({
      type: actions.LOAD_DASHBOARD_DATA + SUCCESS,
      payload: {
        // idealLoanRate,
        // lifeInsuranceLiabilities,
        emergencyFundTarget,
        marketingDeals: marketingDeals.deals,
      },
    });
  } catch (error) {
    yield put({ type: actions.LOAD_DASHBOARD_DATA + FAIL, payload: error });
  }
}

export function* getScore() {
  try {
    const data: GetScoreResponse = yield call(getScoreApi);
    yield put({ type: actions.GET_SCORE + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actions.GET_SCORE + FAIL, payload: error });
  }
}

export function* getScoreRanks() {
  try {
    const data: GetScoreRanksResponse = yield call(getScoreRanksApi);
    yield put({ type: actions.GET_SCORE_RANKS + SUCCESS, payload: data.ranks });
  } catch (error) {
    yield put({ type: actions.GET_SCORE_RANKS + FAIL, payload: error });
  }
}

function* getIdrData({
  payload,
}: FbAction<{ applicant: boolean; spouse: boolean }>): any {
  try {
    const [
      myAccounts,
      myAssumptions,
      myOverview,
      spouseAccounts,
      spouseAssumptions,
      spouseOverview,
    ] = yield all([
      payload.applicant ? call(getIdrAccountsApi, { who: "applicant" }) : null,
      payload.applicant
        ? call(getIdrAssumptionsApi, { who: "applicant" })
        : null,
      payload.applicant ? call(getIdrOverviewApi, { who: "applicant" }) : null,
      payload.spouse ? call(getIdrAccountsApi, { who: "spouse" }) : null,
      payload.spouse ? call(getIdrAssumptionsApi, { who: "spouse" }) : null,
      payload.spouse ? call(getIdrOverviewApi, { who: "spouse" }) : null,
    ]);
    yield put({
      type: actions.GET_IDR_DATA + SUCCESS,
      payload: {
        myAccounts,
        myAssumptions,
        myOverview,
        spouseAccounts,
        spouseAssumptions,
        spouseOverview,
      },
    });
  } catch (error) {
    yield put({ type: actions.GET_IDR_DATA + FAIL, payload: error });
  }
}

function* setIdrDetails({ payload }: FbAction<actions.IdrDetailsPayload>) {
  try {
    const accountsPayload: any = { accountIds: payload.accountIds };
    const assumptionsPayload: any = { assumptions: payload.assumptions };
    if (payload.who) {
      accountsPayload.who = payload.who;
      assumptionsPayload.who = payload.who;
    }
    yield call(setIdrAccountsApi, accountsPayload);
    yield call(setIdrAssumptionsApi, assumptionsPayload);
    const getDataPayload = { applicant: false, spouse: false };
    if (payload?.who === "spouse") {
      getDataPayload.spouse = true;
    } else {
      getDataPayload.applicant = true;
    }
    yield getIdrData({
      type: actions.GET_IDR_DATA + START,
      payload: getDataPayload,
    });
    yield put({ type: actions.SET_IDR_DETAILS + SUCCESS, payload });
  } catch (error) {
    yield put({ type: actions.SET_IDR_DETAILS + FAIL, payload: error });
  }
}

function* getMessages() {
  try {
    const data: any[] = yield call(getMessagesApi);
    yield put({
      type: actions.GET_MESSAGES + SUCCESS,
      payload: data.reverse(),
    });
  } catch (error) {
    yield put({ type: actions.GET_MESSAGES + FAIL, payload: error });
  }
}

function* sendMessage({ payload }: FbAction<string>) {
  try {
    yield call(sendMessageApi, payload);
    yield put({ type: actions.SEND_MESSAGE + SUCCESS, payload });
  } catch (error) {
    yield put({ type: actions.SEND_MESSAGE + FAIL, payload: error });
  }
}

function* markMessageRead({ payload }: FbAction<number>) {
  try {
    yield call(markMessageReadApi, payload);
    yield put({ type: actions.MARK_MESSAGE_READ + SUCCESS, payload });
  } catch (error) {
    yield put({ type: actions.MARK_MESSAGE_READ + FAIL, payload: error });
  }
}

function* getCalls() {
  try {
    const data: any[] = yield call(fetchCallsApi);
    yield put({ type: actions.GET_CALLS + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actions.GET_CALLS + FAIL, payload: error });
  }
}

function* getPlanNotifications() {
  try {
    const data: any[] = yield call(fetchPlanNotificationsApi);
    yield put({
      type: actions.GET_PLAN_NOTIFICATIONS + SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: actions.GET_PLAN_NOTIFICATIONS + FAIL, payload: error });
  }
}

function* dismissPlanNotification({ payload }: FbAction<number>) {
  try {
    yield call(dismissPlanNotificationsApi, payload);
    yield put({ type: actions.DISMISS_PLAN_NOTIFICATION + SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actions.DISMISS_PLAN_NOTIFICATION + FAIL,
      payload: error,
    });
  }
}

function* dismissAllPlanNotifications() {
  try {
    const notifications: any[] = yield select(selectPlanNotifications);
    const calls = [];
    for (let i = 0; i < notifications.length; i++) {
      calls.push(call(dismissPlanNotificationsApi, notifications[i].id));
    }
    yield all(calls);
    yield put({ type: actions.DISMISS_ALL_PLAN_NOTIFICATIONS + SUCCESS });
  } catch (error) {
    yield put({
      type: actions.DISMISS_ALL_PLAN_NOTIFICATIONS + FAIL,
      payload: error,
    });
  }
}

function* getStudentCashflowSummaries({
  payload,
}: FbAction<{ year: number; startMonth: number; endMonth: number }>): any {
  try {
    const [incomeSummary, expenseSummary, riskSummary] = yield all([
      call(
        getIncomeYearSummaryApi,
        payload.year,
        payload.startMonth,
        payload.endMonth
      ),
      call(
        getExpenseYearSummaryApi,
        payload.year,
        payload.startMonth,
        payload.endMonth
      ),
      call(
        getRiskYearSummaryApi,
        payload.year,
        payload.startMonth,
        payload.endMonth
      ),
    ]);
    yield put({
      type: actions.GET_STUDENT_INCOME_AND_EXPENSE_SUMMARIES + SUCCESS,
      payload: { incomeSummary, expenseSummary, riskSummary },
    });
  } catch (error) {
    yield put({
      type: actions.GET_STUDENT_INCOME_AND_EXPENSE_SUMMARIES + FAIL,
      payload: error,
    });
  }
}

function* getCashflowSummaries(): any {
  try {
    const [incomeSummary, expenseSummary, riskSummary] = yield all([
      call(getIncomeSummaryApi),
      call(getExpenseSummaryApi),
      call(getRiskSummaryApi),
    ]);
    yield put({
      type: actions.GET_INCOME_AND_EXPENSE_SUMMARIES + SUCCESS,
      payload: { incomeSummary, expenseSummary, riskSummary },
    });
  } catch (error) {
    yield put({
      type: actions.GET_INCOME_AND_EXPENSE_SUMMARIES + FAIL,
      payload: error,
    });
  }
}

function* loadStudentPlanSummary({
  payload,
}: FbAction<{ year: number; startMonth: number; endMonth: number }>): any {
  try {
    const [transactionSummary, educationSummary] = yield all([
      call(
        getTransactionYearlySummaryApi,
        payload.year,
        payload.startMonth,
        payload.endMonth
      ),
      call(
        getEducationYearlySummaryApi,
        payload.year,
        payload.startMonth,
        payload.endMonth
      ),
    ]);
    yield put({
      type: actions.LOAD_STUDENT_PLAN_SUMMARY + SUCCESS,
      payload: { transactionSummary, educationSummary },
    });
  } catch (error) {
    yield put({
      type: actions.LOAD_STUDENT_PLAN_SUMMARY + FAIL,
      payload: error,
    });
  }
}

function* loadPlanSummary(): any {
  try {
    const [plan, transactions] = yield all([
      call(getWholePlanSummaryApi),
      call(getTransactionSummaryApi),
    ]);
    yield put({
      type: actions.LOAD_PLAN_SUMMARY + SUCCESS,
      payload: { plan, transactions },
    });
  } catch (error) {
    yield put({ type: actions.LOAD_PLAN_SUMMARY + FAIL, payload: error });
  }
}

function* getMiscState(): any {
  try {
    const state = yield call(getMiscStateApi);
    yield put({ type: actions.GET_MISC_STATE + SUCCESS, payload: state });
  } catch (error) {
    yield put({ type: actions.GET_MISC_STATE + FAIL, payload: error });
  }
}

function* setMiscState({ payload }: FbAction<any>): any {
  try {
    yield call(setMiscStateApi, payload);
    yield put({ type: actions.SET_MISC_STATE + SUCCESS, payload });
  } catch (error) {
    yield put({ type: actions.SET_MISC_STATE + FAIL, payload: error });
  }
}

function* fetchActionItems(): any {
  try {
    const actionItems = yield call(fetchActionItemsApi);
    yield put({
      type: actions.FETCH_ACTION_ITEMS + SUCCESS,
      payload: actionItems,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_ACTION_ITEMS + FAIL, payload: error });
  }
}

function* hideActionItem({ payload }: FbAction<string>): any {
  try {
    yield call(hideActionItemApi, payload);
    yield put({ type: actions.HIDE_ACTION_ITEM + SUCCESS, payload });
  } catch (error) {
    yield put({ type: actions.HIDE_ACTION_ITEM + FAIL, payload: error });
  }
}

function* clearActionItem({ payload }: FbAction<string>): any {
  try {
    yield call(clearActionItemApi, payload);
    yield put({ type: actions.CLEAR_ACTION_ITEM + SUCCESS });
  } catch (error) {
    yield put({ type: actions.CLEAR_ACTION_ITEM + FAIL, payload: error });
  }
}

export function* dashboardSagas() {
  yield all([
    takeLatest(actions.LOAD_DASHBOARD_DATA + START, loadDashboardData),
    takeLatest(actions.GET_SCORE + START, getScore),
    takeLatest(actions.GET_SCORE_RANKS + START, getScoreRanks),
    takeLatest(actions.GET_CALLS + START, getCalls),
    takeLatest(actions.GET_PLAN_NOTIFICATIONS + START, getPlanNotifications),
    takeLatest(
      actions.DISMISS_PLAN_NOTIFICATION + START,
      dismissPlanNotification
    ),
    takeLatest(
      actions.DISMISS_ALL_PLAN_NOTIFICATIONS + START,
      dismissAllPlanNotifications
    ),
    takeLatest(actions.GET_IDR_DATA + START, getIdrData),
    takeEvery(actions.SET_IDR_DETAILS + START, setIdrDetails),
    takeLatest(actions.GET_MESSAGES + START, getMessages),
    takeEvery(actions.SEND_MESSAGE + START, sendMessage),
    takeEvery(actions.MARK_MESSAGE_READ + START, markMessageRead),
    takeEvery(
      actions.GET_INCOME_AND_EXPENSE_SUMMARIES + START,
      getCashflowSummaries
    ),
    takeEvery(
      actions.GET_STUDENT_INCOME_AND_EXPENSE_SUMMARIES + START,
      getStudentCashflowSummaries
    ),
    takeEvery(actions.LOAD_PLAN_SUMMARY + START, loadPlanSummary),
    takeEvery(
      actions.LOAD_STUDENT_PLAN_SUMMARY + START,
      loadStudentPlanSummary
    ),
    takeLatest(actions.GET_MISC_STATE + START, getMiscState),
    takeLatest(actions.SET_MISC_STATE + START, setMiscState),
    takeLatest(actions.FETCH_ACTION_ITEMS + START, fetchActionItems),
    takeLatest(actions.HIDE_ACTION_ITEM + START, hideActionItem),
    takeLatest(actions.CLEAR_ACTION_ITEM + START, clearActionItem),
  ]);
}
