import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  EuiBasicTable,
  EuiIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiCard,
} from "@elastic/eui";

import {
  getAIPlanReviewMessages,
  getDebtProjection,
  currentPlanFutureProjections,
} from "src/store/planBuild/selector";
import { PROJECTION_DEBTS } from "src/interfaces";
import { getShortDateString, formatWholeDollars } from "src/utils";

import Chart from "src/components/ChartV2";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import ReviewMessages from "../components/ReviewMessages";
import useGoalSummaryData, { DATA_TYPES } from "./useGoalSummaryData";
import { reviewStyles } from "./GoalSummary";
import { chartLayoutStyle } from "./AssetsInvestments";
import { Global, css } from "@emotion/react";
import useWindowSize from "src/hooks/useWindowSize";
import styled from "@emotion/styled";
import { TableWrapper } from "./PayCheckAllocation";

const ScrollableTableContainer = styled.div`
  max-height: 300px; /* Adjust as needed */
  overflow-y: auto;
`;

const Debts = ({ manual }: any) => {
  const debtProjection = useSelector(getDebtProjection);
  const future = useSelector(currentPlanFutureProjections);
  const aiMessages = useSelector(getAIPlanReviewMessages);
  const eventData = useGoalSummaryData(DATA_TYPES.DEBTS);
  const [showDebtBreakdown, setShowDebtBreakdown] = useState(false);
  const windowSize = useWindowSize();
  const isStacked = windowSize.width <= 989;
  const isMobile = windowSize.width <= 567;
  const isSticky = windowSize.width >= 989;
  const toggleDebtBreakdown = () => setShowDebtBreakdown((current) => !current);

  const now = new Date();
  const nowYear = now.getFullYear();
  const years = ["Now", "" + (nowYear + 5), "" + (nowYear + 10), "" + future.retirementYear];

  const debtKeyList = Array.from(future.debtKeys as Set<string>).filter(
    (key: string) => key !== "total"
  );

  const formattedData = useMemo(() => {
    const totalRow = {
      toggle: true,
      [years[0]]: formatWholeDollars(future.debts[0].total),
      [years[1]]: formatWholeDollars(future.debts[1].total),
      [years[2]]: formatWholeDollars(future.debts[2].total),
      [years[3]]: formatWholeDollars(future.debts[3].total),
      type: "",
    };
    const output: any[] = [totalRow];
    if (showDebtBreakdown) {
      output.push(
        ...debtKeyList.map((key: string) => ({
          type: (PROJECTION_DEBTS as any)[key],
          [years[0]]: formatWholeDollars(future.debts[0][key]),
          [years[1]]: formatWholeDollars(future.debts[1][key]),
          [years[2]]: formatWholeDollars(future.debts[2][key]),
          [years[3]]: formatWholeDollars(future.debts[3][key]),
        }))
      );
    }
    return output;
  }, [debtKeyList, showDebtBreakdown, future]);

  return (
    <>
      <Global
        styles={css`
          ${reviewStyles}
          ${chartLayoutStyle}
        `}
      />
      <div className="ai-content-title">
        <h1>Debts</h1>
      </div>
      <StyledSpacer size="24px" />
      <EuiFlexGroup
        direction={isStacked ? "column" : "row"}
        alignItems="flexStart"
      >
        <EuiFlexItem
          style={isStacked ? { width: "100%" } : { flex: "auto", width: "60%" }}
        >
          <EuiCard
            title=""
            className="ai-card"
            style={{ width: "100%", height: "auto" }}
          >
            <div className="ai-card-title">
              <h1>Projected Debt</h1>
            </div>
            <Chart
              dollars
              centerTitle
              data={debtProjection}
              height={320}
              keys={["debt"]}
              xKey="month"
              noTitlePadding
              keyLabels={{
                debt: "Plan Followed",
              }}
              width="100%"
            />
          </EuiCard>
          <StyledSpacer size="24px" />
          <EuiCard
            title=""
            className="ai-card"
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "500px",
              overflow: "hidden",
            }}
          >
            <div className="ai-card-title">
              <h1>Debt Goal Progress</h1>
            </div>
            <ScrollableTableContainer>
              <EuiBasicTable
                responsive={false}
                tableLayout="fixed"
                className="table-border table-container stacked-table ai-table"
                style={{ maxWidth: "100%" }}
                items={eventData.items}
                columns={[
                  {
                    field: "label",
                    name: "Life Event",
                    render: (value: any) => (
                      <span
                        className={`custom-header ai-sub-header-np ${
                          isMobile ? "mobile-content-style" : ""
                        }`}
                      >
                        {value}
                      </span>
                    ),
                  },
                  {
                    field: "date",
                    name: isMobile ? "Date" : "Projected Date",
                    render: (value: string | undefined) => {
                      return value ? (
                        <span
                          className={`ai-sub-content ${
                            isMobile ? "mobile-content-style" : ""
                          }`}
                        >
                          {getShortDateString(value)}
                        </span>
                      ) : (
                        ""
                      );
                    },
                  },
                  {
                    field: "amount",
                    name: isMobile ? "Amount" : "Target Amount",
                    render: (value: number | undefined) => {
                      return value ? (
                        <span
                          className={`ai-sub-content ${
                            isMobile ? "mobile-content-style" : ""
                          }`}
                        >
                          {formatWholeDollars(value)}
                        </span>
                      ) : (
                        ""
                      );
                    },
                  },
                ]}
              />
            </ScrollableTableContainer>
          </EuiCard>
        </EuiFlexItem>
        <EuiFlexItem grow={2} style={{ minWidth: "35%" }}>
          <ReviewMessages messages={aiMessages.debt} manual={manual} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <StyledSpacer size="24px" />
      <EuiCard title="" className="ai-card">
        <div className="ai-card-title">
          <h1>Debt Totals</h1>
        </div>
        <TableWrapper>
          <EuiBasicTable
            responsive={false}
            tableLayout="fixed"
            className={`${isSticky ? "table-border" : ""} ai-table eg-table`}
            items={formattedData}
            rowProps={(item: any) => {
              if (item.type !== "") {
                return { style: { backgroundColor: "#FAFBFD" } };
              }
              return {};
            }}
            columns={[
              {
                field: "type",
                name: "",
                render: (value: any) => {
                  if (!value) {
                    return (
                      <div
                        onClick={toggleDebtBreakdown}
                        className="cursor-pointer"
                      >
                        <EuiIcon
                          type={showDebtBreakdown ? "arrowUp" : "arrowDown"}
                          className="mr-3"
                          style={{ cursor: "pointer", fill: "#0077CC" }}
                        />
                        <span className="custom-header">
                          Total if plan followed
                        </span>
                      </div>
                    );
                  } else if (value !== "Total if plan followed") {
                    return (
                      <span className="custom-header ai-sub-header">
                        {value}
                      </span>
                    );
                  }
                  return value;
                },
              },
              ...years
                .filter((year) => !!year)
                .map((year: string) => ({
                  field: year,
                  name: year,
                  width: isMobile ? "110px" : "",
                  render: (value: any, item: any) => {
                    if (item.type === "") {
                      return <span className="ai-header-content">{value}</span>;
                    } else {
                      return <span className="ai-sub-content">{value}</span>;
                    }
                  },
                })),
            ]}
          />
        </TableWrapper>
      </EuiCard>
      <StyledSpacer size="16px" />
    </>
  );
};

export default Debts;
