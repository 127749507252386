import React from "react";

import {
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalFooter,
  useGeneratedHtmlId,
} from "@elastic/eui";

const ConfirmCancelModal = ({
  confirm,
  onClose,
}: {
  confirm: VoidFunction;
  onClose: VoidFunction;
}) => {
  const modalTitleId = useGeneratedHtmlId();

  return (
    <EuiModal aria-labelledby={modalTitleId} onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle id={modalTitleId}>
          Are you sure you want to leave this page?
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <p>Any changes made on this page will be lost.</p>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButton onClick={onClose}>Cancel</EuiButton>
        <EuiButton fill onClick={confirm}>
          Discard Changes
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default ConfirmCancelModal;
