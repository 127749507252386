import { useDispatch, useSelector } from "react-redux";
import { cloneDeep, forOwn } from "lodash";
import { push } from "connected-react-router";

import {
  ASSET_ALLOCATIONS,
  Cashflow,
  DEBT_ALLOCATIONS,
  Earning,
  Expense,
  EXPENSE_TYPES,
  Marriage,
  Plan,
  PlanListRecord,
  RISK_MANAGEMENT_TYPES,
  SPECIAL_REPAYMENTS,
} from "src/interfaces";
import { getAllFormattedAssetsAndDebts } from "../account/selector";
import { addCashflow, editCashflow, removeCashflow } from "../cashflow/actions";
import { setPopup } from "../dashboard/actions";
import { getProfile, getSpouseProfile } from "../profileBuild/selector";
import { getIsCurrentStudent } from "../system/selector";
import { clearoutReviewSection, setCurrentPlan } from "./actions";
import { getCurrentPlan } from "./selector";

const matchWho = (value1: string, value2: string) => {
  const spouse1 = value1 === "spouse";
  const spouse2 = value2 === "spouse";
  return (spouse1 && spouse2) || (!spouse1 && !spouse2);
};

export const makePlanCopy = (originalPlan: Plan, defaultPlan: Plan) => {
  const originalAllocations: any = originalPlan.allocations[0];
  const defaultAllocations: any = defaultPlan.allocations[0];
  const newAllocations: any = { solo: originalPlan.allocations[0].solo };
  Object.keys(originalAllocations).forEach((key: any) => {
    if (key === "solo") {
      return;
    }
    if (ASSET_ALLOCATIONS[key as keyof typeof ASSET_ALLOCATIONS]) {
      newAllocations[key] = originalAllocations[key];
    } else if (defaultAllocations[key]) {
      newAllocations[key] = Math.max(
        defaultAllocations[key],
        originalAllocations[key]
      );
    }
  });
  Object.keys(defaultPlan.allocations[0]).forEach((key) => {
    if (
      !newAllocations[key] &&
      DEBT_ALLOCATIONS[key as keyof typeof DEBT_ALLOCATIONS]
    ) {
      if (defaultAllocations[key]) {
        newAllocations[key] = defaultAllocations[key];
      }
    }
  });
  const todayMs = Date.now();
  const lifeevents = originalPlan.lifeevents.filter(
    (item) => new Date(item.date).valueOf() > todayMs
  );
  const profile = defaultPlan.profile;
  profile.priority = originalPlan.profile.priority;
  const result: Plan = {
    ...cloneDeep(originalPlan),
    profile,
    incomes: defaultPlan.incomes,
    expenses: defaultPlan.expenses,
    risks: defaultPlan.risks,
    allocations: [newAllocations],
    lifeevents,
  };
  result.studentloan.forEach((item, index) => {
    item.repayplan = defaultPlan.studentloan[index].repayplan;
    item.start = defaultPlan.studentloan[index].start;
  });
  return result;
};

export const makeCashflowUpdates = (
  incomes: Earning[],
  expenses: Expense[],
  risks: Expense[],
  cashflows: Cashflow[]
) => {
  const toEdit: any[] = [];
  const toRemove: any[] = [];
  const toAdd: any[] = [];
  const debugOverview: any = {
    add: [],
    remove: [],
    edit: [],
  };
  const matchedIncomes: Set<number> = new Set([]);
  const matchedExpenses: Set<number> = new Set([]);
  const matchedRisks: Set<number> = new Set([]);
  cashflows.forEach((cashflow) => {
    let match: unknown;
    const isRisk = !!RISK_MANAGEMENT_TYPES[cashflow.type];
    const isExpense = !!EXPENSE_TYPES[cashflow.type];
    if (isRisk) {
      match = risks.find((risk, index) => {
        if (!matchedRisks.has(index) && risk.type === cashflow.type) {
          matchedRisks.add(index);
          return true;
        }
        return false;
      });
    } else if (isExpense) {
      match = expenses.find((expense, index) => {
        if (!matchedExpenses.has(index) && expense.type === cashflow.type) {
          matchedExpenses.add(index);
          return true;
        }
        return false;
      });
    } else {
      match = incomes.find((income, index) => {
        if (
          !matchedIncomes.has(index) &&
          matchWho(income.who as string, cashflow.whose as string) &&
          (income.type === cashflow.type ||
            (income.type === "other" && cashflow.type === "other_income") ||
            (income.type === "rent" && cashflow.type === "rental_income"))
        ) {
          matchedIncomes.add(index);
          return true;
        }
        return false;
      });
    }
    if (!match) {
      debugOverview.remove.push(cashflow);
      toRemove.push(removeCashflow(cashflow.id));
    } else {
      const newAmount =
        isExpense || isRisk
          ? (match as Expense).payment
          : (match as Earning).earning;
      if (newAmount !== cashflow.amount) {
        debugOverview.edit.push(cashflow);
        toEdit.push(editCashflow({ id: cashflow.id, amount: newAmount }));
      }
    }
  });
  expenses.forEach((expense, index) => {
    if (!matchedExpenses.has(index)) {
      debugOverview.add.push(expense);
      toAdd.push(
        addCashflow({
          cashflow: {
            type: expense.type === "other" ? "other_expense" : expense.type,
            amount: expense.payment,
          },
        })
      );
    }
  });
  risks.forEach((risk, index) => {
    if (!matchedRisks.has(index)) {
      debugOverview.add.push(risk);
      toAdd.push(
        addCashflow({
          cashflow: {
            type: risk.type,
            amount: risk.payment,
          },
        })
      );
    }
  });
  incomes.forEach((income, index) => {
    if (!matchedIncomes.has(index)) {
      debugOverview.add.push(income);
      let newType = income.type;
      if (newType === "other") {
        newType = "other_income";
      } else if (newType === "rent") {
        newType = "rental_income";
      }
      toAdd.push(
        addCashflow({
          cashflow: {
            type: newType,
            amount: income.earning,
            who: (income.who as string) || "applicant",
          },
        })
      );
    }
  });
  return [...toAdd, ...toEdit, ...toRemove];
};

export const fillInMissingFunding = (summary: any) => {
  const yearlyRemainingValues = summary.remaining;
  const output: any = {
    addLoans: false,
    years: {},
  };
  let runningTotal = 0;
  forOwn(yearlyRemainingValues, (value: number, year: string) => {
    if (value < runningTotal) {
      output.addLoans = true;
      const newLoanAmount = value - runningTotal;
      runningTotal += newLoanAmount;
      output.years[year] = newLoanAmount;
    }
  });
  return output;
};

// TODO put this in a hook so it can get dispatch and isCurrentStudent via its own hooks
// the complex problem here is that this is called by some other functions from ACTION_ITEMS
// which are not inside a component.
export const openPlanForEditing = (
  dispatch: any,
  isCurrentStudent: boolean,
  plan?: PlanListRecord
) => {
  if (!plan) {
    return;
  }
  dispatch(clearoutReviewSection());
  dispatch(setCurrentPlan({ planId: plan.id, keepId: false }));
  dispatch(setPopup("Build"));
  const destination = plan.questionnaire
    ? "plan-builder-optimized"
    : "/plan-builder";
  dispatch(push(destination));
};

const SAVINGS_EVENT_TYPES = [
  "marriage",
  "house",
  "property",
  "vehicle",
  "savings",
  "vacation",
];

export const usePlanUtils = () => {
  const dispatch = useDispatch();
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const currentPlan = useSelector(getCurrentPlan);
  const { debts } = useSelector(getAllFormattedAssetsAndDebts);
  const myProfile = useSelector(getProfile);
  const spouseProfile = useSelector(getSpouseProfile);
  const applicantIdr =
    SPECIAL_REPAYMENTS.indexOf(myProfile.fed_repayment_plan || "") >= 0;
  const spouseIdr =
    SPECIAL_REPAYMENTS.indexOf(spouseProfile.fed_repayment_plan || "") >= 0;

  const syncGoals = () => {
    const plan = currentPlan;
    let currentId = 0;
    for (let i = 0; i < (plan.goals || []).length; i++) {
      const goal = (plan.goals || [])[i];
      if (goal?.id > currentId) {
        currentId = goal.id;
      }
    }
    const makeId = () => {
      currentId++;
      return currentId;
    };
    const goals =
      plan.goals?.length > 2
        ? plan.goals
            .filter((goal) => {
              if (goal.account_id) {
                return debts.find(
                  (debt) =>
                    debt.id === goal.account_id &&
                    debt.balance &&
                    debt.carrying !== "n"
                );
              }
              if (goal.lifeevent_id) {
                const event = plan.lifeevents.find(
                  (event) => event.id === goal.lifeevent_id
                );
                if (
                  !event ||
                  (goal.goaltype === "down" && !(event as any).down)
                ) {
                  return false;
                }
                return true;
              }
              return true;
            })
            .map((goal) => {
              if (goal.goaltype === "taxbomb") {
                if (goal.who === "applicant") {
                  if (!applicantIdr) {
                    return {
                      ...goal,
                      goaltype: "fedloanpayoff",
                    };
                  }
                } else if (!spouseIdr) {
                  return {
                    ...goal,
                    goaltype: "fedloanpayoff",
                  };
                }
              }
              return goal;
            })
        : [{ id: makeId(), goaltype: "emergencyfund" }];
    const newSavingsGoals: any[] = [];
    const newPayoffGoals: any[] = [];
    const events = [...plan.lifeevents];
    const sortedDebts: any[] = [...debts];
    events.sort(
      (a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf()
    );
    for (let i = 0; i < events.length; i++) {
      const event = events[i];
      if (goals.find((goal) => goal.lifeevent_id === event.id)) {
        continue;
      }
      if (SAVINGS_EVENT_TYPES.includes(event.eventtype as string)) {
        if (
          (event as any).down &&
          (event as any).down <= ((event as any).cost || 0)
        ) {
          newSavingsGoals.push({
            id: makeId(),
            goaltype: "down",
            lifeevent_id: event.id,
          });
        }
        if (event.eventtype === "marriage") {
          if ((event as Marriage).spouseFedLoanBalance) {
            newPayoffGoals.push({
              id: makeId(),
              lifeevent_id: event.id,
              goaltype: applicantIdr ? "taxbomb" : "fedloanpayoff",
              who: "spouse",
            });
          }
          if ((event as Marriage).spousePrivLoanBalance) {
            newPayoffGoals.push({
              id: makeId(),
              lifeevent_id: event.id,
              goaltype: "privloanpayoff",
              who: "spouse",
            });
          }
        }
      }
      if (
        ["house", "property", "vehicle"].includes(event.eventtype as string) &&
        (event as any).cost > (event as any).down
      ) {
        sortedDebts.push({
          lifeevent_id: event.id,
          rate: 5,
          balance: 1,
        });
      }
    }
    sortedDebts.sort((a, b) => {
      if (a.rate === b.rate) {
        return (b.balance || 0) - (a.balance || 0);
      }
      return (b.rate || 0) - (a.rate || 0);
    });
    if (applicantIdr) {
      newSavingsGoals.push({
        id: makeId(),
        goaltype: "taxbomb",
        who: "applicant",
      });
    }
    if (spouseIdr) {
      newSavingsGoals.push({
        id: makeId(),
        goaltype: "taxbomb",
        who: "spouse",
      });
    }
    let myFedLoanPayoff = false;
    let spouseFedLoanPayoff = false;
    let myPrivLoanPayoff = false;
    let spousePrivLoanPayoff = false;
    let myPerkinsLoanPayoff = false;
    let spousePerkinsLoanPayoff = false;
    for (let i = 0; i < sortedDebts.length; i++) {
      const debt = sortedDebts[i];
      if (
        goals.find(
          (goal) =>
            (debt.id && goal.account_id === debt.id) ||
            (debt.lifeevent_id && goal.lifeevent_id === debt.lifeevent_id)
        )
      ) {
        continue;
      }
      if (debt.variable === "fed_loan") {
        if (debt.whose === "spouse") {
          if (!spouseIdr) {
            spouseFedLoanPayoff = true;
          }
        } else if (!applicantIdr) {
          myFedLoanPayoff = true;
        }
        continue;
      }
      if (debt.variable === "priv_loan") {
        if (debt.whose === "spouse") {
          spousePrivLoanPayoff = true;
        } else {
          myPrivLoanPayoff = true;
        }
        continue;
      }
      if (debt.variable === "perkins_loan") {
        if (debt.whose === "spouse") {
          spousePerkinsLoanPayoff = true;
        } else {
          myPerkinsLoanPayoff = true;
        }
        continue;
      }
      if ((debt.balance || debt.balance_live) && debt.carrying !== "n") {
        const newGoal: any = {
          id: makeId(),
          goaltype: "payoff",
        };
        if (debt.id) {
          newGoal.account_id = debt.id;
        } else if (debt.lifeevent_id) {
          newGoal.lifeevent_id = debt.lifeevent_id;
        }
        newPayoffGoals.push(newGoal);
      }
    }
    if (
      myFedLoanPayoff &&
      !goals.find(
        (goal) => goal.goaltype === "fedloanpayoff" && goal.who === "applicant"
      )
    ) {
      newPayoffGoals.push({
        id: makeId(),
        goaltype: "fedloanpayoff",
        who: "applicant",
      });
    }
    if (
      spouseFedLoanPayoff &&
      !goals.find(
        (goal) => goal.goaltype === "fedloanpayoff" && goal.who === "spouse"
      )
    ) {
      newPayoffGoals.push({
        id: makeId(),
        goaltype: "fedloanpayoff",
        who: "spouse",
      });
    }
    if (
      myPrivLoanPayoff &&
      !goals.find(
        (goal) => goal.goaltype === "privloanpayoff" && goal.who === "applicant"
      )
    ) {
      newPayoffGoals.push({
        id: makeId(),
        goaltype: "privloanpayoff",
        who: "applicant",
      });
    }
    if (
      spousePrivLoanPayoff &&
      !goals.find(
        (goal) => goal.goaltype === "privloanpayoff" && goal.who === "spouse"
      )
    ) {
      newPayoffGoals.push({
        id: makeId(),
        goaltype: "privloanpayoff",
        who: "spouse",
      });
    }
    if (
      myPerkinsLoanPayoff &&
      !goals.find(
        (goal) =>
          goal.goaltype === "perkinsloanpayoff" && goal.who === "applicant"
      )
    ) {
      newPayoffGoals.push({
        id: makeId(),
        goaltype: "perkinsloanpayoff",
        who: "applicant",
      });
    }
    if (
      spousePerkinsLoanPayoff &&
      !goals.find(
        (goal) => goal.goaltype === "perkinsloanpayoff" && goal.who === "spouse"
      )
    ) {
      newPayoffGoals.push({
        id: makeId(),
        goaltype: "perkinsloanpayoff",
        who: "spouse",
      });
    }
    goals.push(...newSavingsGoals, ...newPayoffGoals);
    if (!goals.find((goal) => goal.goaltype === "invest")) {
      goals.push({
        id: makeId(),
        goaltype: "invest",
      });
    }
    return goals;
  };

  const _openPlanForEditing = (plan: PlanListRecord) => {
    openPlanForEditing(dispatch, isCurrentStudent, plan);
  };

  return {
    syncGoals,
    openPlanForEditing: _openPlanForEditing,
  };
};
