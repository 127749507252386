import React from "react";
import { useSelector } from "react-redux";

import AssetSummary from "../Components/AssetSummary";
import Chart from "src/components/ChartV2";
import {
  getDashboardAssetProjection,
  getLiveProjection,
} from "src/store/planBuild/selector";
import { getHasPlan } from "src/store/system/selector";
import { chartData } from "../data";
import {
  EuiFlexGroup,
  EuiButtonIcon,
  EuiCard,
  EuiFlexItem,
} from "@elastic/eui";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import { useFlyout } from "src/components/Global/FlyoutContext";

const Summary = () => {
  const hasPlan = useSelector(getHasPlan);
  const assetProjection = useSelector(getDashboardAssetProjection);
  const liveProjection = useSelector(getLiveProjection);
  const { toggleFlyout } = useFlyout();

  return (
    <div className="hc-tab">
      <div className="hc-desc">
        <div className="hc-desc-text">
          <>
            <h1>
              Understanding Financial Assets
              <EuiButtonIcon
                iconType="questionInCircle"
                aria-label="Icon button"
                size="m"
                iconSize="l"
                onClick={() => toggleFlyout("asset-summary")}
              />
            </h1>
          </>
        </div>
        <StyledSpacer size="16px" />
        <p>
          Over time, your Human Capital will convert into additional financial
          assets to consider as you plan for retirement. The key is to ensure
          the appropriate asset allocation across the board to meet your goals
          and income needs throughout retirement.
        </p>
      </div>
      <StyledSpacer size="32px" />
      <EuiFlexGroup>
        <EuiFlexItem>
          <AssetSummary />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            title=""
            className="ai-card"
            style={{ width: "100%", height: "auto" }}
          >
            <div className="ai-card-title">
              <h1>Projected Financial Assets</h1>
            </div>
            {!hasPlan && (
              <Chart
                title="Projected Assets"
                isLocked
                data={chartData}
                xKey="name"
                keys={["uv", "pv", "amt"]}
                lockedButton={{
                  label: "Build and Implement My Plan",
                  to: "/build-plan",
                }}
                width="100%"
              />
            )}
            {hasPlan && (
              <Chart
                dollars
                title="Projected Assets"
                data={assetProjection}
                xKey="month"
                keys={["asset"]}
                keyLabels={{
                  asset: "Current Projected",
                }}
                startMonth={
                  liveProjection ? +liveProjection.start.slice(5) : undefined
                }
                startYear={
                  liveProjection ? +liveProjection.start.slice(0, 4) : undefined
                }
                width="100%"
              />
            )}
          </EuiCard>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};

export default Summary;
