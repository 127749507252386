// External libraries
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  EuiBasicTable,
  EuiIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiCard,
} from "@elastic/eui";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";

import {
  getAIPlanReviewMessages,
  getAssetProjection,
  currentPlanFutureProjections,
} from "src/store/planBuild/selector";
import { PROJECTION_ASSETS } from "src/interfaces";
import { getShortDateString, formatWholeDollars } from "src/utils";
import Chart from "src/components/ChartV2";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import useGoalSummaryData, { DATA_TYPES } from "./useGoalSummaryData";
import ReviewMessages from "../components/ReviewMessages";
import useWindowSize from "src/hooks/useWindowSize";
import { TableWrapper } from "./PayCheckAllocation";
import bot from "src/assets/svgs/bot.svg";
import { colors } from "src/theme";
import { reviewStyles } from "./GoalSummary";

export const chartLayoutStyle = css`
  .ai-main {
    overflow: hidden !important;
  }
`;

const StyledBox = styled.div`
  background-color: ${colors.backgroundInfo};
  padding: 12px 16px;
  margin-bottom: 16px;
  span {
    text-decoration: underline;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    font-family: "Inter";
    margin-bottom: 1rem;
  }
`;

const TitleText = styled.h4`
  color: ${colors.info} !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const ScrollableTableContainer = styled.div`
  max-height: 300px; /* Adjust as needed */
  overflow-y: auto;
`;

const AssetsInvestments = ({ manual }: any) => {
  const assetProjection = useSelector(getAssetProjection);
  const future = useSelector(currentPlanFutureProjections);
  const aiMessages = useSelector(getAIPlanReviewMessages);
  const eventData = useGoalSummaryData(DATA_TYPES.ASSETS);
  const [showAssetBreakdown, setShowAssetBreakdown] = useState(false);
  const windowSize = useWindowSize();
  const isStacked = windowSize.width <= 989;
  const isMobile = windowSize.width <= 567;
  const isSticky = windowSize.width >= 989;
  const toggleAssetBreakdown = () =>
    setShowAssetBreakdown((current) => !current);
  const retirementTotal = assetProjection.length
    ? assetProjection[assetProjection.length - 1].asset
    : 0;

  const now = new Date();
  const nowYear = now.getFullYear();
  const years = ["Now", "" + (nowYear + 5), "" + (nowYear + 10), "" + future.retirementYear];

  const assetKeyList = Array.from(future.assetKeys as Set<string>).filter(
    (key: string) => key !== "total"
  );

  const formattedData = useMemo(() => {
    const totalRow = {
      toggle: true,
      [years[0]]: formatWholeDollars(future.assets[0].total),
      [years[1]]: formatWholeDollars(future.assets[1].total),
      [years[2]]: formatWholeDollars(future.assets[2].total),
      [years[3]]: formatWholeDollars(future.assets[3].total),
      type: "",
    };
    const output: any[] = [totalRow];
    if (showAssetBreakdown) {
      output.push(
        ...assetKeyList.map((key: string) => ({
          type: (PROJECTION_ASSETS as any)[key],
          [years[0]]: formatWholeDollars(future.assets[0][key]),
          [years[1]]: formatWholeDollars(future.assets[1][key]),
          [years[2]]: formatWholeDollars(future.assets[2][key]),
          [years[3]]: formatWholeDollars(future.assets[3][key]),
        }))
      );
    }
    return output;
  }, [assetKeyList, showAssetBreakdown, future]);

  return (
    <>
      <Global
        styles={css`
          ${reviewStyles}
          ${chartLayoutStyle}
        `}
      />
      <div className="ai-content-title">
        <h1>Assets/Investments</h1>
      </div>
      <StyledSpacer size="24px" />
      <EuiFlexGroup
        direction={isStacked ? "column" : "row"}
        alignItems="flexStart"
      >
        <EuiFlexItem
          style={isStacked ? { width: "100%" } : { flex: "auto", width: "60%" }}
        >
          <EuiCard
            title=""
            className="ai-card"
            style={{ width: "100%", height: "auto" }}
          >
            <div className="ai-card-title">
              <h1>Projected Financial Assets</h1>
            </div>
            <Chart
              dollars
              data={assetProjection}
              height={320}
              keys={["asset"]}
              xKey="month"
              noTitlePadding
              keyLabels={{
                asset: "Plan Followed",
              }}
              width="100%"
            />
          </EuiCard>
          <StyledSpacer size="24px" />
          <EuiCard
            title=""
            className="ai-card"
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "500px",
              overflow: "hidden",
            }}
          >
            <div className="ai-card-title">
              <h1>Life Event Progress</h1>
            </div>
            <ScrollableTableContainer>
              <EuiBasicTable
                className="table-border table-container stacked-table ai-table"
                style={{ maxWidth: "100%" }}
                responsive={false}
                tableLayout="fixed"
                items={eventData.items}
                columns={[
                  {
                    field: "label",
                    name: "Life Event",
                    render: (value: any) => (
                      <span
                        className={`custom-header ai-sub-header-np ${
                          isMobile ? "mobile-content-style" : ""
                        }`}
                      >
                        {value}
                      </span>
                    ),
                  },
                  {
                    field: "date",
                    name: isMobile ? "Date" : "Projected Date",
                    render: (value: string | undefined) => {
                      return value ? (
                        <span
                          className={`ai-sub-content ${
                            isMobile ? "mobile-content-style" : ""
                          }`}
                        >
                          {getShortDateString(value)}
                        </span>
                      ) : (
                        ""
                      );
                    },
                  },
                  {
                    field: "amount",
                    name: isMobile ? "Amount" : "Target Amount",
                    render: (value: number | undefined) => {
                      return value ? (
                        <span
                          className={`ai-sub-content ${
                            isMobile ? "mobile-content-style" : ""
                          }`}
                        >
                          {formatWholeDollars(value)}
                        </span>
                      ) : (
                        ""
                      );
                    },
                  },
                ]}
              />
            </ScrollableTableContainer>
          </EuiCard>
        </EuiFlexItem>
        <EuiFlexItem grow={2} style={{ minWidth: "35%" }}>
          <StyledBox>
            <EuiFlexGroup className="items-center no-wrap" gutterSize="s">
              <EuiFlexItem
                grow={0}
                className="align-l"
                style={{ inlineSize: "auto", flexBasis: "auto" }}
              >
                <img height={33} src={bot} />
              </EuiFlexItem>
              <EuiFlexItem grow={2} className="align-l">
                <TitleText>Maximize Your Tangible Net Wealth</TitleText>
              </EuiFlexItem>
            </EuiFlexGroup>
            <StyledSpacer size="16px" />
            <EuiText>
              <span>Your Plan</span>
              <p>
                Following this plan will provide you with{" "}
                {formatWholeDollars(retirementTotal)} at retirement.
              </p>
            </EuiText>
            <StyledSpacer size="16px" />
            <EuiText>
              <span>Your Investments</span>
              <p>
                After you implement your plan, you'll be shown your recommended
                investment allocation on your dashboard.
              </p>
            </EuiText>
          </StyledBox>
          <ReviewMessages messages={aiMessages.assets} manual={manual} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <StyledSpacer size="24px" />
      <EuiCard
        title=""
        className="ai-card"
        style={{ maxHeight: "500px", overflow: "hidden" }}
      >
        <div className="ai-card-title">
          <h1>Financial Assets</h1>
        </div>
        <TableWrapper>
          <EuiBasicTable
            className={`${isSticky ? "table-border" : ""} ai-table eg-table`}
            responsive={false}
            tableLayout="fixed"
            items={formattedData}
            rowProps={(item: any) => {
              if (item.type !== "") {
                return { style: { backgroundColor: "#FAFBFD" } };
              }
              return {};
            }}
            columns={[
              {
                field: "type",
                name: "",
                width: isMobile ? "150px" : "",
                render: (value: any) => {
                  if (!value) {
                    return (
                      <div
                        onClick={toggleAssetBreakdown}
                        className="cursor-pointer"
                      >
                        <EuiIcon
                          type={showAssetBreakdown ? "arrowUp" : "arrowDown"}
                          className="mr-3"
                          style={{ cursor: "pointer", fill: "#0077CC" }}
                        />
                        <span className="custom-header">
                          Total if plan followed
                        </span>
                      </div>
                    );
                  } else if (value !== "Total if plan followed") {
                    return (
                      <span className="custom-header ai-sub-header">
                        {value}
                      </span>
                    );
                  }
                  return value;
                },
              },
              ...years
                .filter((year) => !!year)
                .map((year: string) => ({
                  field: year,
                  name: year,
                  width: isMobile ? "110px" : "",
                  render: (value: any, item: any) => {
                    if (item.type === "") {
                      return <span className="ai-header-content">{value}</span>;
                    } else {
                      return <span className="ai-sub-content">{value}</span>;
                    }
                  },
                })),
            ]}
          />
        </TableWrapper>
      </EuiCard>
      <StyledSpacer size="16px" />
    </>
  );
};

export default AssetsInvestments;
