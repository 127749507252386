import React from "react";

import { EuiIcon, EuiTitle } from "@elastic/eui";
import styled from "@emotion/styled";

import { StyledSpacer } from "src/components/Global/StyledComponents";
import useWindowSize from "src/hooks/useWindowSize";
import { colors } from "src/theme";

const WarningBox = styled.div`
  font-size: 16px;
  background-color: ${colors.backgroundWarning};
  padding: 8px 16px;
  margin-bottom: 10px;
  p {
    margin-bottom: 1rem;
  }
`;

const Introduction = () => {
  const { width } = useWindowSize();
  const titleText =
    width <= 576
      ? "Welcome to Your Optimized Plan"
      : "Welcome to Your AI-Powered Plan";

  return (
    <>
      <EuiTitle className="ai-content-title">
        <h1>{titleText}</h1>
      </EuiTitle>
      <StyledSpacer size="32px" />
      <WarningBox>
        <h3 className="font-semibold mb-2 flex items-center">
          <EuiIcon type="warning" className="mr-2" color={colors.warningText} />
          Negative Cash Flow
        </h3>
        <p>
          It looks like you have a negative cash flow, so our AI can't run your
          financial plan builder at the moment. Please navigate to the Day To
          Day Money section to update your income/expenses and/or the debt
          section of your profile and update your monthly debt payments. Once
          your cash flow is positive, you'll be able to use the AI financial
          plan builder. If you need any assistance, please reach out to your
          FitBUX Expert.
        </p>
        <p>
          If you've already updated your profile and still have negative cash
          flow, then your goal should be to reduce one expense for 40 days. For
          example, if you spend $500 per month eating out, reduce it to $350. Do
          this for 4 - 6 months. If you need help discussing this further, be
          sure to reach out to your FitBUX Expert.
        </p>
      </WarningBox>
    </>
  );
};

export default Introduction;
