import React from "react";
import { useSelector } from "react-redux";
import DashboardLayout from "src/layouts/DashboardLayout";
import NoPlanSummary from "./NoPlanSummary";
import PlanSummary from "./PlanSummary";
import { getHasPlan } from "src/store/system/selector";

const PlanSummaryContainer = () => {
  const hasPlan = useSelector(getHasPlan);

  return (
    <DashboardLayout pageTitle="Plans">
        {hasPlan ? <PlanSummary /> : <NoPlanSummary />}
    </DashboardLayout>
  );
};

export default PlanSummaryContainer;
