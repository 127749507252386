import React from "react";
import { useSelector } from "react-redux";

import NetWealthChart from "../Components/NetWealthChart";
import Chart from "src/components/ChartV2";
import {
  getDashboardNetProjection,
  getLiveProjection,
} from "src/store/planBuild/selector";
import { getHasPlan } from "src/store/system/selector";
import { chartData } from "../data";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import {
  EuiFlexGroup,
  EuiButtonIcon,
  EuiCard,
  EuiFlexItem,
} from "@elastic/eui";
import { useFlyout } from "src/components/Global/FlyoutContext";

const Summary = () => {
  const hasPlan = useSelector(getHasPlan);
  const netProjection = useSelector(getDashboardNetProjection);
  const liveProjection = useSelector(getLiveProjection);
  const { toggleFlyout } = useFlyout();

  return (
    <div className="hc-tab">
      <div className="hc-desc">
        <div className="hc-desc-text">
          <>
            <h1>
              Tracking Your Net Wealth
              <EuiButtonIcon
                iconType="questionInCircle"
                aria-label="Icon button"
                size="m"
                onClick={() => toggleFlyout("net-wealth")}
                iconSize="l"
              />
            </h1>
          </>
        </div>
        <StyledSpacer size="16px" />
        <div className="nw-secondary-text">
          <p>Tracking your net wealth helps you visualize:</p>
          <StyledSpacer size="8px" />
          <ul>
            <li>
              1. How you are converting your intangible wealth (i.e., your human
              capital) into tangible financial wealth over time.
            </li>
            <li>
              2. Whether you are on track to achieve your retirement goals.
            </li>
          </ul>
        </div>
        <StyledSpacer size="32px" />
      </div>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiCard
            title=""
            className="nw-card"
            style={{ width: "100%", height: "auto" }}
          >
            <div className="nw-card-title">
              <h1>Total Net Wealth</h1>
            </div>
            <NetWealthChart />
          </EuiCard>
        </EuiFlexItem>
        <EuiFlexItem>
          {!hasPlan && (
            <EuiCard
              title=""
              className="ai-card"
              style={{ width: "100%", height: "auto" }}
            >
              <div className="ai-card-title">
                <h1>Projected Total Net Wealth</h1>
              </div>
              <Chart
                title="Projected Net Wealth"
                isLocked
                data={chartData}
                xKey="name"
                keys={["uv", "pv", "amt"]}
                lockedButton={{
                  label: "Build and Implement My Plan",
                  to: "/build-plan",
                }}
                width="100%"
              />
            </EuiCard>
          )}
          {hasPlan && (
            <EuiCard
              title=""
              className="ai-card"
              style={{ width: "100%", height: "auto" }}
            >
              <div className="ai-card-title">
                <h1>Projected Total Net Wealth</h1>
              </div>
              <Chart
                dollars
                title="Projected Net Wealth"
                data={netProjection}
                xKey="month"
                keys={["net"]}
                keyLabels={{
                  net: "Current Projected",
                }}
                startMonth={
                  liveProjection ? +liveProjection.start.slice(5) : undefined
                }
                startYear={
                  liveProjection ? +liveProjection.start.slice(0, 4) : undefined
                }
                width="100%"
              />
            </EuiCard>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};

export default Summary;
