import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
} from "@material-ui/core";

import CenterContainer from "../../../Components/CenterContainer";
import Button from "src/components/Button";
import Radio from "src/components/Radio";
import { MAX_401K_ANNUAL_CONTRIBUTION } from "src/constants";
import {
  savePlan,
  updateCurrentPlan,
  estimateCurrentPlanTaxes,
} from "src/store/planBuild/actions";
import { EMPTY_RETIREMENT } from "src/store/planBuild/constants";
import {
  currentPlanIncomeTotal,
  getCurrentPlan,
  getMy401kEligibleIncome,
  getSpouse401kEligibleIncome,
} from "src/store/planBuild/selector";
import { Plan } from "src/interfaces/plan.interface";
import { PlanViewComponent } from "src/interfaces/viewComponent.interface";
import { SidebarContent } from "src/interfaces";
import {
  formatAnnually,
  formatMonthly,
  formatPercent,
  formatWholeDollars,
  PercentTextField,
} from "src/utils";
import { useStyles } from "../styles";
import { CURATED_PLAN_BUILD_STEPS } from "../common";
import ToolTipIcon from "src/components/ToolTipIcon";

// TODO: clean up this file and consolidate with ../AddEdit401k

const employerBlowout: SidebarContent[] = [
  {
    header: "Retirement Savings Matching",
    body: [
      "FitBUX highly recommends taking advantage of your employer retirement match, if available.",
      "Employer matching is a contribution amount your employer makes to your retirement.",
      "For example, if you make $70,000 per year and your employer has a 3% match, if you contribute $2,100 per year to your retirement, your employer will also contribute $2,100.",
      "In short, your match is 'free' money.  That is why we recommend contributing at least the minimum amount to your retirement to trigger any company match available to you.",
    ],
  },
  {
    header: "Eligible Accounts",
    body: [
      "Below is a list of accounts and which type of employees qualify for them:",
      "1) 401k: Employees of for profit companies",
      "2) 403b: Employees of non-profit companies",
      "3) 457b: Employees of state and local governments",
      "4) TSP: Employees of the Federal government",
    ],
  },
  {
    header: "Should I do a Roth 401k?",
    body: [
      "We recommend that  you save both in a 401k and Roth 401k, if offered as they each provide different benefits. ",
    ],
  },
];

const AddOrEdit401kSpouse: PlanViewComponent = ({
  // initialValues,
  onClose,
  onSave,
  render,
  type,
  curatedIndex,
}) => {
  const curatedFlow =
    curatedIndex === CURATED_PLAN_BUILD_STEPS.EMPLOYER_RETIREMENT_SPOUSE;
  const dispatch = useDispatch();
  const styles = useStyles();
  const plan: Plan = useSelector(getCurrentPlan);
  const myEligibleIncome = useSelector(getMy401kEligibleIncome);
  const eligibleIncome = useSelector(getSpouse401kEligibleIncome);
  const totalIncome = useSelector(currentPlanIncomeTotal);
  const initialFormValues: any = {
    additional_match: "n",
    additional_amount_match: "",
    additional_match_percent: 50,
    base_amount_matched: "",
    base_match_percent: 100,
    contribution: "",
    who: "spouse",
    employer_offered: curatedFlow ? "n" : "y",
    "401k": "n",
    "401k_percent": "",
    auto_question: "n",
    auto_match_percent: "",
  };
  const retirement = plan.retirement[1];
  const soloAllocation = plan.allocations[0].solo[1];
  initialFormValues.contribution =
    soloAllocation[type as "401k_value" | "roth_401k_value"];
  initialFormValues.base_amount_matched = retirement.match_limit;
  initialFormValues.base_match_percent = retirement.match_percent;
  initialFormValues.additional_match = retirement.additional_limit ? "y" : "n";
  initialFormValues.additional_amount_match = retirement.additional_limit;
  initialFormValues.additional_match_percent = retirement.additional_percent;
  const [formValues, setFormValues] = useState<any>(initialFormValues);

  const totalMaxPercent = (MAX_401K_ANNUAL_CONTRIBUTION / eligibleIncome) * 100;
  const maxPercent = totalMaxPercent - (formValues["401k_percent"] || 0);
  const rothMaxPercent = totalMaxPercent - (formValues.contribution || 0);

  const setFormValue = (e: React.ChangeEvent<any>) => {
    const field = e.target.name;
    const value = e.target.value;
    const newFormValues = { ...formValues, [field]: value };
    if (field === "who") {
      const itemIndex = value === "spouse" ? 1 : 0;
      const retirement = plan.retirement[itemIndex];
      newFormValues.contribution = undefined;
      newFormValues.base_amount_matched = retirement.match_limit;
      newFormValues.base_match_percent = retirement.match_percent;
      if (retirement.additional_limit) {
        newFormValues.additional_match = "y";
        newFormValues.additional_amount_match = retirement.additional_limit;
        newFormValues.additional_match_percent = retirement.additional_percent;
      }
    }
    setFormValues(newFormValues);
  };

  const spouses = formValues.who === "spouse" ? "spouse's " : "";

  const dollarContribution =
    ((formValues.contribution + formValues["401k_percent"]) / 100) *
    eligibleIncome;

  const totalContribution = (dollarContribution / totalIncome || 0) * 100;

  const save = () => {
    const newRetirement = [...plan.retirement];
    const whoIndex = formValues.who === "applicant" ? 0 : 1;
    const retirementItem = {
      ...EMPTY_RETIREMENT,
      match_limit: formValues.base_amount_matched,
      match_percent: formValues.base_match_percent,
    };
    if (formValues.additional_match === "y") {
      retirementItem.additional_limit =
        +formValues.additional_amount_match || 0;
      retirementItem.additional_percent =
        +formValues.additional_match_percent || 0;
    }
    if (formValues.auto_question === "y") {
      retirementItem.nonelective_limit = +formValues.auto_match_percent || 0;
    }
    newRetirement[whoIndex] = retirementItem;
    const soloAllocations = plan.allocations[0].solo;
    const newSoloAllocations = [...soloAllocations];
    newSoloAllocations[1] = {
      ...soloAllocations[1],
      "401k_value": formValues.contribution || 0,
      roth_401k_value: formValues["401k_percent"] || 0,
    };
    const newAllocations = [...plan.allocations];
    const combinedContribution401k =
      ((newSoloAllocations[0]["401k_value"] || 0) / 100) * myEligibleIncome +
      ((newSoloAllocations[1]["401k_value"] || 0) / 100) * eligibleIncome;
    const combinedContributionRoth =
      ((newSoloAllocations[0]["roth_401k_value"] || 0) / 100) *
        myEligibleIncome +
      ((newSoloAllocations[1]["roth_401k_value"] || 0) / 100) * eligibleIncome;
    newAllocations[0] = {
      ...newAllocations[0],
      solo: newSoloAllocations,
      "401k_value": (combinedContribution401k / totalIncome) * 100,
      roth_401k_value: (combinedContributionRoth / totalIncome) * 100,
    };
    const newPlan = {
      allocations: newAllocations,
      retirement: newRetirement,
    };
    // dont save if employer offer select field is changed to no
    // this only effects the curated flow
    if (formValues.employer_offered === "y") {
      dispatch(updateCurrentPlan(newPlan));
      // Assume Traditional 401k contributions have been changed, update the taxes
      dispatch(estimateCurrentPlanTaxes());
    }
    dispatch(savePlan(null));
    onSave();
  };

  return render({
    component: (
      <CenterContainer
        scrollable
        title="Employer Retirement"
        iconName="fb-model"
        help={employerBlowout}
      >
        {curatedFlow && (
          <Box className="mt-5">
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel className="mb-4">
                  <Typography style={{ fontSize: "13px" }}>
                    Does your spouse's employer offer and do you want to
                    contribute to their 401k/403b/457b/TSP?
                  </Typography>
                </FormLabel>
                <RadioGroup
                  aria-label="employer_offer"
                  name="employer_offered"
                  onChange={setFormValue}
                  value={formValues.employer_offered}
                  className="flex flex-row"
                >
                  <FormControlLabel value="n" control={<Radio />} label="No" />
                  <FormControlLabel value="y" control={<Radio />} label="Yes" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Box>
        )}

        {formValues.employer_offered !== "n" && (
          <>
            {!!formValues.who && (
              <>
                <Grid container spacing={2} className="mt-4">
                  <Grid item xs={6}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend" className="mb-4">
                        <Typography style={{ fontSize: "13px" }}>
                          My {spouses}eligible income for retirement
                        </Typography>
                      </FormLabel>
                      <Box className="flex items-center">
                        <Typography variant="h6">
                          {formatWholeDollars(eligibleIncome)}
                        </Typography>
                        <ToolTipIcon
                          placement="right"
                          title="To increase/decrease this amount, return to Step 2 'Day-To-Day Money' and adjust your salary, wage, or commission income."
                        />
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend" className="mb-4">
                        <Box className="flex items-center">
                          <Typography style={{ fontSize: "13px" }}>
                            My {spouses}% contributions from eligible income
                          </Typography>
                          <ToolTipIcon
                            placement="top"
                            title="This is the amount YOU will contribute to your 401k/403b based on a percenatge of your income.  See the Retirement Contribution Summary at the bottom of the screen for dollar details."
                          />
                        </Box>
                      </FormLabel>

                      <PercentTextField
                        variant="outlined"
                        fullWidth
                        name="contribution"
                        value={formValues.contribution}
                        placeholder="0"
                        max={maxPercent}
                        onChange={setFormValue}
                      />
                      <Typography variant="body1">
                        Max: {(Math.floor(maxPercent * 100) / 100).toFixed(2)}%
                      </Typography>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend" className="mb-4">
                        <Box className="flex items-center">
                          <Typography style={{ fontSize: "13px" }}>
                            What % of your {spouses}eligible income does your{" "}
                            {spouses}employer match?
                          </Typography>
                          <ToolTipIcon
                            placement="right"
                            title="This is the percentage of income your employer matches (i.e. if your employer says they match 3% of your income, you'd enter 3% here)."
                          />
                        </Box>
                      </FormLabel>
                      <PercentTextField
                        variant="outlined"
                        fullWidth
                        name="base_amount_matched"
                        value={formValues.base_amount_matched}
                        placeholder="0"
                        onChange={setFormValue}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      component="fieldset"
                      fullWidth
                      className="h-full"
                    >
                      <FormLabel component="legend" className="mb-4">
                        <Box className="flex items-center">
                          <Typography style={{ fontSize: "13px" }}>
                            Employer match %
                          </Typography>
                          <ToolTipIcon
                            placement="top"
                            title="If your employer says they match 100%, you'd put that percentage here. "
                          />
                        </Box>
                      </FormLabel>
                      <PercentTextField
                        className="mt-auto"
                        variant="outlined"
                        fullWidth
                        name="base_match_percent"
                        value={formValues.base_match_percent}
                        placeholder="0"
                        onChange={setFormValue}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend" className="mb-4">
                        <Box className="flex items-center">
                          <Typography style={{ fontSize: "13px" }}>
                            Does your {spouses}employer offer additional match?
                          </Typography>
                          <ToolTipIcon
                            placement="right"
                            title={`Your employer may say something like, “we match an additional 50% up to 6%”. You would put 6% under ‘What additional % of your eligible income does your employer match?' and 50% under 'Employer additional match.’"`}
                          />
                        </Box>
                      </FormLabel>
                      <RadioGroup
                        aria-label="additional_match"
                        name="additional_match"
                        onChange={setFormValue}
                        value={formValues.additional_match}
                        className="flex flex-row"
                      >
                        <FormControlLabel
                          value="n"
                          control={<Radio />}
                          label="No"
                        />
                        <FormControlLabel
                          value="y"
                          control={<Radio />}
                          label="Yes"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {formValues.additional_match === "y" && (
                    <>
                      <Grid item xs={6}>
                        <FormControl component="fieldset" fullWidth>
                          <FormLabel component="legend" className="mb-4">
                            <Typography style={{ fontSize: "13px" }}>
                              What additional % of your {spouses}eligible income
                              do they match?
                            </Typography>
                          </FormLabel>
                          {/* <FormLabel component="legend" className="mb-4">My % contributions to my Roth 401k from my eligible income</FormLabel> */}
                          <PercentTextField
                            variant="outlined"
                            fullWidth
                            name="additional_amount_match"
                            value={formValues.additional_amount_match}
                            placeholder="0"
                            onChange={setFormValue}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl
                          component="fieldset"
                          fullWidth
                          className="h-full"
                        >
                          <FormLabel component="legend" className="mb-4">
                            <Typography style={{ fontSize: "13px" }}>
                              Employer match %
                            </Typography>
                          </FormLabel>
                          <PercentTextField
                            className="mt-auto"
                            variant="outlined"
                            fullWidth
                            name="additional_match_percent"
                            value={formValues.additional_match_percent}
                            placeholder="0"
                            onChange={setFormValue}
                          />
                        </FormControl>
                      </Grid>
                    </>
                  )}
                </Grid>

                <Grid item xs={12} className="mt-4">
                  <FormControl component="fieldset" fullWidth>
                    <FormLabel component="legend" className="mb-4">
                      <Box className="flex items-center">
                        <Typography style={{ fontSize: "13px" }}>
                          Does your {spouses}employer offer a Roth 401k?
                        </Typography>
                      </Box>
                    </FormLabel>
                    <RadioGroup
                      aria-label="401k"
                      name="401k"
                      onChange={setFormValue}
                      value={formValues["401k"]}
                      className="flex flex-row mb-4"
                    >
                      <FormControlLabel
                        value="n"
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        value="y"
                        control={<Radio />}
                        label="Yes"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                {formValues["401k"] === "y" && (
                  <Grid item xs={6}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend" className="mb-4">
                        <Typography style={{ fontSize: "13px" }}>
                          My % contribution to my {spouses}Roth 401k from my
                          eligible income
                        </Typography>
                      </FormLabel>
                      <PercentTextField
                        variant="outlined"
                        fullWidth
                        name="401k_percent"
                        value={formValues["401k_percent"]}
                        placeholder="0"
                        max={rothMaxPercent}
                        onChange={setFormValue}
                      />
                      <Typography variant="body1">
                        Max:{" "}
                        {(Math.floor(rothMaxPercent * 100) / 100).toFixed(2)}%
                      </Typography>
                    </FormControl>
                  </Grid>
                )}

                <Grid item xs={12} className="mt-2">
                  <FormControl component="fieldset" fullWidth>
                    <FormLabel component="legend" className="mb-4">
                      <Box className="flex items-center">
                        <Typography style={{ fontSize: "13px" }}>
                          Does your {spouses}employer offer contributions to
                          your retirement without you having to contribute?
                        </Typography>
                      </Box>
                    </FormLabel>
                    <RadioGroup
                      aria-label="auto_question"
                      name="auto_question"
                      onChange={setFormValue}
                      value={formValues["auto_question"]}
                      className="flex flex-row mb-4"
                    >
                      <FormControlLabel
                        value="n"
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        value="y"
                        control={<Radio />}
                        label="Yes"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                {formValues["auto_question"] === "y" && (
                  <Grid item xs={12}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend" className="mb-4">
                        <Typography style={{ fontSize: "13px" }}>
                          Employer contributions as a % of my {spouses}eligible
                          income
                        </Typography>
                      </FormLabel>
                      <PercentTextField
                        variant="outlined"
                        fullWidth
                        name="auto_match_percent"
                        value={formValues["auto_match_percent"]}
                        placeholder="0"
                        onChange={setFormValue}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Box className="mb-10">
                  <Box className="w-full flex font-bold mt-4 mb-2">
                    <Box style={{ width: "40%" }} />
                    <Box className={styles.headerCell}>% of Total Income</Box>
                    <Box className={styles.headerCell}>Monthly</Box>
                    <Box className={styles.headerCell}>Annually</Box>
                  </Box>
                  <Box className="w-full flex">
                    <Box className="font-bold" style={{ width: "50%" }}>
                      Retirement Contribution Summary
                    </Box>
                    <Box style={{ width: "30%" }}>
                      {formatPercent(totalContribution)}
                    </Box>
                    <Box style={{ width: "30%" }}>
                      {formatMonthly(Math.round(dollarContribution / 12))}
                    </Box>
                    <Box style={{ width: "30%" }}>
                      {formatAnnually(dollarContribution)}
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </>
        )}

        {!curatedIndex && (
          <Button
            variant="outlined"
            fbColor="primary"
            onClick={onClose}
            className={styles.btnCancel}
          >
            Cancel
          </Button>
        )}
      </CenterContainer>
    ),
    nextLabel: "Save",
    onNext: save,
  });
};

export default AddOrEdit401kSpouse;
