import { BsFillExclamationCircleFill as ErrorIconBase } from "react-icons/bs";
import { BiInfoCircle as InfoIconBase } from "react-icons/bi";
import styled from "@emotion/styled";
import { EuiCallOut } from "@elastic/eui";

const ErrorText = styled.div`
  color: #bd271e;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    color: #bd271e;
    border-radius: 0;
    font-size: 16px;
    cursor: pointer;
  }
  max-width: 100%;
  flex-wrap: nowrap;
`;
export const StyledEuiCallOut = styled(EuiCallOut)`
  width: 100%;
  p {
    display: flex;
    gap: 8px;
    font-size: 16px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
  }
`;
const StyledIcon = styled.div`
  width: 24px;
  height: 24px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const ErrorIcon = () => (
  <StyledIcon>
    <ErrorIconBase />
  </StyledIcon>
);

export const InfoIcon = () => (
  <StyledIcon>
    <InfoIconBase />
  </StyledIcon>
);

interface FormErrorProps {
  children?: React.ReactNode;
  type?: "callout" | "text";
  color?: "primary" | "success" | "warning" | "danger";
  title?: string | React.ReactNode;
  iconType?: typeof ErrorIcon | typeof InfoIcon;
}
const FormError = ({
  children,
  type = "text",
  color = "danger",
  title = "Invalid email or password",
  iconType = ErrorIcon,
}: FormErrorProps) => {
  const IconComponent = iconType || ErrorIcon;

  if (type === "callout") {
    return (
      <StyledEuiCallOut color={color} title={title} iconType={IconComponent}>
        {children}
      </StyledEuiCallOut>
    );
  }

  return (
    <ErrorText>
      <IconComponent />
      {children}
    </ErrorText>
  );
};

export default FormError;
