import React from 'react';
import logo from '../../assets/svgs/fb.svg'
import styled from '@emotion/styled'

interface LogoProps {
  className?: string;
  alt?: string;
  link?: string;
}

const StyledLink = styled.a`
    align-items: center;
    display: flex;
`;

const StyledImg = styled.img`
    width: 85px;
    height: 22px;
`;

function Logo({ link, alt, className }: LogoProps) {
  return (
    <StyledLink href={link} className={className}>
      <StyledImg src={logo} alt={alt} />
    </StyledLink>
  );
}

export default Logo;
