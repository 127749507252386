import React from "react";

import PlanBuilderLayout from "src/layouts/PlanBuilderLayout";
import Content from "./content";

const OptimizedPlan = () => {
  return (
    <PlanBuilderLayout
      steps={["Build Plan", "Review & Compare", "Implement"]}
      pageTitle="Set Up My Optimized Financial Plan"
    >
      <Content />
    </PlanBuilderLayout>
  );
};

export default OptimizedPlan;
