import React from "react";
import {
  BarChart as Chart,
  LabelList,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { makeStyles } from "@material-ui/core/styles";
import { chartStyles } from "src/theme/base";
import colors from "src/theme/colors";

const COLORS = [
  colors.brandingBlue1,
  colors.brandingBlue3,
  colors.brandingBlue2,
];
const useStyles = makeStyles(chartStyles);

interface List {
  name: string;
  value: number;
}
interface Props {
  noNegative?: boolean;
  colors?: string[];
  dataSource: List[];
  labelYOffset?: number;
  formatter?: (value: any) => string;
  hideAxis?: boolean;
  hideTooltip?: boolean;
  xDomain?: number;
}

const BarChart = ({
  noNegative,
  colors,
  dataSource,
  labelYOffset,
  formatter,
  hideAxis,
  hideTooltip,
  xDomain,
}: Props) => {
  const displayColors: string[] = colors || COLORS;

  const CustomTooltip = ({ active, payload, label }: any) => {
    const classes = useStyles();
    if (active && payload && payload.length) {
      return (
        <div className={classes.tooltip}>
          <h5 className="fb-chart-tooltip-title">{label}</h5>
          <ul>
            {payload.map((e: any) => {
              const formattedValue = formatter ? formatter(e.value) : e.value;
              return (
                <li>
                  <span style={{ backgroundColor: e.fill }}></span>
                  {`${e.dataKey}`}
                  <strong>{formattedValue}</strong>
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
    return null;
  };

  const renderCustomizedLabelValues = ({ x, y, width, height, value }: any) => {
    const formattedValue = formatter ? formatter(value) : value;
    const fireOffset = formattedValue.toString().length < 8;
    const negativeOffset = !noNegative && value < 0 ? 60 : 0;
    const offset = (fireOffset ? -40 : -20) + negativeOffset;
    return (
      <text
        x={x + width - offset + 10}
        y={y + height - 20 + 4 * dataSource.length}
        fontSize={14}
        fontWeight={500}
        fill={fireOffset ? "#343741" : "#fff"}
        textAnchor="end"
      >
        {formattedValue}
      </text>
    );
  };

  const customLabelName = ({ x, y, name, width }: any) => {
    const isNegative = Boolean(width < 0);
    return (
      <text
        x={isNegative && !noNegative ? x - 70 : x}
        y={y - 10 + (labelYOffset || 0)}
        fill={"#343741"}
        fontSize={14}
        fontWeight={600}
        textAnchor="start"
      >
        {name}
      </text>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={315}>
      <Chart
        className="nw-bar-chart"
        layout="vertical"
        data={dataSource}
        barCategoryGap={"30%"}
        margin={{
          right: 60,
          bottom: 20,
          left: 60,
          top: 0,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" vertical horizontal={false} />
        <XAxis
          tick={!hideAxis}
          type="number"
          axisLine={false}
          tickLine={false}
          dy={20}
          tickFormatter={formatter}
          domain={!xDomain ? undefined : [0, xDomain]}
        />
        <YAxis
          width={0}
          dataKey="name"
          type="category"
          axisLine={false}
          tick={false}
          tickLine={false}
        />
        {!hideTooltip && <Tooltip content={<CustomTooltip />} />}
        <Bar
          dataKey="value"
          fill="#00a0fc"
          radius={5}
          label={customLabelName}
          isAnimationActive={false}
        >
          {dataSource.map((_: any, index: number) => (
            <Cell key={`cell-${index}`} fill={displayColors[index]} />
          ))}
          <LabelList
            dataKey="value"
            content={renderCustomizedLabelValues}
            position="insideRight"
            style={{ fill: "white" }}
          />
        </Bar>
      </Chart>
    </ResponsiveContainer>
  );
};

export default BarChart;
