import React from "react";
import InsurancePremium from "./Blocks/Insurance";
import Summary from "./Blocks/Summary";
import {
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiText,
} from "@elastic/eui";
import { Global, css } from "@emotion/react";
import { StyledSpacer } from "src/components/Global/StyledComponents";

const riskStyles = css`
  .rm-page {
    .rm-panel {
      min-height: 90vh;
    }
    .rm-header {
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      font-family: "Poppins", sans-serif;
    }
    span.euiTableCellContent__text {
      font-size: 16px;
      line-height: 24px;
    }
    .euiTableCellContent {
      font-size: 16px;
    }
  }
`;

export const Content = () => {
  return (
    <>
      <Global styles={riskStyles} />
      <EuiFlexGroup className="rm-page">
        <EuiFlexItem grow={3}>
          <EuiPanel className="rm-panel">
            <EuiText>
              <h2 className="rm-header">Risk Management</h2>
            </EuiText>
            <StyledSpacer size="32px" />
            <InsurancePremium />
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem grow={1}>
          <Summary />
          <StyledSpacer size="16px" />
          <EuiCallOut
            title="Insurance Recommendations"
            iconType="questionInCircle"
            className="rm-callout"
          >
            <p>
              We recommend that you have long-term disability insurance at a
              minimum. If you have anyone that is dependent on your income (i.e.
              children), then you should also have life insurance.
            </p>
          </EuiCallOut>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};
