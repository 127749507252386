import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";

import { AdapterLink } from "src/utils";
import Button from "src/components/Button";
import {
  fetchPlanProjection,
  implementPlan,
  setBuildStep,
} from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import {
  getCurrentPlan,
  getScoreProjection,
  getAssetProjection,
  getDebtProjection,
  getNetProjection,
  getMinFutureProjections,
  getLoadingProjection,
  getLifeEventProjections,
} from "src/store/planBuild/selector";
import {
  getUserInSchool,
  getSpouseInSchool,
} from "src/store/profileBuild/selector";
import {
  getIsCurrentStudent,
  getIsSubscribed,
} from "src/store/system/selector";
import {
  GOALS,
  PlanViewComponent,
  PROJECTION_ASSETS,
  PROJECTION_DEBTS,
} from "src/interfaces";
import { formatWholeDollars } from "src/utils";

import EditDialog from "./EditDialog";
import OverviewCard from "./components/OverviewCard";
import SchoolYearFundingBreakdown from "./components/SchoolYearFundingBreakdown";
import OverviewTable from "./components/OverviewTable";
import ScoreFactorsDialog from "./components/ScoreFactorsDialog";
import { AppLoader } from "src/components";
import Chart from "src/components/Chart";

const reformatDateString = (dateString: string) => {
  // const month = dateString[5] === "0" ? dateString.slice(6, 7) : dateString.slice(5, 7);
  const month = dateString.slice(5, 7);
  const year = dateString.slice(2, 4);
  return `${month}/${year}`;
};

const ReviewPlan: PlanViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const loading = useSelector(getLoadingProjection);
  const currentPlan = useSelector(getCurrentPlan);
  const subscribed = useSelector(getIsSubscribed);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const userInSchool = useSelector(getUserInSchool);
  const spouseInSchool = useSelector(getSpouseInSchool);

  const scoreProjection = useSelector(getScoreProjection);
  const assetProjection = useSelector(getAssetProjection);
  const debtProjection = useSelector(getDebtProjection);
  const netProjection = useSelector(getNetProjection);
  const future = useSelector(getMinFutureProjections);
  const events = useSelector(getLifeEventProjections);

  useEffect(() => {
    dispatch(fetchPlanProjection());
  }, []);

  const [showEditDialog, setShowEditDialog] = useState(false);

  const onNext = () => {
    if (subscribed) {
      if (currentPlan && currentPlan.id) {
        dispatch(implementPlan(currentPlan.id));
      }
      dispatch(push("/home"));
      dispatch(setBuildStep(PLAN_BUILD_STEPS.NAME));
    } else {
      dispatch(setBuildStep(PLAN_BUILD_STEPS.IMPLEMENT));
    }
  };

  const toggleEditDialog = () => setShowEditDialog(!showEditDialog);

  const now = new Date();
  const nowYear = now.getFullYear();
  const years = [
    "Now",
    "" + (nowYear + 5),
    "" + (nowYear + 10),
    future.retirementYear || "",
  ];

  const assetKeyList = Array.from(future.assetKeys as Set<string>).filter(
    (key: string) => key !== "total"
  );
  const debtKeyList = Array.from(future.debtKeys as Set<string>).filter(
    (key: string) => key !== "total"
  );

  return render({
    component: (
      <Container>
        <Typography variant="h2" component="h2">
          Review
        </Typography>
        {loading && (
          <Box className={styles.loaderContainer}>
            <AppLoader />
          </Box>
        )}
        <Grid container spacing={3}>
          {!loading && !!future.net.length && (
            <>
              <Grid item xs={6}>
                <Chart
                  centerTitle
                  title="Projected FitBUX Score"
                  data={scoreProjection}
                  height="322px"
                  keys={["score", "min"]}
                  xKey="month"
                  keyLabels={{
                    score: "Plan Followed",
                    min: "Plan Not Followed",
                  }}
                  width="100%"
                />
              </Grid>
              <Grid item xs={6}>
                <OverviewTable
                  title="FitBUX Score:"
                  columns={years}
                  rows={[
                    {
                      header: "Total if plan followed",
                      columns: years.map(
                        (year: string, index: number) =>
                          future.score[index].plan
                      ),
                    },
                    {
                      header: "Total if plan not followed",
                      columns: years.map(
                        (year: string, index: number) => future.score[index].min
                      ),
                    },
                  ]}
                />
                <ScoreFactorsDialog />
              </Grid>
              {!!isCurrentStudent && (
                <Grid item xs={12}>
                  <SchoolYearFundingBreakdown />
                </Grid>
              )}
              <Grid item xs={6}>
                <Chart
                  dollars
                  centerTitle
                  title="Projected Financial Assets"
                  data={assetProjection}
                  height="322px"
                  keys={["asset", "min"]}
                  xKey="month"
                  keyLabels={{
                    asset: "Plan Followed",
                    min: "Plan Not Followed",
                  }}
                  width="100%"
                />
              </Grid>
              <Grid item xs={6}>
                <OverviewTable
                  title="Assets:"
                  columns={years}
                  rows={[
                    {
                      header: "Total if plan followed",
                      columns: years.map((year: string, index: number) =>
                        formatWholeDollars(future.assets[index].plan.total)
                      ),
                      children: assetKeyList.map((key: string) => ({
                        header:
                          PROJECTION_ASSETS[
                            key as keyof typeof PROJECTION_ASSETS
                          ],
                        columns: years.map((year, index: number) => {
                          const value = future.assets[index].plan[key];
                          if (value) {
                            return formatWholeDollars(value);
                          }
                          return "";
                        }),
                      })),
                    },
                    {
                      header: "Total if plan not followed",
                      columns: years.map((year: string, index: number) =>
                        formatWholeDollars(future.assets[index].min.total)
                      ),
                      children: assetKeyList.map((key: string) => ({
                        header:
                          PROJECTION_ASSETS[
                            key as keyof typeof PROJECTION_ASSETS
                          ],
                        columns: years.map((year, index: number) => {
                          const value = future.assets[index].min[key];
                          if (value) {
                            return formatWholeDollars(value);
                          }
                          return "";
                        }),
                      })),
                    },
                  ]}
                />
              </Grid>

              <Grid item xs={6}>
                <Chart
                  dollars
                  centerTitle
                  title="Projected Debts"
                  data={debtProjection}
                  height="322px"
                  keys={["debt", "min"]}
                  xKey="month"
                  keyLabels={{
                    debt: "Plan Followed",
                    min: "Plan Not Followed",
                  }}
                  width="100%"
                />
              </Grid>
              <Grid item xs={6}>
                <OverviewTable
                  title="Debts:"
                  columns={years}
                  rows={[
                    {
                      header: "Total if plan followed",
                      columns: years.map((year: string, index: number) =>
                        formatWholeDollars(future.debts[index].plan.total)
                      ),
                      children: debtKeyList.map((key: string) => ({
                        header:
                          PROJECTION_DEBTS[
                            key as keyof typeof PROJECTION_DEBTS
                          ],
                        columns: years.map((year, index: number) => {
                          const value = future.debts[index].plan[key];
                          if (value) {
                            return formatWholeDollars(value);
                          }
                          return "";
                        }),
                      })),
                    },
                    {
                      header: "Total if plan not followed",
                      columns: years.map((year: string, index: number) =>
                        formatWholeDollars(future.debts[index].min.total)
                      ),
                      children: debtKeyList.map((key: string) => ({
                        header:
                          PROJECTION_DEBTS[
                            key as keyof typeof PROJECTION_DEBTS
                          ],
                        columns: years.map((year, index: number) => {
                          const value = future.debts[index].min[key];
                          if (value) {
                            return formatWholeDollars(value);
                          }
                          return "";
                        }),
                      })),
                    },
                  ]}
                />
              </Grid>

              <Grid item xs={6}>
                <Chart
                  dollars
                  centerTitle
                  title="Projected Total Net Wealth"
                  data={netProjection}
                  height="322px"
                  keys={["net", "min", "hc"]}
                  xKey="month"
                  keyLabels={{
                    net: "Plan Followed",
                    min: "Plan Not Followed",
                    hc: "Human Capital",
                  }}
                  width="100%"
                />
              </Grid>
              <Grid item xs={6}>
                <OverviewTable
                  title="Net Wealth:"
                  columns={years}
                  rows={[
                    {
                      header: "Total if plan followed",
                      columns: years.map((year: string, index: number) =>
                        formatWholeDollars(future.net[index].plan)
                      ),
                      children: [
                        {
                          header: "Human Capital",
                          columns: years.map((year, index: number) =>
                            formatWholeDollars((future.hc as any)[index].plan)
                          ),
                        },
                        {
                          header: "Financial Assets",
                          columns: years.map((year, index: number) =>
                            formatWholeDollars(future.assets[index].plan.total)
                          ),
                        },
                        {
                          header: "(Less) Debt",
                          columns: years.map((year, index: number) =>
                            formatWholeDollars(future.debts[index].plan.total)
                          ),
                        },
                      ],
                    },
                    {
                      header: "Total if plan not followed",
                      columns: years.map((year: string, index: number) =>
                        formatWholeDollars(future.net[index].min)
                      ),
                      children: [
                        {
                          header: "Human Capital",
                          columns: years.map((year, index: number) =>
                            formatWholeDollars((future.hc as any)[index].min)
                          ),
                        },
                        {
                          header: "Financial Assets",
                          columns: years.map((year, index: number) =>
                            formatWholeDollars(future.assets[index].min.total)
                          ),
                        },
                        {
                          header: "(Less) Debt",
                          columns: years.map((year, index: number) =>
                            formatWholeDollars(future.debts[index].min.total)
                          ),
                        },
                      ],
                    },
                  ]}
                />
              </Grid>
            </>
          )}
          {!isCurrentStudent && (
            <Grid item xs={6} className="h-96">
              <OverviewCard />
            </Grid>
          )}
          <Grid item xs={6}>
            {!!events && !!events.length && (
              <OverviewTable
                noGrid
                title="Life Events/Goals:"
                columns={["Plan If Followed", "Plan If Not Followed"]}
                rows={events
                  .filter((event) => {
                    if (
                      event.type === "graduate" ||
                      event.type === "repayment"
                    ) {
                      if (
                        (event.who === "spouse" && !spouseInSchool) ||
                        (event.who !== "spouse" && !userInSchool)
                      ) {
                        return false;
                      }
                    }
                    return true;
                  })
                  .map((event) => {
                    let typeLabel =
                      GOALS[event.type as keyof typeof GOALS] || "Goal";
                    if (
                      (event.type === "student_loan" || event.type === "idr") &&
                      event.who === "spouse"
                    ) {
                      typeLabel += ` (spouse)`;
                    }
                    // if (event.type === "retirement") {
                    //   typeLabel = "Retirement";
                    // } else {
                    //   const typeDefinition = LIFE_EVENTS[event.type as keyof typeof LIFE_EVENTS];
                    //   if (!typeDefinition) {
                    //     typeLabel = "Event";
                    //   } else {
                    //     typeLabel = typeDefinition.typeLabel;
                    //   }
                    // }
                    return {
                      header: typeLabel,
                      columns: [
                        reformatDateString(event.plan),
                        event.min ? reformatDateString(event.min) : "",
                      ],
                    };
                  })}
              />
            )}
          </Grid>
          <Grid item xs={12} className="my-10 text-center">
            <AdapterLink to="/financial-plans">
              Build Another Plan &amp; Compare
            </AdapterLink>
          </Grid>
        </Grid>
        <Box className={styles.actionBar}>
          <Button
            className={styles.actionButton}
            fbColor="secondary"
            onClick={toggleEditDialog}
          >
            Edit
          </Button>
          <Button
            className={styles.actionButton}
            fbColor="primary"
            onClick={onNext}
          >
            Implement
          </Button>
        </Box>
        <EditDialog open={showEditDialog} onClose={toggleEditDialog} />
      </Container>
    ),
    hideSidebars: true,
    onNext,
  });
};

export default ReviewPlan;

const useStyles = makeStyles({
  actionBar: {
    background: "white",
    padding: "12px 68px",
    bottom: 0,
    left: 0,
    display: "flex",
    justifyContent: "space-between",
    position: "fixed",
    width: "100%",
  },
  actionButton: {
    width: 168,
  },
  cardRowCell: {
    fontSize: 12,
    width: "16%",
  },
  cardRowLabel: {
    fontSize: 12,
    paddingLeft: 10,
    width: "36%",
  },
  cardTableHeader: {},
  chart: {
    minWidth: 440,
    maxWidth: 524,
  },
  loaderContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 555,
    background: "rgba(255, 255, 255, .8)",
  },
});
