import React, { useState } from "react";
import { useSelector } from "react-redux";

import { GOALS, SPECIAL_REPAYMENTS } from "src/interfaces";
import {
  getLiveLifeEventProjections,
  getLiveProjection,
} from "src/store/planBuild/selector";
import {
  getProfile,
  getSpouseInSchool,
  getSpouseProfile,
  getUserInSchool,
} from "src/store/profileBuild/selector";
import {
  EuiBasicTable,
  EuiCard,
  EuiLink,
  EuiProgress,
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiText,
} from "@elastic/eui";
import {
  StyledEuiLink,
  StyledSpacer,
} from "src/components/Global/StyledComponents";
import { getIsCurrentStudent } from "src/store/system/selector";
import { formatWholeDollars, reformatDateString } from "src/utils";
import { getAccounts } from "src/store/account/selector";
import { Global, css } from "@emotion/react";
import useWindowSize from "src/hooks/useWindowSize";
import styled from "@emotion/styled";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import completed_goal from "src/assets/svgs/completed.svg";

export const TableWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-bottom: none;
  @media (max-width: 767px) {
    border: none;
  }
`;

const eventGoalsStyles = css`
  .card-header {
    justify-content: flex-start !important;
    align-items: center;
    .card-title {
      h2 {
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        line-height: 32px;
        font-weight: 600;
      }
    }
    .card-link {
      font-size: 16px;
      font-family: "Inter", sans-serif;
      line-hegith: 24px;
      font-weight: 400px;
      .link-group {
        display: flex;
        justify-content: space-between;
        gap: 8px;
        flex-wrap: nowrap;
      }
    }
  }
  .category-name {
    font-weight: 600;
    line-height: 20px;
    text-align: left;
  }
  .mobile-styles {
    .tabs-summary {
      span {
        font-size: 12px;
      }
    }
    .no-data-title {
      font-size: 14px;
      font-family: "Inter", sans-serif;
      font-weight: bold;
      text-align: left;
    }
    .row-title {
      font-size: 14px;
      font-family: "Inter", sans-serif;
      font-weight: bold;
      text-align: left;
    }
    .row-title-item {
      flex-wrap: nowrap;
      flex: 0 1 auto !important;
      inline-size: auto !important;
    }
    .progress-chart {
      flex: 0 1 auto !important;
      inline-size: auto !important;
      .CircularProgressbar-text {
        dominant-baseline: middle;
        text-anchor: middle;
        font-weight: 600;
      }
    }
    .row-title-group {
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
    }
    .amounts-review {
      font-size: 14px;
      font-family: "Inter", sans-serif;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
    }
    .planned-text {
      color: #69707d;
    }
    .progress-text {
      font-size: 14px;
      font-family: "Inter", sans-serif;
      line-height: 20px;
      font-weight: 600;
    }
    .child-row-group {
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
    }
    .child-item-icon {
      flex: 0 0 20px !important;
      svg {
        width: 20px;
        height: 20px;
        fill: #343741 !important;
      }
    }
    .mobile-header {
      justify-content: space-between;
      gap: 8px;
      flex-wrap: nowrap;
      .card-title {
        align-items: flex-start;
        h2 {
          font-size: 18px;
        }
      }
      .card-link {
        flex: 0 0 140px !important;
        font-size: 14px;
        align-items: flex-start;
      }
    }
    .mobile-table {
      padding: 0;
      border-radius: 0;
    }
  }
  .tabs-summary {
    .euiButtonGroupButton {
      background-color: #fbfcfd;
      border: 1px solid #cecece;
    }
    .euiButtonGroupButton-isSelected {
      background-color: #69707d;
    }
    span {
      font-size: 14px;
      font-family: "Inter", sans-serif;
    }
  }
  .table-info {
    font-size: 14px !important;
    line-height: 24px !important;
    text-align: left;
    max-width: 100%;
  }
  .eg-table {
    .euiTableCellContent {
      font-size: 16px;
      line-height: 24px;
      font-family: "Inter", sans-serif;
    }
    .euiTableHeaderCell span {
      font-size: 16px;
      line-height: 24px;
    }
    table {
      width: 100%;
      table-layout: auto;
      @media (min-width: 768px) and (max-width: 1400px) {
        table-layout: fixed;
        .euiTableHeaderCell:first-child {
          position: -webkit-sticky;
          position: sticky;
          left: 0;
          background-color: #fff;
          z-index: 2;
          &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 5px;
            background: linear-gradient(
              to right,
              rgba(206, 206, 206, 1),
              rgba(255, 255, 255, 0)
            );
          }
        }
        .euiTableRow > .euiTableRowCell:first-child {
          position: -webkit-sticky;
          position: sticky;
          left: 0;
          background-color: #fff;
          z-index: 2;
        }
        .euiTableRow > .euiTableRowCell:first-child {
          &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 5px;
            background: linear-gradient(
              to right,
              rgba(206, 206, 206, 1),
              rgba(255, 255, 255, 0)
            );
          }
        }
        th:nth-child(1),
        td:nth-child(1) {
          width: 260px;
        }
        th:nth-child(2),
        td:nth-child(2) {
          width: 200px;
        }
        th:nth-child(3),
        td:nth-child(3) {
          width: 200px;
        }
        th:nth-child(4),
        td:nth-child(4) {
          width: 200px;
        }
        th:nth-child(5),
        td:nth-child(5) {
          width: 200px;
        }
        th:nth-child(6),
        td:nth-child(6) {
          width: 260px;
        }
      }
    }
    .progress-section {
      gap: 10px !important;
      justify-content: center !important;
      align-items: center !important;
      .progress-bar {
        width: 174px;
        height: 8px;
        .euiProgress {
          height: 8px;
        }
      }
      .progress-text {
        align-items: flex-start;
        .euiText {
          font-size: 12px;
        }
      }
    }
    .drop-down-row {
      .cell-overflow {
        div {
          padding-left: 0px !important;
        }
      }
      .euiFlexGroup {
        gap: 16px;
      }
    }
    .child-row {
      background-color: #f7f8fc;
    }
    .cell-overflow {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .completed-link {
      font-size: 16px;
      font-family: "Inter", sans-serif;
      line-height: 24px;
    }
  }
`;

type LifeEventRow = {
  type: string;
  header: string;
  who: string;
  date: string;
  currentValue: number;
  goal: number;
  isCompleted: boolean;
};

const LifeEventsGoals = () => {
  const livePlanEvents: any[] = useSelector(getLiveLifeEventProjections);
  const livePlanProjection = useSelector(getLiveProjection);
  const targets = livePlanProjection?.targets;
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const userInSchool = useSelector(getUserInSchool);
  const spouseInSchool = useSelector(getSpouseInSchool);
  const accounts = useSelector(getAccounts);
  const [showCompleted, setShowCompleted] = useState(true);
  const { width } = useWindowSize();
  const myProfile = useSelector(getProfile);
  const spouseProfile = useSelector(getSpouseProfile);
  const applicantIdr =
    SPECIAL_REPAYMENTS.indexOf(myProfile.fed_repayment_plan || "") >= 0;
  const spouseIdr =
    SPECIAL_REPAYMENTS.indexOf(spouseProfile.fed_repayment_plan || "") >= 0;

  const formatLifeEventDollars = (value: number) => {
    if (value) {
      return formatWholeDollars(value);
    } else {
      return "--";
    }
  };

  const StudentLoanTypes: any = {
    priv_loan: 1,
    perkins_loan: 1,
  };

  const SpecialStudentLoanTypes: any = {
    fed_loan: 1,
  };

  const HomeLoanTypes: any = {
    home_loan: 1,
    property_loan: 1,
  };

  const getInitialTotals = () => {
    const results = {
      cash_value: 0,
      other_investments_value: 0,
      auto_loan: 0,
      student_loan: 0,
      student_loan_spouse: 0,
      student_loan_fed: 0,
      student_loan_fed_spouse: 0,
      home: 0,
      creditCard: 0,
      allocateFunds: function (targetAmount: number, type: any) {
        let remaining = targetAmount; //5
        let result = 0;

        let consumed = Math.min(this.cash_value, remaining);
        result += consumed;
        this.cash_value -= consumed;
        remaining -= consumed;

        if (type !== "emergencyfund") {
          consumed = Math.min(this.other_investments_value, remaining);
          result += consumed;
          this.other_investments_value -= consumed;
          remaining -= consumed;
        }
        return result;
      },
    };
    accounts.map((account) => {
      if (account.variable && account.balance) {
        if (account.variable === "cash_value") {
          results.cash_value += account.balance;
        } else if (account.variable === "other_investments_value") {
          results.other_investments_value += account.balance;
        } else if (account.variable === "auto_loan") {
          results.auto_loan += account.balance;
        } else if (StudentLoanTypes[account.variable]) {
          if (account.who === "applicant") {
            results.student_loan += account.balance;
          } else {
            results.student_loan_spouse += account.balance;
          }
        } else if (SpecialStudentLoanTypes[account.variable]) {
          if (account.who === "applicant") {
            results.student_loan_fed += account.balance;
          } else {
            results.student_loan_fed_spouse += account.balance;
          }
        } else if (HomeLoanTypes[account.variable]) {
          results.home += account.balance;
        } else if (account.variable === "credit_card") {
          results.creditCard += account.balance;
        }
      }
    });
    return results;
  };

  const calculateProgress = (current: number, goal: number) => {
    if (!goal) return 0;
    return Math.round((current / goal) * 100);
  };

  const getRows = () => {
    const currentBalances = getInitialTotals();
    const savingTargets: any = {};
    targets?.savings.map((target: any) => {
      savingTargets[target.type] = target.amount;
    });
    const today = new Date();

    const filteredPlanEvents = livePlanEvents.filter((event) => {
      const eventDate = new Date(event.plan);
      if (
        !isCurrentStudent &&
        (event.type === "emergencyfund" || eventDate < today)
      ) {
        return false;
      }
      if (event.type === "graduate" || event.type === "repayment") {
        if (
          (event.who === "spouse" && !spouseInSchool) ||
          (event.who !== "spouse" && !userInSchool)
        ) {
          return false;
        }
      }
      return true;
    });

    if (!isCurrentStudent) {
      filteredPlanEvents.unshift({ type: "emergencyfund", plan: "", who: "" });
    }

    // duplicate filter
    const duplicateGoalFilters: any = {
      mortgage: 0,
      auto_loan: 0,
      credit_card: 0,
    };

    const rows = filteredPlanEvents.map((event) => {
      const typeLabel = GOALS[event.type as keyof typeof GOALS] || "Goal";
      const date = event.plan ? reformatDateString(event.plan) : "";
      let current = 0;
      let goal = 0;
      // stop and aggregate the student loans based on idr and owner
      if (!isCurrentStudent) {
        // get the current based on if this is spouses or not
        if (event.type === "student_loan") {
          if (event.who === "applicant") {
            current = currentBalances.student_loan;
            if (!applicantIdr) {
              current += currentBalances.student_loan_fed;
            }
          } else {
            current = currentBalances.student_loan_spouse;
            if (!spouseIdr) {
              current += currentBalances.student_loan_fed_spouse;
            }
          }
          targets?.debts.forEach((targetDebt: any) => {
            if (
              targetDebt.type === "student_loan" &&
              targetDebt.who === event.who
            ) {
              goal = targetDebt.amount;
            }
          });

          // stop and aggregate the target debts
        } else if (
          event.type === "auto_loan" &&
          duplicateGoalFilters[event.type] === 0
        ) {
          current = currentBalances.auto_loan;
          targets?.debts.forEach((item: any) => {
            if (item.type === "auto_loan") {
              goal = item.amount;
            }
          });
          duplicateGoalFilters[event.type]++;
          // stop and aggregate the mortgage
        } else if (
          event.type === "mortgage" &&
          duplicateGoalFilters[event.type] === 0
        ) {
          targets?.debts.forEach((item: any) => {
            if (item.type === "mortgage") {
              goal = item.amount;
            }
          });
          current = currentBalances.home;
          duplicateGoalFilters[event.type]++;
        } else if (
          event.type === "credit_card" &&
          duplicateGoalFilters[event.type] === 0
        ) {
          targets?.debts.forEach((item: any) => {
            if (item.type === "credit_card") {
              goal = item.amount;
            }
          });
          current = currentBalances.creditCard;
        }
      }

      return {
        type: event.type,
        header: typeLabel,
        who: event.who,
        date,
        currentValue: current,
        goal,
        isCompleted: calculateProgress(current, goal) === 100,
      };
    });

    const consumedSavings: any = {};
    rows.forEach((entry) => {
      const entryType = entry.type.replace("savings-", "");
      for (const item in savingTargets) {
        if (entryType === item && !consumedSavings[entryType]) {
          entry.currentValue = currentBalances.allocateFunds(
            savingTargets[item],
            entry.type
          );
          entry.goal = savingTargets[item];
          consumedSavings[entryType] = true;
          break;
        }
      }
    });

    const ongoingRows: LifeEventRow[] = [];
    const completedRows: LifeEventRow[] = [];

    rows.forEach((row) => {
      if (row.isCompleted) {
        completedRows.push(row);
      } else {
        ongoingRows.push(row);
      }
    });

    ongoingRows.unshift({
      type: "header",
      header: "Current",
      who: "",
      date: "",
      currentValue: NaN,
      goal: NaN,
      isCompleted: false,
    });

    if (completedRows.length > 0) {
      ongoingRows.push({
        type: "header",
        header: "Completed",
        who: "",
        date: "",
        currentValue: NaN,
        goal: NaN,
        isCompleted: false,
      });
    }

    return { ongoingRows, completedRows };
  };

  const columns = [
    {
      field: "header",
      name: "Life Event/Goal",
      render: (header: string) => {
        if (header === "Completed") {
          return (
            <div className="category-name">
              {header}
              &nbsp;&nbsp;
              <EuiLink
                className="completed-link"
                onClick={() => setShowCompleted(!showCompleted)}
              >
                {showCompleted ? "Hide" : "Show"}
              </EuiLink>
            </div>
          );
        } else if (header === "Current") {
          return <div className="category-name">{header}</div>;
        }
        return header;
      },
    },
    {
      field: "date",
      name: "Projected Date",
    },
    {
      field: "currentValue",
      name: "Current",
      render: (value: number) =>
        !isNaN(value) ? formatLifeEventDollars(value) : "",
    },
    {
      field: "goal",
      name: "Target Amount",
      render: (value: number) =>
        !isNaN(value) ? formatLifeEventDollars(value) : "",
    },
    {
      name: "Progress",
      render: (row: LifeEventRow) => {
        if (isNaN(row.currentValue) || isNaN(row.goal)) {
          return "";
        }
        const progress = calculateProgress(row.currentValue, row.goal);
        return (
          <EuiFlexGroup className="progress-section">
            <EuiFlexItem className="progress-bar">
              <EuiProgress
                value={progress}
                max={100}
                size="s"
                color="primary"
              />
            </EuiFlexItem>
            <EuiFlexItem className="progress-text">{`${progress}%`}</EuiFlexItem>
          </EuiFlexGroup>
        );
      },
    },
  ];

  const mobileColumns = [
    {
      render: (row: LifeEventRow) => {
        const progress = calculateProgress(row.currentValue, row.goal);
        if (row.header === "Current" || row.header === "Completed") {
          if (row.header === "Completed") {
            return <div />;
          } else {
            return <div className="category-name">{row.header}</div>;
          }
        }
        return (
          <EuiFlexGroup
            style={{ flexWrap: "nowrap", gap: "16px", alignItems: "center" }}
          >
            <EuiFlexItem className="progress-chart">
              {row.isCompleted ? (
                <EuiImage
                  className=""
                  src={completed_goal}
                  alt="Check"
                  size="original"
                />
              ) : (
                <div style={{ width: "50px", height: "50px" }}>
                  <CircularProgressbar
                    value={progress}
                    text={`${progress}%`}
                    styles={buildStyles({
                      textSize: "25px",
                      pathColor: `rgba(96, 146, 192, 1), ${progress / 100})`,
                      textColor: "#000",
                      trailColor: "#D3DAE6",
                    })}
                  />
                </div>
              )}
            </EuiFlexItem>
            <EuiFlexItem className="row-title row-title-item" grow={false}>
              {row.header}
              <div className="amounts-review">
                <span className="actual-text">
                  {formatLifeEventDollars(row.currentValue)}
                </span>
                /
                <span className="planned-text">
                  {formatLifeEventDollars(row.goal)}
                </span>
              </div>
            </EuiFlexItem>
          </EuiFlexGroup>
        );
      },
    },
  ];

  const { ongoingRows, completedRows } = getRows();
  const rowsForTable = ongoingRows.concat(
    completedRows.length > 0 ? [...(showCompleted ? completedRows : [])] : []
  );

  return (
    <>
      <Global styles={eventGoalsStyles} />
      {width > 767 ? (
        <EuiCard
          className="fp-summary-card"
          title={
            <EuiFlexGroup
              justifyContent="spaceBetween"
              alignItems="center"
              className="card-header"
            >
              <EuiFlexItem grow={false} className="card-title">
                <h2>Life Events/Goal Progress</h2>
              </EuiFlexItem>
              <EuiFlexItem grow={false} className="card-link">
                <div className="link-group">
                  <StyledEuiLink
                    onClick={() => {
                      return null;
                      // console.log("Edit");
                    }}
                    color="primary"
                  >
                    Edit
                  </StyledEuiLink>
                  <StyledEuiLink
                    onClick={() => {
                      return null;
                      // console.log("Goal Ranking");
                    }}
                    color="primary"
                  >
                    Goal Ranking
                  </StyledEuiLink>
                </div>
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        >
          <>
            <StyledSpacer size="24px" />
            <TableWrapper>
              <EuiBasicTable
                className="eg-table"
                items={rowsForTable}
                columns={columns}
              />
            </TableWrapper>
            <StyledSpacer size="24px" />
            <EuiText>
              <p className="table-info">
                Research has shown that increasing the amount going to Money for
                Future Self is the most important action you can take in hitting
                your goals. In addition, it reduces stress because you're
                focusing on one long-term goal instead of the typical 5-10
                financial goals.
              </p>
            </EuiText>
          </>
        </EuiCard>
      ) : (
        <>
          <EuiCard
            className="fp-summary-card mobile-styles"
            title={
              <EuiFlexGroup
                justifyContent="spaceBetween"
                alignItems="center"
                className="card-header mobile-header"
              >
                <EuiFlexItem grow={false} className="card-title">
                  <h2>Life Events/Goals</h2>
                </EuiFlexItem>
                <EuiFlexItem grow={false} className="card-link">
                  <div className="link-group">
                    <StyledEuiLink
                      onClick={() => {
                        return null;
                        // console.log("Edit");
                      }}
                      color="primary"
                    >
                      Edit
                    </StyledEuiLink>
                    <StyledEuiLink
                      onClick={() => {
                        return null;
                        // console.log("Goal Ranking");
                      }}
                      color="primary"
                    >
                      Goal Ranking
                    </StyledEuiLink>
                  </div>
                </EuiFlexItem>
              </EuiFlexGroup>
            }
          >
            <StyledSpacer size="16px" />
            <div>
              <EuiFlexGroup className="mobile-table">
                {ongoingRows.map((row, index) => (
                  <EuiFlexItem key={index} className="mobile-row">
                    {mobileColumns[0].render(row)}
                  </EuiFlexItem>
                ))}
              </EuiFlexGroup>
            </div>
            <StyledSpacer size="24px" />
            <div>
              <div className="category-name">
                Completed &nbsp;&nbsp;
                <EuiLink
                  className="completed-link"
                  onClick={() => setShowCompleted(!showCompleted)}
                >
                  {showCompleted ? "Hide" : "Show"}
                </EuiLink>
              </div>
              {showCompleted && (
                <>
                  <StyledSpacer size="24px" />
                  <EuiFlexGroup className="mobile-table">
                    {completedRows.map((row, index) => (
                      <EuiFlexItem key={index} className="mobile-row">
                        {mobileColumns[0].render(row)}
                      </EuiFlexItem>
                    ))}
                  </EuiFlexGroup>
                </>
              )}
            </div>
            <StyledSpacer size="24px" />
            <EuiText>
              <p className="table-info">
                Research has shown that increasing the amount going to Money for
                Future Self is the most important action you can take in hitting
                your goals. In addition, it reduces stress because you're
                focusing on one long-term goal instead of the typical 5-10
                financial goals.
              </p>
            </EuiText>
          </EuiCard>
        </>
      )}
    </>
  );
};

export default LifeEventsGoals;
