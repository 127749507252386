import React from "react";
import { useSelector } from "react-redux";
import {
  EuiBasicTable,
  EuiButtonIcon,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
} from "@elastic/eui";
import { Global, css } from "@emotion/react";

import {
  currentPlanFutureProjections,
  getScoreProjection,
} from "src/store/planBuild/selector";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import { useFlyout } from "src/components/Global/FlyoutContext";
import Chart from "src/components/ChartV2";
import ScoreFactorsDialog from "../components/ScoreFactorsDialog";
import useWindowSize from "src/hooks/useWindowSize";

import { reviewStyles } from "./GoalSummary";

const Score = () => {
  const scoreProjection = useSelector(getScoreProjection);
  const future = useSelector(currentPlanFutureProjections);
  const { toggleFlyout } = useFlyout();
  const windowSize = useWindowSize();
  const isStacked = windowSize.width <= 989;
  const now = new Date();
  const nowYear = now.getFullYear();
  const years = ["Now", "" + (nowYear + 5), "" + (nowYear + 10), "" + future.retirementYear];

  const columns = [
    {
      field: "header",
      name: " ",
      width: "40%",
      render: (header: string) => (
        <span className="custom-header">{header}</span>
      ),
    },
    ...years.map((year) => ({
      field: year,
      name: year,
    })),
  ];

  const rows: Array<{ [key: string]: string | number }> = [
    {
      header: "If plan followed",
      ...years.reduce<{ [key: string]: number }>((acc, year, index) => {
        acc[year] = future.score[index];
        return acc;
      }, {}),
    },
  ];

  return (
    <>
      <Global
        styles={css`
          ${reviewStyles}
        `}
      />
      <div className="ai-content-title">
        <EuiFlexGroup style={{ gap: 0 }} className="no-wrap">
          <EuiFlexItem
            grow={2}
            style={{ inlineSize: "auto", flexBasis: "auto" }}
          >
            <h1>My FitBUX Score</h1>
          </EuiFlexItem>
          <EuiFlexItem
            grow={1}
            style={{ inlineSize: "auto", flexBasis: "auto" }}
          >
            <EuiButtonIcon
              onClick={() => toggleFlyout("fitbux-score")}
              iconType="questionInCircle"
              aria-label="Help"
              className="help-icon"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
      <StyledSpacer size="24px" />
      {!!scoreProjection.length && (
        <EuiFlexGroup
          direction={isStacked ? "column" : "row"}
          alignItems="flexStart"
        >
          <EuiFlexItem
            style={
              isStacked ? { width: "100%" } : { flex: "auto", width: "60%" }
            }
          >
            <EuiCard
              title=""
              className="ai-card"
              style={{ width: "100%", height: "auto" }}
            >
              <div className="ai-card-title">
                <h1>Projected FitBUX Score</h1>
              </div>
              <Chart
                data={scoreProjection}
                height={320}
                keys={["score"]}
                xKey="month"
                keyLabels={{ score: "Plan Followed" }}
                width="100%"
                className="ai-chart"
              />
            </EuiCard>
            <StyledSpacer size="16px" />
            <EuiCard title="" className="ai-card">
              <div className="ai-card-title">
                <h1>FitBUX Score</h1>
              </div>
              <EuiBasicTable
                responsive={false}
                tableLayout="fixed"
                className="table-border table-container ai-table"
                columns={columns}
                items={rows as any}
              />
            </EuiCard>
          </EuiFlexItem>
          <EuiFlexItem grow={2} style={{ minWidth: "35%" }}>
            <ScoreFactorsDialog />
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </>
  );
};

export default Score;