import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { EuiBadge } from "@elastic/eui";

import {
  getAccounts,
  getAssetBalanceTotals,
  getMyFedLoanBalance,
  getSpouseFedLoanBalance,
} from "src/store/account/selector";
import {
  getCurrentPlan,
  getEmergencyMonthlyExpenses,
  getFormattedGoalData,
  getPlanProjection,
} from "src/store/planBuild/selector";
import { getProfile, getSpouseProfile } from "src/store/profileBuild/selector";
import {
  getIsMarried,
  spouseSelector,
  userSelector,
} from "src/store/system/selector";
import useWindowSize from "src/hooks/useWindowSize";
import { GRADUATED_LIFE_EVENTS } from "src/interfaces";
import { colors } from "src/theme";

const GROUP_LABELS: any = {
  house: "Home",
  vehicle: "Vehicle",
  property: "Investment Property",
  savings: "Custom Goal",
  vacation: "Vacation",
};

export enum DATA_TYPES {
  GOALS,
  ASSETS,
  DEBTS,
}

const useGoalSummaryData = (dataType: DATA_TYPES) => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 567;
  const plan = useSelector(getCurrentPlan);
  const projection = useSelector(getPlanProjection);
  const timeline = projection?.timeline || [];
  const planEvents: any[] = plan.lifeevents;
  const planGoals = useSelector(getFormattedGoalData);
  const monthlyExpenses = useSelector(getEmergencyMonthlyExpenses);
  const assetBalanceTotals = useSelector(getAssetBalanceTotals);
  const user = useSelector(userSelector);
  const spouse = useSelector(spouseSelector);
  const profile = useSelector(getProfile);
  const spouseProfile = useSelector(getSpouseProfile);
  const isMarried = useSelector(getIsMarried);
  const accounts = useSelector(getAccounts);
  const myFedLoanBalance = useSelector(getMyFedLoanBalance);
  const spouseFedLoanBalance = useSelector(getSpouseFedLoanBalance);
  const emergencyFundTarget = monthlyExpenses * plan.emergencymonths;
  const spouseFirstName = spouse?.first_name || "fiance";
  const myFirstName = user?.first_name || "";

  const populateMarriageGroup = (
    items: any[],
    skipIndices: Set<number>,
    isShortTermGoal?: boolean,
    goalIsMet?: boolean
  ) => {
    if (dataType === DATA_TYPES.DEBTS) {
      return;
    }
    const lifeEvent = planEvents.find(
      (event) => event.eventtype === "marriage"
    );
    const goal = planGoals.find((g) => g.lifeevent_id === lifeEvent?.id);
    const saveItemIndex = timeline.findIndex(
      (item: any) => item.goal_id === goal?.id
    );
    const getMarriedItemIndex = timeline.findIndex(
      (item: any) => item.lifeevent_id === lifeEvent?.id
    );
    skipIndices.add(saveItemIndex);
    skipIndices.add(getMarriedItemIndex);
    const saveItem = timeline[saveItemIndex];
    const getMarriedItem = timeline[getMarriedItemIndex];
    if (!getMarriedItem) {
      return;
    }
    if (!saveItem) {
      items.push({
        label: "Have wedding",
        date: getMarriedItem.date,
        amount: 0,
      });
      return;
    }
    items.push({
      label: <strong>Wedding</strong>,
      date: "",
      amount: 0,
    });
    items.push({
      label: (
        <div className="ml-3">
          Save for wedding
          {dataType === DATA_TYPES.GOALS && isShortTermGoal && (
            <EuiBadge
              className={`${isMobile ? "ml-0" : "ml-4"}`}
              color={colors.backgroundInfo}
            >
              SHORT-TERM
            </EuiBadge>
          )}
          {dataType === DATA_TYPES.GOALS && goalIsMet && (
            <EuiBadge
              className={`${isMobile ? "ml-0" : "ml-4"}`}
              color={colors.backgroundSuccess}
            >
              GOAL MET
            </EuiBadge>
          )}
        </div>
      ),
      date: saveItem.date,
      amount: lifeEvent.down,
    });
    items.push({
      label: <div className="ml-3">Have wedding</div>,
      date: getMarriedItem.date,
      amount: 0,
    });
  };

  const populatePurchaseGroup = (
    lifeEvent: any,
    items: any[],
    skipIndices: Set<number>,
    isShortTermGoal?: boolean,
    goalIsMet?: boolean
  ) => {
    const downpaymentGoal = planGoals.find(
      (goal) => goal.lifeevent_id === lifeEvent?.id && goal.goaltype === "down"
    );
    const payoffGoal = planGoals.find(
      (goal) =>
        goal.lifeevent_id === lifeEvent?.id && goal.goaltype === "payoff"
    );
    const downpaymentItemIndex = downpaymentGoal
      ? timeline.findIndex((item: any) => item.goal_id === downpaymentGoal?.id)
      : -1;
    const purchaseItemIndex = timeline.findIndex(
      (item: any) => item.lifeevent_id === lifeEvent?.id
    );
    const payoffItemIndex = payoffGoal
      ? timeline.findIndex((item: any) => item.goal_id === payoffGoal?.id)
      : -1;
    const downpaymentItem = timeline[downpaymentItemIndex];
    const purchaseItem = timeline[purchaseItemIndex];
    const payoffItem = timeline[payoffItemIndex];
    const validItems = [downpaymentItem, purchaseItem, payoffItem].filter(
      (item) => !!item
    );

    skipIndices.add(downpaymentItemIndex);
    skipIndices.add(purchaseItemIndex);
    skipIndices.add(payoffItemIndex);
    let indent = false;
    if (validItems.length > 1) {
      indent = true;
      items.push({
        label: <strong>{GROUP_LABELS[lifeEvent.eventtype]}</strong>,
        date: "",
        amount: 0,
      });
    }

    if (downpaymentItem) {
      items.push({
        label: (
          <div className={indent ? "ml-3" : ""}>
            {downpaymentGoal.title}
            {dataType === DATA_TYPES.GOALS && isShortTermGoal && (
              <EuiBadge
                className={`${isMobile ? "ml-0" : "ml-4"}`}
                color={colors.backgroundInfo}
              >
                SHORT-TERM
              </EuiBadge>
            )}
            {dataType === DATA_TYPES.GOALS && goalIsMet && (
              <EuiBadge
                className={`${isMobile ? "ml-0" : "ml-4"}`}
                color={colors.backgroundSuccess}
              >
                GOAL MET
              </EuiBadge>
            )}
          </div>
        ),
        date: downpaymentItem.date,
        amount: lifeEvent?.down || 0,
      });
    }
    if (purchaseItem) {
      const labelText = lifeEvent.name
        ? `${lifeEvent.name} - goal achieved`
        : GRADUATED_LIFE_EVENTS[lifeEvent.eventtype]?.goalLabel || "";
      items.push({
        label: (
          <div className={indent ? "ml-3" : ""}>
            {labelText}
            {lifeEvent.recurring === "annually" && (
              <EuiBadge
                className={`${isMobile ? "ml-0" : "ml-4"}`}
                iconType="refresh"
                color={colors.backgroundSubdued}
              >
                ANNUAL
              </EuiBadge>
            )}
          </div>
        ),
        date: purchaseItem.date,
        amount: lifeEvent.recurring ? lifeEvent.down : 0,
      });
    }
    if (dataType !== DATA_TYPES.ASSETS && payoffItem) {
      items.push({
        label: <div className={indent ? "ml-3" : ""}>{payoffGoal.title}</div>,
        date: payoffItem.date,
        amount: lifeEvent.cost - lifeEvent.down,
      });
    }
  };

  const populateIDRGroup = (
    who: string,
    items: any[],
    skipIndices: Set<number>
  ) => {
    const taxbombGoal = planGoals.find(
      (goal) => goal.goaltype === "taxbomb" && goal.who === who
    );
    const forgivenessItemIndex = timeline.findIndex(
      (item: any) => item.goal === "fedloanforgiveness"
    );
    const taxbombItemIndex = timeline.findIndex(
      (item: any) => item.goal_id === taxbombGoal?.id
    );
    const forgivenessItem = timeline[forgivenessItemIndex];
    const taxbombItem = timeline[taxbombItemIndex];
    let indent = false;
    skipIndices.add(forgivenessItemIndex);
    skipIndices.add(taxbombItemIndex);
    if (taxbombItem && forgivenessItem) {
      indent = true;
      items.push({
        label: <strong>Federal loans on IDR</strong>,
        date: "",
        amount: 0,
      });
    }
    const name = who === "spouse" ? myFirstName : spouseFirstName;
    if (taxbombItem) {
      items.push({
        label: (
          <div className={indent ? "ml-3" : ""}>
            Save for {isMarried || who === "spouse" ? `${name}'s ` : ""}IDR tax
          </div>
        ),
        date: taxbombItem.date,
        amount: 0,
      });
    }
    if (forgivenessItem) {
      items.push({
        label: (
          <div className={indent ? "ml-3" : ""}>
            {isMarried || who === "spouse" ? `${name}'s ` : ""}Federal loans are
            forgiven
          </div>
        ),
        date: forgivenessItem.date,
        amount: 0,
      });
    }
  };

  const formattedData = useMemo(() => {
    const items = [];
    const shortTermGoal: any = {};
    const skipIndices = new Set<number>([]);
    let runningCashTotal =
      (assetBalanceTotals.cash_value || 0) - emergencyFundTarget;
    for (let i = 0; i < timeline.length; i++) {
      let isShortTermGoal = false;
      let goalIsMet = false;
      if (skipIndices.has(i)) {
        continue;
      }
      const item = timeline[i];
      const goal = planGoals.find((g) => g.id === item.goal_id);
      if (goal?.goaltype === "emergencyfund") {
        let label: React.ReactNode = "Save for emergency fund";
        if (runningCashTotal > 0) {
          label = (
            <div>
              Save for emergency fund
              <EuiBadge
                className={`${isMobile ? "ml-0" : "ml-4"}`}
                color={colors.backgroundSuccess}
              >
                GOAL MET
              </EuiBadge>
            </div>
          );
        } else {
          shortTermGoal.goal = goal;
          label = (
            <div>
              Save for emergency fund
              <EuiBadge
                className={`${isMobile ? "ml-0" : "ml-4"}`}
                color={colors.backgroundInfo}
              >
                SHORT-TERM
              </EuiBadge>
            </div>
          );
        }
        if (dataType === DATA_TYPES.GOALS) {
          items.push({
            label,
            date: item.date,
            amount: emergencyFundTarget,
          });
        } else if (dataType === DATA_TYPES.ASSETS) {
          items.push({
            label: "Save for emergency fund",
            date: item.date,
            amount: emergencyFundTarget,
          });
        }
        continue;
      }
      if (item.goal_id && !goal) {
        continue;
      }
      if (goal) {
        const lifeEvent = planEvents.find(
          (event) => event.id === goal.lifeevent_id
        );
        const badges: React.ReactNode[] = [];
        const amount =
          goal.goaltype === "payoff" ? goal.balance : goal.savingsGoal;
        if (amount && runningCashTotal > 0) {
          runningCashTotal -= amount;
          if (runningCashTotal <= 0) {
            shortTermGoal.goal = goal;
            shortTermGoal.event = lifeEvent;
            shortTermGoal.account = accounts.find(
              (a) => a.id === goal.accountid
            );
            isShortTermGoal = true;
            badges.push(
              <EuiBadge
                className={`${isMobile ? "ml-0" : "ml-4"}`}
                color={colors.backgroundInfo}
              >
                SHORT-TERM
              </EuiBadge>
            );
          } else {
            if (amount) {
              goalIsMet = true;
              badges.push(
                <EuiBadge
                  className={`${isMobile ? "ml-0" : "ml-4"}`}
                  color={colors.backgroundSuccess}
                >
                  GOAL MET
                </EuiBadge>
              );
            }
          }
        }
        if (goal.recurring === "annually") {
          badges.push(
            <EuiBadge
              className={`${isMobile ? "ml-0" : "ml-4"}`}
              iconType="refresh"
              color={colors.backgroundSubdued}
            >
              ANNUAL
            </EuiBadge>
          );
        }
        if (goal.goaltype === "taxbomb" || item.goal === "fedloanforgiveness") {
          if (dataType === DATA_TYPES.GOALS) {
            const who = item.who || goal.who;
            populateIDRGroup(who, items, skipIndices);
            continue;
          }
        }
        if (goal.goaltype === "down" && dataType === DATA_TYPES.GOALS) {
          if (
            [
              "marriage",
              "house",
              "property",
              "vehicle",
              "savings",
              "vacation",
            ].includes(lifeEvent?.eventtype)
          ) {
            switch (lifeEvent.eventtype) {
              case "marriage":
                populateMarriageGroup(
                  items,
                  skipIndices,
                  isShortTermGoal,
                  goalIsMet
                );
                break;
              case "house":
              case "property":
              case "vehicle":
              case "savings":
              case "vacation":
                populatePurchaseGroup(
                  lifeEvent,
                  items,
                  skipIndices,
                  isShortTermGoal,
                  goalIsMet
                );
                break;
              default:
                break;
            }
            continue;
          } else {
            items.push({
              label: (
                <div>
                  {goal.title}
                  {dataType === DATA_TYPES.GOALS && <>{badges}</>}
                </div>
              ),
              date: item.date,
              amount: goal.savingsGoal || 0,
            });
          }
        } else {
          if (
            (dataType === DATA_TYPES.ASSETS && goal.goaltype !== "taxbomb") ||
            (dataType === DATA_TYPES.DEBTS &&
              ["down", "taxbomb"].includes(goal.goaltype))
          ) {
            continue;
          }
          items.push({
            label: (
              <div>
                {goal.title}
                {dataType === DATA_TYPES.GOALS && <>{badges}</>}
              </div>
            ),
            date: item.date,
            amount: goal.balance || 0,
          });
        }
      } else {
        const lifeEvent = planEvents.find(
          (event) => event.id === item.lifeevent_id
        );
        if (lifeEvent) {
          if (
            ["house", "property", "vehicle", "savings", "vacation"].includes(
              lifeEvent?.eventtype
            ) &&
            dataType === DATA_TYPES.GOALS
          ) {
            populatePurchaseGroup(lifeEvent, items, skipIndices, false, false);
            continue;
          }
          if (
            dataType === DATA_TYPES.DEBTS &&
            !["house", "property", "vehicle"].includes(lifeEvent?.eventtype)
          ) {
            continue;
          }
          let labelText = lifeEvent.name
            ? `${lifeEvent.name} - goal achieved`
            : GRADUATED_LIFE_EVENTS[lifeEvent.eventtype]?.goalLabel || "";
          if (
            isMarried &&
            ["reducejob", "enlargejob"].includes(lifeEvent.eventtype)
          ) {
            const name =
              lifeEvent.who === "spouse" ? spouseFirstName : myFirstName;
            labelText = labelText.replace("Expected", `${name}'s expected`);
          }
          if (isMarried && lifeEvent?.eventtype === "partjob") {
            const name =
              lifeEvent.who === "spouse" ? spouseFirstName : myFirstName;
            labelText = labelText.replace("Begin", `${name} begins`);
          }
          if (lifeEvent.eventtype === "child" && lifeEvent.reduce) {
            if (!isMarried && lifeEvent.reduce !== "spouse") {
              labelText = "Have a child and reduce income";
            } else {
              const name =
                lifeEvent.reduce === "spouse" ? spouseFirstName : myFirstName;
              labelText = `Have a child and ${name} reduces income`;
            }
          }
          let label: React.ReactNode = <div>{labelText}</div>;
          let amount = 0;
          if (
            lifeEvent.eventtype === "vacation" &&
            dataType === DATA_TYPES.GOALS
          ) {
            label = "Go on vacation";
            amount = lifeEvent.down;
            if (lifeEvent.recurring === "annually") {
              label = (
                <div>
                  Go on vacation{" "}
                  <EuiBadge
                    className={`${isMobile ? "ml-0" : "ml-4"}`}
                    iconType="refresh"
                    color={colors.backgroundSubdued}
                  >
                    ANNUAL
                  </EuiBadge>
                </div>
              );
            }
          }
          if (
            !(
              dataType !== DATA_TYPES.GOALS &&
              lifeEvent.eventtype === "vacation"
            )
          ) {
            items.push({
              label,
              date: item.date,
              amount,
            });
          }
        } else {
          switch (item.goal) {
            case "fedloanpayoff":
              if (dataType !== DATA_TYPES.ASSETS) {
                items.push({
                  label: `Pay off ${
                    item.who === "spouse" ? spouseFirstName : myFirstName
                  }'s Federal student loans`,
                  date: item.date,
                  amount:
                    item.who === "spouse"
                      ? spouseFedLoanBalance
                      : myFedLoanBalance,
                });
              }
              break;

            case "fedloanforgiveness":
              if (dataType !== DATA_TYPES.ASSETS) {
                items.push({
                  label: `${
                    item.who === "spouse" ? spouseFirstName : myFirstName
                  }'s Federal student loans are forgiven`,
                  date: item.date,
                  amount: 0,
                });
              }
              break;
            case "child_leaves_nest":
              if (dataType !== DATA_TYPES.DEBTS) {
                items.push({
                  label: "Child leaves nest",
                  date: item.date,
                  amount: 0,
                });
              }
              break;
            default:
              items.push({
                label: JSON.stringify(item),
                date: item.date,
                amount: 0,
              });
          }
        }
      }
    }
    if (dataType === DATA_TYPES.GOALS) {
      const retirementEvents = planEvents.filter(
        (event) => event.eventtype === "retire"
      );
      retirementEvents.forEach((event) => {
        if (event.who === "spouse" && !isMarried) {
          return;
        }
        const dobYear =
          event.who === "spouse" ? spouseProfile.dob_year : profile.dob_year;
        const retirementYear = event.date.slice(0, 4);
        const age = +retirementYear - +dobYear;
        let label = `Retirement, age ${age}`;
        if (isMarried || event.who === "spouse") {
          label = `Retirement (${
            event.who === "spouse" ? spouseFirstName : myFirstName
          }), age ${age}`;
        }
        items.push({
          label,
          date: event.date,
          amount: 0,
        });
      });
    }
    return { items, shortTermGoal };
  }, [timeline, planGoals, planEvents, dataType]);

  return formattedData;
};

export default useGoalSummaryData;
