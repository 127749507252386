import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from "recharts";
import colors from "src/theme/colors";
import { formatWholeDollars } from "src/utils"; // Import the formatting utility

const DEFAULT_COLORS = [colors.vizBehindText2, colors.CoreLightShade];

interface IChart {
  key?: string;
  value?: number;
}

interface ICircularProgress {
  data: IChart[];
  format?: "percent" | "currency"; // Add a format type
  labelXOffset?: number;
  labelYOffset?: number;
  value?: number;
  chartLabel?: string;
  colors?: string[]; // Add a colors prop
}

const CustomLabel = ({ viewBox, label, value, format }: { viewBox: any, label: string, value: number, format?: "percent" | "currency" }) => {
  if (!viewBox) return null;
  const { cx, cy } = viewBox;

  // Format the value based on the format prop
  let formattedValue = `${value}%`; // Default to percentage
  if (format === "currency") {
    formattedValue = formatWholeDollars(value); // Use your currency formatting function
  }

  return (
    <text x={cx} y={cy} textAnchor="middle" dominantBaseline="central">
      <tspan x={cx} dy="-0.3em" fontSize="24px" fontFamily="Inter" fontWeight="800" fill={colors.textShade}>
        {formattedValue}
      </tspan>
      <tspan x={cx} dy="2em" fontSize="14px" fontFamily="Inter" fontWeight="400" fill={colors.textShade} style={{ marginTop: "1rem" }}>
        {label}
      </tspan>
    </text>
  );
};

const CircularProgressBar = ({ data, chartLabel, value, format, colors = DEFAULT_COLORS }: ICircularProgress) => {
  if (!data || data.length < 2) {
    console.error("Invalid data passed to CircularProgressBar:", data);
    return null;
  }

  // Set default values for chartLabel and value if they are undefined
  const label = chartLabel || "Default Label";
  const displayValue = value !== undefined ? value : 0;

  return (
    <div style={{ width: '157px', height: '157px', margin: 'auto' }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            innerRadius="80%"
            outerRadius="100%"
            startAngle={90}
            endAngle={-270}
            fill="#8884d8"
            dataKey="value"
            labelLine={false}
            label={({ cx, cy }) => <CustomLabel viewBox={{ cx, cy }} label={label} value={displayValue} format={format} />}
          >
            {data.map((item, index) => (
              <Cell
                name={item.key}
                key={`cell-${index}`}
                fill={colors[index] || DEFAULT_COLORS[index % DEFAULT_COLORS.length]} // Use provided colors or fallback
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CircularProgressBar;
