import React from "react";
import { useSelector } from "react-redux";

import HumanCapital from "../Components/HumanCapital";
import Chart from "src/components/ChartV2";
import {
  getDashboardHcProjection,
  getLiveProjection,
} from "src/store/planBuild/selector";
import { getHasPlan } from "src/store/system/selector";
import { chartData } from "../data";
import {
  EuiFlexGroup,
  EuiButtonIcon,
  EuiCard,
  EuiFlexItem,
} from "@elastic/eui";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import { useFlyout } from "src/components/Global/FlyoutContext";

const Summary = () => {
  const hasPlan = useSelector(getHasPlan);
  const hcProjection = useSelector(getDashboardHcProjection);
  const liveProjection = useSelector(getLiveProjection);
  const { toggleFlyout } = useFlyout();

  return (
    <>
      <div className="hc-tab">
        <div className="hc-desc">
          <div className="hc-desc-text">
            <>
              <h1>
                Understanding Human Capital
                <EuiButtonIcon
                  iconType="questionInCircle"
                  aria-label="Icon button"
                  size="m"
                  iconSize="l"
                  onClick={() => toggleFlyout("human-capital")}
                />
              </h1>
            </>
          </div>
          <StyledSpacer size="16px" />
          <p>
            Human Capital represents the value of one's future earnings and is
            the largest asset for most young professionals. By saving one's
            earnings over time, intangible Human Capital is converted into
            tangible financial assets. FitBUX looks at your Human Capital,
            including your income potential and any risks to your income, to
            help you build a customized financial plan and inform key financial
            decisions (e.g., Should you buy vs. rent?).
          </p>
        </div>
      </div>
      <StyledSpacer size="32px" />
      <EuiFlexGroup>
        <EuiFlexItem>
          <HumanCapital />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            title=""
            className="ai-card"
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "350px",
            }}
          >
            <div className="ai-card-title">
              <h1>Projected Human Capital</h1>
            </div>
            {!hasPlan && (
              <Chart
                title="Projected Human Capital"
                isLocked
                data={chartData}
                xKey="name"
                keys={["uv", "pv", "amt"]}
                lockedButton={{
                  label: "Build and Implement My Plan",
                  to: "/build-plan",
                }}
                width="100%"
              />
            )}
            {hasPlan && (
              <Chart
                dollars
                title="Projected Human Capital"
                data={hcProjection}
                xKey="month"
                keys={["hc"]}
                keyLabels={{
                  hc: "Current Projected",
                }}
                startMonth={
                  liveProjection ? +liveProjection.start.slice(5) : undefined
                }
                startYear={
                  liveProjection ? +liveProjection.start.slice(0, 4) : undefined
                }
                width="100%"
              />
            )}
          </EuiCard>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

export default Summary;
