import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  EuiBadge,
  EuiButtonIcon,
  EuiBasicTable,
  EuiText,
  EuiTitle,
  EuiPopover,
  EuiContextMenu,
  EuiIcon,
} from "@elastic/eui";
import { Global, css } from "@emotion/react";

import pencil from "src/assets/svgs/pencil.svg";
import trash from "src/assets/svgs/trash.svg";
import actions from "src/assets/svgs/actions_mobile.svg";
import { MONTHS } from "src/constants";
import {
  emptyPlan,
  OptimizedRecapItem,
  QuestionFormProps,
} from "src/interfaces/optimizedPlanBuild.interface";
import {
  getIsMarried,
  spouseSelector,
  userSelector,
} from "src/store/system/selector";
import { STEP_INDICES } from "../common";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import useWindowSize from "src/hooks/useWindowSize";
import { getShortDateString } from "src/utils";

const contextMenuStyles = css`
  .euiPopover__arrow::before {
    border-color: transparent transparent #fff transparent !important;
  }
`;

interface RecapProps extends QuestionFormProps {
  handleClick?: (n: number, subI: number, isSpouse?: boolean) => void;
}

const Recap = ({
  planSetter,
  planData,
  nextClicked,
  goToNext,
  handleClick,
}: RecapProps) => {
  const isMarried = useSelector(getIsMarried);
  const user = useSelector(userSelector);
  const spouse = useSelector(spouseSelector);
  const [nextClick] = useState(nextClicked);
  const myFirstName = user?.first_name;
  const spouseFirstName = spouse?.first_name;

  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 567;

  const recapEntries = useMemo(() => {
    const entries: OptimizedRecapItem[] = [];
    if (planData.will_nonprofit === "y") {
      entries.push({
        description: `${
          isMarried ? myFirstName + " works" : "Work"
        } at a Non-profit`,
        stepIndex: STEP_INDICES.WorkStatus,
        resetKeys: ["will_nonprofit"],
      });
    }
    if (planData.s_will_nonprofit === "y") {
      entries.push({
        description: `${spouseFirstName} works at a Non-profit`,
        stepIndex: STEP_INDICES.WorkStatus,
        resetKeys: ["s_will_nonprofit"],
        spouse: true,
      });
    }
    if (planData.has_401k === "y") {
      entries.push({
        description: `${
          isMarried ? myFirstName + "'s r" : "R"
        }etirement - 401K/403b/457/TSP`,
        stepIndex: STEP_INDICES.EmployerRetirement,
        resetKeys: [
          "has_401k",
          "retirement_limit",
          "retirement_match",
          "retirement_additional_limit",
          "retirement_additional_match",
          "retirement_student_loan_match",
          "has_roth_401k",
          "retirement_nonelective_contribution",
        ],
      });
    }
    if (planData.s_has_401k === "y") {
      entries.push({
        description: `${spouseFirstName}'s Retirement - 401K/403b/457/TSP`,
        stepIndex: STEP_INDICES.EmployerRetirement,
        resetKeys: [
          "s_has_401k",
          "s_retirement_limit",
          "s_retirement_match",
          "s_retirement_additional_limit",
          "s_retirement_additional_match",
          "s_retirement_student_loan_match",
          "s_has_roth_401k",
          "s_retirement_nonelective_contribution",
        ],
        spouse: true,
      });
    }
    if (planData.has_hsa === "y") {
      entries.push({
        description: "Retirement - HSA",
        stepIndex: STEP_INDICES.HSA,
        resetKeys: ["has_hsa", "employer_hsa_contribution"],
      });
    }
    if (planData.will_marry === "y") {
      entries.push({
        description: "Getting Married",
        date: getShortDateString(planData.marry_date),
        stepIndex: STEP_INDICES.Marriage,
        resetKeys: [
          "will_marry",
          "marry_date",
          "marry_spouse_fed_loan_balance",
          "marry_spouse_priv_loan_balance",
        ],
      });
    }
    if (planData.will_move === "y") {
      entries.push({
        description: "Moving to a Different Area",
        date: getShortDateString(planData.move_date),
        stepIndex: STEP_INDICES.Moving,
        resetKeys: ["will_move", "move_zip_code", "move_date"],
      });
    }
    if (planData.vehicle_date !== "") {
      entries.push({
        description: "Purchase a Vehicle",
        date: getShortDateString(planData.vehicle_date),
        stepIndex: STEP_INDICES.AutoLoan,
        resetKeys: [
          "vehicle_date",
          "vehicle_down",
          "vehicle_cost",
          "vehicle_type",
        ],
      });
    }
    if (planData.vacation_date !== "") {
      let date = planData.vacation_date;
      if (planData.vacation_recurring === "y") {
        const monthIndex = +date.slice(5) - 1;
        date = `Every ${MONTHS[monthIndex]}`;
      } else {
        date = getShortDateString(date);
      }
      entries.push({
        description: "Go on vacation",
        recurring: planData.vacation_recurring === "y",
        date,
        stepIndex: STEP_INDICES.Vacation,
        resetKeys: ["vacation_down", "vacation_date", "vacation_recurring"],
      });
    }
    planData.children_dates.forEach((e, index) => {
      entries.push({
        description: "Have a Child",
        date: getShortDateString(e),
        subIndex: index,
        stepIndex: STEP_INDICES.Children,
        resetKeysAtIndex: [
          "children_dates",
          "child_income_drop",
          "s_child_income_drop",
        ],
      });
    });
    planData.income_increase_date.forEach((e, index) => {
      entries.push({
        description: `${
          isMarried ? myFirstName + " expects" : "Expect"
        } increase in income`,
        date: getShortDateString(e),
        subIndex: index,
        stepIndex: STEP_INDICES.IncomeIncrease,
        resetKeysAtIndex: ["income_increase", "income_increase_date"],
      });
    });
    planData.income_decrease_date.forEach((e, index) => {
      entries.push({
        description: `${
          isMarried ? myFirstName + " expects" : "Expect"
        } decrease in income`,
        date: getShortDateString(e),
        subIndex: index,
        stepIndex: STEP_INDICES.IncomeDecrease,
        resetKeysAtIndex: ["income_decrease", "income_decrease_date"],
      });
    });
    planData.s_income_increase_date.forEach((e, index) => {
      entries.push({
        description: `${spouseFirstName} expects increase in income`,
        date: getShortDateString(e),
        stepIndex: STEP_INDICES.IncomeIncrease,
        subIndex: index,
        resetKeysAtIndex: ["s_income_increase", "s_income_increase_date"],
        spouse: true,
      });
    });
    planData.s_income_decrease_date.forEach((e, index) => {
      entries.push({
        description: `${spouseFirstName} expects decrease in income`,
        date: getShortDateString(e),
        subIndex: index,
        stepIndex: STEP_INDICES.IncomeDecrease,
        resetKeysAtIndex: ["s_income_decrease", "s_income_decrease_date"],
        spouse: true,
      });
    });
    if (planData.employer_student_loan_contribution) {
      entries.push({
        description: `${
          isMarried ? myFirstName + "'s e" : "E"
        }mployer contributions to student loans`,
        stepIndex: STEP_INDICES.EmployerStudentLoans,
        resetKeys: ["employer_student_loan_contribution"],
      });
    }
    if (planData.s_employer_student_loan_contribution) {
      entries.push({
        description: `${spouseFirstName}'s employer contributions to student loans`,
        stepIndex: STEP_INDICES.EmployerStudentLoans,
        resetKeys: ["s_employer_student_loan_contribution"],
        spouse: true,
      });
    }
    const averageRiskTolerance =
      (planData.risk_comfort_level +
        planData.invest_comfort_level +
        planData.debt_comfort_level) /
      3;
    if (averageRiskTolerance >= 3) {
      entries.push({
        description: "Greater preference for investing",
        stepIndex: STEP_INDICES.InvestOrPayoff,
      });
    } else {
      entries.push({
        description: "Greater preference for paying off debt",
        stepIndex: STEP_INDICES.InvestOrPayoff,
      });
    }
    // sort in ascending order
    entries.sort((a, b) => {
      const hasBLessThanA =
        a.date && b.date && (b.recurring || a.date > b.date);
      const hasAButNoB = a.date && !b.date;
      if (hasBLessThanA || hasAButNoB) {
        return 1;
      } else {
        return -1;
      }
    });
    return entries;
  }, [planData, isMarried, myFirstName, spouseFirstName]);

  useEffect(() => {
    if (nextClicked !== nextClick) {
      goToNext();
    }
  }, [nextClicked, nextClick, goToNext]);

  const editItem = (entry: any) => {
    if (handleClick) {
      handleClick(entry.stepIndex, entry.subIndex || 0, entry.spouse);
    }
  };

  const deleteItem = (entry: any) => {
    if (!entry) {
      return;
    }
    const empty: any = {};
    if (entry.resetKeys) {
      entry.resetKeys.forEach((key: any) => {
        empty[key as keyof typeof empty] =
          emptyPlan[key as keyof typeof emptyPlan];
      });
    } else if (entry.resetKeysAtIndex && entry.subIndex !== undefined) {
      entry.resetKeysAtIndex.forEach((key: any) => {
        const data = planData[key as keyof typeof planData];
        if (Array.isArray(data)) {
          // this is to be sure that we are working with fresh data and not a reference
          const pData = [...data];
          pData.splice(entry.subIndex || 0, 1);
          empty[key as keyof typeof empty] = pData;
        }
      });
    }
    const newPlan = {
      ...planData,
      ...empty,
    };
    planSetter(newPlan);
  };

  const RenderActions = ({ item }: { item: any }) => {
    const [isPopoverOpen, setPopoverOpen] = useState(false);

    const closePopover = () => {
      setPopoverOpen(false);
    };

    const button = (
      <EuiButtonIcon
        iconType={actions}
        onClick={() => setPopoverOpen(!isPopoverOpen)}
        aria-label="Actions"
      />
    );

    const items = [
      {
        name: "Edit",
        icon: <EuiIcon type={pencil} />,
        onClick: () => editItem(item),
      },
      {
        name: "Delete",
        icon: <EuiIcon type={trash} />,
        onClick: () => deleteItem(item),
      },
    ];

    return (
      <>
        <Global styles={contextMenuStyles} />
        <EuiPopover
          button={button}
          isOpen={isPopoverOpen}
          closePopover={closePopover}
          panelPaddingSize="none"
          anchorPosition="downLeft"
          className="recap-actions-popover"
        >
          <EuiContextMenu
            initialPanelId={0}
            panels={[{ id: 0, items }]}
            className="context-menu"
          />
        </EuiPopover>
      </>
    );
  };

  return (
    <>
      <EuiTitle className="ai-content-title">
        <h1>Recap</h1>
      </EuiTitle>
      <StyledSpacer size="16px" />
      <EuiText className="ai-secondary-text">
        <p>
          Below are the responses you'd like us to use in your financial plan.
        </p>
      </EuiText>
      <StyledSpacer size="32px" />
      <EuiBasicTable
        className="table-border table-container"
        responsive={false}
        tableLayout="fixed"
        items={recapEntries}
        columns={[
          {
            field: "description",
            name: "",
            width: isMobile ? "60%" : "",
            render: (value: string, item: any) => {
              if (value === "Go on vacation" && item.recurring) {
                return (
                  <div>
                    {value} <EuiBadge iconType="refresh">ANNUAL</EuiBadge>
                  </div>
                );
              }
              return value;
            },
          },
          {
            field: "date",
            name: isMobile ? "Date" : "Target Date",
            width: isMobile ? "30%" : "300px",
            // render: (value: string | undefined) => {
            //   return value ? getShortDateString(value) : "";
            // },
          },
          {
            field: "",
            name: isMobile ? "" : "Actions",
            width: isMobile ? "10%" : "75px",
            className: "text-center",
            render: (item: any) =>
              isMobile ? (
                <RenderActions item={item} />
              ) : (
                <>
                  <EuiButtonIcon
                    aria-label="Edit"
                    iconType={pencil}
                    onClick={() => editItem(item)}
                  />
                  {item?.stepIndex !== STEP_INDICES.InvestOrPayoff && (
                    <>
                      &nbsp;&nbsp;
                      <EuiButtonIcon
                        aria-label="Delete"
                        iconType={trash}
                        onClick={() => deleteItem(item)}
                      />
                    </>
                  )}
                </>
              ),
          },
        ]}
      />
    </>
  );
};

export default Recap;
