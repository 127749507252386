import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
  EuiLink,
  EuiButton,
} from "@elastic/eui";

import ConfirmedTransactions from "./Blocks/ConfirmedTransactions";
import ConfirmIncome from "./Blocks/ConfirmIncome";
import ConfirmIncomeDialog from "./Dialogs/ConfirmIncomeDialog";
import { getBreakoutBaseApi } from "src/apiService";
import { Transaction } from "src/interfaces";
import { hideActionItem } from "src/store/dashboard/actions";
import AccountLinkPage from "src/pages/Dashboard/MyProfile/Accounts/components/AccountLinkPage";
import { useFlyout } from "src/components/Global/FlyoutContext";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import AddManualTransaction from "./Dialogs/AddManualTransactionDialog";
import useWindowSize from "src/hooks/useWindowSize";

const emptyTransaction: Transaction = {
  account: -999,
  amount: -999,
  date: "1999-01-01",
  description: "",
  id: -999,
  type: 7000,
};

export const Content = () => {
  const dispatch = useDispatch();
  // TODO
  // const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [linkAccountDialogVisible, setLinkAccountDialogVisible] = useState(
    false
  );
  const openLinkAccountModal = () => setLinkAccountDialogVisible(true);
  const closeLinkAccountModal = () => setLinkAccountDialogVisible(false);
  const { openCustomFlyout, closeFlyout } = useFlyout();
  // const [showEditExpenseDialog, setShowEditExpenseDialog] = useState(false);
  const [
    confirmingTransaction,
    setConfirmingTransaction,
  ] = useState<Transaction>(emptyTransaction);
  const [editing, setEditing] = useState(false);
  // const [addDialogVisible, setAddDialogVisible] = useState(false);

  // Use the custom hook to get the window size
  const windowSize = useWindowSize();

  // Determine the text based on the screen size
  const isMobile = windowSize.width <= 768;
  const linkText = isMobile ? "Add Manually" : "Add Account Manually";
  const buttonText = isMobile ? "Connect" : "Connect Account";

  useEffect(() => {
    dispatch(hideActionItem("TRANS_REVIEW"));
  }, [dispatch]);

  const openAddFlyout = () => {
    openCustomFlyout({
      title: "Add Manual Transaction",
      component: AddManualTransaction,
    });
  };

  const openConfirmDialog = (transaction?: Transaction, editing?: boolean) => {
    setEditing(!!editing);
    let rootTransaction = transaction;
    let promise = Promise.resolve();

    if (transaction) {
      promise = getBreakoutBaseApi(transaction.id)
        .then((result: any) => {
          rootTransaction = result?.transactions?.[0] || transaction;
          console.log("Transaction from API:", result?.transactions?.[0]);
        })
        .catch(console.error);
    }

    return promise.then(() => {
      console.log("Root transaction before setting state:", rootTransaction);
      if (rootTransaction) {
        setConfirmingTransaction(rootTransaction);
      } else {
        setConfirmingTransaction(emptyTransaction);
      }

      openCustomFlyout({
        title: "Confirm Transaction",
        component: (props: any) => (
          <ConfirmIncomeDialog
            {...props}
            transaction={rootTransaction}
            alreadyConfirmed={editing}
            onClose={closeConfirmDialog}
          />
        ),
      });
    });
  };

  const closeConfirmDialog = () => {
    closeFlyout();
    setEditing(false);
  };

  return (
    <div className="tx-page">
      {isMobile ? (
        <EuiFlexGroup className="tx-flex-group">
          <EuiFlexItem grow={false}>
            <EuiTitle size="s" className="tx-header-text">
              <h1>Transactions</h1>
            </EuiTitle>
          </EuiFlexItem>
          <EuiFlexItem grow={false} className="align-center justify-center">
            <EuiLink color="primary" onClick={openAddFlyout}>
              {linkText}
            </EuiLink>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton
              fill
              style={{ maxWidth: "160px", width: "100%" }}
              onClick={openLinkAccountModal}
            >
              {buttonText}
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      ) : (
        <EuiFlexGroup className="tx-flex-group">
          <EuiFlexItem>
            <EuiTitle size="s" className="tx-header-text">
              <h1>Transactions</h1>
            </EuiTitle>
          </EuiFlexItem>
          <EuiFlexItem className="tx-flex-end">
            <EuiFlexGroup className="tx-link-group" justifyContent="flexEnd">
              <EuiFlexItem className="align-center justify-center" grow={0}>
                <EuiLink color="primary" onClick={openAddFlyout}>
                  Add Transaction Manually
                </EuiLink>
              </EuiFlexItem>
              <EuiFlexItem grow={0}>
                <EuiButton
                  fill
                  style={{ maxWidth: "160px;", width: "100%" }}
                  onClick={openLinkAccountModal}
                >
                  Connect Account
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
      <StyledSpacer size="32px" />
      <ConfirmIncome openConfirmDialog={openConfirmDialog} />
      <StyledSpacer size="32px" />
      <ConfirmedTransactions
        openDialog={(transaction: Transaction) =>
          openConfirmDialog(transaction, true)
        }
      />

      {linkAccountDialogVisible && (
        <AccountLinkPage
          isOpen={linkAccountDialogVisible}
          onClose={closeLinkAccountModal}
        />
      )}
    </div>
  );
};
