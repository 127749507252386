import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiText,
  EuiTitle,
  EuiIcon,
  EuiButton,
  EuiTabs,
  EuiTab,
  EuiBasicTableColumn,
} from "@elastic/eui";

import "src/pages/PageStyles/Accounts.css";
import pencil from "src/assets/svgs/grey_pencil.svg";

import { StyledSpacer } from "src/components/Global/StyledComponents";
import { useFlyout } from "src/components/Global/FlyoutContext";
import FormError from "src/components/Global/FormError";
import AccountLinkPage from "src/pages/Dashboard/MyProfile/Accounts/components/AccountLinkPage";
import AddManualAccountForm from "src/pages/ProfileBuilder/Views/AssetsAndDebts/AddManualAccountForm";
import useWindowSize from "src/hooks/useWindowSize";

import {
  getAllFormattedAssetsAndDebts,
  getAccountProviders,
} from "src/store/account/selector";
import { getIsMarried } from "src/store/system/selector";
import {
  getAccountProviders as fetchAccountProviders,
  getAccounts as fetchAccounts,
} from "src/store/account/actions";
import { closeTourCard } from "src/store/tourcard/actions";

import { PROFILE_TYPE } from "src/store/profileBuild/constants";
import { MappedAccount } from "src/interfaces";

interface GroupedAccount {
  type: string;
  totalBalance: number;
  accountCount: number;
  accounts: {
    id: number;
    type: string;
    name: string;
    owner?: string;
    firm?: string;
    balance: number;
  }[];
}

export const Content = () => {
  const dispatch = useDispatch();
  const { openCustomFlyout } = useFlyout();
  const [selectedTab, setSelectedTab] = useState("assets");
  const [expandedAssetRows, setExpandedAssetRows] = useState<Set<string>>(
    new Set()
  );
  const [expandedDebtRows, setExpandedDebtRows] = useState<Set<string>>(
    new Set()
  );
  const [linkAccountDialogVisible, setLinkAccountDialogVisible] = useState(
    false
  );
  const { assets, debts }: any = useSelector(getAllFormattedAssetsAndDebts);
  const providers = useSelector(getAccountProviders);
  const isMarried = useSelector(getIsMarried);
  const errorProviders = providers.filter(
    (provider) => provider.error !== null
  );
  const errorAccountNames = errorProviders.map((provider) => provider.name);

  // Use the custom hook to get the window size
  const windowSize = useWindowSize();

  // Determine the text based on the screen size
  const isMobile = windowSize.width <= 768;
  const linkText = isMobile ? "Add Manually" : "Add Account Manually";
  const buttonText = isMobile ? "Connect" : "Connect Account";

  const tabs = [
    { id: "assets", name: "Asset Accounts" },
    { id: "debts", name: "Debt Accounts" },
  ];

  const onTabClick = (tabId: any) => setSelectedTab(tabId);
  const openLinkAccountModal = () => setLinkAccountDialogVisible(true);
  const closeLinkAccountModal = () => setLinkAccountDialogVisible(false);

  useEffect(() => {
    dispatch(fetchAccountProviders());
    dispatch(fetchAccounts());
  }, [dispatch]);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const formatBalance = (balance: number) => {
    const formatted = balance.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });

    return formatted;
  };

  const assetTotal = assets.reduce(
    (total: number, account: any) => total + (account.balance || 0),
    0
  );
  const debtTotal = debts.reduce(
    (total: number, account: any) => total + (account.balance || 0),
    0
  );

  const renderErrorMessage = () => {
    if (errorAccountNames.length === 0) return null;

    const formattedAccountNames =
      errorAccountNames.length > 1
        ? `${errorAccountNames.slice(0, -1).join(", ")}${
            errorAccountNames.length > 2 ? "," : ""
          } and ${errorAccountNames[errorAccountNames.length - 1]}`
        : errorAccountNames[0];

    return (
      <>
        <FormError type="callout" title="Please fix the following error(s)">
          We encountered issues connecting to {formattedAccountNames}{" "}
          <EuiLink
            color="text"
            onClick={openLinkAccountModal}
            style={{ textDecoration: "underline" }}
          >
            Click here to resolve
          </EuiLink>
          .
        </FormError>
        <StyledSpacer size="32px" />
      </>
    );
  };

  const renderExpandedRows = (accounts: MappedAccount[]) => {
    return isMobile ? (
      <div style={{ width: "100%" }}>
        {accounts.map((account) => (
          <div
            key={account.id}
            style={{ padding: "16px", borderBottom: "1px solid #ccc" }}
          >
            {account.name && (
              <EuiFlexGroup
                style={{ paddingBottom: "8px", flexWrap: "nowrap" }}
              >
                <EuiFlexItem style={{ flex: "0 0 30%", maxWidth: "30%" }}>
                  <EuiText>
                    <strong>Name:</strong>
                  </EuiText>
                </EuiFlexItem>
                <EuiFlexItem style={{ flex: "1", maxWidth: "70%" }}>
                  <EuiText>{account.name}</EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            )}
            {isMarried && account.whose && (
              <EuiFlexGroup
                style={{ paddingBottom: "8px", flexWrap: "nowrap" }}
              >
                <EuiFlexItem style={{ flex: "0 0 30%", maxWidth: "30%" }}>
                  <EuiText>
                    <strong>Owner:</strong>
                  </EuiText>
                </EuiFlexItem>
                <EuiFlexItem style={{ flex: "1", maxWidth: "70%" }}>
                  <EuiText>{account.whose}</EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            )}
            {account.firm && (
              <EuiFlexGroup
                style={{ paddingBottom: "8px", flexWrap: "nowrap" }}
              >
                <EuiFlexItem style={{ flex: "0 0 30%", maxWidth: "30%" }}>
                  <EuiText>
                    <strong>Institution:</strong>
                  </EuiText>
                </EuiFlexItem>
                <EuiFlexItem style={{ flex: "1", maxWidth: "70%" }}>
                  <EuiText>{account.firm}</EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            )}
            {account.balance != null && (
              <EuiFlexGroup
                style={{ paddingBottom: "8px", flexWrap: "nowrap" }}
              >
                <EuiFlexItem style={{ flex: "0 0 30%", maxWidth: "30%" }}>
                  <EuiText>
                    <strong>Balance:</strong>
                  </EuiText>
                </EuiFlexItem>
                <EuiFlexItem style={{ flex: "1", maxWidth: "70%" }}>
                  <EuiText>{formatBalance(account.balance)}</EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            )}
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiLink onClick={() => getOpenEditFlyoutFunction()(account)}>
                  Edit
                </EuiLink>
              </EuiFlexItem>
            </EuiFlexGroup>
          </div>
        ))}
      </div>
    ) : (
      <div>
        <EuiBasicTable
          items={accounts}
          className="expanded-rows"
          columns={[
            { field: "name", name: "Name", truncateText: true },
            ...(isMarried
              ? [{ field: "owner", name: "Owner", truncateText: true }]
              : []),
            { field: "firm", name: "Institution", truncateText: true },
            {
              field: "balance",
              name: "Balance",
              render: (balance: number) => formatBalance(balance),
            },
            {
              name: "Actions",
              render: (account: any) => (
                <EuiIcon
                  type={pencil}
                  size="s"
                  onClick={() => getOpenEditFlyoutFunction()(account)}
                  aria-label="Edit account"
                  style={{ cursor: "pointer" }}
                />
              ),
            },
          ]}
        />
      </div>
    );
  };

  const groupedAssetAccounts = useMemo(() => {
    const grouped = assets.reduce((acc: any, account: any) => {
      const typeLabel = account.variableLabel || account.type;
      if (!acc[typeLabel]) {
        acc[typeLabel] = { type: typeLabel, totalBalance: 0, accounts: [] };
      }
      acc[typeLabel].totalBalance += account.balance;
      acc[typeLabel].accounts.push({
        id: account.id,
        name: account.name,
        owner: account.who === PROFILE_TYPE.SPOUSE ? "Spouse" : "Mine",
        firm: account.firm,
        balance: account.balance,
        type: account.variable || typeLabel,
      });
      return acc;
    }, {});

    return Object.values(grouped).map((item: any) => ({
      ...item,
      accountCount: item.accounts.length,
    }));
  }, [assets]);

  const groupedDebtAccounts = useMemo(() => {
    const grouped = debts.reduce((acc: any, account: any) => {
      const typeLabel = account.variableLabel || account.type;
      if (!acc[typeLabel]) {
        acc[typeLabel] = { type: typeLabel, totalBalance: 0, accounts: [] };
      }
      acc[typeLabel].totalBalance += account.balance;
      acc[typeLabel].accounts.push({
        id: account.id,
        name: account.name,
        owner: account.who === PROFILE_TYPE.SPOUSE ? "Spouse" : "Mine",
        firm: account.firm,
        balance: account.balance,
        type: account.variable || typeLabel,
      });
      return acc;
    }, {});

    return Object.values(grouped).map((item: any) => ({
      ...item,
      accountCount: item.accounts.length,
    }));
  }, [debts]);

  const expandedAssetRowsMap = useMemo(() => {
    const map: Record<string, React.ReactNode> = {};
    groupedAssetAccounts.forEach((item: any) => {
      if (expandedAssetRows.has(item.type)) {
        map[item.type] = renderExpandedRows(item.accounts);
      }
    });
    return map;
  }, [expandedAssetRows, groupedAssetAccounts]);

  const expandedDebtRowsMap = useMemo(() => {
    const map: Record<string, React.ReactNode> = {};
    groupedDebtAccounts.forEach((item: any) => {
      if (expandedDebtRows.has(item.type)) {
        map[item.type] = renderExpandedRows(item.accounts);
      }
    });
    return map;
  }, [expandedDebtRows, groupedDebtAccounts]);

  const toggleAssetRow = (type: string) => {
    setExpandedAssetRows((prev) => {
      const newExpandedRows = new Set(prev);
      if (newExpandedRows.has(type)) {
        newExpandedRows.delete(type);
      } else {
        newExpandedRows.add(type);
      }
      return newExpandedRows;
    });
  };

  const toggleDebtRow = (type: string) => {
    setExpandedDebtRows((prev) => {
      const newExpandedRows = new Set(prev);
      if (newExpandedRows.has(type)) {
        newExpandedRows.delete(type);
      } else {
        newExpandedRows.add(type);
      }
      return newExpandedRows;
    });
  };

  const assetColumns: Array<EuiBasicTableColumn<GroupedAccount>> = [
    {
      field: "isExpanded",
      name: "",
      width: "32px",
      render: (_, record: GroupedAccount) => {
        return (
          <EuiIcon
            type={expandedAssetRows.has(record.type) ? "arrowUp" : "arrowDown"}
            onClick={() => toggleAssetRow(record.type)}
            aria-label="Expand row"
          />
        );
      },
    },
    {
      field: "type",
      name: "Type",
      truncateText: true,
      width: isMobile ? "60%" : "50%",
    },
    {
      field: "totalBalance",
      name: "Balance",
      width: isMobile ? "40%" : "30%",
      render: (balance: number) => formatBalance(balance),
    },
    ...(isMobile
      ? []
      : [
          {
            field: "accountCount",
            name: "# of Accounts",
            width: "20%",
          },
        ]),
  ];

  const debtColumns: Array<EuiBasicTableColumn<GroupedAccount>> = [
    {
      field: "isExpanded",
      name: "",
      width: "32px",
      render: (_, record: GroupedAccount) => {
        return (
          <EuiIcon
            type={expandedDebtRows.has(record.type) ? "arrowUp" : "arrowDown"}
            onClick={() => toggleDebtRow(record.type)}
            aria-label="Expand row"
          />
        );
      },
    },
    {
      field: "type",
      name: "Type",
      truncateText: true,
      width: isMobile ? "60%" : "50%",
    },
    {
      field: "totalBalance",
      name: "Balance",
      width: isMobile ? "40%" : "30%",
      render: (balance: number) => formatter.format(balance),
    },
    ...(isMobile
      ? []
      : [
          {
            field: "accountCount",
            name: "# of Accounts",
            width: "20%",
          },
        ]),
  ];

  const openAddFlyout = () => {
    dispatch(closeTourCard());
    openCustomFlyout({
      title: "Add manual account",
      component: AddManualAccountForm,
    });
  };

  const openEditFlyout = (account: MappedAccount) => {
    openCustomFlyout({
      title: "Edit account",
      component: AddManualAccountForm,
      selected: account,
    });
  };

  const openAddDebtFlyout = () => {
    dispatch(closeTourCard());
    openCustomFlyout({
      title: "Add manual account",
      component: AddManualAccountForm,
      isDebt: true,
    });
  };

  const openEditDebtFlyout = (account: MappedAccount) => {
    openCustomFlyout({
      title: "Edit account",
      component: AddManualAccountForm,
      isDebt: true,
      selected: account,
    });
  };

  const getOpenFlyoutFunction = () => {
    return selectedTab === "debts" ? openAddDebtFlyout : openAddFlyout;
  };

  const getOpenEditFlyoutFunction = () => {
    return selectedTab === "debts" ? openEditDebtFlyout : openEditFlyout;
  };

  return (
    <>
      <div className="acct-page">
        {isMobile ? (
          <EuiFlexGroup className="acct-flex-group">
            <EuiFlexItem grow={false}>
              <EuiTitle size="s" className="acct-header-text">
                <h1>Accounts</h1>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem grow={false} className="align-center justify-center">
              <EuiLink color="primary" onClick={getOpenFlyoutFunction()}>
                {linkText}
              </EuiLink>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton
                fill
                style={{ maxWidth: "160px", width: "100%" }}
                onClick={openLinkAccountModal}
              >
                {buttonText}
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        ) : (
          <EuiFlexGroup className="acct-flex-group">
            <EuiFlexItem grow={false}>
              <EuiTitle size="s" className="acct-header-text">
                <h1>Accounts</h1>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem className="acct-flex-end acct-flex-start" grow={false}>
              <EuiFlexGroup
                className="acct-link-group acct-no-wrap"
                responsive={false}
              >
                <EuiFlexItem
                  grow={false}
                  className="align-center justify-center"
                >
                  <EuiLink color="primary" onClick={getOpenFlyoutFunction()}>
                    {linkText}
                  </EuiLink>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton
                    fill
                    style={{ maxWidth: "160px", width: "100%" }}
                    onClick={openLinkAccountModal}
                  >
                    {buttonText}
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        )}
        <StyledSpacer size="32px" />
        {renderErrorMessage()}
        <EuiFlexGroup className="acct-no-wrap acct-total-group">
          <EuiFlexItem grow={0}>
            <EuiText className="acct-list-header">Asset Total</EuiText>
            <div className="acct-list-totals">
              {formatter.format(assetTotal)}
            </div>
          </EuiFlexItem>
          <EuiFlexItem grow={1}>
            <EuiText className="acct-list-header">Debt Total</EuiText>
            <div className="acct-list-totals">
              {formatter.format(debtTotal)}
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
        {isMobile ? <StyledSpacer size="16px" /> : <StyledSpacer size="32px" />}
        <EuiTabs>
          {tabs.map((tab) => (
            <EuiTab
              key={tab.id}
              isSelected={tab.id === selectedTab}
              onClick={() => onTabClick(tab.id)}
              className="acct-tab"
            >
              {tab.name}
            </EuiTab>
          ))}
        </EuiTabs>

        <StyledSpacer size="32px" />

        {selectedTab === "assets" && (
          <>
            <EuiBasicTable
              responsive={false}
              className="table-border table-styles"
              items={groupedAssetAccounts as GroupedAccount[]}
              columns={assetColumns}
              itemId="type"
              rowProps={(item: GroupedAccount) => ({
                "data-test-subj": `row-${item.type}`,
                isExpandable: true,
              })}
              itemIdToExpandedRowMap={expandedAssetRowsMap}
            />
          </>
        )}

        {selectedTab === "debts" && (
          <>
            <EuiBasicTable
              responsive={false}
              className="table-border table-styles"
              items={groupedDebtAccounts as GroupedAccount[]}
              columns={debtColumns}
              itemId="type"
              rowProps={(item: GroupedAccount) => ({
                "data-test-subj": `row-${item.type}`,
                isExpandable: true,
              })}
              itemIdToExpandedRowMap={expandedDebtRowsMap}
            />
          </>
        )}
      </div>
      {linkAccountDialogVisible && (
        <AccountLinkPage
          isOpen={linkAccountDialogVisible}
          onClose={closeLinkAccountModal}
        />
      )}
    </>
  );
};

export default Content;
