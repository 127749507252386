import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import styled from "@emotion/styled";
import {
  EuiBadge,
  EuiCallOut,
  EuiCard,
  EuiIcon,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiProvider,
} from "@elastic/eui";

import { getRawCashflows } from "src/store/cashflow/selector";
import { setPopup } from "src/store/dashboard/actions";
import {
  clearoutReviewSection,
  resetCurrentPlan,
  setBuildStep,
  setCurrentPlan,
  setPlanBuildReady,
} from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import { fetchUser } from "src/store/system/actions";
import { getIsCurrentStudent } from "src/store/system/selector";
import { colors } from "src/theme";
import bot_ai from "src/assets/svgs/robot.svg";
import hand_ai from "src/assets/svgs/hand.svg";
import { StyledSpacer, StyledEuiButton } from "../Global/StyledComponents";

// Styled components
const StyledEuiModalHeader = styled(EuiModalHeader)`
  padding-inline: 24px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CustomEuiCallOut = styled(EuiCallOut)`
  svg {
    width: 20px;
    height: 20px;
  }
`;

const StyledCalloutText = styled.p`
  font-size: 16px;
  line-height: 24px;
`;

const StyledEuiIcon = styled(EuiIcon)`
  width: 24px;
  height: 24px;
`;

const StyledEuiCard = styled(EuiCard)`
  box-shadow: none;
  border: 1px solid ${colors.blueGray5};
`;

const PlanSelect = () => {
  const dispatch = useDispatch();
  const isStudent = useSelector(getIsCurrentStudent);
  const incomeSources = useSelector(getRawCashflows);
  const selfEmployed = incomeSources.some(
    (source) => source.type === "side_hustle"
  );

  const handleClose = () => {
    dispatch(push("financial-plans"));
  };

  useEffect(() => {
    dispatch(resetCurrentPlan());
  }, [dispatch]);

  const routeToOptimized = () => {
    if (!isStudent) {
      dispatch(setPlanBuildReady());
      dispatch(push("/plan-builder-optimized"));
    }
  };

  const routeToManual = () => {
    dispatch(clearoutReviewSection());
    dispatch(setCurrentPlan({ planId: 0, keepId: false }));
    dispatch(fetchUser());
    dispatch(setBuildStep(PLAN_BUILD_STEPS.NAME));
    dispatch(setPopup("Build"));
    dispatch(push("/plan-builder"));
  };

  return (
    <EuiProvider>
      <EuiModal onClose={handleClose} style={{ maxWidth: 600 }}>
        <StyledEuiModalHeader>
          <EuiModalHeaderTitle>
            How do you want to build your plan?
          </EuiModalHeaderTitle>
          <StyledEuiIcon
            type="cross"
            onClick={handleClose}
            style={{
              cursor: "pointer",
            }}
          />
        </StyledEuiModalHeader>
        <EuiModalBody>
          <StyledEuiCard
            layout="horizontal"
            icon={<EuiIcon size="xl" type={bot_ai} />}
            title={"Use AI-Powered Assistant 1.0"}
            description="You’re now able to create an optimized plan with minimal effort. Answer some basic questions and we'll customize your plan using AI technology."
            onClick={routeToOptimized}
            isDisabled={isStudent}
          />
          <StyledSpacer size="32px" />
          <StyledEuiCard
            layout="horizontal"
            icon={<EuiIcon size="xl" type={hand_ai} />}
            title={
              <>
                Manually
                {selfEmployed && (
                  <EuiBadge
                    color={colors.vizBehindText}
                    style={{ marginLeft: "10px" }}
                  >
                    Recommended
                  </EuiBadge>
                )}
              </>
            }
            description="Our easy-to-use plan building technology allows you to set up and simulate your plan."
            onClick={routeToManual}
          />
          <StyledSpacer size="16px" />
          {selfEmployed && (
            <CustomEuiCallOut
              title="Schedule a call with your FitBUX Expert"
              iconType="iInCircle"
            >
              <StyledCalloutText>
                Since a significant portion of your income comes from
                self-employment or 1099 contracts, you have access to special
                investment options that our AI doesn't fully account for yet. We
                recommend scheduling a call with your FitBUX Expert and using
                our manual plan builder to make the most of these opportunities.
              </StyledCalloutText>
            </CustomEuiCallOut>
          )}
        </EuiModalBody>
        <EuiModalFooter>
          <StyledEuiButton color="text" onClick={handleClose}>
            Cancel
          </StyledEuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiProvider>
  );
};

export default PlanSelect;
