import React from "react";

import { EuiCard, EuiImage, EuiButton } from "@elastic/eui";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import { Link } from "react-router-dom";
import zero_state_plan_summary from "src/assets/svgs/zero_state_no_plan.svg";
import { Global, css } from "@emotion/react";

const noPlanSummaryStyles = css`
  .css-4ld6o8-euiCard__children {
    margin-block-start: 0 !important;
  }
`;

export const NoPlanSummary = () => {
  return (
    <>
      <Global styles={noPlanSummaryStyles} />
      <EuiCard
        title=""
        paddingSize="l"
        description=""
        textAlign="center"
        className="zero-state-card"
      >
        <EuiImage
          className=""
          src={zero_state_plan_summary}
          alt="woman on tablet"
          size="original"
        />
        <StyledSpacer size="24px" />
        <Link to="/financial-plans">
          <EuiButton fill color="primary">
            Build Your Financial Plan
          </EuiButton>
        </Link>
      </EuiCard>
    </>
  );
};

export default NoPlanSummary;
