import React, { useState, useEffect } from "react";
import {
  EuiButton,
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiTitle,
  EuiFormRow,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSuperSelect,
} from "@elastic/eui";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import { useFlyout } from "src/components/Global/FlyoutContext";
import { DollarTextField } from "src/utils";

interface AddItemDialogProps {
  list?: Array<{ label: string; value: string }>;
  onAdd: (values: { type: string; annual: string; monthly: string; who?: string }) => void;
  showOwner?: boolean;
  title?: string;
}

const AddItemDialog: React.FC<AddItemDialogProps> = ({ list, onAdd, showOwner, title }) => {
  console.log('Received title:', title);
  const [state, setState] = useState({ type: "", annual: "", monthly: "", who: "applicant" });
  const [isEditingAnnual, setIsEditingAnnual] = useState(true); // Keep track of which field is being edited
  const { closeFlyout } = useFlyout();
  const { type, annual, monthly, who } = state;

  // Update monthly when annual changes and it's the active input
  useEffect(() => {
    if (isEditingAnnual && annual && !isNaN(Number(annual))) {
      setState((prevState) => ({
        ...prevState,
        monthly: (Number(annual) / 12).toFixed(2),
      }));
    }
  }, [annual, isEditingAnnual]);

  useEffect(() => {
    if (!isEditingAnnual && monthly && !isNaN(Number(monthly))) {
      setState((prevState) => ({
        ...prevState,
        annual: (Number(monthly) * 12).toFixed(2),
      }));
    }
  }, [monthly, isEditingAnnual]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "annual") {
      setIsEditingAnnual(true);
    } else if (name === "monthly") {
      setIsEditingAnnual(false);
    }
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleWhoChange = (value: string) => {
    setState((prevState) => ({ ...prevState, who: value }));
  };

  const handleSelectChange = (value: string) => {
    setState((prevState) => ({ ...prevState, type: value }));
  };

  const mappedList = list ? list.map(({ label, value }) => ({ value, inputDisplay: label })) : [];

  return (
    <EuiFlyout onClose={closeFlyout} size="s" ownFocus>
      <EuiFlyoutHeader hasBorder>
        <EuiTitle size="m">
          <h2>{title}</h2>
        </EuiTitle>
      </EuiFlyoutHeader>

      <EuiFlyoutBody>
        {showOwner && (
          <>
            <EuiFormRow label="Owner">
              <EuiSuperSelect
                options={[
                  { value: "applicant", inputDisplay: "Mine" },
                  { value: "spouse", inputDisplay: "Spouse" },
                ]}
                itemLayoutAlign="top"
                hasDividers
                valueOfSelected={who}
                onChange={handleWhoChange}
              />
            </EuiFormRow>
            <StyledSpacer size="32px" />
          </>
        )}
        <EuiFormRow label="Type">
          <EuiSuperSelect
            options={[{ value: "", inputDisplay: "Select" }, ...mappedList]}
            onChange={handleSelectChange}
            itemLayoutAlign="top"
            hasDividers
            valueOfSelected={type}
          />
        </EuiFormRow>
        <StyledSpacer size="32px" />
        <EuiFormRow label="Annual">
          <DollarTextField
            fullWidth
            placeholder="$0"
            eui
            name="annual"
            value={annual}
            onChange={handleChange}
          />
        </EuiFormRow>
        <StyledSpacer size="32px" />
        <EuiFormRow label="Monthly">
          <DollarTextField
            fullWidth
            placeholder="$0"
            eui
            name="monthly"
            value={monthly}
            onChange={handleChange}
          />
        </EuiFormRow>
        <StyledSpacer size="32px" />
        <EuiFlexGroup justifyContent="flexEnd" gutterSize="m">
          <EuiFlexItem grow={false}>
            <EuiButton onClick={closeFlyout} color="text">
              Cancel
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton
              onClick={() => {
                console.log("Adding premium with values: ", state);
                onAdd(state);
                closeFlyout();
              }}
              fill
              isDisabled={!type || !annual || !monthly}
            >
              Add
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutBody>
    </EuiFlyout>
  );
};

export default AddItemDialog;
