import React from "react";
import { Transaction } from "src/interfaces";
import { formatDollarsAndCents } from "src/utils";
import { Global, css } from "@emotion/react";
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiButtonIcon,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiCard,
} from "@elastic/eui";
import {
  StyledEuiButtonIcon,
  StyledEuiHorizontalRule,
  StyledSpacer,
} from "src/components/Global/StyledComponents";
import check_icon from "src/assets/svgs/check_icon.svg";
import fill_check from "src/assets/svgs/fill_check.svg";
import useWindowSize from "src/hooks/useWindowSize";

const confirmlistStyles = css`
  .transaction-list-group {
    display: flex;
    padding: 0;
    justify-content: space-between !important;
    .euiFlexItem {
      flex: 0 1 auto;
    }
    .transaction-label-group-transfer {
      display: flex;
      padding: 0;
      justify-content: space-between !important;
      color: #343741;
      gap: 16px;
      align-items: center;
      .euiAvatar,
      img {
        width: 40px;
        height: 40px;
      }
    }
    .transaction-label-group {
      color: #343741;
      gap: 16px;
      align-items: flex-start;
      justify-content: space-between;
    }
    .transaction-main-text {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      font-family: "Inter", sans-serif;
    }
    .transaction-secondary-text {
      font-size: 14px;
      line-height: 24px;
      font-family: "Inter", sans-serif;
    }
    .confirm-button {
      font-size: 14px !important;
      line-height: 21px;
      max-width: 71px;
      min-width: 71px;
      padding: 8px;
    }
  }
  .euiBasicTable.income-list {
    table-layout: fixed;
    width: 100%;
    border-spacing: 0;
    .euiTableCellContent {
      font-size: 16px;
    }
    thead {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: white;
      border-bottom: 1px solid #d3dae6;
    }
    tbody {
      display: block;
      max-height: 160px;
      overflow-y: auto;
      width: 100%;
    }

    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    th,
    td {
      white-space: 100%;
      border-top: none;
    }
  }

  .css-4ld6o8-euiCard__children {
    margin-block-start: 0 !important;
  }

  .transaction-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 16px 0;
  }

  .transaction-details {
    display: flex;
    flex-direction: column;
    width: 60%;
    align-items: flex-start;
  }

  .transaction-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .transaction-confirm {
  }

  .transaction-amount {
    font-weight: 600;
  }

  .transaction-button {
    margin-top: 8px;
  }
`;

interface IncomeTransactionListProps {
  items: Transaction[];
  onSelect?: (item: Transaction) => void;
  openConfirmDialog: (item: Transaction) => void;
  selectedIds?: Set<number>;
  transactionPage?: boolean;
}

const IncomeTransactionList = ({
  items,
  onSelect,
  openConfirmDialog,
  selectedIds,
  transactionPage = false,
}: IncomeTransactionListProps) => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  const columns: Array<EuiBasicTableColumn<Transaction>> = [
    {
      field: "date",
      name: "Date",
      render: (date: string) =>
        new Date(date).toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        }),
      width: "20%",
    },
    {
      field: "description",
      name: "Description",
      truncateText: true,
      width: "50%",
    },
    {
      field: "amount",
      name: "Amount",
      render: (amount: number) => (
        <span style={{ fontWeight: "600" }}>
          {amount < 0 ? "+" : "-"}
          {formatDollarsAndCents(Math.abs(amount))}
        </span>
      ),
      width: "20%",
    },
    {
      name: "",
      width: "10%",
      render: (item: Transaction) => (
        <EuiButton
          style={{ height: "32px" }}
          className="confirm-button"
          color="primary"
          fill
          onClick={() => openConfirmDialog(item)}
        >
          Confirm
        </EuiButton>
      ),
    },
  ];

  return (
    <>
      <Global styles={confirmlistStyles} />
      {transactionPage ? (
        isMobile ? (
          <EuiCard title>
            {items.map((item) => (
              <div key={item.id} className="transaction-row">
                <div className="transaction-details">
                  <div>{item.description}</div>
                </div>
                <div className="transaction-actions">
                  <div className="transaction-amount">
                    {item.amount < 0 ? "+" : "-"}
                    {formatDollarsAndCents(Math.abs(item.amount))}
                  </div>
                  <div>{new Date(item.date).toLocaleDateString("en-US")}</div>
                </div>
                <div className="transaction-confirm">
                  <EuiButtonIcon
                    className="transaction-button"
                    display="fill"
                    color="primary"
                    iconType="check"
                    onClick={() => openConfirmDialog(item)}
                    aria-label="Confirm Transaction"
                  />
                </div>
              </div>
            ))}
          </EuiCard>
        ) : (
          <EuiBasicTable<Transaction>
            items={items}
            columns={columns}
            tableLayout="fixed"
          />
        )
      ) : (
        items.map((item, index) => (
          <React.Fragment key={item.id}>
            {!!onSelect && !!selectedIds && (
              <EuiFlexGroup className="transaction-list-group">
                <EuiFlexItem>
                  <EuiFlexGroup className="transaction-label-group-transfer">
                    <EuiFlexItem>
                      <StyledEuiButtonIcon
                        className="euiAvatar"
                        iconType={
                          selectedIds.has(item.id) ? fill_check : check_icon
                        }
                        onClick={() => onSelect(item)}
                      />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      {!!item.description && (
                        <span className="transaction-main-text">
                          {item.description.replace(/\(.*?\)/g, "").length > 20
                            ? item.description.substring(0, 20) + "..."
                            : item.description}
                        </span>
                      )}
                      <span className="transaction-secondary-text">
                        {new Date(item.date).toLocaleDateString("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </span>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFlexGroup className="transaction-amount-group">
                    <EuiFlexItem className="transaction-secondary-text">
                      <EuiText style={{ textAlign: "right" }}>
                        <span style={{ fontWeight: "600" }}>
                          {item.amount < 0 ? "+" : "-"}
                          {formatDollarsAndCents(Math.abs(item.amount))}
                        </span>
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiButtonIcon
                        iconType="edit"
                        aria-label="Edit transaction"
                      />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              </EuiFlexGroup>
            )}
            {!onSelect && (
              <div className="transaction-list-group">
                <EuiFlexGroup className="transaction-label-group">
                  <EuiFlexItem className="transaction-main-text">
                    {!!item.description && (
                      <span>
                        {item.description.length > 20
                          ? item.description.substring(0, 20) + "..."
                          : item.description}
                      </span>
                    )}
                  </EuiFlexItem>
                  <EuiFlexItem className="transaction-secondary-text">
                    <EuiText style={{ textAlign: "right" }}>
                      <span style={{ fontWeight: "600" }}>
                        {item.amount < 0 ? "+" : "-"}
                        {formatDollarsAndCents(Math.abs(item.amount))}
                      </span>
                      <br />
                      <span>
                        {new Date(item.date).toLocaleDateString("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </span>
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiButton
                      className="confirm-button"
                      color="primary"
                      fill
                      onClick={() => openConfirmDialog(item)}
                    >
                      Confirm
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </div>
            )}
            {index !== items.length - 1 && (
              <>
                <StyledSpacer size="16px" />
                <StyledEuiHorizontalRule />
                <StyledSpacer size="16px" />
              </>
            )}
          </React.Fragment>
        ))
      )}
    </>
  );
};

export default IncomeTransactionList;
