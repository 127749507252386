import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { EuiTitle, EuiTabs, EuiTab } from "@elastic/eui";

import NetSummary from "./Blocks/NetSummary";
import AssetSummary from "./Blocks/AssetSummary";
import DebtSummary from "./Blocks/DebtSummary";
import HumanCapital from "./Blocks/HumanCapital";
import { activeTabText } from "./data";

import { SidebarContent } from "src/interfaces";
import { getAccounts } from "src/store/account/actions";
import { getRefreshNeeded } from "src/store/dashboard/selector";
import { fetchLivePlanProjection } from "src/store/planBuild/actions";
import { getRouterState } from "src/store/router/selector";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import useWindowSize from "src/hooks/useWindowSize";
import { HELP } from "src/store/dashboard/helpTopics";

export const Content = () => {
  const dispatch = useDispatch();
  const routerState: any = useSelector(getRouterState);
  const refreshNeeded = useSelector(getRefreshNeeded);
  const [currentTab, setCurrentTab] = useState(0);
  const windowSize = useWindowSize();
  const { heading, helpTopic } = activeTabText[currentTab] || {};

  const handleTabClick = (selectedTab: any) => {
    setCurrentTab(selectedTab);
  };

  const renderContent = () => {
    switch (currentTab) {
      case 0:
        return <NetSummary />;
      case 1:
        return <AssetSummary />;
      case 2:
        return <HumanCapital />;
      case 3:
        return <DebtSummary />;
      default:
        return null;
    }
  };

  const renderHumanCapitalLabel = () => {
    return windowSize.width <= 768 ? "HC" : "Human Capital";
  };

  let helpContent: any = null;

  // TODO this is an ugly hack to temporarily bridge between two competing help interfaces
  if (helpTopic) {
    const foundHelp = HELP[helpTopic];
    const content: SidebarContent = {
      header: foundHelp.topic,
      body: foundHelp.content,
      videoKey: foundHelp.videoKey,
    };
    helpContent = [content];
  }

  useEffect(() => {
    dispatch(getAccounts());
  }, []);

  useEffect(() => {
    if (routerState?.location?.hash === "#hc") {
      setCurrentTab(2);
    } else if (routerState?.location?.hash === "#debt") {
      setCurrentTab(3);
    }
  }, [routerState]);
  useEffect(() => {
    if (refreshNeeded.liveProjection) {
      dispatch(fetchLivePlanProjection(false));
    }
  }, [refreshNeeded.liveProjection]);

  return (
    <div className="nw-page">
      <EuiTitle size="s" className="nw-header-text">
        <h1>Net Wealth</h1>
      </EuiTitle>
      <StyledSpacer size="32px" />
      <EuiTabs>
        <StyledSpacer size="32px" />
        <EuiTab
          className="nw-tab"
          onClick={() => handleTabClick(0)}
          isSelected={currentTab === 0}
        >
          Summary
        </EuiTab>
        <StyledSpacer size="32px" />
        <EuiTab
          className="nw-tab"
          onClick={() => handleTabClick(1)}
          isSelected={currentTab === 1}
        >
          Assets
        </EuiTab>
        <StyledSpacer size="32px" />
        <EuiTab onClick={() => handleTabClick(2)} isSelected={currentTab === 2}>
          {renderHumanCapitalLabel()}
        </EuiTab>
        <StyledSpacer size="32px" />
        <EuiTab
          className="nw-tab"
          onClick={() => handleTabClick(3)}
          isSelected={currentTab === 3}
        >
          Debts
        </EuiTab>
        <StyledSpacer size="32px" />
      </EuiTabs>
      <StyledSpacer size="32px" />
      {renderContent()}
    </div>
  );
};
