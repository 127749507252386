import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EuiCard,
  EuiListGroup,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiForm,
  EuiFormRow,
  EuiRadioGroup,
  EuiFlyoutBody,
  EuiFieldText,
} from "@elastic/eui";
import { forOwn } from "lodash";
import { useFlyout } from "src/components/Global/FlyoutContext";
import pencil_edit from "src/assets/svgs/pencil.svg";
import { RISK_MANAGEMENT_TYPES } from "src/interfaces";
import {
  addCashflow,
  editCashflow,
  removeCashflow,
} from "src/store/cashflow/actions";
import { getRiskSummary } from "src/store/cashflow/selector";
import { updateHousehold } from "src/store/profileBuild/actions";
import { getIsMarried } from "src/store/system/selector";
import { formatWholeDollars } from "src/utils";
import { Global, css } from "@emotion/react";
import { StyledSpacer } from "src/components/Global/StyledComponents";

const summaryStyles = css`
  .summary-card {
    width: 100%;
    max-height: 557px;
    overflow: hidden;
    overflow-y: auto;
    .summary-card-lists {
      font-size: 16px;
      line-height: 24px;
      font-family: "Inter", sans-serif;
    }
  }
`;

const Summary = () => {
  const dispatch = useDispatch();
  const isMarried = useSelector(getIsMarried);
  const summaryData: any = useSelector(getRiskSummary);
  const { openCustomFlyout, closeFlyout } = useFlyout();

  const [changes, setChanges] = useState<any>(null);
  const formState = { ...summaryData, ...(changes || {}) };

  const setFormValue = (e: any) => {
    const { name, value } = e.target;
    setChanges((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const toggleEdit = () => {
    openCustomFlyout({
      component: (props: any) => (
        <FlyoutForm
          {...props}
          formState={formState}
          setFormValue={setFormValue}
          closeFlyout={closeFlyout}
        />
      ),
      title: "Edit Insurance Details",
    });

    if (changes) {
      const actions = [];

      if (changes.qual_trust || changes.qual_will) {
        actions.push(
          updateHousehold({
            update: {
              qual_trust: formState.qual_trust,
              qual_will: formState.qual_will,
            },
          })
        );
      }

      if (changes.life_insure_death_benefit_value !== summaryData.life_insure_death_benefit_value) {
        if (changes.life_insure_death_benefit_value === "n") {
          actions.push(removeCashflow(summaryData.my_life_insurance_id));
        } else {
          actions.push(
            addCashflow({
              cashflow: {
                amount: 0,
                death_benefit: formState.life_insure_death_benefit_value || 0,
                type: "life_insurance",
                who: "applicant",
              },
            })
          );
        }
      }

      if (isMarried && changes.spouse_life_insure !== summaryData.spouse_life_insure) {
        if (changes.spouse_life_insure === "n") {
          actions.push(removeCashflow(summaryData.spouse_life_insurance_id));
        } else {
          actions.push(
            addCashflow({
              cashflow: {
                amount: 0,
                death_benefit: formState.spouse_death_benefit || 0,
                type: "life_insurance",
                who: "spouse",
              },
            })
          );
        }
      }

      actions.forEach(dispatch);
      setChanges(null);
    }
  };

  const insuranceRows = [];
  if (formState.life_insure_death_benefit_value) {
    insuranceRows.push({
      label: "My Death Benefit",
      total: formatWholeDollars(formState.life_insure_death_benefit_value),
    });
  }

  forOwn(RISK_MANAGEMENT_TYPES, (label, key) => {
    if (key === "life_insurance" && insuranceRows.length > 0) {
      return;
    }
    insuranceRows.push({
      label,
      detail: summaryData[key] === "y" ? "Yes" : "No",
    });
  });

  insuranceRows.push(
    {
      label: (
        <EuiFlexGroup
          justifyContent="spaceBetween"
          alignItems="center"
          style={{ gap: "8px" }}
        >
          <EuiFlexItem grow={0}>Do you have a trust?</EuiFlexItem>
          <EuiFlexItem grow={0}>
            <EuiIcon
              type={pencil_edit}
              onClick={toggleEdit}
              style={{ cursor: "pointer" }}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      ),
      detail: formState.qual_trust === "y" ? "Yes" : "No",
    },
    {
      label: (
        <EuiFlexGroup
          justifyContent="spaceBetween"
          alignItems="center"
          style={{ gap: "8px" }}
        >
          <EuiFlexItem grow={0}>Do you have a will?</EuiFlexItem>
          <EuiFlexItem grow={0}>
            <EuiIcon
              type={pencil_edit}
              onClick={toggleEdit}
              style={{ cursor: "pointer" }}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      ),
      detail: formState.qual_will === "y" ? "Yes" : "No",
    }
  );

  return (
    <>
      <Global styles={summaryStyles} />
      <EuiCard title="" className="ai-card summary-card" style={{}}>
        <div className="ai-card-title">
          <h1>Summary</h1>
        </div>
        <EuiListGroup flush>
          {insuranceRows.map((section, index) => (
            <div key={index}>
              <EuiFlexGroup
                justifyContent="spaceBetween"
                alignItems="center"
                className="summary-card-lists"
                style={{ cursor: "pointer" }}
              >
                <EuiFlexItem grow={0} style={{ textAlign: "left" }}>
                  {section.label}
                </EuiFlexItem>
                <EuiFlexItem grow={0} style={{ fontWeight: "600" }}>
                  {section.detail}
                </EuiFlexItem>
              </EuiFlexGroup>
              <StyledSpacer size="16px" />
            </div>
          ))}
        </EuiListGroup>
      </EuiCard>
    </>
  );
};

const FlyoutForm = ({ formState, setFormValue, closeFlyout }: any) => {
  const lifeInsuranceOptions = [
    { id: "n", label: "No" },
    { id: "y", label: "Yes" },
  ];

  console.log("Rendering FlyoutForm with formState:", formState); // Log formState to check values

  const handleRadioChange = (id: string, name: string) => {
    console.log(`Radio selected for ${name}:`, id);
    setFormValue({
      target: { name, value: id },
    });
  };

  return (
    <EuiFlyoutBody>
      <EuiForm>
        {/* Life Insurance */}
        <EuiFormRow label="Do you have life insurance?">
          <EuiRadioGroup
            options={lifeInsuranceOptions}
            idSelected={formState?.life_insurance} // Ensure the selected value matches the formState
            onChange={(id) => handleRadioChange(id, "life_insurance")} // Use 'y' and 'n' values
            name="life_insurance"
          />
        </EuiFormRow>

        {/* Show death benefit amount if life insurance is selected */}
        {formState?.life_insurance === "y" && (
          <>
            <StyledSpacer size="32px" />
            <EuiFormRow label="What is the death benefit amount?">
              <EuiFieldText
                name="my_death_benefit"
                value={Number(formState?.my_death_benefit) || 0}
                onChange={(e) => setFormValue(e)}
                aria-label="my_death_benefit"
              />
            </EuiFormRow>
          </>
        )}

        <StyledSpacer size="32px" />

        {/* Trust */}
        <EuiFormRow label="Do you have a trust?">
          <EuiRadioGroup
            options={lifeInsuranceOptions}
            idSelected={formState?.qual_trust} // Ensure the selected value matches the formState
            onChange={(id) => handleRadioChange(id, "qual_trust")} // Use 'y' and 'n' values
            name="qual_trust"
          />
        </EuiFormRow>

        <StyledSpacer size="32px" />

        {/* Will */}
        <EuiFormRow label="Do you have a will?">
          <EuiRadioGroup
            options={lifeInsuranceOptions}
            idSelected={formState?.qual_will} // Ensure the selected value matches the formState
            onChange={(id) => handleRadioChange(id, "qual_will")} // Use 'y' and 'n' values
            name="qual_will"
          />
        </EuiFormRow>

        <StyledSpacer size="32px" />

        <EuiButton fill onClick={closeFlyout}>
          Done
        </EuiButton>
      </EuiForm>
    </EuiFlyoutBody>
  );
};





export default Summary;
