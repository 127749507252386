import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EuiBasicTable,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSelect,
  EuiBasicTableColumn,
  Criteria,
  EuiTitle,
  EuiIcon,
  EuiAvatar,
  EuiCard,
} from "@elastic/eui";
import edit_pencil from "src/assets/svgs/grey_pencil.svg";
import Fuse from "fuse.js";
import { getTransactions } from "src/store/transaction/actions";
import { getTransactionsByCategory } from "src/store/transaction/selector";
import {
  getDateString,
  oneYearAgo,
  ninetyDaysAgo,
  thirtyDaysAgo,
} from "src/utils";
import { Transaction } from "src/interfaces";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import { CASHFLOW_TO_TYPE_AND_CATEGORY } from "src/interfaces/transaction.interface";
import useWindowSize from "src/hooks/useWindowSize";

type CashflowKey = keyof typeof CASHFLOW_TO_TYPE_AND_CATEGORY;

const formatTypeName = (name: string) => {
  return name
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const typeToNameMapping = Object.keys(CASHFLOW_TO_TYPE_AND_CATEGORY).reduce(
  (acc, name) => {
    const { type } = CASHFLOW_TO_TYPE_AND_CATEGORY[name as CashflowKey];
    if (!acc[type]) acc[type] = formatTypeName(name);
    return acc;
  },
  {} as { [key: number]: string }
);

const ConfirmedTransactions = ({ openDialog }: any) => {
  const dispatch = useDispatch();
  const {
    all,
    income,
    expenses,
    assets,
    debts,
    risk,
    educationCosts,
    educationFunding,
  } = useSelector(getTransactionsByCategory);

  const [tab, setTab] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [dateRange, setDateRange] = useState<number>(0);
  const [sortField, setSortField] = useState<keyof Transaction>("date");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const size = useWindowSize();

  useEffect(() => {
    const to = getDateString(new Date());
    let from = getDateString(thirtyDaysAgo());
    if (dateRange === 1) {
      from = getDateString(ninetyDaysAgo());
    } else if (dateRange === 2) {
      from = getDateString(oneYearAgo());
    }
    dispatch(getTransactions({ from, to }));
  }, [dispatch, dateRange]);

  const transactionList = useMemo(
    () => ({
      0: all,
      1: income,
      2: expenses,
      3: assets,
      4: debts,
      5: risk,
      6: educationFunding,
      7: educationCosts,
    }),
    [
      all,
      income,
      expenses,
      assets,
      debts,
      risk,
      educationFunding,
      educationCosts,
    ]
  );

  const transactions =
    transactionList[tab as keyof typeof transactionList] || [];

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onDateRangeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDateRange(Number(e.target.value));
  };

  const onTabChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTab(Number(e.target.value));
  };

  const onSort = (criteria: Criteria<Transaction>) => {
    const { sort } = criteria;

    if (sort) {
      setSortField(sort.field);
      setSortDirection(sort.direction);
    }
  };

  const getSortIcon = (field: keyof Transaction) => {
    if (field === sortField) {
      return sortDirection === "asc" ? "arrowUp" : "arrowDown";
    }
    return "arrowUp";
  };

  const filteredTransactions = useMemo(() => {
    const fuse = new Fuse(transactions, {
      keys: ["description", "type"],
    });

    return search ? fuse.search(search).map(({ item }) => item) : transactions;
  }, [transactions, search]);

  const sortedTransactions = useMemo(() => {
    return [...filteredTransactions].sort((a, b) => {
      const aValue = a[sortField];
      const bValue = b[sortField];

      if (aValue === undefined || bValue === undefined) {
        return 0;
      }

      const comparison = aValue > bValue ? 1 : bValue > aValue ? -1 : 0;
      return sortDirection === "asc" ? comparison : -comparison;
    });
  }, [filteredTransactions, sortField, sortDirection]);

  const columns: EuiBasicTableColumn<Transaction>[] = [
    {
      field: "date",
      name: (
        <>
          Date <EuiIcon type={getSortIcon("date")} />
        </>
      ),
      sortable: true,
      dataType: "date",
      width: "15%",
      render: (date: string) =>
        new Date(date).toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        }),
    },
    {
      field: "description",
      name: (
        <>
          Description <EuiIcon type={getSortIcon("description")} />
        </>
      ),
      sortable: true,
      truncateText: true,
      width: "45%",
    },
    {
      field: "type",
      name: (
        <>
          Type <EuiIcon type={getSortIcon("type")} />
        </>
      ),
      sortable: true,
      width: "25%",
      render: (typeValue: number) => {
        return typeToNameMapping[typeValue] || "Unknown";
      },
    },
    {
      field: "amount",
      name: (
        <>
          Amount <EuiIcon type={getSortIcon("amount")} />
        </>
      ),
      sortable: true,
      width: "10%",
      render: (amount: number) => (
        <span style={{ color: amount < 0 ? "green" : "red" }}>
          {amount < 0 ? "+" : "-"}${Math.abs(amount).toFixed(2)}
        </span>
      ),
    },
    {
      name: "",
      width: "5%",
      render: (item: Transaction) => (
        <EuiIcon
          type={edit_pencil}
          size="m"
          onClick={() => openDialog(item)}
          aria-label="Edit transaction"
          style={{ cursor: "pointer" }}
        />
      ),
    },
  ];

  const renderMobileLayout = () => (
    <>
      <EuiTitle size="s" className="tx-table-text">
        <h1>Account Activity</h1>
      </EuiTitle>
      <StyledSpacer size="16px" />
      <EuiCard title="">
        {sortedTransactions.map((transaction) => {
          const truncatedDescription =
            transaction.description.length > 25
              ? `${transaction.description.slice(0, 25)}...`
              : transaction.description;

          return (
            <div
              key={transaction.id}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 0",
                borderBottom: "1px solid #e1e1e1",
                gap: "8px",
                textAlign: "left",
              }}
            >
              <EuiFlexItem grow={false}>
                <EuiAvatar
                  name={transaction.description}
                  initials={transaction.description.slice(0, 2).toUpperCase()}
                  size="m"
                />
              </EuiFlexItem>

              <EuiFlexItem>
                <div>
                  <strong>{truncatedDescription}</strong>
                </div>
                <div style={{ fontSize: "12px", color: "#666" }}>
                  {transaction.type}
                </div>
              </EuiFlexItem>

              <EuiFlexItem style={{ textAlign: "right" }}>
                <div
                  style={{ color: transaction.amount < 0 ? "green" : "red" }}
                >
                  {transaction.amount < 0 ? "+" : "-"}$
                  {Math.abs(transaction.amount).toFixed(2)}
                </div>
                <div style={{ fontSize: "12px", color: "#666" }}>
                  {new Date(transaction.date).toLocaleDateString()}
                </div>
              </EuiFlexItem>

              <EuiFlexItem grow={false}>
                <EuiIcon
                  type={edit_pencil}
                  size="m"
                  onClick={() => openDialog(transaction)}
                  style={{ cursor: "pointer" }}
                />
              </EuiFlexItem>
            </div>
          );
        })}
      </EuiCard>
    </>
  );

  const renderDesktopTable = () => (
    <div>
      <EuiFlexGroup>
        <EuiFlexItem style={{ justifyContent: "center" }}>
          <EuiTitle size="s" className="tx-table-text">
            <h1>Account Activity</h1>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup justifyContent="flexEnd">
            <EuiFlexItem style={{ maxWidth: "360px" }}>
              <EuiFieldSearch
                placeholder="Search..."
                value={search}
                onChange={onSearchChange}
                isClearable
                aria-label="Search transactions"
              />
            </EuiFlexItem>
            <EuiFlexItem style={{ maxWidth: "120px" }}>
              <EuiSelect
                options={[
                  { value: "0", text: "Type: All" },
                  { value: "1", text: "Income" },
                  { value: "2", text: "Expenses" },
                  { value: "3", text: "Assets" },
                  { value: "4", text: "Debt" },
                  { value: "5", text: "Risk Mgmt." },
                  { value: "6", text: "Education Funding" },
                  { value: "7", text: "Education Costs" },
                ]}
                value={String(tab)}
                onChange={onTabChange}
                aria-label="Transaction Type"
              />
            </EuiFlexItem>
            <EuiFlexItem style={{ maxWidth: "145px" }}>
              <EuiSelect
                options={[
                  { value: "0", text: "Last 30 Days" },
                  { value: "1", text: "Last 90 Days" },
                  { value: "2", text: "Last 12 Months" },
                ]}
                value={String(dateRange)}
                onChange={onDateRangeChange}
                aria-label="Date Range"
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
      <StyledSpacer size="16px" />
      <EuiBasicTable<Transaction>
        className="table-border table-activity table-text"
        items={sortedTransactions}
        columns={columns}
        sorting={{
          sort: {
            field: sortField,
            direction: sortDirection,
          },
        }}
        onChange={onSort}
      />
    </div>
  );

  return <>{size.width <= 768 ? renderMobileLayout() : renderDesktopTable()}</>;
};

export default ConfirmedTransactions;
