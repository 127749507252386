import { RetirementEntry, StudentLoan } from "src/interfaces";

// This is a combination of 2 different sets of steps
// The left nav bar relies on the order to
// properly display its menu items
export enum PLAN_BUILD_STEPS {
  NAME = 1,
  REUSE_SELECTIONS,
  EDUCATION_FUNDING,
  EXPENSES_AND_INSURANCE,
  GRADUATED_LIFE_EVENTS_OVERVIEW,
  INCOME_AND_EXPENSES,
  ASSETS_AND_DEBTS,
  STUDENT_ASSETS_AND_DEBTS,
  RISK_MANAGEMENT,
  FUTURE_QUESTIONS,
  LIFE_EVENTS_OVERVIEW,
  SAVINGS,
  GOALS,
  RECOMMENDATIONS,
  REVIEW,
  GRADUATED_REVIEW,
  IMPLEMENT,
  COMPARE,
}

export enum COMPARE_PLAN_STEPS {
  SCORE = 1,
  GOAL_SUMMARY,
  ASSETS_AND_INVESTMENTS,
  DEBTS,
  NET_WEALTH,
  PAYCHECK_ALLOCATION,
}

export const graduatedProgressSteps = {
  [PLAN_BUILD_STEPS.NAME]: 100,
  [PLAN_BUILD_STEPS.GRADUATED_LIFE_EVENTS_OVERVIEW]: 101,
  [PLAN_BUILD_STEPS.INCOME_AND_EXPENSES]: 102,
  [PLAN_BUILD_STEPS.ASSETS_AND_DEBTS]: 103,
  [PLAN_BUILD_STEPS.RISK_MANAGEMENT]: 104,
  [PLAN_BUILD_STEPS.SAVINGS]: 105,
  [PLAN_BUILD_STEPS.GOALS]: 106,
  [PLAN_BUILD_STEPS.GRADUATED_REVIEW]: 107,
};

export const inschoolProgressSteps = {
  [PLAN_BUILD_STEPS.EDUCATION_FUNDING]: 200,
  [PLAN_BUILD_STEPS.EXPENSES_AND_INSURANCE]: 201,
  [PLAN_BUILD_STEPS.ASSETS_AND_DEBTS]: 202,
  [PLAN_BUILD_STEPS.FUTURE_QUESTIONS]: 203,
  [PLAN_BUILD_STEPS.LIFE_EVENTS_OVERVIEW]: 204,
  [PLAN_BUILD_STEPS.REVIEW]: 205,
};

export const WHO_TYPES: any = {
  mine: "Mine",
  spouse: "Spouse",
};

export enum ReviewSections {
  HOME,
  SCORE,
  GOALSUMMARY,
  ASSETSINVESTMENTS,
  DEBTS,
  NETWEALTH,
  PAYCHECKALLOCATION,
}

export const EMPTY_RETIREMENT: RetirementEntry = {
  who: "applicant",
  has_401k: "n",
  has_roth_401k: "n",
  match_limit: 0,
  match_percent: 0,
  additional_limit: 0,
  additional_percent: 0,
  traditional_limit: 0,
  nonelective_limit: 0,
  nonelective_traditional_limit: 0,
  student_loan_match: "n",
};

export const EMPTY_PLAN = {
  id: -1,
  name: "",
  emergencymonths: 0,
  profile: {
    zip: "90210",
    married: true,
    spouseDOB: "1999-09-09",
    children: [1],
    has_hsa: false,
    filing_jointly: true,
  },
  retirement: [EMPTY_RETIREMENT],
  studentloan: [
    {
      strategy: "low_balance",
      who: "applicant",
      repayplan: "std_plan",
      idroption: "standard",
      perkinscancel: false,
      start: "2021-01-01",
    },
    {
      strategy: "low_balance",
      who: "spouse",
      repayplan: "std_plan",
      idroption: "standard",
      perkinscancel: false,
      start: "2021-01-01",
    },
  ] as StudentLoan[],
  incomes: [],
  expenses: [],
  allocations: [
    {
      solo: [
        {
          who: "applicant",
        },
        {
          who: "spouse",
        },
      ],
      cash_value: 0,
      "401k_value": 0,
      roth_ira_value: 0,
      ira_value: 0,
      other_retirement_value: 0,
      other_investments_value: 0,
      student_loan: 0,
      credit_card: 0,
      auto_loan: 0,
      home_loan: 0,
      property_loan: 0,
      personal_loan: 0,
      other_debt: 0,
    },
  ],
  risks: [],
  lifeevents: [],
  goals: [
    {
      id: 1,
      goaltype: "emergencyfund",
    },
    {
      id: 2,
      goaltype: "invest",
    },
  ],
  assumptions: {
    income: 0,
    savings: 0,
    dividend: 0,
    investments: 0,
    property: 0,
    auto_loan_rate: 0,
    auto_loan_term: 0,
    home_loan_rate: 0,
    home_loan_term: 0,
    property_loan_rate: 0,
    property_loan_term: 0,
    property_loan_down: 0,
  },
  reviewSection: 0,
  progress: 0,
};
