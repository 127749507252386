import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Questionnaire from "src/components/Questionnaire";
import {
  spouseQuestions,
  QuestionFormProps,
  singleQuestions,
} from "src/interfaces/optimizedPlanBuild.interface";
import { QuestionForm } from "src/interfaces/questionnaire.interface";
import { getIsMarried } from "src/store/system/selector";
// import CenterContainer from "../PlanBuilder/Components/CenterContainer";

const WorkStatus = ({
  planSetter,
  planData,
  nextClicked,
  goToNext,
  errors,
  setErrors,
  validate,
  spouseFlag,
  setSpouseFlag,
}: QuestionFormProps) => {
  // note: hold initial next click count
  const [nextClick] = useState(nextClicked);
  const isMarried = useSelector(getIsMarried);
  const [spouseView, setSpouseView] = useState(spouseFlag);
  const questionData: QuestionForm = spouseView
    ? spouseQuestions.nonProfit
    : singleQuestions.nonProfit;

  useEffect(() => {
    if (spouseFlag) {
      setSpouseFlag(false);
    }
  }, [spouseFlag]);

  const startingValues = {
    will_nonprofit: planData.will_nonprofit || undefined,
    s_will_nonprofit: planData.s_will_nonprofit || undefined,
  };
  const [values, setValues] = useState(startingValues);

  const handleChange = (newValues: any) => {
    updatePlanData(newValues);
    setValues(newValues);
  };

  useEffect(() => {
    const planUpdate: any = {};
    if (nextClicked !== nextClick) {
      if (!validate || validate(questionData.fields)) {
        if (!planData.will_nonprofit) {
          planUpdate.will_nonprofit = "n";
        }
        if (isMarried) {
          if (!spouseView) {
            setSpouseView(true);
          } else {
            if (!planData.s_will_nonprofit) {
              planUpdate.s_will_nonprofit = "n";
            }
            planSetter({ ...planData, ...planUpdate });
            goToNext();
          }
        } else {
          planSetter({ ...planData, ...planUpdate });
          goToNext();
        }
      }
    }
  }, [nextClicked]);

  const updatePlanData = (changedValues: any) => {
    const newPlan = { ...planData, ...changedValues };
    planSetter(newPlan);
  };

  return (
    <Questionnaire
      questions={questionData}
      values={values}
      onChange={handleChange}
      errors={errors}
      setErrors={setErrors}
    />
  );
};

export default WorkStatus;
