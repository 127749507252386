import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Global, css } from "@emotion/react";
import {
  EuiBasicTable,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
} from "@elastic/eui";

import { getAIPlanReviewMessages } from "src/store/planBuild/selector";
import { getIsMarried, spouseSelector } from "src/store/system/selector";
import { Account, LifeEvent, PlanGoal } from "src/interfaces";
import { getShortDateString, formatWholeDollars } from "src/utils";
import useWindowSize from "src/hooks/useWindowSize";
import { StyledSpacer } from "src/components/Global/StyledComponents";

import useGoalSummaryData, { DATA_TYPES } from "./useGoalSummaryData";
import ReviewMessages from "../components/ReviewMessages";

export const reviewStyles = css`
  .ai-content {
    background-color: transparent !important;
    padding: 0 !important;
    margin-bottom: 0 !important;
    @media (max-width: 567px) {
      padding: 16px !important;
    }
  }
`;

const GoalSummary = ({ manual }: any) => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 567;
  const isStacked = windowSize.width <= 1415;
  const spouse = useSelector(spouseSelector);
  const aiMessages = useSelector(getAIPlanReviewMessages);
  const isMarried = useSelector(getIsMarried);
  const spouseFirstName = spouse?.first_name || "";

  const formattedData = useGoalSummaryData(DATA_TYPES.GOALS);

  const formattedMessages = useMemo(() => {
    const renderGoalName = (data: {
      goal: PlanGoal | undefined;
      event: LifeEvent | undefined;
      account: Account | undefined;
    }) => {
      if (!data.goal) {
        return "";
      }
      switch (data.goal.goaltype) {
        case "fedloanpayoff":
          if (data.goal.who === "spouse") {
            return `pay off ${
              isMarried ? spouseFirstName : "future spouse"
            }'s Federal student loans`;
          }
          return "pay off your Federal student loans";
        case "perkinsloanpayoff":
          return "pay off your Perkins student loans";
        case "privloanpayoff":
          return "pay off your private student loans";
        case "payoff":
          return `pay off ${data.account?.name || "debt"}`;
        case "emergencyfund":
          return "save for your emergency fund";
        case "down":
          switch (data.event?.eventtype) {
            case "marriage":
              return "save for your wedding";
            case "house":
              return "save for the down payment on your home purchase";
            case "property":
              return "save for the down payment on your investment property purchase";
            case "vehicle":
              return "save for the down payment on your vehicle purchase";
            default:
              return "save for a future down payment";
          }
        case "taxbomb":
          if (data.goal.who === "spouse") {
            return `save for ${
              isMarried ? spouseFirstName : "future spouse"
            }'s IDR tax liability`;
          }
          return "save for your IDR tax liability";
        case "invest":
        default:
          return "prioritize investing";
      }
    };
    const messages = [...aiMessages.goalSummary];
    messages[0] = {
      ...messages[0],
      text: [...messages[0].text],
    };
    const newGoalName = renderGoalName(formattedData.shortTermGoal);
    messages[0].text[0] = messages[0].text[0].replace("GOAL_NAME", newGoalName);
    return messages;
  }, [formattedData, aiMessages.goalSummary]);

  return (
    <>
      <Global styles={reviewStyles} />
      <div className="ai-content-title">
        <h1>Goal Summary</h1>
      </div>
      <StyledSpacer size="24px" />
      <EuiFlexGroup direction={isStacked ? "column" : "row"}>
        <EuiFlexItem grow={3}>
          <EuiCard title="" className="ai-card">
            <div className="ai-card-title">
              <h1>Life Events/Goal Progress</h1>
            </div>
            <EuiBasicTable
              className="table-border table-container ai-table"
              responsive={false}
              tableLayout="fixed"
              items={formattedData.items}
              columns={[
                {
                  field: "label",
                  name: "Life Event/Goal",
                  width: isMobile ? "40%" : "50%",
                  render: (value: any) => (
                    <span
                      className={`custom-header ai-sub-header-np ${
                        isMobile ? "mobile-content-style" : ""
                      }`}
                    >
                      {value}
                    </span>
                  ),
                },
                {
                  field: "date",
                  name: isMobile ? "Date" : "Projected Date",
                  render: (value: string | undefined) => {
                    return value ? (
                      <span
                        className={`ai-sub-content ${
                          isMobile ? "mobile-content-style" : ""
                        }`}
                      >
                        {getShortDateString(value)}
                      </span>
                    ) : (
                      ""
                    );
                  },
                },
                {
                  field: "amount",
                  name: isMobile ? "Amount" : "Target Amount",
                  className: "text-center",
                  render: (value: number | undefined) => {
                    return value ? (
                      <span
                        className={`ai-sub-content ${
                          isMobile ? "mobile-content-style" : ""
                        }`}
                      >
                        {formatWholeDollars(value)}
                      </span>
                    ) : (
                      ""
                    );
                  },
                },
              ]}
            />
          </EuiCard>
          {isStacked ? null : <StyledSpacer size="16px" />}
        </EuiFlexItem>
        <EuiFlexItem grow={2}>
          <ReviewMessages messages={formattedMessages} manual={manual} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

export default GoalSummary;
