import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { QuestionFormProps } from "src/interfaces/optimizedPlanBuild.interface";
import Score from "src/pages/PlanBuilder/Views/Review/ReviewViews/Score";
import GoalSummary from "src/pages/PlanBuilder/Views/Review/ReviewViews/GoalSummary";
import AssetsInvestments from "src/pages/PlanBuilder/Views/Review/ReviewViews/AssetsInvestments";
import Debts from "src/pages/PlanBuilder/Views/Review/ReviewViews/Debts";
import NetWealth from "src/pages/PlanBuilder/Views/Review/ReviewViews/NetWealth";
import PayCheckAllocation from "src/pages/PlanBuilder/Views/Review/ReviewViews/PayCheckAllocation";
import { getLoadingProjection } from "src/store/planBuild/selector";
import Interstitial from "./Interstitial";

const Transition = ({ reviewStepIndex }: QuestionFormProps) => {
  const loading = useSelector(getLoadingProjection);
  const [settingPlan, setSettingPlan] = useState(true);

  // fetch projections
  // TODO uncomment when plan optimizer backend is ready
  // useEffect(() => {
  //   if (settingPlan && loading) {
  //     setSettingPlan(false);
  //   }
  // }, [loading]);

  useEffect(() => {
    setTimeout(() => setSettingPlan(false), 5000);
  });

  const Sections = [
    GoalSummary,
    PayCheckAllocation,
    Score,
    AssetsInvestments,
    Debts,
    NetWealth,
  ];

  const Section = Sections[reviewStepIndex];

  return settingPlan || loading ? <Interstitial /> : <Section />;
};

export default Transition;
