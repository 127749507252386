import React from "react";
import { useSelector } from "react-redux";
import { AppLoader } from "src/components";
import DashboardLayout from "src/layouts/DashboardLayout";
import LandingPage from "./LandingPage";
import Content from "./Content";
import { getLoadingPlans, getPlans } from "src/store/planBuild/selector";

const PlanListContainer = () => {
  const loadingPlans = useSelector(getLoadingPlans);
  const plans = useSelector(getPlans);

  const renderContent = () => {
    if (!plans || !plans.length) {
      if (loadingPlans) {
        return <AppLoader />;
      }
      return <LandingPage />;
    }
    return <Content />;
  };

  return (
    <DashboardLayout pageTitle="Edit/Review Plans">
      {renderContent()}
    </DashboardLayout>
  );
};

export default PlanListContainer;
