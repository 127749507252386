import * as React from "react";

import DashboardLayout from "src/layouts/DashboardLayout";
import { Content } from "./Content";
import "src/pages/Dashboard/MyProfile/Transactions/TransactionsPage.css";

const Transactions = () => {
  return (
    <DashboardLayout pageTitle="Transactions">
      <Content />
    </DashboardLayout>
  );
};

export default Transactions;
