import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";

import Questionnaire from "src/components/Questionnaire";
import {
  QuestionFormProps,
  singleQuestions,
  spouseQuestions,
} from "src/interfaces/optimizedPlanBuild.interface";
import { Question, QuestionForm } from "src/interfaces/questionnaire.interface";
import { getIsMarried } from "src/store/system/selector";
import { fillInDefaults } from "../common";

const EmployerStudentLoans = ({
  planSetter,
  planData,
  goToNext,
  nextClicked,
  errors,
  setErrors,
  validate,
  spouseFlag,
  setSpouseFlag,
}: QuestionFormProps) => {
  const [nextClick] = useState(nextClicked);
  const isMarried = useSelector(getIsMarried);
  const [spouseView, setSpouseView] = useState(spouseFlag);
  const questionData: QuestionForm = spouseView
    ? spouseQuestions.employerStudentLoans
    : singleQuestions.employerStudentLoans;

  useEffect(() => {
    if (spouseFlag) {
      setSpouseFlag(false);
    }
  }, [spouseFlag]);

  const startingValues = cloneDeep(questionData.emptyValue);
  for (const key in questionData.emptyValue) {
    if (key.slice(2) === "employer_contribute_to_student_loans") {
      const contributionKey = spouseView
        ? "s_employer_student_loan_contribution"
        : "employer_student_loan_contribution";
      startingValues[key] = planData[contributionKey] ? "y" : "n";
    } else {
      const savedData = planData[key as keyof typeof planData];
      const defaultValue = questionData.emptyValue[key];
      if (savedData && savedData !== defaultValue) {
        const question = questionData.fields.find(
          (question) => question.field === key
        );
        if (question && question.enabled) {
          const enablers = question.enabled;
          enablers.forEach((enabler) => {
            startingValues[enabler] = "y";
          });
        }
        startingValues[key] = savedData;
      }
    }
  }
  const [values, setValues] = useState<Question>(startingValues);

  useEffect(() => {
    if (nextClicked !== nextClick) {
      if (!validate || validate(questionData.fields)) {
        if (isMarried && !spouseView) {
          setValues(spouseQuestions.employerStudentLoans.emptyValue);
          setSpouseView(true);
        } else {
          goToNext();
        }
        planSetter(fillInDefaults({ ...planData }, questionData.defaultValue));
      }
    }
  }, [nextClicked]);

  const handleChange = (newValues: any) => {
    const newPlan = { ...planData, ...newValues };
    const enableKey = `${
      spouseView ? "s_" : ""
    }employer_contribute_to_student_loans`;
    const valueKey = `${
      spouseView ? "s_" : ""
    }employer_student_loan_contribution`;
    if (newPlan[enableKey] !== "y") {
      newPlan[valueKey] = 0;
      newValues[valueKey] = 0;
    }
    planSetter(newPlan);
    setValues(newValues);
  };

  return (
    <Questionnaire
      questions={questionData}
      values={values}
      onChange={handleChange}
      spouseView={spouseView}
      errors={errors}
      setErrors={setErrors}
    />
  );
};

export default EmployerStudentLoans;
