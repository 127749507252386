import React from 'react';
import { ReactComponent as Home } from 'src/assets/svgs/home.svg';
import { ReactComponent as PlanSummary } from 'src/assets/svgs/plan_summary.svg';
import { ReactComponent as FinancialPlans } from 'src/assets/svgs/financial_plans.svg';
import { ReactComponent as MoneyDetails } from 'src/assets/svgs/money_details.svg';
import { ReactComponent as Transactions } from 'src/assets/svgs/transactions.svg';
import { ReactComponent as Accounts } from 'src/assets/svgs/accounts.svg';
import { ReactComponent as ToolsProducts } from 'src/assets/svgs/tools.svg';
import { ReactComponent as Refer } from 'src/assets/svgs/Refer_Friend.svg';
import { ReactComponent as Coach } from 'src/assets/svgs/coach_icon.svg';
import { ReactComponent as Works } from 'src/assets/svgs/works_icon.svg';
import { ReactComponent as Logout } from 'src/assets/svgs/logout.svg';
import { ReactComponent as Settings } from 'src/assets/svgs/settings_dash.svg';

const iconMap = {
  Home,
  PlanSummary,
  FinancialPlans,
  MoneyDetails,
  Transactions,
  Accounts,
  ToolsProducts,
  Settings,
  Coach,
  Refer,
  Works,
  Logout,
};

export const getCustomIcon = (iconName: keyof typeof iconMap) => {
  const IconComponent = iconMap[iconName];

  if (!IconComponent) {
    console.warn(`Icon "${iconName}" is not a recognized custom icon. Please add it to the icon map.`);
    return null;
  }

  // Return a function that returns a React SVG Element
  return () => <IconComponent />;
};