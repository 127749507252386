import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forOwn } from "lodash";
import { format } from 'date-fns';
import { MappedCashflow, RISK_MANAGEMENT_TYPES, CASHFLOW_TYPES, Cashflow, } from "src/interfaces";
import BudgetCard from "../../DayToDay/Components/BudgetCard";
import Dialog from "../../DayToDay/Components/Dialog";
import ReviewTransactionsDialog from "src/pages/Dashboard/MyProfile/Transactions/Dialogs/ReviewTransactionsDialog";
import { AddCashflowItemPayload } from "src/store/cashflow/actions";
import { getIncomeAndExpenseSummaries } from "src/store/dashboard/actions";
import {
  getLoadedSummaries,
  getRefreshNeeded,
  getRiskSummary,
} from "src/store/dashboard/selector";
import {
  addCashflow,
  editCashflow,
  removeCashflow,
} from "src/store/cashflow/actions";
import { getRiskCashflows } from "src/store/cashflow/selector";
import { getIsSubscribed } from "src/store/system/selector";
import {
  EuiButtonGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
} from "@elastic/eui";
import { Global, css } from "@emotion/react";
import AddItemDialog from "../../DayToDay/Components/Dialog";
import { useFlyout } from "src/components/Global/FlyoutContext";

const rmInsuranceStyles = css`
  .rm-insurance-block {
    .rm-secondary-header {
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      font-family: "Inter", sans-serif;
    }
    .rm-tab-group {
      min-width: 400px;
      .euiButtonGroupButton {
        background-color: #fbfcfd;
        border: 1px solid #cecece;
      }
      .euiButtonGroupButton-isSelected {
        background-color: #69707d;
      }
      span {
        font-size: 14px;
        font-family: "Inter", sans-serif;
      }
    }
  }
`;


const Insurance = () => {
  const dispatch = useDispatch();
  const subscribed = useSelector(getIsSubscribed);
  const cashflows: MappedCashflow[] = useSelector(getRiskCashflows);
  const riskSummary = useSelector(getRiskSummary);
  const { openCustomFlyout } = useFlyout();
  const loadedSummaries = useSelector(getLoadedSummaries);
  const refreshNeeded = useSelector(getRefreshNeeded);
  const [currentTab, setCurrentTab] = useState(0);
  const [isEditing, setEditing] = useState(false);
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  // const [showTransactionsDialog, setShowTransactionsDialog] = useState(false);
  // const [transactionQuery, setTransactionQuery] = useState({});

  const tabOptions = [
    {
      id: "current",
      label: "Current",
    },
    {
      id: "monthly",
      label: "Last Month",
    },
    {
      id: "annual",
      label: "Last 12 Months",
    },
  ];

  const _handleTabs = (id: any) => {
    const tabIndex = tabOptions.findIndex((tab) => tab.id === id);
    setCurrentTab(tabIndex);
  };

  const openAddDialog = () => {
    const availableTypeList = Object.entries(RISK_MANAGEMENT_TYPES).map(([value, label]) => ({
      value,
      label,
    }));

    openCustomFlyout({
      component: (props: any) => (
        <AddItemDialog
          {...props}
          list={availableTypeList}
          onAdd={(values: { type: string; annual: string; who?: string }) => {
            const amount = +values.annual || 0;
            const cashflow = {
              amount,
              type: values.type,
              who: values.who === "spouse" ? "spouse" : undefined,
              inschool: false,
            };
            dispatch(addCashflow({ cashflow }));
          }}
        />
      ),
      title: "Add Insurance Premium",
      showOwner: true,
    });
  };


  const calculateDateRange = (): string => {
    const now = new Date();
    let startDate: Date;
    const endDate = now;

    switch (currentTab) {
      case 1: // Last 30 Days
        startDate = new Date();
        startDate.setDate(now.getDate() - 30);
        break;
      case 2: // Last 12 Months
        startDate = new Date();
        startDate.setFullYear(now.getFullYear() - 1);
        break;
      case 0: // Current
      default:
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        break;
    }

    return `${format(startDate, "MMM yyyy")} - ${format(endDate, "MMM yyyy")}`;
  };

  const loadSummaries = () => dispatch(getIncomeAndExpenseSummaries());

  useEffect(() => {
    if (subscribed && (!loadedSummaries || refreshNeeded.summaries)) {
      loadSummaries();
    }
  }, [subscribed, loadedSummaries, refreshNeeded.summaries]);

  const handleCardEditClick = (
    changes: { [stringId: string]: number },
    deletedIds: Set<number>
  ) => {
    if (isEditing) {
      forOwn(changes, (amount: number, stringId: string) => {
        const cashflow = cashflows.find(
          (item: MappedCashflow) => item.id === +stringId
        );
        if (cashflow) {
          const newAmount = currentTab === 2 ? amount : amount * 12;
          const update: any = { id: +stringId, amount: newAmount };
          dispatch(editCashflow(update));
        }
      });
      Array.from(deletedIds).forEach((id: number) =>
        dispatch(removeCashflow(id))
      );
    }
    setEditing(!isEditing);
  };

  const availableTypeList: Array<{ label: string; value: string }> = [];
  forOwn(RISK_MANAGEMENT_TYPES, (label, cashflowType) => {
    availableTypeList.push({
      label,
      value: cashflowType,
    });
  });

  // const getActual = () => {
  //   if (currentTab === 0) {
  //     return riskSummary.current;
  //   } else if (currentTab === 1) {
  //     return riskSummary.monthly;
  //   }
  //   return riskSummary.annual;
  // };

  // const openTransactionsDialog = (key: string) => {
  //   const timeframes = ["current", "monthly", "annual"];
  //   const timeframe = timeframes[currentTab];
  //   const query: any = {
  //     category: "risk",
  //     period: timeframe,
  //     type: key,
  //   };
  //   setShowTransactionsDialog(true);
  //   setTransactionQuery(query);
  // };

  // const closeTransactionsDialog = () => {
  //   setShowTransactionsDialog(false);
  //   setTransactionQuery({});
  // };

  return (
    <>
      <Global styles={rmInsuranceStyles} />
      <div className="rm-insurance-block">
        <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
          <EuiFlexItem grow={false}>
            <EuiTitle className="rm-secondary-header">
              <h2>Insurance Premiums</h2>
            </EuiTitle>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonGroup
              legend="Insurance tabs"
              options={tabOptions}
              idSelected={tabOptions[currentTab].id}
              onChange={_handleTabs}
              isFullWidth
              className="rm-tab-group"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
      <BudgetCard
        buttonLabel="Add Premium"
        budget={cashflows}
        actual={subscribed ? riskSummary : undefined}
        onSave={handleCardEditClick}
        onAdd={openAddDialog}
        editing={isEditing}
        openTransactionsDialog={() => null /* openTransactionsDialog */}
        annual={currentTab === 2}
        dateRange={calculateDateRange()}
      />
    </>
  );
};

export default Insurance;
