import React from "react";
import { EuiCard, EuiTitle, EuiImage, EuiButton } from "@elastic/eui";
import zero_state_plan_summary from "src/assets/svgs/zero_state_no_plan.svg";
import { Link } from "react-router-dom";
import { StyledSpacer } from "src/components/Global/StyledComponents";

const LandingPage = () => {
  return (
    <div className="fp-general fp-page-zero">
      <EuiTitle size="s" className="fp-header-text">
        <h1>Financial Plans</h1>
      </EuiTitle>
      <StyledSpacer size="24px" />
      <div className="fp-content">
        <p>
          Building a financial plan is key to reaching your financial goals. You
          can add life events and goals so the plan is tailored to you and what
          you want to accomplish. You can then use FitBUX to run a simulation of
          your plan to make sure you can succeed in achieving your goals. If
          everything looks good, implement your plan to start tracking your
          progress.
        </p>
      </div>
      <StyledSpacer size="24px" />
      <EuiCard
        title=""
        paddingSize="l"
        textAlign="center"
        className="zero-state-card fp-card"
      >
        <StyledSpacer size="16px" />
        <EuiImage
          className=""
          src={zero_state_plan_summary}
          alt="phone and pencil"
          size="original"
        />
        <StyledSpacer size="24px" />
        <Link to="/plan-select">
          <EuiButton fill color="primary">
            Build Your Financial Plan
          </EuiButton>
        </Link>
        <StyledSpacer size="16px" />
      </EuiCard>
    </div>
  );
};

export default LandingPage;
