import React from "react";
import { useDispatch, useSelector } from "react-redux";
import colors from "src/theme/colors";
import { additionalQuestionsBlowout } from "./blowoutContent";

import {
  Box,
  Container,
  FormControl,
  FormLabel,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

import {
  savePlan,
  setBuildStep,
  updateCurrentPlan,
} from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import {
  getCurrentPlan,
  getEmergencyMonthlyExpenses,
} from "src/store/planBuild/selector";
import { PlanViewComponent } from "src/interfaces/viewComponent.interface";
import { Plan } from "src/interfaces/plan.interface";
import { formatMonthly, formatWholeDollars } from "src/utils";
import CenterContainer from "../../Components/CenterContainer";
import { cloneDeep } from "lodash";
import ToolTipIcon from "src/components/ToolTipIcon";

const EmergencyFund: PlanViewComponent = ({ render }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const monthlyExpenses = useSelector(getEmergencyMonthlyExpenses);
  const plan: Plan = useSelector(getCurrentPlan);
  const myStudentLoanEntry = plan.studentloan[0];

  const handleSetMonths = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    dispatch(updateCurrentPlan({ emergencymonths: +value }));
    dispatch(savePlan(null));
  };

  const handleChangePriority = (e: React.ChangeEvent<any>) => {
    const planProfile = {
      ...plan.profile,
      priority: e.target.value,
    };
    dispatch(updateCurrentPlan({ profile: planProfile }));
    dispatch(savePlan(null));
  };

  const handleChangePrepay = (e: React.ChangeEvent<any>) => {
    const value = e.target.value;
    const updatedStudentloan = cloneDeep(plan.studentloan);
    updatedStudentloan[0].strategy = value;
    updatedStudentloan[1].strategy = value;
    dispatch(updateCurrentPlan({ studentloan: updatedStudentloan }));
    dispatch(savePlan(null));
  };

  const onNext = () => {
    dispatch(setBuildStep(PLAN_BUILD_STEPS.GOALS));
  };

  // const isMoneyNegative = remainingMoney < 0;

  // const nextTooltip = !isMoneyNegative
  //   ? undefined
  //   : `Vacation / Fun money is negative, which means you are over saving. Go back to steps 1, 2, 3 and edit your plan so vacation / fun money is positive before you continue.`;

  const priority = plan.profile.priority || "debt";
  const months = plan.emergencymonths;

  return render({
    component: (
      <CenterContainer
        scrollable
        title="Additional Questions"
        iconName="fb-life-saver"
        help={additionalQuestionsBlowout}
      >
        <Box className="mt-5 flex items-center">
          <Typography className="text-sm">
            Enter how much of an emergency fund you'd like to have. This will
            add a goal to your financial plan that FitBUX will track for you!
            <span style={{ position: "absolute" }}>
              <ToolTipIcon
                title="If you currently do not have an amount in you checking/savings accounts that are greater than your emergency fund goal, FitBUX automatically makes saving for your emergency fund goal #1 in your financial plan."
                placement="right"
              />
            </span>
          </Typography>
        </Box>
        <Container maxWidth="md">
          <Box className={styles.header}>
            <Box style={{ width: "25%" }}>Number of Months</Box>
            <Box style={{ width: "10%" }} />
            <Box style={{ width: "25%", textAlign: "center" }}>
              Monthly expenses
            </Box>
            <Box style={{ width: "10%" }} />
            <Box style={{ width: "15%", textAlign: "center" }}>Total</Box>
          </Box>
          <Box className={styles.inputRow}>
            <Box style={{ width: "25%" }}>
              <TextField
                id="emergencymonths"
                name="emergencymonths"
                fullWidth
                onChange={handleSetMonths}
                type="number"
                value={plan.emergencymonths}
                variant="outlined"
                style={{ fontSize: "13px" }}
              />
            </Box>
            <Box
              className={styles.symbol}
              style={{ width: "10%", textAlign: "center" }}
            >
              ×
            </Box>
            <Box
              style={{ width: "25%", textAlign: "center", fontSize: "13px" }}
            >
              {formatMonthly(monthlyExpenses)}
            </Box>
            <Box className={styles.symbol} style={{ width: "10%" }}>
              =
            </Box>
            <Box
              style={{ width: "15%", textAlign: "center", fontSize: "13px" }}
            >
              {formatWholeDollars(monthlyExpenses * months)}
            </Box>
          </Box>
        </Container>

        <Box className="mt-5 mb-5">
          <Typography className="mb-4 text-sm">
            Do you want to prioritize investing or paying off debt?
          </Typography>

          <Grid item xs={6}>
            <Select
              placeholder="Month"
              variant="outlined"
              style={{ width: "50%", fontSize: "13px" }}
              name="month"
              onChange={handleChangePriority}
              value={priority}
              // className={styles.autoText + " mr-4"}
            >
              <MenuItem value={"debt"}>Pay Off Debt</MenuItem>
              <MenuItem value={"asset"}>Invest</MenuItem>
            </Select>
          </Grid>
        </Box>

        {priority === "debt" && (
          <>
            <Typography className="text-sm">
              Do you want to use the high interest rate or low balance method to
              prepay debt?
            </Typography>
            <Grid item xs={6}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" className="mb-4">
                  Choose a repayment method:
                </FormLabel>
                <Select
                  variant="outlined"
                  fullWidth
                  value={myStudentLoanEntry?.strategy || "high_interest_rate"}
                  name="strategy"
                  placeholder="Select"
                  onChange={handleChangePrepay}
                  style={{ fontSize: "13px" }}
                >
                  <MenuItem value="high_interest_rate">
                    High interest rate
                  </MenuItem>
                  <MenuItem value="low_balance">Low balance</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </>
        )}
      </CenterContainer>
    ),
    // nextDisabled: isMoneyNegative,
    onNext,
    // nextTooltip,
  });
};

export default EmergencyFund;

const useStyles = makeStyles({
  root: {
    borderRadius: 12,
    marginBottom: 10,
  },
  content: {
    padding: 15,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    color: colors.brandingBlue1,
    marginBottom: "1.25rem",
    fontSize: 14,
    marginTop: "1.25rem",
  },
  inputRow: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  symbol: {
    fontSize: "2em",
    color: colors.brandingBlue1,
  },
});
