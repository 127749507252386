import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@material-ui/core";

import { getCurrentStep } from "src/store/planBuild/selector";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import Button from "src/components/Button";

interface BuildPopProps {
  close: (value: string) => void;
  transitionPopup: VoidFunction;
}

type buildProps = BuildPopProps;

const BuildPopup = ({ close, transitionPopup }: buildProps) => {
  const currentStep = useSelector(getCurrentStep);

  useEffect(() => {
    if (currentStep <= PLAN_BUILD_STEPS.REUSE_SELECTIONS) {
      close("plan");
    }
  }, [currentStep]);

  return (
    <Box className="flex flex-col justify-between items-center h-36">
      <Typography variant="h1">Implement Your Plan</Typography>
      <Typography variant="h6">
        Would you like to implement and track the plan you just built?
      </Typography>
      <Box className="flex justify-between">
        <Button
          fbColor="primary"
          onClick={() => {
            transitionPopup();
          }}
          className="mr-4"
        >
          Yes, I Want To Implement My Plan
        </Button>
        <Button
          fbColor="secondary"
          onClick={() => {
            close("plan-list");
          }}
        >
          Close this plan
        </Button>
      </Box>
    </Box>
  );
};

export default BuildPopup;
