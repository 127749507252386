import * as React from "react";

import PlanBuilderLayout from "src/layouts/PlanBuilderLayout";
import { Content } from "./Content";

const PlanBuild = () => {
  return (
    <PlanBuilderLayout>
      <Content />
    </PlanBuilderLayout>
  );
};

export default PlanBuild;
