import React from "react";

import { EuiButton, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeaderTitle, EuiModalHeader, EuiIcon } from "@elastic/eui";
import { StyledEuiButtonEmpty } from "../Global/StyledComponents";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";

const modalStyles = css`
  .modal-body {
    padding: 0 16px;
    p {
      font-size: 16px;
      font-family: "Inter", sans-serif;
    }
    .euiModalBody__overflow {
      padding-inline: 0;
    }
  }
`;

export const StyledEuiModalHeader = styled(EuiModalHeader)`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledEuiIcon = styled(EuiIcon)`
  width: 24px;
  height: 24px;
`;

export const ConfirmDialog = ({
  visible,
  title,
  message,
  confirmButton,
  cancelButton,
  onConfirm,
  onCancel,
}: any) => {
  return (
    <>
      <Global styles={modalStyles} />
      {visible && (
        <EuiModal
          onClose={onCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ maxWidth: 600 }}
        >
          <StyledEuiModalHeader>
            <EuiModalHeaderTitle id="alert-dialog-title">{title}</EuiModalHeaderTitle>
            <StyledEuiIcon
              type="cross"
              onClick={onCancel}
              style={{
                cursor: "pointer",
              }}
            />
          </StyledEuiModalHeader>
          <EuiModalBody className="modal-body">
            <p>{message}</p>
          </EuiModalBody>
          <EuiModalFooter>
            <StyledEuiButtonEmpty onClick={onCancel}>
              {cancelButton || "No"}
            </StyledEuiButtonEmpty>
            <EuiButton fill onClick={onConfirm}>
              {confirmButton || "Yes"}
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      )}
    </>
  );
};

export default ConfirmDialog;