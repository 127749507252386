import produce from "immer";
import { cloneDeep, pickBy } from "lodash";
import { handleActions } from "redux-actions";

import {
  Assumptions,
  Earning,
  Expense,
  FbAction,
  LifeEvent,
  PlanAllocations,
  PlanBuildState,
  Plan,
  PlanLiabilities,
  PlanListRecord,
  PlanProjection,
} from "src/interfaces";
import { FAIL, START, SUCCESS } from "../common";
import * as actions from "./actions";
import {
  PLAN_BUILD_STEPS,
  ReviewSections,
  EMPTY_PLAN,
  graduatedProgressSteps,
  inschoolProgressSteps,
} from "./constants";
import { LOG_OUT, SET_GRADUATED_MODE } from "../system/actions";

const initialState: PlanBuildState = {
  compareWithPlanIndex: -1,
  compareWithPlanDetail: cloneDeep(EMPTY_PLAN),
  currentStep: PLAN_BUILD_STEPS.NAME,
  planBuildReady: false,
  dirty: false,
  error: {
    comparePlan: false,
    currentPlan: false,
    emergencyFund: false,
    liabilities: false,
    livePlan: false,
    plans: false,
    projection: false,
    savedProjections: false,
    taxes: false,
  },
  savedPlans: [],
  saving: false,
  currentPlan: cloneDeep(EMPTY_PLAN),
  livePlan: cloneDeep(EMPTY_PLAN),
  loaded: {
    currentPlan: false,
    livePlan: false,
    plans: false,
    projection: false,
    savedProjections: false,
  },
  loading: {
    comparePlan: false,
    currentPlan: false,
    emergencyFund: false,
    liabilities: false,
    livePlan: false,
    plans: false,
    projection: false,
    savedProjections: false,
    taxes: false,
  },
  monthlyOverview: false,
  projection: null,
  liveProjection: null,
  minimalProjection: null,
  savedPlanProjections: [],
  estimatedMonthlyEmergencyFund: 0,
  compareMonthlyEmergencyFund: 0,
  liveMonthlyEmergencyFund: 0,
  estimatedStudentTax: {},
  compareStudentTax: {},
  liveStudentTax: {},
  estimatedMonthlyTax: 0,
  compareMonthlyTax: 0,
  liveMonthlyTax: 0,
  liabilities: {
    min: {
      auto_loan: 0,
      home_loan: 0,
      property_loan: 0,
      personal_loan: 0,
      credit_card: 0,
      other_debt: 0,
      fed_loan: 0,
      perkins_loan: 0,
      priv_loan: 0,
      solo: [
        {
          fed_loan: 0,
        },
        {
          fed_loan: 0,
        },
      ],
    },
    federal: {
      applicant: false,
      spouse: false,
    },
    perkins: {
      applicant: false,
      spouse: false,
    },
    private: {
      applicant: false,
      spouse: false,
    },
  },
  reviewSection: 0,
  plans: [],
};

const reducerDefinitions: any = {
  [actions.RESET_CURRENT_PLAN]: (state: PlanBuildState) => ({
    ...state,
    currentPlan: cloneDeep(EMPTY_PLAN),
    compareWithPlanDetail: cloneDeep(EMPTY_PLAN),
  }),
  [actions.UPDATE_CURRENT_PLAN]: (
    state: PlanBuildState,
    { payload }: FbAction<Partial<Plan>>
  ) =>
    produce(state, (draft) => {
      draft.dirty = true;
      draft.currentPlan = { ...draft.currentPlan, ...payload };
    }),
  [actions.REPLACE_CURRENT_PLAN]: (
    state: PlanBuildState,
    { payload }: FbAction<Plan>
  ) => ({ ...state, dirty: true, currentPlan: payload }),
  [actions.UPDATE_ALLOCATIONS]: (
    state: PlanBuildState,
    { payload }: FbAction<PlanAllocations>
  ) =>
    produce(state, (draft) => {
      draft.dirty = true;
      draft.currentPlan.allocations[0] = payload;
    }),
  [actions.UPDATE_ASSUMPTIONS]: (
    state: PlanBuildState,
    { payload }: FbAction<Partial<Assumptions>>
  ) =>
    produce(state, (draft) => {
      draft.dirty = true;
      draft.currentPlan.assumptions = {
        ...draft.currentPlan.assumptions,
        ...payload,
      };
    }),
  [actions.LOAD_LIVE_PLAN + SUCCESS]: (
    state: PlanBuildState,
    {
      payload,
    }: FbAction<{
      plan: Plan;
      taxes: number;
      studentTax: any;
      emergencyFund: number;
      allocations: any;
    }>
  ) =>
    produce(state, (draft) => {
      payload.plan.allocations[0] = {
        ...pickBy(payload.plan.allocations[0]),
        solo: payload.plan.allocations[0].solo,
      };
      draft.livePlan = payload.plan;
      draft.liveMonthlyTax = payload.taxes;
      draft.liveStudentTax = payload.studentTax;
      draft.liveMonthlyEmergencyFund = payload.emergencyFund;
      draft.loaded.livePlan = true;
      draft.currentPlan.allocations = payload.plan.allocations;
    }),
  [actions.SET_MONTHLY_OVERVIEW]: (
    state: PlanBuildState,
    { payload }: FbAction<boolean>
  ) =>
    produce(state, (draft) => {
      draft.monthlyOverview = payload;
    }),
  [actions.SET_PLAN_BUILD_READY]: (state: PlanBuildState) => ({
    ...state,
    planBuildReady: true,
  }),
  [actions.SET_CURRENT_PLAN + START]: (
    state: PlanBuildState,
    { payload }: FbAction<actions.SetCurrentPlanPayload>
  ) =>
    produce(state, (draft) => {
      draft.loading.currentPlan = true;
      draft.planBuildReady = true;
      if (
        payload.index !== undefined &&
        draft.savedPlanProjections[payload.index]
      ) {
        draft.projection = draft.savedPlanProjections[payload.index];
      }
    }),
  [actions.SET_OPTIMIZED_PLAN + START]: (
    state: PlanBuildState,
    { payload }: FbAction<actions.SetCurrentPlanPayload>
  ) =>
    produce(state, (draft) => {
      draft.loading.currentPlan = true;
      draft.planBuildReady = true;
      if (
        payload.index !== undefined &&
        draft.savedPlanProjections[payload.index]
      ) {
        draft.projection = draft.savedPlanProjections[payload.index];
      }
    }),
  [actions.SET_CURRENT_PLAN + SUCCESS]: (
    state: PlanBuildState,
    { payload }: FbAction<{ plan: Plan; dirty?: boolean; keepId?: boolean }>
  ) =>
    produce(state, (draft) => {
      if (payload.dirty) {
        draft.dirty = true;
      }
      const newPlan = payload.keepId
        ? {
            ...payload.plan,
            id: draft.currentPlan.id || 0,
            name: draft.currentPlan.name,
          }
        : payload.plan;
      draft.currentPlan = newPlan;
      if (draft.currentPlan.profile) {
        if (!draft.currentPlan.profile.spouseDOB) {
          draft.currentPlan.profile.spouseDOB = "1999-01-01";
        }
      }
      if (newPlan.progress) {
        const inschool = newPlan.progress >= 200;
        const progressSteps = inschool
          ? inschoolProgressSteps
          : graduatedProgressSteps;
        const currentProgressStep = Object.entries(progressSteps).find(
          (entry: any) => entry[1] === newPlan.progress
        );
        if (currentProgressStep) {
          (draft as any).currentStep = currentProgressStep[0];
        }
      }
      draft.loading.currentPlan = false;
      draft.loaded.currentPlan = true;
      draft.minimalProjection = null;
    }),
  [actions.SET_CURRENT_PLAN + FAIL]: (
    state: PlanBuildState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.loading.currentPlan = false;
      draft.error.currentPlan = payload;
      draft.planBuildReady = false;
    }),
  [actions.FETCH_PLANS + START]: (state: PlanBuildState) =>
    produce(state, (draft) => {
      draft.loading.plans = true;
    }),
  [actions.FETCH_PLANS + SUCCESS]: (
    state: PlanBuildState,
    { payload }: FbAction<PlanListRecord[]>
  ) =>
    produce(state, (draft) => {
      draft.loading.plans = false;
      draft.loaded.plans = true;
      draft.savedPlans = payload.slice(0, 3);
    }),
  [actions.FETCH_PLANS + FAIL]: (
    state: PlanBuildState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.loading.plans = false;
      draft.error.plans = payload;
    }),
  [actions.DELETE_PLAN + START]: (state: PlanBuildState) =>
    produce(state, (draft) => {
      draft.loading.plans = true;
    }),
  [actions.DELETE_PLAN + SUCCESS]: (
    state: PlanBuildState,
    { payload }: FbAction<number>
  ) =>
    produce(state, (draft) => {
      draft.loading.plans = false;
      let matchIndex = 0;
      draft.savedPlans = draft.savedPlans.filter(
        (item: PlanListRecord, index) => {
          if (item.id !== payload) {
            return true;
          }
          matchIndex = index;
          return false;
        }
      );
      draft.savedPlanProjections.splice(matchIndex, 1);
    }),
  [actions.DELETE_PLAN + FAIL]: (
    state: PlanBuildState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.loading.plans = false;
      draft.error.plans = payload;
    }),
  [actions.ESTIMATE_TAXES + START]: (state: PlanBuildState) =>
    produce(state, (draft) => {
      draft.loading.taxes = true;
    }),
  [actions.ESTIMATE_TAXES + SUCCESS]: (
    state: PlanBuildState,
    { payload }: FbAction<number>
  ) =>
    produce(state, (draft) => {
      draft.loading.taxes = false;
      draft.estimatedMonthlyTax = payload;
    }),
  [actions.ESTIMATE_TAXES + FAIL]: (
    state: PlanBuildState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.loading.taxes = false;
      draft.error.taxes = payload;
    }),
  [actions.ESTIMATE_STUDENT_TAXES + START]: (state: PlanBuildState) =>
    produce(state, (draft) => {
      draft.loading.taxes = true;
    }),
  [actions.ESTIMATE_STUDENT_TAXES + SUCCESS]: (
    state: PlanBuildState,
    { payload }: FbAction<number>
  ) =>
    produce(state, (draft) => {
      draft.loading.taxes = false;
      draft.estimatedStudentTax = payload;
    }),
  [actions.ESTIMATE_STUDENT_TAXES + FAIL]: (
    state: PlanBuildState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.loading.taxes = false;
      draft.error.taxes = payload;
    }),
  [actions.ESTIMATE_EMERGENCY_FUND + START]: (state: PlanBuildState) =>
    produce(state, (draft) => {
      draft.loading.emergencyFund = true;
    }),
  [actions.ESTIMATE_EMERGENCY_FUND + SUCCESS]: (
    state: PlanBuildState,
    { payload }: FbAction<number>
  ) =>
    produce(state, (draft) => {
      draft.loading.emergencyFund = false;
      draft.estimatedMonthlyEmergencyFund = payload;
    }),
  [actions.ESTIMATE_EMERGENCY_FUND + FAIL]: (
    state: PlanBuildState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.loading.emergencyFund = false;
      draft.error.emergencyFund = payload;
    }),
  [actions.GET_LIABILITIES + START]: (state: PlanBuildState) =>
    produce(state, (draft) => {
      draft.loading.liabilities = true;
    }),
  [actions.GET_LIABILITIES + SUCCESS]: (
    state: PlanBuildState,
    { payload }: FbAction<PlanLiabilities>
  ) =>
    produce(state, (draft) => {
      draft.loading.liabilities = false;
      draft.liabilities = payload;
      draft.error.liabilities = false;
    }),
  [actions.GET_LIABILITIES + FAIL]: (
    state: PlanBuildState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.loading.liabilities = false;
      draft.error.liabilities = payload;
    }),
  [actions.SET_BUILD_STEP]: (
    state: PlanBuildState,
    { payload }: FbAction<PLAN_BUILD_STEPS>
  ) =>
    produce(state, (draft) => {
      draft.currentStep = payload;
    }),
  [actions.ADD_LIFE_EVENT]: (
    state: PlanBuildState,
    { payload }: FbAction<LifeEvent>
  ) =>
    produce(state, (draft) => {
      draft.dirty = true;
      draft.currentPlan.lifeevents.push(payload);
    }),
  // [actions.OPEN_EDIT_LIFE_EVENT]: (state: PlanBuildState, { payload }: FbAction<number>) =>
  //   produce(state, (draft) => {
  //     draft.editingLifeEvent = payload;
  //   }),
  [actions.EDIT_LIFE_EVENT]: (
    state: PlanBuildState,
    { payload }: FbAction<actions.EditPayload<LifeEvent>>
  ) =>
    produce(state, (draft) => {
      draft.dirty = true;
      draft.currentPlan.lifeevents[payload.index] = {
        ...draft.currentPlan.lifeevents[payload.index],
        ...payload.update,
      };
    }),
  [actions.REMOVE_LIFE_EVENT]: (
    state: PlanBuildState,
    { payload }: FbAction<number>
  ) =>
    produce(state, (draft) => {
      draft.dirty = true;
      draft.currentPlan.lifeevents.splice(payload, 1);
    }),
  [actions.ADD_EARNING]: (
    state: PlanBuildState,
    { payload }: FbAction<Earning>
  ) =>
    produce(state, (draft) => {
      draft.dirty = true;
      (draft.currentPlan.incomes || []).push(payload);
    }),
  [actions.EDIT_EARNING]: (
    state: PlanBuildState,
    { payload }: FbAction<actions.EditPayload<Earning>>
  ) =>
    produce(state, (draft) => {
      draft.dirty = true;
      (draft.currentPlan.incomes || [])[payload.index] = {
        ...(draft.currentPlan.incomes || [])[payload.index],
        ...payload.update,
      };
    }),
  [actions.REMOVE_EARNING]: (
    state: PlanBuildState,
    { payload }: FbAction<number>
  ) =>
    produce(state, (draft) => {
      draft.dirty = true;
      (draft.currentPlan.incomes || []).splice(payload, 1);
    }),
  [actions.ADD_EXPENSE]: (
    state: PlanBuildState,
    { payload }: FbAction<Expense>
  ) =>
    produce(state, (draft) => {
      draft.dirty = true;
      draft.currentPlan.expenses.push(payload);
    }),
  [actions.EDIT_EXPENSE]: (
    state: PlanBuildState,
    { payload }: FbAction<actions.EditPayload<Expense>>
  ) =>
    produce(state, (draft) => {
      draft.dirty = true;
      draft.currentPlan.expenses[payload.index] = {
        ...draft.currentPlan.expenses[payload.index],
        ...payload.update,
      };
    }),
  [actions.REMOVE_EXPENSE]: (
    state: PlanBuildState,
    { payload }: FbAction<number>
  ) =>
    produce(state, (draft) => {
      draft.dirty = true;
      draft.currentPlan.expenses.splice(payload, 1);
    }),
  [actions.ADD_RISK]: (state: PlanBuildState, { payload }: FbAction<Expense>) =>
    produce(state, (draft) => {
      draft.dirty = true;
      draft.currentPlan.risks.push(payload);
    }),
  [actions.EDIT_RISK]: (
    state: PlanBuildState,
    { payload }: FbAction<actions.EditPayload<Expense>>
  ) =>
    produce(state, (draft) => {
      draft.dirty = true;
      draft.currentPlan.risks[payload.index] = {
        ...draft.currentPlan.risks[payload.index],
        ...payload.update,
      };
    }),
  [actions.REMOVE_RISK]: (
    state: PlanBuildState,
    { payload }: FbAction<number>
  ) =>
    produce(state, (draft) => {
      draft.dirty = true;
      draft.currentPlan.risks.splice(payload, 1);
    }),
  [actions.SAVE_PLAN + START]: (state: PlanBuildState) =>
    produce(state, (draft) => {
      draft.loading.currentPlan = true;
    }),
  [actions.SAVE_PLAN + SUCCESS]: (
    state: PlanBuildState,
    { payload }: FbAction<number>
  ) =>
    produce(state, (draft) => {
      draft.dirty = false;
      draft.loading.currentPlan = false;
      if (!draft.currentPlan.id) {
        draft.currentPlan.id = payload;
      }
      const questionnaire = !!draft.currentPlan.questionnaire;
      const messages = !!draft.currentPlan.messages;
      const newListItem = {
        id: payload,
        name: draft.currentPlan.name,
        implemented: false,
        questionnaire,
        messages,
      };
      if (draft.savedPlans) {
        if (
          draft.savedPlans.length < 3 &&
          !draft.savedPlans.find((plan) => plan.id === draft.currentPlan.id)
        ) {
          draft.savedPlans.push(newListItem);
        } else if (!draft.savedPlans.length) {
          draft.savedPlans = [newListItem];
        }
      }
      const currentPlanDetail = state.savedPlans.find(
        (plan) => plan.id === draft.currentPlan.id
      );
      if (currentPlanDetail?.implemented) {
        draft.livePlan = draft.currentPlan;
      }
    }),
  [actions.SAVE_PLAN + FAIL]: (
    state: PlanBuildState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.loading.currentPlan = false;
      draft.error.currentPlan = payload;
    }),
  [actions.IMPLEMENT_PLAN + START]: (state: PlanBuildState) =>
    produce(state, (draft) => {
      draft.loading.currentPlan = true;
    }),
  [actions.IMPLEMENT_PLAN + SUCCESS]: (
    state: PlanBuildState,
    { payload }: FbAction<number>
  ) =>
    produce(state, (draft) => {
      draft.loading.currentPlan = false;
      draft.savedPlans.forEach((plan) => {
        plan.implemented = plan.id === payload;
      });
    }),
  [actions.IMPLEMENT_PLAN + FAIL]: (
    state: PlanBuildState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.loading.currentPlan = false;
      draft.error.currentPlan = payload;
    }),
  [actions.LOAD_COMPARISON_PLAN + START]: (
    state: PlanBuildState,
    { payload }: FbAction<number>
  ) =>
    produce(state, (draft) => {
      draft.compareWithPlanIndex = payload;
      draft.loading.comparePlan = true;
    }),
  [actions.LOAD_COMPARISON_PLAN + SUCCESS]: (
    state: PlanBuildState,
    {
      payload,
    }: FbAction<{
      plan: Plan;
      tax: number;
      studentTax: any;
      emergencyExpenses: number;
    }>
  ) =>
    produce(state, (draft) => {
      draft.loading.comparePlan = false;
      draft.compareWithPlanDetail = payload.plan;
      draft.compareMonthlyTax = payload.tax;
      draft.compareStudentTax = payload.studentTax;
      draft.compareMonthlyEmergencyFund = payload.emergencyExpenses;
    }),
  [actions.LOAD_COMPARISON_PLAN + FAIL]: (
    state: PlanBuildState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.loading.comparePlan = false;
      draft.error.comparePlan = payload;
    }),
  [actions.CLEAR_PLAN_COMPARISON]: (state: PlanBuildState) => ({
    ...state,
    compareWithPlanIndex: -1,
  }),
  [actions.GET_PLAN_PROJECTION + START]: (state: PlanBuildState) =>
    produce(state, (draft) => {
      draft.loading.projection = true;
    }),
  [actions.GET_PLAN_PROJECTION + SUCCESS]: (
    state: PlanBuildState,
    {
      payload,
    }: FbAction<{
      planProjection: PlanProjection;
      minimalProjection: PlanProjection;
    }>
  ) =>
    produce(state, (draft) => {
      draft.loading.projection = false;
      draft.loaded.projection = true;
      draft.projection = payload.planProjection;
      draft.minimalProjection = payload.minimalProjection;
    }),
  [actions.GET_PLAN_PROJECTION + FAIL]: (
    state: PlanBuildState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.loading.projection = false;
      draft.error.projection = payload;
    }),
  [actions.GET_LIVE_PLAN_PROJECTION + START]: (state: PlanBuildState) =>
    produce(state, (draft) => {
      draft.loading.projection = true;
    }),
  [actions.GET_LIVE_PLAN_PROJECTION + SUCCESS]: (
    state: PlanBuildState,
    {
      payload,
    }: FbAction<{
      liveProjection: PlanProjection;
      minimalProjection?: PlanProjection;
    }>
  ) =>
    produce(state, (draft) => {
      draft.loading.projection = false;
      draft.liveProjection = payload.liveProjection;
      if (payload.minimalProjection) {
        draft.minimalProjection = payload.minimalProjection;
      }
    }),
  [actions.GET_LIVE_PLAN_PROJECTION + FAIL]: (
    state: PlanBuildState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.loading.projection = false;
      draft.error.projection = payload;
    }),
  [actions.GET_SAVED_PLAN_PROJECTIONS + START]: (state: PlanBuildState) =>
    produce(state, (draft) => {
      draft.loading.savedProjections = true;
    }),
  [actions.GET_SAVED_PLAN_PROJECTIONS + SUCCESS]: (
    state: PlanBuildState,
    { payload }: FbAction<PlanProjection[]>
  ) =>
    produce(state, (draft) => {
      draft.loading.savedProjections = false;
      draft.loaded.savedProjections = true;
      draft.savedPlanProjections = payload;
    }),
  [actions.GET_SAVED_PLAN_PROJECTIONS + FAIL]: (
    state: PlanBuildState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.loading.savedProjections = false;
      draft.error.savedProjections = payload;
    }),
  [actions.SET_REVIEW_SECTION]: (
    state: PlanBuildState,
    { payload }: FbAction<ReviewSections>
  ) => ({ ...state, reviewSection: payload }),
  [actions.CLEAROUT_REVIEW_SECTION]: (state: PlanBuildState) => ({
    ...state,
    reviewSection: 0,
  }),
  [SET_GRADUATED_MODE]: (state: PlanBuildState) => ({
    ...state,
    savedPlans: [],
    liveProjection: null,
  }),
  [actions.SET_PLAN_DIRTY]: (
    state: PlanBuildState,
    { payload }: FbAction<boolean>
  ) => ({ ...state, dirty: payload }),
  [LOG_OUT]: () => initialState,
};

const profileBuildReducer = handleActions<PlanBuildState, any>(
  reducerDefinitions,
  initialState
);

export default profileBuildReducer;
