import React from "react";

import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPanel,
  EuiText,
} from "@elastic/eui";

import { formatPercent, formatWholeDollars } from "src/utils";

const GoalCard = ({
  goal,
  providedProps,
  moveUp,
  moveDown,
  index,
  message,
}: any) => {
  return (
    <>
      <EuiFlexGroup alignItems="center" className="w-full">
        <EuiText>{index + 1}.</EuiText>
        <EuiPanel paddingSize="s">
          <EuiFlexGroup alignItems="center" gutterSize="s">
            <EuiFlexItem grow={false}>
              <EuiPanel
                color="transparent"
                paddingSize="s"
                {...(providedProps?.dragHandleProps || {})}
                aria-label="Drag Handle"
              >
                <EuiIcon type="grab" />
              </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem className="w-96">{goal.title}</EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexGroup>
                <EuiFlexItem>
                  {!!goal.balance && formatWholeDollars(goal.balance)}
                  {!!goal.savingsGoal && formatWholeDollars(goal.savingsGoal)}
                </EuiFlexItem>
                <EuiFlexItem>
                  {!!goal.rate && `${formatPercent(goal.rate)} APR`}
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem grow>
              <EuiFlexGroup className="ml-auto">
                {!!moveUp && (
                  <EuiFlexItem>
                    <EuiButtonIcon
                      iconType="arrowUp"
                      onClick={moveUp}
                      aria-label="move up"
                      className="ml-auto"
                    />
                  </EuiFlexItem>
                )}
                {!!moveDown && (
                  <EuiFlexItem>
                    <EuiButtonIcon
                      iconType="arrowDown"
                      onClick={moveDown}
                      aria-label="move down"
                      className="ml-auto"
                    />
                  </EuiFlexItem>
                )}
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      </EuiFlexGroup>
      {message || ""}
    </>
  );
};

export default GoalCard;
