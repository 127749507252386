import React from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import Chart from "src/components/Chart";
import {
  getDashboardScoreProjection,
  getLoadingState,
} from "src/store/planBuild/selector";
import { getHasPlan, getIsSubscribed } from "src/store/system/selector";
import { chartStyles } from "src/theme/base";
import { AdapterLink } from "src/utils";
import { EuiButton, EuiCard, EuiImage } from "@elastic/eui";
import zero_state_plan_summary from "src/assets/svgs/zero-plansummary.svg";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import "src/Cards.css";
import { Link } from "react-router-dom";
import { Global, css } from "@emotion/react";

const planSummaryCard = css`
  .fplan-text-mobile {
    display: none;
  }
  @media screen and (max-width: 991px) {
    .fplan-text-mobile {
      display: block;
      p {
        font-size: 12px !important;
      }
    }
    .fplan-text {
      display: none;
    }
  }
`;

const useStyles = makeStyles(chartStyles);

const PlanSummaryChart = () => {
  const styles = useStyles();
  const subscribed = useSelector(getIsSubscribed);
  const hasPlan = useSelector(getHasPlan);
  const scoreProjection = useSelector(getDashboardScoreProjection);
  const loading = useSelector(getLoadingState);

  if (!subscribed || !hasPlan) {
    return (
      <>
        <Global styles={planSummaryCard} />
        <EuiCard
          title=""
          paddingSize="l"
          description=""
          textAlign="center"
          className="zero-state-card"
        >
          <EuiImage
            className=""
            src={zero_state_plan_summary}
            alt="woman on tablet"
            size="original"
          />
          <div className="fplan-text-mobile">
            <StyledSpacer size="10px" />
            <p>
              Build and implement your Financial Plan to start tracking your
              progress.
            </p>
          </div>
          <StyledSpacer size="16px" />
          <Link to="/financial-plans">
            <EuiButton fill color="primary">
              Implement Your Financial Plan
            </EuiButton>
          </Link>
          <div className="fplan-text">
            <StyledSpacer size="14px" />
            <p>
              Build and implement your Financial Plan to start tracking your
              progress.
            </p>
          </div>
        </EuiCard>
      </>
    );
  }
  return (
    <Chart
      loading={loading.projection}
      title="Projected FitBUX Score"
      headComponent={
        <AdapterLink to="/financial-plans" className={styles.chartLink}>
          Explore My Plan
        </AdapterLink>
      }
      data={scoreProjection}
      height="244px"
      keys={["score"]}
      xKey="month"
      keyLabels={{
        score: "Current Projected",
      }}
      width="100%"
    />
  );
};

export default PlanSummaryChart;
