import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Divider,
    makeStyles,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@material-ui/core";
import {
    EuiBadge,
    EuiButtonGroup,
    EuiCard,
    EuiFlexGroup,
    EuiFlexItem,
    EuiIcon,
    EuiSpacer,
    EuiText,
} from "@elastic/eui";
import { planBuilderStyles, budgetOverviewStyle, colors } from "src/theme";
import { setBuildStep, setMonthlyOverview } from "src/store/planBuild/actions";
import {
    currentPlanAllocationTotals,
    currentPlanIncomeTotal,
    currentPlanExpenseTotal,
    currentPlanRiskManagementTotal,
    getCurrentPlanSurplus,
    getIsMonthly,
    isCurrentPlanImplemented,
    livePlanAllocationTotals,
    livePlanExpenseTotal,
    livePlanIncomeTotal,
    livePlanRiskManagementTotal,
} from "src/store/planBuild/selector";
import { getHeaderClicked } from "src/store/dashboard/selector";
import { getIsCurrentStudent } from "src/store/system/selector";
import { formatWholeDollars } from "src/utils";
import Card from "src/components/Card";
import CircularProgressBar from "src/components/CircleProgressChart/CircleProgressChartV2";
import CurrentStudentSidebarContent from "src/pages/PlanBuilder/Components/CurrentStudentSidebarContent";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import PingEffect from "src/components/PingAnimation";
import Button from "src/components/Button";
import { Global, css } from "@emotion/react";
import { StyledSpacer } from "src/components/Global/StyledComponents";

const chartStyles = css`
    tspan:nth-of-type(1) {
        font-size: 18px;
    }
    .rm-tab-group {
      .euiButtonGroupButton {
        background-color: #FBFCFD;
      }
      .euiButtonGroupButton-isSelected {
        background-color: #69707D;
      }
      span {
        font-size: 14px;
        font-family: "Inter", sans-serif;
      }
    }
    .dd-header {
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      font-family: "Poppins", sans-serif;
    }
    .bo-header {
        font-size: 16px !important;
        font-weight: 600 !important;
        line-height: 22px !important;
        font-family: "Inter", sans-serif;
        margin-bottom: 8px !important;
    }
    .bo-subtext {
        font-size: 16px !important;
        line-height: 24px !important;
        font-family: "Inter", sans-serif;
    }
    .bo-amount {
        font-size: 16px !important;
        line-height: 24px !important;
        font-family: "Inter", sans-serif;
    }
`;

const useStyles = makeStyles(planBuilderStyles);
const useBudgetStyles = makeStyles(budgetOverviewStyle);

const RightSidebar = ({
    onNext,
    nextDisabled,
    nextLabel,
    nextTooltip,
}: any) => {
    const styles = useStyles();
    const budgeStyles = useBudgetStyles();
    const dispatch = useDispatch();
    const isMonthly = useSelector(getIsMonthly);
    const planIncomeTotal = useSelector(currentPlanIncomeTotal);
    const planExpenseTotal = useSelector(currentPlanExpenseTotal);
    const liveIncomeTotal = useSelector(livePlanIncomeTotal);
    const liveExpenseTotal = useSelector(livePlanExpenseTotal);
    const planRiskTotal = useSelector(currentPlanRiskManagementTotal);
    const liveRiskTotal = useSelector(livePlanRiskManagementTotal);
    const isCurrentStudent = useSelector(getIsCurrentStudent);
    const currentPlanIsImplemented = useSelector(isCurrentPlanImplemented);
    const {
        assetsTotal: planAssetsTotal,
        debtsTotal: planDebtsTotal,
    } = useSelector(currentPlanAllocationTotals);
    const {
        assetsTotal: liveAssetsTotal,
        debtsTotal: liveDebtsTotal,
    } = useSelector(livePlanAllocationTotals);
    const planRemainingMoney = useSelector(getCurrentPlanSurplus);
    const shortScreen = useMediaQuery("(max-height:720px)");
    const headerClickedCount = useSelector(getHeaderClicked);
    const [triggerCount, setTriggerCount] = useState(headerClickedCount);

    const {
        incomeTotal,
        expenseTotal,
        riskTotal,
        assetsTotal,
        debtsTotal,
    } = useMemo(() => {
        if (!currentPlanIsImplemented) {
            return {
                incomeTotal: planIncomeTotal,
                expenseTotal: planExpenseTotal,
                riskTotal: planRiskTotal,
                assetsTotal: planAssetsTotal,
                debtsTotal: planDebtsTotal,
            };
        }
        return {
            incomeTotal: liveIncomeTotal,
            expenseTotal: liveExpenseTotal,
            riskTotal: liveRiskTotal,
            assetsTotal: planAssetsTotal,
            debtsTotal: planDebtsTotal,
        };
    }, [
        currentPlanIsImplemented,
        planIncomeTotal,
        planExpenseTotal,
        planAssetsTotal,
        planDebtsTotal,
        planRiskTotal,
        liveIncomeTotal,
        liveExpenseTotal,
        liveRiskTotal,
        liveAssetsTotal,
        liveDebtsTotal,
    ]);

    const remainingMoney = !currentPlanIsImplemented
        ? planRemainingMoney
        : incomeTotal - expenseTotal - riskTotal - assetsTotal - debtsTotal;

    const remainingMoneyStyle =
        remainingMoney >= 0 ? styles.emphasis : styles.danger;

    const setIsMonthly = (optionId: string) =>
        dispatch(setMonthlyOverview(optionId === "monthly"));

    const formatValue = (value: number) => {
        const formatted = isMonthly ? Math.floor(value / 12) : Math.floor(value);
        return formatWholeDollars(formatted);
    };

    const chartData = [
        { key: "Household Expenses", value: expenseTotal },
        { key: "Asset Contributions", value: assetsTotal },
        { key: "Debt Payments", value: debtsTotal },
        { key: "Premium Payments", value: riskTotal },
        { key: "Available Funds", value: remainingMoney },
    ];


    // if triggerAnimation is updated, run the animation
    useEffect(() => {
        if (headerClickedCount !== triggerCount) {
            setTriggerCount(headerClickedCount);
        }
    }, [headerClickedCount]);

    // const renderNextButton = () => {
    //     const button = (
    //         <Box className="flex relative">
    //             <PingEffect trigger={headerClickedCount !== triggerCount} />
    //             <Button
    //                 color="primary"
    //                 disabled={nextDisabled}
    //                 onClick={onNext}
    //                 className="w-full"
    //             >
    //                 {nextLabel || "Next"}
    //             </Button>
    //         </Box>
    //     );
    //     if (nextTooltip) {
    //         return (
    //             <Tooltip
    //                 placement="left"
    //                 title={
    //                     <div style={{ fontSize: 12, lineHeight: "16px", padding: 2 }}>
    //                         {nextTooltip}
    //                     </div>
    //                 }
    //             >
    //                 <span>{button}</span>
    //             </Tooltip>
    //         );
    //     }
    //     return button;
    // };

    const renderGraduatedContent = () => {
        // Button group options
        const buttonGroupOptions = [
            {
                id: 'monthly',
                label: 'Monthly',
            },
            {
                id: 'annually',
                label: 'Annually',
            },
        ];

        const chartColors = [
            colors.vizDefaultGreen,   // Household Expenses
            colors.vizDefaultBlue,    // Asset Contributions
            colors.vizDefaultBlue,    // Debt Payments
            colors.vizDefaultRed,     // Premium Payments
            colors.CoreWarning,       // Available Funds
        ];


        return (
            <div>
                <Global styles={chartStyles} />
                <EuiText>
                    <h2 className="dd-header">Budget Overview</h2>
                </EuiText>
                <StyledSpacer size="24px" />
                <EuiButtonGroup
                    legend="Select period type"
                    options={buttonGroupOptions}
                    idSelected={isMonthly ? "monthly" : "annually"}
                    onChange={setIsMonthly}
                    buttonSize="compressed"
                    isFullWidth
                    className="rm-tab-group"
                />
                <StyledSpacer size="24px" />
                {!shortScreen && (
                    <CircularProgressBar
                        data={chartData}
                        value={incomeTotal}
                        chartLabel="Total Income"
                        format="currency"
                        colors={chartColors}
                    />
                )}
                <StyledSpacer size="24px" />
                <div className="bo-header">
                    <EuiIcon type="dot" color={colors.vizDefaultGreen} />
                    Day-To-Day Money
                </div>
                <EuiFlexGroup justifyContent="spaceBetween">
                    <EuiFlexItem grow={false} className="bo-subtext">
                        Household Expenses
                    </EuiFlexItem>
                    <EuiFlexItem className="bo-amount" grow={false} style={{ justifyContent: 'flex-end' }}>
                        {formatValue(expenseTotal)}
                    </EuiFlexItem>
                </EuiFlexGroup>
                <StyledSpacer size="16px" />
                <div className="bo-header">
                    <EuiIcon type="dot" color={colors.vizDefaultBlue} />
                    Money For Future Goals
                </div>
                <EuiFlexGroup justifyContent="spaceBetween">
                    <EuiFlexItem grow={false} className="bo-subtext">
                        Asset Contributions
                    </EuiFlexItem>
                    <EuiFlexItem className="bo-amount" grow={false} style={{ justifyContent: 'flex-end' }}>
                        {formatValue(assetsTotal)}
                    </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup justifyContent="spaceBetween">
                    <EuiFlexItem grow={false} className="bo-subtext">
                        Debt Payments
                    </EuiFlexItem>
                    <EuiFlexItem className="bo-amount" grow={false} style={{ justifyContent: 'flex-end' }}>
                        {formatValue(debtsTotal)}
                    </EuiFlexItem>
                </EuiFlexGroup>
                <StyledSpacer size="16px" />
                <div className="bo-header">
                    <EuiIcon type="dot" color={colors.vizDefaultRed} />
                    Risk Management
                </div>
                <EuiFlexGroup justifyContent="spaceBetween">
                    <EuiFlexItem grow={false} className="bo-subtext">
                        Premium Payments
                    </EuiFlexItem>
                    <EuiFlexItem className="bo-amount" grow={false} style={{ justifyContent: 'flex-end' }}>
                        {formatValue(riskTotal)}
                    </EuiFlexItem>
                </EuiFlexGroup>
                <StyledSpacer size="16px" />
                <EuiFlexGroup justifyContent="spaceBetween">
                    <EuiFlexItem grow={false}>
                        <div className="bo-header">
                            <EuiIcon type="dot" color={colors.CoreWarning} />
                            Available Funds
                        </div>
                    </EuiFlexItem>
                    <EuiFlexItem className="bo-amount" grow={false} style={{ justifyContent: 'flex-end' }}>
                        {formatValue(remainingMoney)}
                    </EuiFlexItem>
                </EuiFlexGroup>

            </div >
        );
    };

    return (
        <>
            {isCurrentStudent ? (
                <CurrentStudentSidebarContent />
            ) : (
                renderGraduatedContent()
            )}
        </>
    );
};

export default RightSidebar;