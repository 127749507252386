import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  EuiPopover,
  EuiText,
  EuiContextMenuPanel,
  EuiContextMenuItem,
  EuiIcon,
} from "@elastic/eui";
import { getIsMarried } from "src/store/system/selector";
import {
  StyledEuiButton,
  StyledSpacer,
} from "src/components/Global/StyledComponents";
import "src/pages/Dashboard/MyProfile/Accounts/components/AccountLinkPage/LinkPageStyles.css";

const LeftSidebar = ({
  activeProviderId,
  activeProvider,
  fixMode,
  openLinkDialog,
  providers,
  selectProvider,
  close,
}: any) => {
  const isMarried = useSelector(getIsMarried);

  const sortedProviders = useMemo(() => {
    const filteredProviders = fixMode
      ? providers.filter((provider: any) => !!provider.error)
      : [...providers];
    return filteredProviders.sort((a: any, b: any) => {
      if (a.name === b.name) {
        return a.accounts - b.accounts;
      }
      if (a.name > b.name) {
        return 1;
      }
      return -1;
    });
  }, [providers, fixMode]);

  useEffect(() => {
    if (!activeProviderId && sortedProviders.length) {
      selectProvider(sortedProviders[0].item_id);
    }
  }, [activeProviderId, sortedProviders]);

  useEffect(() => {
    if (fixMode && !sortedProviders.length) {
      close();
    }
  }, [fixMode, sortedProviders, close]);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const onButtonClick = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const closePopover = () => {
    setIsPopoverOpen(false);
  };

  const button = (
    <StyledEuiButton
      className="lp-dropdown"
      onClick={onButtonClick}
      isDisabled={activeProvider?.error}
    >
      Add Financial Institution
      <EuiIcon type="arrowDown" />
    </StyledEuiButton>
  );

  const items = [
    <EuiContextMenuItem
      key="applicant"
      onClick={() => openLinkDialog("applicant")}
    >
      Add New Link{isMarried ? " (Mine)" : ""}
    </EuiContextMenuItem>,
    ...(isMarried
      ? [
          <EuiContextMenuItem
            key="spouse"
            onClick={() => openLinkDialog("spouse")}
          >
            Add New Link (Spouse)
          </EuiContextMenuItem>,
        ]
      : []),
  ];

  return (
    <div className="side-bar-wrapper lp-sidebar">
      <EuiText className="side-bar-header">
        <h2>Connected Financial Institutions</h2>
      </EuiText>
      <StyledSpacer size="32px" />
      <EuiPopover
        button={button}
        isOpen={isPopoverOpen}
        closePopover={closePopover}
        anchorPosition="downLeft"
        hasArrow={false}
        panelClassName="customPopoverPanel"
      >
        <EuiContextMenuPanel items={items} style={{ width: "235px" }} />
      </EuiPopover>
      <StyledSpacer size="32px" />
      <div className="lp-sidebar-list">
        {sortedProviders.map((provider: any) => (
          <React.Fragment key={provider.item_id}>
            <div
              className={`lp-sidebar-item ${
                activeProviderId === provider.item_id ? "active" : ""
              } ${provider.error ? "error-provider" : ""}`}
              onClick={() => selectProvider(provider.item_id)}
            >
              <div className="lp-sidebar-item-name">{provider.name}</div>
              <div className="lp-sidebar-item-accounts">
                &nbsp;({provider.accounts})
              </div>
            </div>
            <StyledSpacer size="12px" />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default LeftSidebar;
