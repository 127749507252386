import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";

import Questionnaire from "src/components/Questionnaire";
import {
  QuestionFormProps,
  singleQuestions,
  spouseQuestions,
} from "src/interfaces/optimizedPlanBuild.interface";
import { QuestionForm } from "src/interfaces/questionnaire.interface";
import { savePlan } from "src/store/planBuild/actions";
import { getIsMarried } from "src/store/system/selector";
// import CenterContainer from "../PlanBuilder/Components/CenterContainer";
import { fillInDefaults, loadInitialValues } from "../common";

const EmployerRetirement = ({
  planSetter,
  planData,
  goToNext,
  nextClicked,
  errors,
  setErrors,
  validate,
  spouseFlag,
  setSpouseFlag,
}: QuestionFormProps) => {
  const dispatch = useDispatch();
  const [nextClick] = useState(nextClicked);
  const isMarried = useSelector(getIsMarried);
  const [spouseView, setSpouseView] = useState(spouseFlag);
  const [specialErrors, setSpecialErrors] = useState({});
  const questionData: QuestionForm = spouseView
    ? spouseQuestions.employerRetirement
    : singleQuestions.employerRetirement;

  useEffect(() => {
    if (spouseFlag) {
      setSpouseFlag(false);
    }
  }, [spouseFlag]);
  const prereqKey = `${spouseView ? "s_" : ""}has_401k`;
  const firstLimitKey = `${spouseView ? "s_" : ""}retirement_limit`;
  const secondLimitKey = `${spouseView ? "s_" : ""}retirement_additional_limit`;
  const nonelectiveKey = `${
    spouseView ? "s_" : ""
  }retirement_nonelective_contribution`;

  const startingValues =
    (planData as any)[prereqKey] === "y"
      ? loadInitialValues(questionData, planData)
      : cloneDeep({
          ...questionData.emptyValue,
          [prereqKey]: (planData as any)[prereqKey],
        });
  if (startingValues[prereqKey]) {
    if (startingValues[secondLimitKey]) {
      startingValues.preReq1 = "y";
    } else {
      startingValues.preReq1 = "n";
    }
    if (startingValues[nonelectiveKey]) {
      startingValues.preReq3 = "y";
    } else {
      startingValues.preReq3 = "n";
    }
  }
  if (!startingValues.id) {
    startingValues.id = Date.now();
  }
  const [values, setValues] = useState(startingValues);

  const handleChange = (newValues: any) => {
    updatePlanData(newValues);
    setValues(newValues);
  };

  const specialValidation = () => {
    if (values[prereqKey] !== "y" || values.preReq1 !== "y") {
      setErrors((current) => {
        const newErrors = new Set(current);
        newErrors.delete(secondLimitKey);
        return newErrors;
      });
      return true;
    }
    if (values[secondLimitKey] <= values[firstLimitKey]) {
      setSpecialErrors((current) => {
        const newErrors: any = { ...current };
        newErrors[
          secondLimitKey
        ] = `Percentage must be greater than ${values[firstLimitKey]}%`;
        return newErrors;
      });
      setErrors((current) => {
        const newErrors = new Set(current);
        newErrors.add(secondLimitKey);
        return newErrors;
      });
      return false;
    } else {
      setErrors((current) => {
        const newErrors = new Set(current);
        newErrors.delete(secondLimitKey);
        return newErrors;
      });
      return true;
    }
  };

  useEffect(() => {
    if (nextClicked !== nextClick) {
      const isValid = validate ? validate(questionData.fields) : true;
      if (isValid) {
        if (!specialValidation()) {
          return;
        }
        const planUpdate: any = {};
        if (planData.has_401k === "n" && !planData.has_roth_401k) {
          planUpdate.has_roth_401k = "n";
        }
        if (isMarried) {
          if (!spouseView) {
            const spouseStartingValues =
              planData.s_has_401k === "y"
                ? loadInitialValues(
                    spouseQuestions.employerRetirement,
                    planData
                  )
                : cloneDeep({
                    ...spouseQuestions.employerRetirement.emptyValue,
                    s_has_401k: planData.s_has_401k,
                  });
            if (!spouseStartingValues.id) {
              spouseStartingValues.id = Date.now();
            }
            setValues(spouseStartingValues);
            planSetter(
              fillInDefaults(
                { ...planData, ...planUpdate },
                questionData.defaultValue
              )
            );
            dispatch(savePlan(null));
            setSpouseView(true);
          } else {
            if (planData.s_has_401k === "n" && !planData.s_has_roth_401k) {
              planUpdate.s_has_roth_401k = "n";
            }
            planSetter(
              fillInDefaults(
                { ...planData, ...planUpdate },
                questionData.defaultValue
              )
            );
            goToNext();
          }
        } else {
          planSetter(
            fillInDefaults(
              { ...planData, ...planUpdate },
              questionData.defaultValue
            )
          );
          goToNext();
        }
      }
    }
  }, [nextClicked]);

  const updateSingleData = (changedValues: any) => {
    setErrors(new Set([]));
    const newPlan = { ...planData };
    if (changedValues.has_401k === "n") {
      newPlan.has_401k = "n";
      newPlan.retirement_limit = 0;
      newPlan.retirement_match = 100;
      newPlan.has_roth_401k = "n";
      newPlan.retirement_nonelective_contribution = 0;
    } else if (changedValues.has_401k === "y") {
      newPlan.has_401k = changedValues.has_401k;
      newPlan.retirement_limit = changedValues.retirement_limit;
      newPlan.retirement_match = changedValues.retirement_match;
      newPlan.has_roth_401k = changedValues.has_roth_401k;
      newPlan.retirement_student_loan_match =
        changedValues.retirement_student_loan_match;
    }
    if (changedValues.preReq1 === "n" || changedValues.has_401k !== "y") {
      newPlan.retirement_additional_limit = 0;
      newPlan.retirement_additional_match = 0;
    } else {
      if (changedValues.preReq1 === "y") {
        newPlan.retirement_additional_limit =
          changedValues.retirement_additional_limit;
        newPlan.retirement_additional_match =
          changedValues.retirement_additional_match;
      }

      if (changedValues.preReq3 === "n") {
        newPlan.retirement_nonelective_contribution = 0;
      } else if (changedValues.preReq3 === "y") {
        newPlan.retirement_nonelective_contribution =
          changedValues.retirement_nonelective_contribution;
      }
    }
    planSetter(newPlan);
  };

  const updateSpouseData = (changedValues: any) => {
    setErrors(new Set([]));
    const newPlan = { ...planData };
    if (changedValues.s_has_401k === "n") {
      newPlan.s_has_401k = "n";
      newPlan.s_retirement_limit = 0;
      newPlan.s_retirement_match = 100;
      newPlan.s_has_roth_401k = "n";
      newPlan.s_retirement_nonelective_contribution = 0;
    } else if (changedValues.s_has_401k === "y") {
      newPlan.s_has_401k = "y";
      newPlan.s_retirement_limit = changedValues.s_retirement_limit;
      newPlan.s_retirement_match = changedValues.s_retirement_match;
      newPlan.s_has_roth_401k = changedValues.s_has_roth_401k;
      newPlan.s_retirement_student_loan_match =
        changedValues.s_retirement_student_loan_match;
    }

    if (changedValues.preReq1 === "n" || changedValues.s_has_401k !== "y") {
      newPlan.s_retirement_additional_limit = 0;
      newPlan.s_retirement_additional_match = 0;
    } else {
      if (changedValues.preReq1 === "y") {
        newPlan.s_retirement_additional_limit =
          changedValues.s_retirement_additional_limit;
        newPlan.s_retirement_additional_match =
          changedValues.s_retirement_additional_match;
      }

      if (changedValues.preReq3 === "n") {
        newPlan.s_retirement_nonelective_contribution = 0;
      } else if (changedValues.preReq3 === "y") {
        newPlan.s_retirement_nonelective_contribution =
          changedValues.s_retirement_nonelective_contribution;
      }
    }
    planSetter(newPlan);
  };

  const updatePlanData = spouseView ? updateSpouseData : updateSingleData;

  return (
    <Questionnaire
      questions={questionData}
      values={values}
      onChange={handleChange}
      errors={errors}
      setErrors={setErrors}
      specialErrors={specialErrors}
    />
  );
};

export default EmployerRetirement;
