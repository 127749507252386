import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";

import Questionnaire from "src/components/Questionnaire";
import {
  marriedQuestions,
  QuestionFormProps,
  singleQuestions,
  HSA_SINGLE_MAX,
  HSA_MARRIED_MAX,
} from "src/interfaces/optimizedPlanBuild.interface";
import { Question, QuestionForm } from "src/interfaces/questionnaire.interface";
import { getIsMarried } from "src/store/system/selector";
import { fillInDefaults } from "../common";

const HSA = ({
  planSetter,
  planData,
  goToNext,
  nextClicked,
  errors,
  setErrors,
  validate,
}: QuestionFormProps) => {
  const [nextClick] = useState(nextClicked);
  const isMarried = useSelector(getIsMarried);
  const questionCollection = isMarried ? marriedQuestions : singleQuestions;
  const questionData: QuestionForm = questionCollection.hsa;

  const MAX_HSA_CONTRIBUTION = isMarried ? HSA_MARRIED_MAX : HSA_SINGLE_MAX;

  const startingValues = cloneDeep(questionData.emptyValue);
  for (const key in questionData.emptyValue) {
    const savedData = planData[key as keyof typeof planData];
    if (savedData) {
      startingValues[key] = savedData;
    }
  }
  if (planData.employer_hsa_contribution) {
    startingValues.preReq = "y";
  }
  if (!startingValues.id) {
    startingValues.id = Date.now();
  }
  const [values, setValues] = useState<Question>(startingValues);

  const handleChange = (newValues: any) => {
    const updatedValues = { ...newValues };
    if (updatedValues.preReq === "y") {
      updatedValues.employer_hsa_contribution = Math.min(
        updatedValues.employer_hsa_contribution,
        MAX_HSA_CONTRIBUTION
      );
    }
    updatePlanData(updatedValues);
    setValues(updatedValues);
  };

  useEffect(() => {
    if (nextClicked !== nextClick) {
      if (!validate || validate(questionData.fields)) {
        goToNext();
      }
      planSetter(fillInDefaults({ ...planData }, questionData.defaultValue));
    }
  }, [nextClicked]);

  const updatePlanData = (changedValues: any) => {
    const newPlan = {
      ...planData,
      has_hsa: changedValues.has_hsa,
      employer_hsa_contribution:
        changedValues.has_hsa === "y" && changedValues.preReq === "y"
          ? changedValues.employer_hsa_contribution
          : 0,
    };
    newPlan.has_hsa = changedValues.has_hsa;
    planSetter(newPlan);
  };

  return (
    <Questionnaire
      questions={questionData}
      values={values}
      onChange={handleChange}
      errors={errors}
      setErrors={setErrors}
    />
  );
};

export default HSA;
