import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import {
  Avatar,
  Button,
  Container,
  CssBaseline,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import createStyles from "@material-ui/core/styles/createStyles";

import { logOut } from "src/store/system/actions";
import { loginStateSelector } from "src/store/system/selector";
import { AdapterLink } from "src/utils";

const redirect = process.env.REACT_APP_OVERRIDE_REDIRECT !== "true";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  })
);

const BaseLogout = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loggedIn } = useSelector(loginStateSelector);
  if (!loggedIn) {
    return <Redirect to="/login" />;
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOpenOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Log Out
        </Typography>
        <form>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            href="#"
            onClick={() => {
              dispatch(logOut());
              if (redirect) {
                window.location.replace(
                  "https://www.fitbux.com/member-feedback/"
                );
              }
            }}
            size="large"
          >
            Log Out Now
          </Button>
        </form>
        <Button
          size="small"
          href="#"
          component={AdapterLink}
          to="/home"
          color="secondary"
        >
          Back to Home
        </Button>
      </div>
    </Container>
  );
};

export default BaseLogout;
