import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlanListRecord } from "src/interfaces";
import { getRefreshNeeded } from "src/store/dashboard/selector";
import {
  EuiCard,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiButtonIcon,
  EuiPopover,
  EuiContextMenu,
  EuiButton,
  EuiBadge,
} from "@elastic/eui";
import { Link } from "react-router-dom";
import actions from "src/assets/svgs/actions_mobile.svg";
import { colors } from "src/theme";
import {
  fetchLivePlanProjection,
  fetchSavedPlanProjections,
  deletePlan,
  implementPlan,
} from "src/store/planBuild/actions";
import {
  getLivePlan,
  getLoadedSavedProjections,
  getLoadingSavedProjections,
  getPlans,
  getSavedProjectionsError,
} from "src/store/planBuild/selector";
import { openPlanForEditing } from "src/store/planBuild/util";
import { getHasGraduated } from "src/store/profileBuild/selector";
import { deleteImplementedPlan } from "src/store/system/actions";
import { getIsCurrentStudent } from "src/store/system/selector";
import { planListText } from "src/text";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import Chart from "src/components/ChartV2";
import CircularProgressBar from "src/components/CircleProgressChart/CircleProgressChartV2";
import dummyData from "src/utils/5731.json";

const PlanList: React.FC = () => {
  const dispatch = useDispatch();
  const loadedProjections = useSelector(getLoadedSavedProjections);
  const loadingProjections = useSelector(getLoadingSavedProjections);
  const projectionsError = useSelector(getSavedProjectionsError);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const plans = useSelector(getPlans);
  const livePlan = useSelector(getLivePlan);
  const refreshNeeded = useSelector(getRefreshNeeded);
  const hasGraduated = useSelector(getHasGraduated);
  const [popoverOpen, setPopoverOpen] = useState<number | null>(null);

  const prepareChartData = (jsonData: any) => {
    const startDate = new Date(jsonData.start);
    const assetData = jsonData.series.asset;

    return assetData.map((value: any, index: any) => {
      const date = new Date(startDate);
      date.setFullYear(startDate.getFullYear() + index);
      return {
        year: date.getFullYear(),
        net: value,
      };
    });
  };

  const chartData = prepareChartData(dummyData);

  useEffect(() => {
    if (!loadingProjections) {
      if (
        (!loadedProjections && !projectionsError) ||
        refreshNeeded.liveProjection
      ) {
        dispatch(fetchLivePlanProjection(false));
      }
      if (
        (!loadedProjections && !projectionsError) ||
        refreshNeeded.savedProjections
      ) {
        dispatch(fetchSavedPlanProjections());
      }
    }
  }, [loadedProjections, loadingProjections, projectionsError]);

  const closePopover = () => setPopoverOpen(null);

  const calculateProgress = (currentStep: any) => {
    if (currentStep >= 0 && currentStep <= 10) {
      return (currentStep / 10) * 100;
    } else if (currentStep >= 100 && currentStep <= 106) {
      return ((currentStep - 100) / 6) * 100;
    }
    return 0;
  };

  const RenderPlanActions = ({
    plan,
    isCurrentStudent,
    hasGraduated,
    livePlanId,
  }: {
    plan: PlanListRecord;
    isCurrentStudent: boolean;
    hasGraduated: boolean;
    livePlanId?: number;
  }) => {
    const handleDeletePlan = () => {
      if (plan.id === livePlanId) {
        dispatch(deleteImplementedPlan());
      }
      dispatch(deletePlan(plan.id));
      closePopover();
    };

    const panelTree = [
      {
        id: 0,
        items: [
          {
            name: "Edit",
            icon: "pencil",
            disabled: isCurrentStudent && hasGraduated,
            onClick: () => {
              openPlanForEditing(dispatch, isCurrentStudent, plan);
              closePopover();
            },
          },
          {
            name: plan.implemented ? "Implemented" : "Implement",
            icon: "checkInCircleFilled",
            disabled: plan.implemented || (isCurrentStudent && hasGraduated),
            onClick: () => {
              dispatch(implementPlan(plan.id));
              closePopover();
            },
          },
          {
            name: "Delete",
            icon: "trash",
            onClick: handleDeletePlan,
          },
        ],
      },
    ];

    return (
      <EuiPopover
        button={
          <EuiButtonIcon
            aria-label="Actions"
            iconType={actions}
            onClick={() =>
              setPopoverOpen(popoverOpen === plan.id ? null : plan.id)
            }
          />
        }
        isOpen={popoverOpen === plan.id}
        closePopover={closePopover}
        panelPaddingSize="none"
      >
        <EuiContextMenu
          initialPanelId={0}
          panels={panelTree}
          className="fp-context-menu"
        />
      </EuiPopover>
    );
  };

  return (
    <div className="fp-general fp-page-list">
      <EuiFlexGroup className="fp-flex-group">
        <EuiFlexItem>
          <EuiTitle size="s" className="fp-header-text">
            <h1>Financial Plans</h1>
          </EuiTitle>
        </EuiFlexItem>
        {/* <EuiFlexItem className="fp-flex-end">
          <EuiLink>Compare Plans</EuiLink>
        </EuiFlexItem> */}
      </EuiFlexGroup>
      <StyledSpacer size="24px" />
      <div className="fp-list-desc">{planListText}</div>
      <StyledSpacer size="24px" />
      <div className="fp-card-list-container">
        {plans.map((plan) => {
          const currentStep = plan.progress;
          const percentComplete = calculateProgress(currentStep);

          return (
            <div className="fp-card-list" key={plan.id}>
              <EuiCard
                paddingSize="l"
                textAlign="left"
                className="fp-card"
                title={
                  <EuiFlexGroup
                    justifyContent="spaceBetween"
                    alignItems="center"
                    style={{ flexWrap: "nowrap" }}
                  >
                    <EuiFlexItem grow={false}>
                      {plan.name}
                      <StyledSpacer size="8px" />
                      <EuiBadge
                        color={colors.vizBehindText3}
                        style={{
                          maxWidth: "95px",
                          visibility: plan.implemented ? "visible" : "hidden",
                        }}
                      >
                        Implemented
                      </EuiBadge>
                    </EuiFlexItem>
                    <EuiFlexItem
                      grow={false}
                      style={{
                        flexBasis: "auto",
                        inlineSize: "auto",
                        alignSelf: "flex-start",
                      }}
                    >
                      <RenderPlanActions
                        plan={plan}
                        isCurrentStudent={isCurrentStudent}
                        hasGraduated={hasGraduated}
                        livePlanId={livePlan?.id}
                      />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                }
              >
                <StyledSpacer size="16px" />

                <div className="fp-progress-container">
                  {percentComplete < 100 ? (
                    <>
                      <CircularProgressBar
                        data={[
                          { key: 'Completed', value: percentComplete },
                          { key: 'Remaining', value: 100 - percentComplete },
                        ]}
                        chartLabel="Completed"
                        value={percentComplete}
                        format="percent"
                      />
                      <StyledSpacer size="16px" />
                      <EuiButton
                        fill
                        color="primary"
                        onClick={() => {
                          openPlanForEditing(dispatch, isCurrentStudent, plan);
                          closePopover();
                        }}
                      >
                        Finish Building Your Plan
                      </EuiButton>
                      <StyledSpacer size="16px" />
                      <p>
                        Finish building your plan to view plan projections and
                        start tracking your progress.
                      </p>
                    </>
                  ) : (
                    <Chart
                      dollars
                      data={chartData}
                      loading={false}
                      keys={["net"]}
                      keyLabels={{
                        net: "Net Wealth",
                      }}
                      width={320}
                      xKey="month"
                    />
                  )}
                </div>

                <StyledSpacer size="16px" />
              </EuiCard>
            </div>
          );
        })}
        {plans.length === 2 && (
          <div className="fp-card-list">
            <Link to="/plan-select">
              <EuiCard
                title=""
                paddingSize="l"
                textAlign="center"
                className="fp-card fp-card-empty"
              >
                <div className="fp-card-empty-content">
                  <EuiIcon type="plus" size="xxl" />
                </div>
              </EuiCard>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlanList;
