import { NavigatorEntry } from "src/interfaces";

export const nestedNavigation: Array<{
  title: string;
  icon?: string;
  items: NavigatorEntry[];
}> = [
  {
    title: "My Money Details",
    icon: "MoneyDetails",
    items: [
      {
        id: "budget",
        title: "Day-to-Day Money",
        href: "/budget",
      },
      {
        id: "wealth",
        title: "Net Wealth",
        href: "/wealth",
      },
      {
        id: "risk",
        title: "Risk Management",
        href: "/risk",
      },
      {
        id: "human-capital",
        title: "Human Capital",
        href: "/wealth#hc",
      },
    ],
  },
  {
    title: "My Profile",
    items: [
      {
        id: "accounts",
        title: "Accounts",
        href: "/accounts",
      },
      {
        id: "transactions",
        title: "Transactions",
        href: "/transactions",
      },
    ],
  },
  {
    title: "Tools & Products",
    icon: "ToolsProducts",
    items: [
      {
        id: "studentloans",
        title: "Student Loans",
        href: "/studentloans",
        isBottom: true,
      },
      {
        id: "investments",
        title: "Investments",
        href: "/investments",
        isBottom: true,
      },
      {
        id: "homebuying",
        title: "Home Ownership",
        href: "/homebuying",
        isBottom: true,
      },
      {
        id: "insurance",
        title: "Insurance",
        href: "/insurance",
        isBottom: true,
      },
      {
        id: "credit",
        title: "Credit/Personal Loans",
        href: "/credit",
        isBottom: true,
      },
      {
        id: "otheritems",
        title: "Other Financial Items",
        href: "/otheritems",
        isBottom: true,
      },
    ],
  },
];

export const navigation: NavigatorEntry[] = [
  {
    id: "home",
    title: "Home",
    icon: "Home",
    href: "/home",
    single: true,
  },
  {
    id: "plan-summary",
    title: "Plan Summary",
    icon: "PlanSummary",
    href: "/plan-summary",
    single: true,
  },
  {
    id: "financial-plans",
    title: "Financial Plans",
    icon: "FinancialPlans",
    href: "/financial-plans",
    single: true,
  },
  {
    id: "build-plan",
    title: "Build a Plan",
    href: "/financial-plans",
  },
  {
    id: "accounts",
    title: "Accounts",
    href: "/accounts",
    single: true,
  },
  {
    id: "transactions",
    title: "Transactions",
    href: "/transactions",
    single: true,
  },
  {
    id: "budget",
    title: "Budget",
    href: "/budget",
  },
  {
    id: "wealth",
    title: "Net Wealth",
    href: "/wealth",
  },
  {
    id: "human-capital",
    title: "Human Capital",
    href: "/wealth",
  },
  {
    id: "risk",
    title: "Risk Management",
    href: "/risk",
  },
  {
    id: "studentloans",
    title: "Student Loans",
    href: "/studentloans",
    isBottom: true,
  },
  {
    id: "coach",
    title: "FitBUX Coach",
    href: "/coach",
    isBottom: true,
  },
  {
    id: "upgrade",
    title: "Upgrade Membership",
    href: "/upgrade",
    freeOnly: true,
    isBottom: true,
  },
  {
    id: "homebuying",
    title: "Home Ownership",
    href: "/homebuying",
    isBottom: true,
  },
  {
    id: "insurance",
    title: "Insurance",
    href: "/insurance",
    isBottom: true,
  },
  {
    id: "credit",
    title: "Credit/Personal Loans",
    href: "/credit",
    isBottom: true,
  },
  {
    id: "otheritems",
    title: "Other Financial Items",
    href: "/otheritems",
    isBottom: true,
  },
  {
    id: "investments",
    title: "Investments",
    href: "/investments",
    isBottom: true,
  },
  {
    id: "referral",
    title: "Refer A Friend",
    href: "/referral",
    isBottom: true,
  },
];

export const combinedNavigation: Array<{
  title: string;
  icon?: string;
  items: NavigatorEntry[];
  single?: boolean;
}> = [
    {
      title: "Home",
      icon: "Home",
      items: navigation.filter(item => item.single && item.id === 'home'),
      single: true,
    },
    {
      title: "Plan Summary",
      icon: "PlanSummary",
      items: navigation.filter(item => item.single && item.id === 'plan-summary'),
      single: true,
    },
    {
      title: "Financial Plans",
      icon: "FinancialPlans",
      items: navigation.filter(item => item.id === 'financial-plans'),
      single: true,
    },
    nestedNavigation[0], // "My Money Details"
    {
      title: "Transactions",
      icon: "Transactions",
      items: navigation.filter(item => item.single && item.id === 'transactions'),
      single: true,
    },
    {
      title: "Accounts",
      icon: "Accounts",
      items: navigation.filter(item => item.single && item.id === 'accounts'),
      single: true,
    },
    nestedNavigation[2], // "Tools & Products"
  ];

export const secondaryMobileNavigation = [
  {
    id: "settings",
    title: "Settings",
    icon: "Settings",
    href: "/settings",
  },
  {
    id: "coach",
    title: "FitBUX Coach",
    icon: "Coach",
    href: "/coach",
  },
  {
    id: "referral",
    title: "Refer A Friend",
    icon: "Refer",
    href: "/referral",
  },
  {
    id: "works",
    title: "How FitBUX Works",
    icon: "Works",
    href:
      "https://www.youtube.com/playlist?list=PLYk9ILj711p4xl0yQOm2G5QSMp_ZTBPeG",
  },
  {
    id: "logout",
    title: "Log Out",
    icon: "Logout",
    href: "/logout",
  },
];
