import React from 'react';
import { EuiText, EuiSpacer } from '@elastic/eui';

const TransactionHelpContent: React.FC = () => (
  <>
    <EuiText>
      <p>
        To track your financial plan correctly, you need to know how your overall income is allocated using percentages.
      </p>
      <p>
        Your primary goal should be getting 50% of your Total Income allocated to money for your future self (in order to grow your assets and/or pay off debts)!
      </p>
      <p>
        To track your financial plan properly, we need to make sure your linked transactions reflect a percentage of your Total Income and not Net Income. Therefore, on this screen enter the Total Income from your paycheck, before any deductions.
      </p>
      <p>
        Total Income should be broken out into salary and any other sources of income, such as bonuses or commission. This is important because salary and commissions have different risks which are reflected in your FitBUX Score and our recommendations. Also, income such as stipends are taxed differently. Total Income is the same as Gross Income.
      </p>
      <p>
        Deductions should be any item taken directly out of your paycheck before the money hits your bank account.
      </p>
    </EuiText>
    <EuiSpacer size="m" />
  </>
);

export default TransactionHelpContent;