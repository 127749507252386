import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import AiPlanBuilderLayout from "src/layouts/AiPlanBuilderLayout";
import { getV2PlanProjectionApi } from "src/apiService";
import { getDebtObligations } from "src/store/account/selector";
import {
  getLiveExpenseTotal,
  getLiveIncomeTotal,
} from "src/store/cashflow/selector";
import { SUCCESS } from "src/store/common";
import {
  convertOptimizedToManual,
  implementPlan,
  savePlan,
  setBuildStep,
  setCurrentPlan,
  updateCurrentPlan,
  GET_PLAN_PROJECTION,
} from "src/store/planBuild/actions";
import {
  getCurrentPlan,
  getPlanBuilderReady,
  getLoadingState,
} from "src/store/planBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import { fetchUser } from "src/store/system/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import { setPopup } from "src/store/dashboard/actions";
import { getRouterState } from "src/store/router/selector";
import { emptyPlan } from "src/interfaces/optimizedPlanBuild.interface";

import { sections } from "src/layouts/AiPlanBuilderLayout/LeftSidebar";
import { progressSteps, STEP_INDICES } from "./common";
import MainForm from "./MainForm";
import NegativeCashflow from "./Views/NegativeCashflow";
import { reviewSections } from "../PlanBuilder/Components/ReviewSidebar";

const STEPS_WITH_SPOUSE_PAGES = [
  STEP_INDICES.EmployerRetirement,
  STEP_INDICES.EmployerStudentLoans,
  STEP_INDICES.WorkStatus,
  STEP_INDICES.IncomeIncrease,
  STEP_INDICES.IncomeDecrease,
];

const Content = () => {
  const router = useSelector(getRouterState);
  const query = router.location.query;
  const planId = query.plan;
  const debugMode = !!planId;
  const dispatch = useDispatch();
  const currentPlan = useSelector(getCurrentPlan);
  const isMarried = useSelector(getIsMarried);
  const currentIncomeTotal = useSelector(getLiveIncomeTotal);
  const currentExpenseTotal = useSelector(getLiveExpenseTotal);
  const debtObligations = useSelector(getDebtObligations);
  const ready = useSelector(getPlanBuilderReady);
  const planLoading = useSelector(getLoadingState);
  const [nextClicked, setNextClicked] = useState(0);
  const [reviewStep, setReviewStep] = useState(0);
  const [stepLoaded, setStepLoaded] = useState(debugMode);
  const [spouseFlag, setSpouseFlag] = useState(false);
  let startingStep = STEP_INDICES.Introduction;
  if (debugMode) {
    startingStep = STEP_INDICES.Transition;
  }
  const [stepIndex, setStepIndex] = useState<STEP_INDICES>(startingStep);
  const [errors, setErrors] = useState(new Set<string>());
  const [negativeCashflow, setNegativeCashflow] = useState(false);
  const overviewSection = reviewSections.find(
    (section) => section.label === "Overview"
  );
  const overviewSteps = overviewSection ? overviewSection.steps : [];

  const totalReviewSteps = overviewSteps.length;

  useEffect(() => {
    if (!ready && !debugMode) {
      dispatch(push("/home"));
    }
  }, []);

  useEffect(() => {
    if (!stepLoaded && !planLoading.currentPlan) {
      setStepLoaded(true);
      const progress = (currentPlan.progress || 0) % 200;
      if (progress > 0) {
        const stepEntries = Object.entries(progressSteps);
        const stepForProgressEntry = stepEntries.find(
          (entry) => entry[1] === progress
        );
        if (stepForProgressEntry) {
          let newStep = +stepForProgressEntry[0] as STEP_INDICES;
          if (newStep >= STEP_INDICES.Transition) {
            newStep = STEP_INDICES.Recap;
          }
          setStepIndex(newStep);
        }
      }
    }
  }, [stepLoaded, planLoading, currentPlan]);

  useEffect(() => {
    if (debugMode) {
      getV2PlanProjectionApi(+planId).then((planProjection) => {
        dispatch({
          type: GET_PLAN_PROJECTION + SUCCESS,
          payload: { planProjection, minimalProjection: planProjection },
        });
        dispatch(setCurrentPlan({ planId: +planId, keepId: true }));
      });
    }
  }, [debugMode]);

  useEffect(() => {
    if (!debugMode && !negativeCashflow) {
      if (
        (currentPlan?.id || 0) > -1 &&
        currentIncomeTotal < currentExpenseTotal - debtObligations.total
      ) {
        setNegativeCashflow(true);
      }
    }
  }, [
    debugMode,
    currentPlan,
    currentIncomeTotal,
    currentExpenseTotal,
    debtObligations,
    negativeCashflow,
  ]);

  const currentStepLabel = useMemo(() => {
    const mainSteps = sections.flatMap((section) => section.steps);
    for (let i = 0; i < mainSteps.length; i++) {
      const step = mainSteps[i];
      const targetIndex = step.targets.indexOf(stepIndex);
      if (targetIndex >= 0) {
        // TODO this should be handled by CSS
        const label =
          step.label.length > 20
            ? `${step.label.substring(0, 20)}...`
            : step.label;
        return `${label} (${targetIndex + 1} of ${step.targets.length})`;
      }
    }
    return "";
  }, [stepIndex]);

  const currentReviewStepInfo = overviewSteps.find(
    (step) => step.target === reviewStep
  ) || {
    target: 0,
    label: "",
  };

  const currentReviewStepLabel = currentReviewStepInfo.label;

  // TODO this should be handled by CSS
  const truncatedReviewStepLabel =
    currentReviewStepLabel.length > 20
      ? `${currentReviewStepLabel.substring(0, 20)}...`
      : currentReviewStepLabel;

  const persistChanges = (index: STEP_INDICES) => {
    if (debugMode) {
      return;
    }
    const newProgress = progressSteps[index];
    if (newProgress > (currentPlan.progress || 0)) {
      dispatch(
        updateCurrentPlan({
          questionnaire: {
            ...(currentPlan.questionnaire || emptyPlan),
          },
          progress: newProgress,
        })
      );
    }
    dispatch(savePlan(null));
  };

  const navigate = (newIndex: STEP_INDICES, toSpouse?: boolean) => {
    if (toSpouse && isMarried) {
      setSpouseFlag(true);
    }
    const correctIndex =
      isMarried && newIndex === STEP_INDICES.Marriage ? newIndex + 1 : newIndex;
    persistChanges(correctIndex);
    setStepIndex(correctIndex);
  };

  const implementOptimizedPlan = () => {
    if (currentPlan && currentPlan.id) {
      dispatch(implementPlan(currentPlan.id));
    }
  };

  const handleNextClick = () => {
    setNextClicked((current) => current + 1);
    if (stepIndex === STEP_INDICES.Transition) {
      setReviewStep((current) => current + 1);
    } else {
      dispatch(savePlan(null));
    }
  };

  const handleBackClick = () => {
    if (!errors.size) {
      persistChanges(0);
    }
    setErrors(new Set([]));
    setNextClicked(nextClicked - 1);
    if (stepIndex === STEP_INDICES.Transition && reviewStep > 0) {
      setReviewStep((current) => current - 1);
    } else {
      let nextStep = stepIndex - 1;
      if (isMarried && nextStep === STEP_INDICES.Marriage) {
        nextStep = nextStep - 1;
      }
      navigate(nextStep, STEPS_WITH_SPOUSE_PAGES.includes(nextStep));
    }
  };

  const incrementStep = () => {
    let nextStep = stepIndex + 1;

    if (isMarried && nextStep === STEP_INDICES.Marriage) {
      nextStep++;
    }
    if (nextStep !== undefined) {
      navigate(nextStep);
    } else {
      navigate(STEP_INDICES.Transition);
    }
  };

  const handleEditRoute = (optimized: boolean) => {
    if (optimized) {
      setStepIndex(10);
    } else {
      dispatch(convertOptimizedToManual());
      dispatch(fetchUser());
      // if we ever change it so graduated users can go back to school this will need to be expanded on
      dispatch(setBuildStep(PLAN_BUILD_STEPS.GRADUATED_LIFE_EVENTS_OVERVIEW));
      dispatch(setPopup("Build"));
      dispatch(push("/plan-builder"));
    }
  };

  const exit = () => {
    dispatch(push("/plan-summary"));
  };

  const saveAndClose = () => {
    persistChanges(stepIndex);
    exit();
  };

  return (
    <AiPlanBuilderLayout
      handleBackClick={handleBackClick}
      stepIndex={stepIndex}
      currentStepLabel={currentStepLabel}
      reviewStepLabel={truncatedReviewStepLabel}
      totalReviewSteps={totalReviewSteps}
      reviewStep={reviewStep}
      setReviewStep={setReviewStep}
      exit={exit}
      saveAndClose={saveAndClose}
      implementPlan={implementOptimizedPlan}
      errors={errors}
      handleNextClick={handleNextClick}
      handleEditRoute={handleEditRoute}
      nextDisabled={!!errors.size || negativeCashflow}
      navigate={navigate}
    >
      {negativeCashflow && <NegativeCashflow />}
      {!negativeCashflow && stepLoaded && (
        <MainForm
          debugMode={debugMode}
          stepIndex={stepIndex}
          reviewStepIndex={reviewStep}
          goToNext={incrementStep}
          nextClicked={nextClicked}
          onSummaryClick={navigate}
          errors={errors}
          setErrors={setErrors}
          spouseFlag={spouseFlag}
          setSpouseFlag={setSpouseFlag}
        />
      )}
    </AiPlanBuilderLayout>
  );
};

export default Content;
