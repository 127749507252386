import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EuiForm,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiIcon,
} from "@elastic/eui";
import { StyledEuiButton } from "src/components/Global/StyledComponents";
import {
  editTransactionApi,
  getBreakoutsApi,
  updateBreakoutsApi,
} from "src/apiService";
import {
  Breakout,
  getCategoryForType,
  INCOME_BREAKOUTS,
  isIncomeTypeToConfirm,
  STUDENT_TOP_LEVEL_CATEGORIES,
  TOP_LEVEL_CATEGORIES,
} from "src/interfaces";
import {
  addTransactionSuccess,
  getUnconfirmedTransactions,
  markTransactionsConfirmed,
  setUpdatedBreakouts,
  removeTransaction,
  updateTransaction,
} from "src/store/transaction/actions";
import { getIsCurrentStudent } from "src/store/system/selector";
import TransactionDetails from "./TransactionDetails";
import InputIncome from "./InputIncome";
import InputDeductions from "./InputDeductions";
import TransactionHelpContent from "./TransactionHelpContent";
import { Global, css } from "@emotion/react";

const confirmDialogStyles = css`
  .flyout-footer {
    background-color: #fff !important;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid #d3dae6;
    button {
      margin: 0;
    }
  }
`;

enum STEPS {
  TRANSACTION_DETAILS = 1,
  INPUT_INCOME,
  INPUT_DEDUCTIONS,
}

const ConfirmIncomeDialog = ({
  open,
  onClose,
  transaction,
  alreadyConfirmed,
  onFinish,
  onRemove,
  onSave,
  setTitle,
  description,
}: any) => {
  const dispatch = useDispatch();
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const topLevelCategories = isCurrentStudent
    ? STUDENT_TOP_LEVEL_CATEGORIES
    : TOP_LEVEL_CATEGORIES;
  const [step, setStep] = useState(STEPS.TRANSACTION_DETAILS);
  const [formValues, _setFormValues] = useState<any>({});
  const setFormValues = (arg: any) => {
    _setFormValues(arg);
  };
  const [showHelp, setShowHelp] = useState(false);

  // Ensure transaction is valid
  if (!transaction || !transaction.type) {
    return <div>Loading transaction details...</div>;
  }

  const titles = [
    "Confirm Transaction",
    <>
      <h2>
        Confirm Transaction
        {!!description && <> - {description} Income Details</>}
      </h2>
      <EuiIcon
        color="primary"
        type="questionInCircle"
        onClick={() => setShowHelp(true)}
        style={{ cursor: "pointer", marginLeft: 8, width: 20, height: 20 }}
      />
    </>,
    <>
      <h2>
        Confirm Transaction
        {!!description && <> - {description} Deduction Details</>}
      </h2>
      <EuiIcon
        color="primary"
        type="questionInCircle"
        onClick={() => setShowHelp(true)}
        style={{ cursor: "pointer", marginLeft: 8, width: 20, height: 20 }}
      />
    </>,
  ];

  // useEffect(() => {
  //   setTitle(showHelp ? "Your Income and Deductions" : titles[step - 1]);
  // }, [step, setTitle, description, showHelp]);

  const handleChange = (e: React.ChangeEvent<any>) => {
    const field = e.target.name;
    const value = e.target.value;
    setFormValues((current: any) => ({ ...current, [field]: value }));
  };

  const removeBreakout = (
    breakoutType: "incomes" | "deductions",
    index: number
  ) => {
    const newValues = [...formValues[breakoutType]];
    newValues.splice(index, 1);
    setFormValues({ ...formValues, [breakoutType]: newValues });
  };

  useEffect(() => {
    if (
      open &&
      transaction.isManual &&
      isIncomeTypeToConfirm(transaction.type)
    ) {
      setStep(STEPS.INPUT_INCOME);
    }
  }, [open, transaction]);

  useEffect(() => {
    if (transaction) {
      const category = getCategoryForType(transaction.type, topLevelCategories);
      const newFormValues = {
        ...formValues,
        category,
        ...transaction,
        amount: Math.abs(transaction.amount),
      };
      if (!alreadyConfirmed && transaction.manual !== "manual") {
        newFormValues.amount = 0;
      }
      setFormValues(newFormValues);
    }
  }, [transaction, alreadyConfirmed]);

  const submitIncome = () => {
    const absValueMap = (item: any) => ({
      ...item,
      amount: Math.abs(item.amount),
    });
    const finalBreakouts = [
      ...formValues.incomes.map(absValueMap),
      ...formValues.deductions.map(absValueMap),
    ];
    updateBreakoutsApi(transaction.id, finalBreakouts)
      .then((result) => {
        dispatch(getUnconfirmedTransactions(+transaction.id));
        if (result?.transactions?.[0]) {
          if (onSave) {
            onSave(transaction);
          }
          if (onFinish) {
            onFinish(transaction);
          }
        }
        dispatch(setUpdatedBreakouts(result.transactions));
        if (setTitle) {
          setTitle("Welcome Back!");
        }
        onClose();
      })
      .catch((e) => {
        console.error(e);
        onClose();
      });
  };

  const submitNonIncome = () => {
    const update: any = {
      type: formValues.type,
      description: formValues.description,
    };
    if (formValues.ignore === "y") {
      update.ignore = "duplicate";
    }
    if (transaction.manual === "manual") {
      update.amount =
        transaction.amount < 0
          ? -Math.abs(formValues.amount)
          : Math.abs(formValues.amount);
    }
    if (!alreadyConfirmed) {
      dispatch(markTransactionsConfirmed([transaction.id]));
    }
    return editTransactionApi(transaction.id, update)
      .then((result) => {
        if (onRemove && formValues.ignore === "y") {
          onRemove(transaction.id);
        }
        const updatedTransaction = result?.transactions?.[0];
        if (updatedTransaction) {
          if (formValues.ignore !== "y") {
            if (onSave) {
              onSave(updatedTransaction);
            }
            if (onFinish) {
              onFinish(transaction);
            }
          }
          if (!alreadyConfirmed && formValues.ignore !== "y") {
            dispatch(addTransactionSuccess(updatedTransaction));
          } else {
            dispatch(updateTransaction({ id: transaction.id, update }));
          }
        }
        if (setTitle) {
          setTitle("Welcome Back!");
        }
        onClose();
      })
      .catch((e) => {
        console.error(e);
        onClose();
      });
  };

  const goBack = () => {
    if (step === STEPS.TRANSACTION_DETAILS) {
      if (setTitle) {
        setTitle("Welcome Back!");
      }
      if (onClose) {
        onClose();
      }
    } else {
      setStep((prevStep) => prevStep - 1);
    }
  };

  const goForward = () => {
    if (step === STEPS.TRANSACTION_DETAILS) {
      const update: any = {
        type: formValues.type,
        description: formValues.description,
      };
      if (formValues.ignore === "y") {
        update.ignore = "duplicate";
      }
      if (transaction.manual === "manual") {
        update.amount =
          transaction.amount < 0 ? -formValues.amount : formValues.amount;
      }
      editTransactionApi(transaction.id, update)
        .then((result) => {
          const updatedTransaction = result?.transactions?.[0];
          if (formValues.ignore === "y") {
            dispatch(removeTransaction(transaction.id));
          } else if (updatedTransaction) {
            if (onSave && formValues.ignore !== "y") {
              onSave(updatedTransaction);
            }
            dispatch(updateTransaction({ id: transaction.id, update }));
          }
        })
        .then(() => {
          getBreakoutsApi(transaction.id)
            .then((data: Breakout[]) => {
              const incomes: Breakout[] = [];
              const deductions: Breakout[] = [];
              data.forEach((item: Breakout) => {
                if (!("" + item.type in INCOME_BREAKOUTS)) {
                  deductions.push(item);
                } else {
                  incomes.push(item);
                }
              });
              if (transaction.type === 7000 || transaction.type === 7001) {
                if (!incomes.length) {
                  incomes.push({ type: 1001, amount: 0, id: Math.random() });
                }
                if (!deductions.length) {
                  deductions.push(
                    { type: 12302, amount: 0, id: Math.random() },
                    { type: 15000, amount: 0, id: Math.random() },
                    { type: 13102, amount: 0, id: Math.random() }
                  );
                }
              } else if (transaction.type === 7002) {
                incomes.push({ type: 1008, amount: 0, id: Math.random() });
              } else if (transaction.type === 7003) {
                incomes.push({ type: 1010, amount: 0, id: Math.random() });
              }
              setFormValues((current: typeof formValues) => ({
                ...current,
                incomes,
                deductions,
              }));
            })
            .catch(console.error);
        });
    }
    setStep((prevStep) => {
      const newStep = prevStep + 1;
      return newStep;
    });
  };

  const renderContent = [
    null,
    TransactionDetails,
    InputIncome,
    InputDeductions,
  ];

  const Content: any = renderContent[step];
  const type = formValues.type || transaction.type;
  let nextFunction = goForward;
  let nextLabel = "Next: Income Details";
  if (!isIncomeTypeToConfirm(type) || formValues.ignore === "y") {
    nextFunction = submitNonIncome;
    nextLabel = "Confirm";
  } else if (step === STEPS.INPUT_DEDUCTIONS) {
    nextFunction = submitIncome;
    nextLabel = "Confirm";
  }

  return (
    <>
      <Global styles={confirmDialogStyles} />
      <EuiFlyoutBody>
        <EuiForm>
          {showHelp ? (
            <TransactionHelpContent />
          ) : (
            <>
              <Content
                formValues={formValues}
                handleChange={handleChange}
                transaction={transaction}
                setFormValues={setFormValues}
                removeBreakout={removeBreakout}
              />
            </>
          )}
          <EuiFlyoutFooter className="flyout-footer">
            <EuiFlexGroup>
              {showHelp ? (
                <EuiFlexItem style={{ alignItems: "flex-end" }}>
                  <StyledEuiButton
                    color="text"
                    onClick={() => setShowHelp(false)}
                    style={{ maxWidth: "120px" }}
                  >
                    Back
                  </StyledEuiButton>
                </EuiFlexItem>
              ) : (
                <>
                  <EuiFlexItem>
                    <StyledEuiButton
                      color="text"
                      className="btn-spacing"
                      onClick={goBack}
                    >
                      {step === STEPS.TRANSACTION_DETAILS ? "Cancel" : "Back"}
                    </StyledEuiButton>
                  </EuiFlexItem>
                  {step === STEPS.INPUT_DEDUCTIONS ? (
                    <EuiFlexItem>
                      <EuiButton
                        className="btn-text"
                        fill
                        onClick={submitIncome}
                        disabled={!formValues.type}
                      >
                        Confirm
                      </EuiButton>
                    </EuiFlexItem>
                  ) : (
                    <EuiFlexItem>
                      <EuiButton
                        className="btn-text"
                        fill
                        onClick={nextFunction}
                        disabled={!formValues.type}
                      >
                        {nextLabel}
                      </EuiButton>
                    </EuiFlexItem>
                  )}
                </>
              )}
            </EuiFlexGroup>
          </EuiFlyoutFooter>
        </EuiForm>
      </EuiFlyoutBody>
    </>
  );
};

export default ConfirmIncomeDialog;
