import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Grid,
  makeStyles,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Select,
  RadioGroup,
} from "@material-ui/core";

import { profileBuilderStyles } from "src/theme";

import CenterContainer from "../../Components/CenterContainer";
import Button from "src/components/Button";
import Radio from "src/components/Radio";
import {
  savePlan,
  updateCurrentPlan,
  estimateCurrentPlanStudentTaxes,
} from "src/store/planBuild/actions";
import {
  getCurrentPlan,
  getMyMax401kDollarContribution,
  getMyMaxRoth401kDollarContribution,
  getSpouseMax401kDollarContribution,
  getSpouseMaxRoth401kDollarContribution,
} from "src/store/planBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import { Plan, RetirementEntry } from "src/interfaces/plan.interface";
import { PlanViewComponent } from "src/interfaces/viewComponent.interface";
import {
  DollarTextField,
  formatAnnually,
  formatMonthly,
  formatWholeDollars,
  PercentTextField,
} from "src/utils";
import { TAX_DEFERRED_INVESTMENT_TYPES } from "src/interfaces";

const useStyles = makeStyles(profileBuilderStyles);

type AccountTypes = "401k_value" | "roth_401k_value";

const EMPTY_RETIREMENT: RetirementEntry = {
  who: "applicant",
  has_401k: "n",
  has_roth_401k: "n",
  match_limit: 0,
  match_percent: 0,
  additional_limit: 0,
  additional_percent: 0,
  traditional_limit: 0,
  nonelective_limit: 0,
  nonelective_traditional_limit: 0,
  student_loan_match: "n",
};

const AddOrEdit401k: PlanViewComponent = ({
  initialValues,
  onClose,
  onSave,
  render,
  type,
}) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const plan: Plan = useSelector(getCurrentPlan);
  const isMarried = useSelector(getIsMarried);
  const initialFormValues: any = {
    additional_match: "n",
    additional_amount_match: "",
    additional_match_percent: "",
    base_amount_matched: "",
    base_match_percent: "",
    annual: "",
    monthly: "",
    who: isMarried ? "" : "applicant",
  };
  if (initialValues) {
    initialFormValues.who = initialValues.who || "applicant";
    const itemIndex = isMarried && initialValues.who === "spouse" ? 1 : 0;
    const retirement = plan?.retirement?.find(
      (r) => r.who === initialValues.who
    ) || { ...EMPTY_RETIREMENT };
    const soloAllocation = plan.allocations[0].solo[itemIndex];
    initialFormValues.annual =
      soloAllocation[type as "401k_value" | "roth_401k_value"];
    initialFormValues.monthly = Math.round(initialFormValues.annual / 12);
    initialFormValues.base_amount_matched = retirement.match_limit;
    initialFormValues.base_match_percent = retirement.match_percent;
    initialFormValues.additional_match = retirement.additional_limit
      ? "y"
      : "n";
    initialFormValues.additional_amount_match = retirement.additional_limit;
    initialFormValues.additional_match_percent = retirement.additional_percent;
  }
  const [formValues, setFormValues] = useState<any>(initialFormValues);
  let maxSelector = getMyMax401kDollarContribution;
  if (formValues.who === "spouse") {
    if (type === "401k_value") {
      maxSelector = getSpouseMax401kDollarContribution;
    } else {
      maxSelector = getSpouseMaxRoth401kDollarContribution;
    }
  } else if (type === "roth_401k_value") {
    maxSelector = getMyMaxRoth401kDollarContribution;
  }
  const maxDollars = useSelector(maxSelector);

  const setFormValue = (e: React.ChangeEvent<any>) => {
    const field = e.target.name;
    const value = e.target.value;
    const newFormValues = { ...formValues, [field]: value };
    if (field === "who") {
      const itemIndex = value === "spouse" ? 1 : 0;
      const retirement = plan.retirement[itemIndex];
      newFormValues.contribution = undefined;
      newFormValues.base_amount_matched = retirement.match_limit;
      newFormValues.base_match_percent = retirement.match_percent;
      if (retirement.additional_limit) {
        newFormValues.additional_match = "y";
        newFormValues.additional_amount_match = retirement.additional_limit;
        newFormValues.additional_match_percent = retirement.additional_percent;
      }
    } else if (field === "annual") {
      newFormValues.monthly = Math.round(value / 12);
    } else if (field === "monthly") {
      newFormValues.annual = value * 12;
    }
    setFormValues(newFormValues);
  };

  const spouses = formValues.who === "spouse" ? "spouse's " : "";

  const save = () => {
    const newRetirement = [...plan.retirement];
    const whoIndex = formValues.who === "applicant" ? 0 : 1;
    const retirementItem = {
      ...EMPTY_RETIREMENT,
      match_limit: formValues.base_amount_matched,
      match_percent: formValues.base_match_percent,
    };
    if (formValues.additional_match === "y") {
      retirementItem.additional_limit =
        +formValues.additional_amount_match || 0;
      retirementItem.additional_percent =
        +formValues.additional_match_percent || 0;
    }
    if (formValues.auto_question === "y") {
      retirementItem.nonelective_limit = +formValues.auto_match_percent || 0;
    }
    newRetirement[whoIndex] = retirementItem;
    const soloAllocations = plan.allocations[0].solo;
    const newSoloAllocations = [...soloAllocations];
    newSoloAllocations[whoIndex] = {
      ...soloAllocations[whoIndex],
      [type]: formValues.annual || 0,
    };
    if (initialValues && formValues.who !== initialValues.who) {
      const otherIndex = whoIndex ? 0 : 1;
      newSoloAllocations[otherIndex] = {
        ...newSoloAllocations[otherIndex],
        [type]: 0,
      };
    }
    const newAllocations = [...plan.allocations];
    let combinedContribution = newSoloAllocations[0][type as AccountTypes] || 0;
    if (isMarried) {
      combinedContribution += newSoloAllocations[1][type as AccountTypes] || 0;
    }
    newAllocations[0] = {
      ...newAllocations[0],
      solo: newSoloAllocations,
      [type]: combinedContribution,
    };
    const newPlan = {
      allocations: newAllocations,
      retirement: newRetirement,
    };
    dispatch(updateCurrentPlan(newPlan));
    if (type in TAX_DEFERRED_INVESTMENT_TYPES) {
      dispatch(estimateCurrentPlanStudentTaxes());
    }
    dispatch(savePlan(null));
    onSave();
  };

  return render({
    component: (
      <CenterContainer title="401(k)/403(b)/457/TSP" iconName="fb-model">
        {isMarried && (
          <Box className="mt-4">
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" className="mb-4">
                Whose contribution is this?
              </FormLabel>
              <Select
                variant="outlined"
                fullWidth
                value={formValues.who}
                name="who"
                placeholder="Select"
                onChange={setFormValue}
                native
              >
                <option value="applicant">Mine</option>
                <option value="spouse">My spouse's</option>
              </Select>
            </FormControl>
          </Box>
        )}
        {!!formValues.who && (
          <>
            <Grid container spacing={2} className="mt-4">
              <Grid item xs={6}>
                <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend" className="mb-4">
                    Monthly contribution
                  </FormLabel>
                  <DollarTextField
                    variant="outlined"
                    name="monthly"
                    placeholder="0"
                    value={formValues.monthly}
                    onChange={setFormValue}
                  />
                  <Typography variant="body1">
                    Max: {formatWholeDollars(Math.floor(maxDollars / 12))}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend" className="mb-4">
                    Annual contribution
                  </FormLabel>
                  <DollarTextField
                    variant="outlined"
                    name="annual"
                    placeholder="0"
                    value={formValues.annual}
                    onChange={setFormValue}
                  />
                  <Typography variant="body1">
                    Max: {formatWholeDollars(maxDollars)}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend" className="mb-4">
                    What % of your {spouses}eligible income does your {spouses}
                    employer match?
                  </FormLabel>
                  <PercentTextField
                    variant="outlined"
                    fullWidth
                    name="base_amount_matched"
                    value={formValues.base_amount_matched}
                    placeholder="0"
                    onChange={setFormValue}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl component="fieldset" fullWidth className="h-full">
                  <FormLabel component="legend" className="mb-4">
                    Employer match %
                  </FormLabel>
                  <PercentTextField
                    className="mt-auto"
                    variant="outlined"
                    fullWidth
                    name="base_match_percent"
                    value={formValues.base_match_percent}
                    placeholder="0"
                    onChange={setFormValue}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend" className="mb-4">
                    Does your {spouses}employer offer additional match?
                  </FormLabel>
                  <RadioGroup
                    aria-label="additional_match"
                    name="additional_match"
                    onChange={setFormValue}
                    value={formValues.additional_match}
                    className="flex flex-row"
                  >
                    <FormControlLabel
                      value="n"
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value="y"
                      control={<Radio />}
                      label="Yes"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {formValues.additional_match === "y" && (
                <>
                  <Grid item xs={6}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend" className="mb-4">
                        What % of your {spouses}eligible income do they match?
                      </FormLabel>
                      <PercentTextField
                        variant="outlined"
                        fullWidth
                        name="additional_amount_match"
                        value={formValues.additional_amount_match}
                        placeholder="0"
                        onChange={setFormValue}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      component="fieldset"
                      fullWidth
                      className="h-full"
                    >
                      <FormLabel component="legend" className="mb-4">
                        Employer additional match %
                      </FormLabel>
                      <PercentTextField
                        className="mt-auto"
                        variant="outlined"
                        fullWidth
                        name="additional_match_percent"
                        value={formValues.additional_match_percent}
                        placeholder="0"
                        onChange={setFormValue}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
            <Box className="w-full flex font-bold mt-6 mb-2">
              <Box style={{ width: "40%" }} />
              <Box style={{ width: "30%" }}>Monthly</Box>
              <Box style={{ width: "30%" }}>Annually</Box>
            </Box>
            <Box className="w-full flex">
              <Box className="font-bold" style={{ width: "40%" }}>
                Retirement contribution summary
              </Box>
              <Box style={{ width: "30%" }}>
                {formatMonthly(Math.round(formValues.annual / 12))}
              </Box>
              <Box style={{ width: "30%" }}>
                {formatAnnually(formValues.annual)}
              </Box>
            </Box>
          </>
        )}
        <Button
          variant="outlined"
          fbColor="primary"
          onClick={onClose}
          className={styles.btnCancel}
        >
          Cancel
        </Button>
      </CenterContainer>
    ),
    nextLabel: "Save",
    onNext: save,
  });
};

export default AddOrEdit401k;
