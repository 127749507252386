import { wealthPageText } from "src/text";

export const activeTabText = [
  {
    _id: 0,
    heading: "Tracking Your Net Wealth",
    helpTopic: "netWealthAndHc",
    description: wealthPageText[0],
    buttons: [
      { color: "primary", text: "Build My Plan", to: "/financial-plans" },
    ],
    subscribedButtons: [
      { color: "primary", text: "View My Plan", to: "/plan-summary" },
    ],
  },
  {
    _id: 1,
    heading: "Understanding Financial Assets",
    helpTopic: "assetSummary",
    description: wealthPageText[1],
  },
  {
    _id: 2,
    heading: "Understanding Human Capital",
    helpTopic: "netWealthAndHc",
    description: wealthPageText[2],
  },
  {
    _id: 3,
    heading: "Understanding Debt",
    helpTopic: "debtSummary",
    description: wealthPageText[3],
  },
];

export const chartData = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];
