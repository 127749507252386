import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forOwn } from "lodash";

import { ListSubheader, MenuItem } from "@material-ui/core";
import { format } from "date-fns";
import { tabLabels } from "../data";
import Tabs from "src/components/Tabs";
import { MappedCashflow, GROUPED_EXPENSE_TYPES, EXPENSE_TYPES } from "src/interfaces";
import BudgetCard from "../Components/BudgetCard";
import Dialog from "../Components/Dialog";
import {
  addCashflow,
  editCashflow,
  removeCashflow,
} from "src/store/cashflow/actions";
import { getExpenseSummary } from "src/store/dashboard/selector";
import { getDashboardCashflows } from "src/store/cashflow/selector";
import { getIsSubscribed } from "src/store/system/selector";
import { EuiButtonGroup, EuiFlexGroup, EuiFlexItem, EuiTitle } from "@elastic/eui";
import { Global, css } from "@emotion/react";
import { useFlyout } from "src/components/Global/FlyoutContext";
import AddItemDialog from "../Components/Dialog";

const ddInsuranceStyles = css`
  .rm-insurance-block {
    .rm-secondary-header {
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      font-family: "Inter", sans-serif;
    }
    .rm-tab-group {
      min-width: 400px;
      .euiButtonGroupButton {
        background-color: #fbfcfd;
        border: 1px solid #cecece;
      }
      .euiButtonGroupButton-isSelected {
        background-color: #69707d;
      }
      span {
        font-size: 14px;
        font-family: "Inter", sans-serif;
      }
    }
  }
`;

const ExpensesCard = ({ openTransactionsDialog, taxBudget }: any) => {
  const dispatch = useDispatch();
  const subscribed = useSelector(getIsSubscribed);
  const expenseSummary = useSelector(getExpenseSummary);
  const { expenses }: any = useSelector(getDashboardCashflows);
  const { openCustomFlyout } = useFlyout();
  const [currentTab, setCurrentTab] = useState(0);
  const [isEditing, setEditing] = useState(false);
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const tabOptions = [
    {
      id: "current",
      label: "Current",
    },
    {
      id: "monthly",
      label: "Last Month",
    },
    {
      id: "annual",
      label: "Last 12 Months",
    },
  ];

  const _handleTabs = (id: any) => {
    const tabIndex = tabOptions.findIndex((tab) => tab.id === id);
    setCurrentTab(tabIndex);
  };

  const openAddDialog = () => {
    const availableTypeList = Object.entries(EXPENSE_TYPES).map(([value, label]) => ({
      value,
      label,
    }));

    openCustomFlyout({
      component: (props: any) => (
        <AddItemDialog
          {...props}
          title="Add Expense"
          list={availableTypeList}
          onAdd={(values: { type: string; annual: string; who?: string }) => {
            const amount = +values.annual || 0;
            const cashflow = {
              amount,
              type: values.type,
              who: values.who === "spouse" ? "spouse" : undefined,
              inschool: false,
            };
            dispatch(addCashflow({ cashflow }));
          }}
        />
      ),
      showOwner: true,
    });
  };

  const calculateDateRange = (): string => {
    const now = new Date();
    let startDate: Date;
    const endDate = now;

    switch (currentTab) {
      case 1: // Last 30 Days
        startDate = new Date();
        startDate.setDate(now.getDate() - 30);
        break;
      case 2: // Last 12 Months
        startDate = new Date();
        startDate.setFullYear(now.getFullYear() - 1);
        break;
      case 0: // Current
      default:
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        break;
    }

    return `${format(startDate, "MMM yyyy")} - ${format(endDate, "MMM yyyy")}`;
  };

  const handleCardEditClick = (
    changes: { [stringId: string]: number },
    deletedIds: Set<number>,
    newValues: { [type: string]: number }
  ) => {
    if (isEditing) {
      forOwn(changes, (amount: number, stringId: string) => {
        const cashflow = expenses.find(
          (item: MappedCashflow) => item.id === +stringId
        );
        if (cashflow) {
          const newAmount = currentTab === 2 ? amount : amount * 12;
          const update: any = { id: +stringId, amount: newAmount };
          dispatch(editCashflow(update));
        }
      });
      Array.from(deletedIds).forEach((id: number) =>
        dispatch(removeCashflow(id))
      );
      forOwn(newValues, (rawAmount: number, type: string) => {
        if (rawAmount) {
          const amount = currentTab === 2 ? rawAmount : rawAmount * 12;
          dispatch(addCashflow({ cashflow: { amount, type } }));
        }
      });
    }
    setEditing(!isEditing);
  };

  const closeAddDialog = () => {
    setAddDialogVisible(() => false);
  };
  const addItem = (values: { type: string; annual: string }) => {
    dispatch(
      addCashflow({
        cashflow: { type: values.type, amount: +values.annual || 0 },
      })
    );
    closeAddDialog();
  };

  const renderAvailableTypes: JSX.Element[] = [];
  GROUPED_EXPENSE_TYPES.forEach(({ header, items }: any) => {
    renderAvailableTypes.push(
      <ListSubheader key={header} disableSticky>
        {header}
      </ListSubheader>
    );
    items.forEach((item: { label: string; value: string }) => {
      renderAvailableTypes.push(
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      );
    });
  });

  const getActual = () => {
    if (currentTab === 0) {
      return expenseSummary.current;
    } else if (currentTab === 1) {
      return expenseSummary.monthly;
    }
    return expenseSummary.annual;
  };

  const timeframes = ["current", "monthly", "annual"];
  const timeframe = timeframes[currentTab];

  return (
    <>
      <Global styles={ddInsuranceStyles} />
      <div className="rm-insurance-block">
        <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
          <EuiFlexItem grow={false}>
            <EuiTitle className="rm-secondary-header">
              <h2>Household Expenses</h2>
            </EuiTitle>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonGroup
              legend="Insurance tabs"
              options={tabOptions}
              idSelected={tabOptions[currentTab].id}
              onChange={_handleTabs}
              isFullWidth
              className="rm-tab-group"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
      <BudgetCard
        buttonLabel="Add Expense"
        budget={expenses}
        taxBudget={taxBudget}
        openTransactionsDialog={
          subscribed
            ? (key) => openTransactionsDialog("expense", key, timeframe)
            : undefined
        }
        showTax
        actual={subscribed ? getActual() : undefined}
        onSave={handleCardEditClick}
        onAdd={openAddDialog}
        editing={isEditing}
        annual={currentTab === 2}
        dateRange={calculateDateRange()}
      />
    </>
  );
};

export default ExpensesCard;
