import React, { useEffect, useState } from "react";

import Questionnaire from "src/components/Questionnaire";
import {
  QuestionFormProps,
  singleQuestions,
} from "src/interfaces/optimizedPlanBuild.interface";
import { Question, QuestionForm } from "src/interfaces/questionnaire.interface";
import { fillInDefaults, loadInitialValues } from "../common";

const AutoLoan = ({
  planSetter,
  planData,
  nextClicked,
  goToNext,
  errors,
  setErrors,
  validate,
}: QuestionFormProps) => {
  const [nextClick] = useState(nextClicked);
  const questionData: QuestionForm = singleQuestions.auto;

  const startingValues: any = loadInitialValues(questionData, planData);
  if (!startingValues.id) {
    startingValues.id = Date.now();
  }
  if (startingValues.vehicle_type) {
    startingValues.preReq = "y";
  }
  const [values, setValues] = useState<Question>(startingValues);

  const handleChange = (newValues: any) => {
    updatePlanData(newValues);
    setValues(newValues);
  };

  useEffect(() => {
    if (nextClicked !== nextClick) {
      if (!validate || validate(questionData.fields, values)) {
        planSetter(
          fillInDefaults({ ...planData }, questionData.defaultValue || {})
        );
        goToNext();
      }
    }
  }, [nextClicked]);

  const updatePlanData = (changedValues: any) => {
    const newPlan = { ...planData };
    if (changedValues.preReq === "n") {
      newPlan.vehicle_date = "";
      newPlan.vehicle_down = 0;
      newPlan.vehicle_cost = 0;
      newPlan.vehicle_type = "add";
      setErrors(new Set([]));
    } else if (changedValues.preReq === "y") {
      newPlan.vehicle_date = changedValues.vehicle_date;
      newPlan.vehicle_down = changedValues.vehicle_down;
      newPlan.vehicle_cost = changedValues.vehicle_cost;
      newPlan.vehicle_type = changedValues.vehicle_type;
    }
    planSetter(newPlan);
  };

  return (
    <Questionnaire
      questions={questionData}
      values={values}
      onChange={handleChange}
      errors={errors}
      setErrors={setErrors}
    />
  );
};

export default AutoLoan;
