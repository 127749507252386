import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import AiPlanBuilderLayout from "src/layouts/AiPlanBuilderLayout";
import { getRefreshNeeded } from "src/store/dashboard/selector";
import {
  fetchLivePlanProjection,
  fetchPlanProjection,
  implementPlan,
  setBuildStep,
} from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import {
  getCurrentPlan,
  isCurrentPlanImplemented,
} from "src/store/planBuild/selector";

import Score from "./ReviewViews/Score";
import GoalSummary from "./ReviewViews/GoalSummary";
import AssetsInvestments from "./ReviewViews/AssetsInvestments";
import Debts from "./ReviewViews/Debts";
import NetWealth from "./ReviewViews/NetWealth";
import PayCheckAllocation from "./ReviewViews/PayCheckAllocation";
import { STEP_INDICES } from "src/pages/OptimizedPlan/common";

const ReviewPlan = ({ debugMode }: any) => {
  const dispatch = useDispatch();
  const currentPlan = useSelector(getCurrentPlan);
  const currentPlanIsImplemented = useSelector(isCurrentPlanImplemented);
  const refreshNeeded = useSelector(getRefreshNeeded);
  const [reviewStep, setReviewStep] = useState(
    currentPlanIsImplemented ? 1 : 0
  );

  useEffect(() => {
    if (!currentPlanIsImplemented && !debugMode) {
      dispatch(fetchPlanProjection());
    } else if (refreshNeeded.liveProjection) {
      dispatch(fetchLivePlanProjection(true));
    }
  }, []);

  const handleImplement = () => {
    if (currentPlan && currentPlan.id) {
      dispatch(implementPlan(currentPlan.id));
    }
    dispatch(push("/home"));
    dispatch(setBuildStep(PLAN_BUILD_STEPS.NAME));
  };

  const exit = () => dispatch(push("home"));

  const sections = [
    GoalSummary,
    PayCheckAllocation,
    Score,
    AssetsInvestments,
    Debts,
    NetWealth,
  ];
  const stepLabels = [
    "My FitBUX Score",
    "Goal Summary",
    "Paycheck Allocation",
    "Assets/Investments",
    "Debts",
    "Net Wealth Summary",
  ];
  const Section: any = sections[reviewStep];
  return (
    <AiPlanBuilderLayout
      manual
      handleBackClick={() => {
        if (reviewStep > 0) {
          setReviewStep((current) => current - 1);
        } else {
          dispatch(setBuildStep(PLAN_BUILD_STEPS.RECOMMENDATIONS));
        }
      }}
      stepIndex={STEP_INDICES.Transition}
      currentStepLabel="Overview"
      reviewStepLabel={stepLabels[reviewStep]}
      totalReviewSteps={sections.length}
      implementPlan={!currentPlanIsImplemented ? handleImplement : undefined}
      reviewStep={reviewStep}
      setReviewStep={setReviewStep}
      exit={exit}
      saveAndClose={exit}
      handleNextClick={() =>
        setReviewStep((current) => {
          if (current < sections.length - 1) {
            return current + 1;
          }
          return current;
        })
      }
      handleEditRoute={() =>
        dispatch(setBuildStep(PLAN_BUILD_STEPS.GRADUATED_LIFE_EVENTS_OVERVIEW))
      }
      errors={new Set([])}
    >
      <Section manual />
    </AiPlanBuilderLayout>
  );
};

export default ReviewPlan;
