import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";
import {
  clearPlanComparison,
  implementPlan,
  setBuildStep,
} from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import {
  getLoadingState,
  getPlans,
  getScoreProjection,
  getAssetProjection,
  getCompareLifeEventProjections,
  getComparePlanIndex,
  getCurrentPlan,
  getDebtProjection,
  getNetProjection,
  getCompareFutureProjections,
  isCurrentPlanImplemented,
} from "src/store/planBuild/selector";
import {
  GOALS,
  PlanViewComponent,
  PROJECTION_ASSETS,
  PROJECTION_DEBTS,
} from "src/interfaces";
import {
  getIsCurrentStudent,
  getIsSubscribed,
} from "src/store/system/selector";
import { colors } from "src/theme";
import { formatWholeDollars, reformatDateString } from "src/utils";

import EditDialog from "./EditDialog";
import OverviewCard from "./components/OverviewCard";
import OverviewTable from "./components/OverviewTable";
import { AppLoader } from "src/components";
import Chart from "src/components/Chart";

const ComparePlans: PlanViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const currentPlan = useSelector(getCurrentPlan);
  const compareIndex = useSelector(getComparePlanIndex);
  let comparePlanName = "Minimal";
  const existingPlans: any[] = useSelector(getPlans);
  let comparePlan: any;
  if (compareIndex >= 0) {
    comparePlan = existingPlans[compareIndex];
    comparePlanName = comparePlan.name;
  }
  const loadingState = useSelector(getLoadingState);
  const loading = loadingState.comparePlan;
  const subscribed = useSelector(getIsSubscribed);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const currentPlanIsImplemented = useSelector(isCurrentPlanImplemented);
  const scoreProjection = useSelector(getScoreProjection);
  const assetProjection = useSelector(getAssetProjection);
  const debtProjection = useSelector(getDebtProjection);
  const netProjection = useSelector(getNetProjection);
  const future = useSelector(getCompareFutureProjections);
  const { plan1Events, plan2Events } = useSelector(
    getCompareLifeEventProjections
  );

  useEffect(
    () => () => {
      dispatch(clearPlanComparison());
    },
    []
  );

  const [showEditDialog, setShowEditDialog] = useState(false);

  let planIdToImplement = 0;
  if (currentPlan && currentPlan.id) {
    planIdToImplement = currentPlan.id;
  }
  const onNext = () => {
    if (subscribed) {
      if (planIdToImplement) {
        dispatch(implementPlan(planIdToImplement));
      }
      dispatch(push("/financial-plans"));
      dispatch(setBuildStep(PLAN_BUILD_STEPS.NAME));
    } else {
      dispatch(setBuildStep(PLAN_BUILD_STEPS.IMPLEMENT));
    }
  };
  const implementPlan2 = () => {
    if (comparePlan && comparePlan.id) {
      planIdToImplement = comparePlan.id;
    }
    onNext();
  };

  const toggleEditDialog = () => setShowEditDialog(!showEditDialog);

  const now = new Date();
  const nowYear = now.getFullYear();
  const years = [
    "Now",
    "" + (nowYear + 5),
    "" + (nowYear + 10),
    future.retirementYear || "",
  ];

  const assetKeyList = Array.from(future.assetKeys as Set<string>).filter(
    (key: string) => key !== "total"
  );
  const debtKeyList = Array.from(future.debtKeys as Set<string>).filter(
    (key: string) => key !== "total"
  );

  return render({
    component: (
      <Container>
        <Typography variant="h2" component="h2">
          Review
        </Typography>
        {loading && (
          <Box className={styles.loaderContainer}>
            <AppLoader />
          </Box>
        )}
        <Grid container spacing={3}>
          {!loading && !!future.net.length && (
            <>
              <Grid item xs={6}>
                <Chart
                  centerTitle
                  title="Projected FitBUX Score"
                  data={scoreProjection}
                  height="322px"
                  keys={["score", "min"]}
                  xKey="month"
                  keyLabels={{
                    score: currentPlan.name,
                    min: comparePlanName,
                  }}
                  width="100%"
                />
              </Grid>
              <Grid item xs={6}>
                <OverviewTable
                  title={"FitBUX Score:"}
                  columns={years}
                  rows={[
                    {
                      header: currentPlan.name,
                      columns: years.map(
                        (year: string, index: number) =>
                          future.score[index].plan
                      ),
                    },
                    {
                      header: comparePlanName,
                      columns: years.map(
                        (year: string, index: number) => future.score[index].min
                      ),
                    },
                  ]}
                />
              </Grid>

              <Grid item xs={6}>
                <Chart
                  dollars
                  centerTitle
                  title="Projected Financial Assets"
                  data={assetProjection}
                  height="322px"
                  keys={["asset", "min"]}
                  xKey="month"
                  keyLabels={{
                    asset: currentPlan.name,
                    min: comparePlanName,
                  }}
                  width="100%"
                />
              </Grid>
              <Grid item xs={6}>
                <OverviewTable
                  title="Assets:"
                  columns={years}
                  rows={[
                    {
                      header: currentPlan.name,
                      columns: years.map((year: string, index: number) =>
                        formatWholeDollars(future.assets[index].plan.total)
                      ),
                      children: assetKeyList.map((key: string) => ({
                        header:
                          PROJECTION_ASSETS[
                            key as keyof typeof PROJECTION_ASSETS
                          ],
                        columns: years.map((year, index: number) => {
                          const value = future.assets[index].plan[key];
                          if (value) {
                            return formatWholeDollars(value);
                          }
                          return "";
                        }),
                      })),
                    },
                    {
                      header: comparePlanName,
                      columns: years.map((year: string, index: number) =>
                        formatWholeDollars(future.assets[index].min.total)
                      ),
                      children: assetKeyList.map((key: string) => ({
                        header:
                          PROJECTION_ASSETS[
                            key as keyof typeof PROJECTION_ASSETS
                          ],
                        columns: years.map((year, index: number) => {
                          const value = future.assets[index].min[key];
                          if (value) {
                            return formatWholeDollars(value);
                          }
                          return "";
                        }),
                      })),
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={6}>
                <Chart
                  dollars
                  centerTitle
                  title="Projected Debts"
                  data={debtProjection}
                  height="322px"
                  keys={["debt", "min"]}
                  xKey="month"
                  keyLabels={{
                    debt: currentPlan.name,
                    min: comparePlanName,
                  }}
                  width="100%"
                />
              </Grid>
              <Grid item xs={6}>
                <OverviewTable
                  title="Debts:"
                  columns={years}
                  rows={[
                    {
                      header: currentPlan.name,
                      columns: years.map((year: string, index: number) =>
                        formatWholeDollars(future.debts[index].plan.total)
                      ),
                      children: debtKeyList.map((key: string) => ({
                        header:
                          PROJECTION_DEBTS[
                            key as keyof typeof PROJECTION_DEBTS
                          ],
                        columns: years.map((year, index: number) => {
                          const value = future.debts[index].plan[key];
                          if (value) {
                            return formatWholeDollars(value);
                          }
                          return "";
                        }),
                      })),
                    },
                    {
                      header: comparePlanName,
                      columns: years.map((year: string, index: number) =>
                        formatWholeDollars(future.debts[index].min.total)
                      ),
                      children: debtKeyList.map((key: string) => ({
                        header:
                          PROJECTION_DEBTS[
                            key as keyof typeof PROJECTION_DEBTS
                          ],
                        columns: years.map((year, index: number) => {
                          const value = future.debts[index].min[key];
                          if (value) {
                            return formatWholeDollars(value);
                          }
                          return "";
                        }),
                      })),
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={6}>
                <Chart
                  dollars
                  centerTitle
                  title="Projected Total Net Wealth"
                  data={netProjection}
                  height="322px"
                  keys={["net", "min"]}
                  xKey="month"
                  keyLabels={{
                    net: currentPlan.name,
                    min: comparePlanName,
                  }}
                  width="100%"
                />
              </Grid>
              <Grid item xs={6}>
                <OverviewTable
                  title="Net Wealth:"
                  columns={years}
                  rows={[
                    {
                      header: currentPlan.name,
                      columns: years.map((year: string, index: number) =>
                        formatWholeDollars(future.net[index].plan)
                      ),
                      children: [
                        {
                          header: "Human Capital",
                          columns: years.map((year, index: number) =>
                            formatWholeDollars((future.hc as any)[index].plan)
                          ),
                        },
                        {
                          header: "Financial Assets",
                          columns: years.map((year, index: number) =>
                            formatWholeDollars(future.assets[index].plan.total)
                          ),
                        },
                        {
                          header: "(Less) Debt",
                          columns: years.map((year, index: number) =>
                            formatWholeDollars(future.debts[index].plan.total)
                          ),
                        },
                      ],
                    },
                    {
                      header: comparePlanName,
                      columns: years.map((year: string, index: number) =>
                        formatWholeDollars(future.net[index].min)
                      ),
                      children: [
                        {
                          header: "Human Capital",
                          columns: years.map((year, index: number) =>
                            formatWholeDollars((future.hc as any)[index].min)
                          ),
                        },
                        {
                          header: "Financial Assets",
                          columns: years.map((year, index: number) =>
                            formatWholeDollars(future.assets[index].min.total)
                          ),
                        },
                        {
                          header: "(Less) Debt",
                          columns: years.map((year, index: number) =>
                            formatWholeDollars(future.debts[index].min.total)
                          ),
                        },
                      ],
                    },
                  ]}
                />
              </Grid>
            </>
          )}
          {!isCurrentStudent && (
            <>
              <Grid item xs={6} className="h-96">
                <OverviewCard title={currentPlan.name} />
              </Grid>
              <Grid item xs={6} className="h-96">
                <OverviewCard title={comparePlanName} compare />
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <OverviewTable
              noGrid
              noColors
              title={`${currentPlan.name} Life Events/Goals`}
              columns={["Date Acquired"]}
              rows={plan1Events.map((event) => {
                let typeLabel =
                  GOALS[event.type as keyof typeof GOALS] || "Goal";
                if (
                  (event.type === "student_loan" || event.type === "idr") &&
                  event.who === "spouse"
                ) {
                  typeLabel += ` (spouse)`;
                }
                // if (event.type === "retirement") {
                //   typeLabel = "Retirement";
                // } else {
                //   const typeDefinition = LIFE_EVENTS[event.type as keyof typeof LIFE_EVENTS];
                //   if (!typeDefinition) {
                //     typeLabel = "Event";
                //   } else {
                //     typeLabel = typeDefinition.typeLabel;
                //   }
                // }
                return {
                  header: typeLabel,
                  columns: [reformatDateString(event.plan)],
                };
              })}
            />
          </Grid>
          <Grid item xs={6}>
            <OverviewTable
              noGrid
              noColors
              title={`${comparePlanName} Life Events/Goals`}
              columns={["Date Acquired"]}
              rows={plan2Events.map((event) => {
                let typeLabel =
                  GOALS[event.type as keyof typeof GOALS] || "Goal";
                if (
                  (event.type === "student_loan" || event.type === "idr") &&
                  event.who === "spouse"
                ) {
                  typeLabel += ` (spouse)`;
                }
                // if (event.type === "retirement") {
                //   typeLabel = "Retirement";
                // } else {
                //   const typeDefinition = LIFE_EVENTS[event.type as keyof typeof LIFE_EVENTS];
                //   if (!typeDefinition) {
                //     typeLabel = "Event";
                //   } else {
                //     typeLabel = typeDefinition.typeLabel;
                //   }
                // }
                return {
                  header: typeLabel,
                  columns: [reformatDateString(event.plan)],
                };
              })}
            />
          </Grid>
        </Grid>
        <Box className={styles.actionBar}>
          <Box className="flex items-center">
            <Typography variant="h6" className={styles.implementName}>
              {currentPlan.name}
            </Typography>
            <Button
              disabled={currentPlanIsImplemented}
              className={styles.actionButton}
              fbColor="primary"
              onClick={onNext}
            >
              {currentPlanIsImplemented ? "Implemented" : "Implement"}
            </Button>
          </Box>
          <Box className="flex items-center">
            <Typography variant="h6" className={styles.implementName}>
              {comparePlanName}
            </Typography>
            <Button
              disabled={comparePlan?.implemented}
              className={styles.actionButton}
              fbColor="primary"
              onClick={implementPlan2}
            >
              {comparePlan?.implemented ? "Implemented" : "Implement"}
            </Button>
          </Box>
        </Box>
        <EditDialog open={showEditDialog} onClose={toggleEditDialog} />
      </Container>
    ),
    hideSidebars: true,
    onNext,
  });
};

export default ComparePlans;

const useStyles = makeStyles({
  actionBar: {
    background: "white",
    padding: "12px 68px",
    bottom: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    position: "fixed",
    width: "100%",
  },
  implementName: {
    color: colors.brandingBlue1,
    fontSize: 14,
    marginLeft: 60,
    marginRight: 40,
  },
  actionButton: {
    width: 168,
  },
  cardRowCell: {
    fontSize: 12,
    width: "16%",
  },
  cardRowLabel: {
    fontSize: 12,
    paddingLeft: 10,
    width: "36%",
  },
  cardTableHeader: {},
  chart: {
    minWidth: 440,
    maxWidth: 524,
  },
  loaderContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 555,
    background: "rgba(255, 255, 255, .8)",
  },
});
