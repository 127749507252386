import React, { useEffect, useState } from "react";

import { EuiProgress, EuiText } from "@elastic/eui";
import styled from "@emotion/styled";
import mainGraphic from "src/assets/svgs/plan-optimizer-interstitial.svg";
import useWindowSize from "src/hooks/useWindowSize";

const FullPageOverlay = styled.div`
  background-color: rgb(247, 248, 252);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  padding-top: 200px;
  width: 100%;
  z-index: 9999;
  text-align: center;
`;

const Interstitial = () => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 567;
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const interval = setInterval(
      () => setProgress((current) => current + 1),
      1500
    );
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <FullPageOverlay>
      <img src={mainGraphic} />
      <div style={{ margin: "0 auto", width: isMobile ? 320 : 455 }}>
        <EuiProgress size="l" value={progress} max={80} />
      </div>
      <div style={{ margin: "20px auto", width: isMobile ? 320 : 540 }}>
        <EuiText>
          <h1>Creating your financial plan</h1>
          <p>
            Sybil is running thousands of simulations based on your preferences.
            This can take a few minutes.
          </p>
        </EuiText>
      </div>
    </FullPageOverlay>
  );
};

export default Interstitial;
