import React from "react";

import {
  EuiIcon,
  EuiCard,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalFooter,
  EuiModalHeaderTitle,
} from "@elastic/eui";

import styled from "@emotion/styled";

import {
  StyledEuiButton,
  StyledSpacer,
} from "src/components/Global/StyledComponents";
import bot_ai from "src/assets/svgs/robot.svg";
import hand_ai from "src/assets/svgs/hand.svg";
import { colors } from "src/theme";

const StyledEuiModalHeader = styled(EuiModalHeader)`
  padding-inline: 24px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledEuiIcon = styled(EuiIcon)`
  width: 24px;
  height: 24px;
`;

const StyledEuiCard = styled(EuiCard)`
  box-shadow: none;
  border: 1px solid ${colors.blueGray5};
`;

const EditOptions = ({
  handleClick,
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
  handleClick: (editOptimized: boolean) => void;
}) => {
  return open ? (
    <EuiModal onClose={handleClose} style={{ maxWidth: 600 }}>
      <StyledEuiModalHeader>
        <EuiModalHeaderTitle>
          How do you want to edit your plan?
        </EuiModalHeaderTitle>
        <StyledEuiIcon
          type="cross"
          onClick={handleClose}
          style={{
            cursor: "pointer",
          }}
        />
      </StyledEuiModalHeader>
      <EuiModalBody>
        <StyledEuiCard
          layout="horizontal"
          icon={<EuiIcon size="xl" type={bot_ai} />}
          title={"Edit AI Plan Setup"}
          description="Edit your answers for the AI Financial Plan and have FitBUX create a new optimized plan."
          onClick={() => {
            handleClick(true);
          }}
        />
        <StyledSpacer size="32px" />
        <StyledEuiCard
          layout="horizontal"
          icon={<EuiIcon size="xl" type={hand_ai} />}
          title={"Edit Plan Manually"}
          description="This will convert your AI Financial Plan into a manual plan which you can then edit and simulate manually."
          onClick={() => {
            handleClick(true);
          }}
        />
      </EuiModalBody>
      <EuiModalFooter>
        <StyledEuiButton color="text" onClick={handleClose}>
          Cancel
        </StyledEuiButton>
      </EuiModalFooter>
    </EuiModal>
  ) : null;
};

export default EditOptions;
