import React from "react";
import { EuiProvider, EuiPageBody } from "@elastic/eui";
import "../PB.css";
import { FlyoutProvider } from "src/components/Global/FlyoutContext";
import ProfileBuilderHeader from "src/components/Headers/ProfileBuilderHeader";
import Flyout from "src/components/Global/Flyout";

export const ProfileBuilderLayout = (props: any) => {
  return (
    <EuiProvider>
      <FlyoutProvider>
        <Flyout />
        <ProfileBuilderHeader />
        <EuiPageBody>{props.children || ""}</EuiPageBody>
      </FlyoutProvider>
    </EuiProvider>
  );
};

export default ProfileBuilderLayout;
