import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getV2PlanProjectionApi } from "src/apiService";
import { QuestionField } from "src/interfaces";
import { emptyPlan } from "src/interfaces/optimizedPlanBuild.interface";
import { SUCCESS } from "src/store/common";
import {
  setCurrentPlan,
  // setOptimizedPlan,
  updateCurrentPlan,
  GET_PLAN_PROJECTION,
} from "src/store/planBuild/actions";
import { getCurrentPlan /*, getPlans*/ } from "src/store/planBuild/selector";
import { steps, STEP_INDICES } from "./common";
import Recap from "./Views/Recap";

interface FormProps {
  stepIndex: number;
  nextClicked: number;
  goToNext: () => void;
  reviewStepIndex: number;
  onSummaryClick: (i: number, isSpouse?: boolean) => void;
  errors: Set<string>;
  setErrors: React.Dispatch<React.SetStateAction<Set<string>>>;
  debugMode?: boolean;
  spouseFlag: boolean;
  setSpouseFlag: any;
}

const TEST_PLAN_ID = 5559;

const MainForm = ({
  stepIndex,
  nextClicked,
  goToNext,
  reviewStepIndex,
  onSummaryClick,
  errors,
  setErrors,
  debugMode,
  spouseFlag,
  setSpouseFlag,
}: FormProps) => {
  const dispatch = useDispatch();
  const currentPlan = useSelector(getCurrentPlan);
  const [subIndex, setSubIndex] = useState(0);
  const planData = currentPlan.questionnaire;

  useEffect(() => {
    if (!debugMode && stepIndex === STEP_INDICES.Transition) {
      // TODO actual logic is commented out to prevent attempting to run the optimizer
      getV2PlanProjectionApi(TEST_PLAN_ID).then((planProjection) => {
        dispatch({
          type: GET_PLAN_PROJECTION + SUCCESS,
          payload: { planProjection, minimalProjection: planProjection },
        });
        dispatch(setCurrentPlan({ planId: TEST_PLAN_ID, keepId: true }));
      });
      // dispatch(
      //   setOptimizedPlan({
      //     planId: useCurrentPlanId || 0,
      //     keepId: !!useCurrentPlanId,
      //     planName: useCurrentPlanId ? currentPlan.name : planName,
      //     questionnaire: planData || emptyPlan,
      //   })
      // );
    }
  }, [debugMode, stepIndex]);

  const handleSummary = (index: number, subI: number, isSpouse?: boolean) => {
    setSubIndex(subI);
    onSummaryClick(index, isSpouse);
  };

  const updatePlan = (planDetails: any) => {
    dispatch(
      updateCurrentPlan({
        questionnaire: {
          ...currentPlan.questionnaire,
          ...planDetails,
        },
      })
    );
  };

  useEffect(() => {
    setSubIndex(0);
  }, [stepIndex]);

  const validate = (fields: QuestionField[], values?: any) => {
    let isValid = true;
    const newErrors = new Set(errors);
    for (let i = 0; i < fields.length; i++) {
      const item = fields[i];
      if (item.required) {
        const value = (planData as any)[item.field];
        let canHaveError = true;
        if (item.enabled?.length) {
          for (let j = 0; j < item.enabled.length; j++) {
            const enableField = item.enabled[j];
            if ((values || (planData as any))[enableField] !== "y") {
              canHaveError = false;
              break;
            }
          }
        }
        if (canHaveError && (item.canBeZero ? value === undefined : !value)) {
          isValid = false;
          newErrors.add(item.field);
        }
      } else {
        newErrors.delete(item.field);
      }
    }
    setErrors(() => newErrors);
    return isValid;
  };

  const Step = steps[stepIndex];

  return (
    <>
      {stepIndex !== STEP_INDICES.Recap && (
        <Step
          debugMode={debugMode}
          errors={errors}
          setErrors={setErrors}
          planData={planData || emptyPlan}
          planSetter={updatePlan}
          nextClicked={nextClicked}
          goToNext={goToNext}
          reviewStepIndex={reviewStepIndex}
          onRecapClick={onSummaryClick}
          subIndex={subIndex}
          validate={validate}
          spouseFlag={spouseFlag}
          setSpouseFlag={setSpouseFlag}
        />
      )}
      {stepIndex === STEP_INDICES.Recap && (
        <Recap
          errors={errors}
          setErrors={setErrors}
          planData={planData || emptyPlan}
          planSetter={updatePlan}
          nextClicked={nextClicked}
          goToNext={goToNext}
          reviewStepIndex={reviewStepIndex}
          onRecapClick={onSummaryClick}
          handleClick={handleSummary}
          subIndex={subIndex}
        />
      )}
    </>
  );
};

export default MainForm;
