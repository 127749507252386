import React, { useEffect, useState } from "react";

import Questionnaire from "src/components/Questionnaire";
import {
  QuestionFormProps,
  singleQuestions,
} from "src/interfaces/optimizedPlanBuild.interface";
import { Question, QuestionForm } from "src/interfaces/questionnaire.interface";

const Marriage = ({
  planSetter,
  planData,
  goToNext,
  nextClicked,
  errors,
  setErrors,
  validate,
}: QuestionFormProps) => {
  // note: hold initial next click count
  const [nextClick] = useState(nextClicked);
  const questionData: QuestionForm = singleQuestions.marriage;

  const startingValues = questionData.emptyValue;
  for (const key in questionData.emptyValue) {
    const savedData = planData[key as keyof typeof planData];
    if (savedData) {
      if (savedData !== "n") {
        const question = questionData.fields.find(
          (question) => question.field === key
        );
        if (question && question.enabled) {
          const enablers = question.enabled;
          enablers.forEach((enabler) => {
            startingValues[enabler] = "y";
          });
        }
      }
      startingValues[key] = savedData;
    }
  }
  if (!startingValues.id) {
    startingValues.id = Date.now();
  }
  const [values, setValues] = useState<Question>(startingValues);

  const handleChange = (newValues: any) => {
    if (newValues.will_marry === "n") {
      newValues.marry_date = "";
      newValues.marry_spouse_income = 0;
      newValues.marry_cost = 0;
      newValues.enable_fedloan = "n";
      newValues.enable_privloan = "n";
      newValues.marry_spouse_fed_loan_balance = 0;
      newValues.marry_spouse_priv_loan_balance = 0;
      setErrors((current) => {
        const newErrors = new Set(current);
        newErrors.delete("marry_date");
        newErrors.delete("marry_spouse_income");
        newErrors.delete("marry_cost");
        newErrors.delete("enable_fedloan");
        newErrors.delete("enable_privloan");
        newErrors.delete("marry_spouse_fed_loan_balance");
        newErrors.delete("marry_spouse_priv_loan_balance");
        return newErrors;
      });
    } else {
      if (newValues.enable_fedloan === "n") {
        newValues.marry_spouse_fed_loan_balance = 0;
        setErrors((current) => {
          const newErrors = new Set(current);
          newErrors.delete("marry_spouse_fed_loan_balance");
          return newErrors;
        });
      }
      if (newValues.enable_privloan === "n") {
        newValues.marry_spouse_priv_loan_balance = 0;
        setErrors((current) => {
          const newErrors = new Set(current);
          newErrors.delete("marry_spouse_priv_loan_balance");
          return newErrors;
        });
      }
    }
    updatePlanData(newValues);
    setValues(newValues);
  };

  useEffect(() => {
    if (nextClicked !== nextClick) {
      if (!validate || validate(questionData.fields)) {
        if (!planData.will_marry) {
          planSetter({ ...planData, will_marry: "n" });
        }
        goToNext();
      }
    }
  }, [nextClicked]);

  const updatePlanData = (changedValues: any) => {
    const newPlan = { ...planData, ...changedValues };
    planSetter(newPlan);
  };

  return (
    <Questionnaire
      questions={questionData}
      values={values}
      onChange={handleChange}
      errors={errors}
      setErrors={setErrors}
    />
  );
};

export default Marriage;
