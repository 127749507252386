import { Who } from "./common";

interface IncomeProjection {
  annual: boolean;
  rate: number;
  type: string;
  who: Who;
  earning: number;
}

interface DebtProjection {
  balance: number;
  payment: number;
  prepay?: number;
  principal: number;
  interest: number;
  type: string;
}

interface FutureProjection {
  date: string;
  score: number;
  hc: number;
  assets: {
    [fieldName: string]: number;
    total: number;
  };
  debts: {
    [fieldName: string]: number;
    total: number;
  };
}

interface RequiredPayment {
  date: string;
  payment: {
    [fieldName: string]: number;
  };
}

export interface Goal {
  type: string;
  date: string;
  payoff?: boolean;
  target?: string;
  who?: string;
}

export interface PlanProjection {
  start: string;
  score: number[];
  series: {
    asset: number[];
    debt: number[];
    hc: number[];
  };
  now: {
    score: number;
    incomes: IncomeProjection[];
    assets: Array<{
      balance: number;
      type: string;
    }>;
    expenses: Array<{
      type: string;
      payment: number;
    }>;
    debts: DebtProjection[];
  };
  summary: {
    now: FutureProjection;
    looming: FutureProjection; // about 6 months
    next: FutureProjection; // about 1 year
    soon: FutureProjection; // about 5 years
    later: FutureProjection; // about 10 years
    retirement: FutureProjection;
  };
  goal: Goal[];
  required: RequiredPayment[];
  allocation: {
    assets: number;
    debts: number;
    risks: number;
  };
  targets: {
    savings: Array<{
      type: string;
      amount: number;
    }>;
    debts: Array<{
      type: string;
      amount: number;
      who: string;
    }>;
  };
  timeline?: any;
}

export interface PlanProjectionV2 {
  start: string;
  score: number[];
  timeline: Array<{
    goal_id: number;
    date: string;
  }>;
  series: {
    asset: number[];
    debt: number[];
    hc: number[];
  };
  taxbomb: Array<{
    who: string;
    amount: number;
  }>;
  summary: {
    now: FutureProjection;
    looming: FutureProjection; // about 6 months
    next: FutureProjection; // about 1 year
    soon: FutureProjection; // about 5 years
    later: FutureProjection; // about 10 years
    retirement: FutureProjection;
  };
}

export interface ProjectionDataPoint {
  monthYear: number;
  value: number;
}

export const PROJECTION_ASSETS = {
  cash: "Checking/savings",
  investment: "Investments",
  retirement: "Retirement",
  home: "Primary Home",
  property: "Investment Property",
  vehicle: "Autos",
  other: "Other Assets",
};

export const PROJECTION_DEBTS = {
  credit_card: "Credit Cards",
  student_loan: "Student Loans",
  home: "Home Mortgage",
  property: "Property Investments",
  vehicle: "Auto Loans",
  personal: "Personal Loans",
  other: "Other Debt",
};
