import React, { useEffect /*, useMemo*/, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import IncomeCard from "./Blocks/Income";
import ExpensesCard from "./Blocks/Expenses";
// TODO
// import RightSidebar from "src/pages/PlanBuilder/Components/RightSidebar";
// import ConfirmIncomeDialog from "src/pages/Dashboard/MyProfile/Transactions/Dialogs/ConfirmIncomeDialog";
// import { getBreakoutBaseApi } from "src/apiService";
// import { MappedCashflow, Transaction } from "src/interfaces";
// import ReviewTransactionsDialog from "src/pages/Dashboard/MyProfile/Transactions/Dialogs/ReviewTransactionsDialog";
import { getIncomeAndExpenseSummaries } from "src/store/dashboard/actions";
import { fetchLivePlanProjection } from "src/store/planBuild/actions";
import {
  getLoadedSummaries,
  getRefreshNeeded,
} from "src/store/dashboard/selector";
import // getDashboardCashflows,
// getEstimatedTaxes,
"src/store/cashflow/selector";
// import { getHasPlan } from "src/store/system/selector";
import { Global, css } from "@emotion/react";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiText, EuiTabs, EuiTab } from "@elastic/eui";
import BudgetOverview from "src/pages/Dashboard/MoneyDetail/DayToDay/Components/BudgetOverview";

const ddStyles = css`
  .dd-page {
    .dd-panel {
      min-height: 90vh;
    }
    .dd-header {
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      font-family: "Poppins", sans-serif;
    }
    span.euiTableCellContent__text {
      font-size: 16px;
      line-height: 24px;
    }
    .euiTableCellContent {
      font-size: 16px;
    }
    .euiTabs {
      max-inline-size: fit-content;
    }
    .acct-tab {
      span {
        font-family: Inter, sans-serif;
        font-size: 16px;
      }
    }
  }
`;

// const emptyTransaction: Transaction = {
//   account: -999,
//   amount: -999,
//   date: "1999-01-01",
//   description: "",
//   id: -999,
//   type: 7000,
// };

export const Content = () => {
  const dispatch = useDispatch();
  const loadedSummaries = useSelector(getLoadedSummaries);
  // const {
  //   income: incomeCashflows,
  //   expenses: expenseCashflows,
  // }: any = useSelector(getDashboardCashflows);
  // TODO
  // const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  // const [showTransactionsDialog, setShowTransactionsDialog] = useState(false);
  // const [transactionQuery, setTransactionQuery] = useState({});
  // const [
  //   confirmingTransaction,
  //   setConfirmingTransaction,
  // ] = useState<Transaction>(emptyTransaction);
  // const [editing, setEditing] = useState(false);
  // const estimatedTaxes: number = useSelector(getEstimatedTaxes);
  // TODO
  // const hasPlan = useSelector(getHasPlan);
  const refreshNeeded = useSelector(getRefreshNeeded);
  // const [dialogTitle, setDialogTitle] = useState("");

  const [activeTab, setActiveTab] = useState<"income" | "expenses">("income"); // State for active tab

  const loadSummaries = () => dispatch(getIncomeAndExpenseSummaries());

  useEffect(() => {
    if (!loadedSummaries || refreshNeeded.summaries) {
      loadSummaries();
    }
  }, [loadedSummaries, refreshNeeded.summaries]);

  useEffect(() => {
    if (refreshNeeded.liveProjection) {
      dispatch(fetchLivePlanProjection(false));
    }
  }, [refreshNeeded.liveProjection]);

  // const incomeTotal = useMemo(
  //   () =>
  //     incomeCashflows.reduce((result: number, item: MappedCashflow) => {
  //       return (item.monthly || 0) + result;
  //     }, 0),
  //   [incomeCashflows]
  // );

  // const expenseTotal = useMemo(
  //   () =>
  //     expenseCashflows.reduce((result: number, item: MappedCashflow) => {
  //       return (item.monthly || 0) + result;
  //     }, 0),
  //   [expenseCashflows]
  // );

  // TODO
  // const openConfirmDialog = (transaction?: Transaction, editing?: boolean) => {
  //   setEditing(!!editing);
  //   let rootTransaction = transaction;
  //   let promise = Promise.resolve();
  //   if (transaction) {
  //     promise = getBreakoutBaseApi(transaction.id)
  //       .then((result: any) => {
  //         rootTransaction = result?.transactions?.[0] || transaction;
  //       })
  //       .catch(console.error);
  //   }
  //   return promise.then(() => {
  //     if (rootTransaction) {
  //       setConfirmingTransaction(rootTransaction);
  //     } else {
  //       setConfirmingTransaction(emptyTransaction);
  //     }
  //     setShowConfirmDialog(true);
  //   });
  // };

  // const closeConfirmDialog = () => {
  //   setShowConfirmDialog(false);
  //   setEditing(false);
  // };

  // const openTransactionsDialog = (
  //   category: string,
  //   key: string,
  //   timeframe: string
  // ) => {
  //   const query: any = {
  //     category,
  //     period: timeframe,
  //     type: key,
  //   };
  //   setShowTransactionsDialog(true);
  //   setTransactionQuery(query);
  // };

  // const closeTransactionsDialog = () => {
  //   setShowTransactionsDialog(false);
  //   setTransactionQuery({});
  // };

  return (
    <>
      <Global styles={ddStyles} />
      <EuiFlexGroup className="dd-page">
        <EuiFlexItem grow={3}>
          <EuiPanel className="dd-panel">
            <EuiText>
              <h2 className="dd-header">Day-to-Day Money</h2>
            </EuiText>
            <StyledSpacer size="32px" />
            <EuiTabs style={{ gap: "32px" }}>
              <EuiTab
                onClick={() => setActiveTab("income")}
                isSelected={activeTab === "income"}
                className="acct-tab"
              >
                Income
              </EuiTab>
              <EuiTab
                onClick={() => setActiveTab("expenses")}
                isSelected={activeTab === "expenses"}
                className="acct-tab"
              >
                Expenses
              </EuiTab>
            </EuiTabs>
            <StyledSpacer size="24px" />
            {activeTab === "income" && (
              <IncomeCard
                openTransactionsDialog={() => null /* openTransactionsDialog */}
              />
            )}
            {activeTab === "expenses" && <ExpensesCard />}
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem grow={1}>
          <EuiPanel className="dd-panel" style={{ minHeight: 'auto' }}>
            <BudgetOverview />
          </EuiPanel>
          <StyledSpacer size="16px" />
        </EuiFlexItem>
      </EuiFlexGroup>
      {/* <ConfirmIncomeDialog
        open={showConfirmDialog}
        onClose={closeConfirmDialog}
        transaction={confirmingTransaction}
        alreadyConfirmed={editing}
        setTitle={setDialogTitle}
      /> */}
      {/* <ReviewTransactionsDialog
        onAdd={loadSummaries}
        onClose={closeTransactionsDialog}
        open={showTransactionsDialog}
        query={transactionQuery}
      /> */}
    </>
  );
};
