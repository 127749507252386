import React from "react";
import { useSelector } from "react-redux";

import DebtSummary from "../Components/DebtSummary";
import Chart from "src/components/ChartV2";
import {
  getDashboardDebtProjection,
  getLiveProjection,
} from "src/store/planBuild/selector";
import { getHasPlan } from "src/store/system/selector";
import { chartData } from "../data";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import {
  EuiFlexGroup,
  EuiButtonIcon,
  EuiCard,
  EuiFlexItem,
} from "@elastic/eui";
import { useFlyout } from "src/components/Global/FlyoutContext";

const Summary = () => {
  const hasPlan = useSelector(getHasPlan);
  const debtProjection = useSelector(getDashboardDebtProjection);
  const liveProjection = useSelector(getLiveProjection);
  const { toggleFlyout } = useFlyout();

  return (
    <div className="hc-tab">
      <div className="hc-desc">
        <div className="hc-desc-text">
          <>
            <h1>
              Understanding Debt
              <EuiButtonIcon
                iconType="questionInCircle"
                aria-label="Icon button"
                size="m"
                iconSize="l"
                onClick={() => toggleFlyout("debt-summary")}
              />
            </h1>
          </>
        </div>
        <StyledSpacer size="16px" />
        <p>
          Sometimes your best investment is paying off debt. Doing so reduces
          your financial risk and increases your FitBUX Score.
        </p>
      </div>
      <StyledSpacer size="32px" />
      <EuiFlexGroup>
        <EuiFlexItem>
          <DebtSummary />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            title=""
            className="ai-card"
            style={{ width: "100%", maxHeight: "300px" }}
          >
            <div className="ai-card-title">
              <h1>Projected Debt</h1>
            </div>
            {!hasPlan && (
              <Chart
                isLocked
                data={chartData}
                xKey="name"
                keys={["uv", "pv", "amt"]}
                lockedButton={{
                  label: "Build and Implement My Plan",
                  to: "/build-plan",
                }}
                width="100%"
              />
            )}
            {hasPlan && (
              <Chart
                dollars
                title="Projected Debt"
                data={debtProjection}
                xKey="month"
                keys={["debt"]}
                keyLabels={{
                  debt: "Current Projected",
                }}
                startMonth={
                  liveProjection ? +liveProjection.start.slice(5) : undefined
                }
                startYear={
                  liveProjection ? +liveProjection.start.slice(0, 4) : undefined
                }
                width="100%"
              />
            )}
          </EuiCard>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};

export default Summary;
