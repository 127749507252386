import React, { useEffect, useState } from "react";

import { EuiFlexGroup, EuiFlexItem, EuiTitle } from "@elastic/eui";
import styled from "@emotion/styled";
import { StyledSpacer } from "src/components/Global/StyledComponents";
import { colors } from "src/theme";
import botCircle from "src/assets/svgs/bot-circle.svg";
import useWindowSize from "src/hooks/useWindowSize";

const MessageBox = styled.div`
  display: inline-flex;
  flex-direction: column;
  border-radius: 0 8px 8px 8px;
  background-color: ${colors.v5Gray1};
  padding: 8px 16px;
  margin-bottom: 10px;
  p {
    margin-bottom: 1rem;
  }
  @media (min-width: 991px) {
    max-width: 75%;
  }
`;

const Introduction = ({ nextClicked, goToNext }: any) => {
  const { width } = useWindowSize();
  const imageSize = width <= 576 ? "36px" : "95px";
  const titleText =
    width <= 576
      ? "Welcome to Your Optimized Plan"
      : "Welcome to Your AI-Powered Plan";
  const [nextClick] = useState(nextClicked);

  useEffect(() => {
    if (nextClick !== nextClicked) {
      goToNext();
    }
  }, [nextClicked]);

  return (
    <>
      <EuiTitle className="ai-content-title">
        <h1>{titleText}</h1>
      </EuiTitle>
      <StyledSpacer size="32px" />
      <EuiFlexGroup justifyContent="spaceBetween" className="ai-flex-content">
        <EuiFlexItem>
          <img src={botCircle} style={{ width: imageSize }} />
        </EuiFlexItem>
        <EuiFlexItem>
          <div>
            <MessageBox>Hi, I'm Sybil, the FitBUX AI assistant.</MessageBox>
          </div>
          <MessageBox>
            <p>
              I'm here to make your financial plan easy to build and implement.
            </p>
            <p>
              I use advanced AI that takes your behavior and financials into
              account. I'm going to ask you a few questions so I can customize
              your plan. Make sure your profile is up-to-date before proceeding.
            </p>
          </MessageBox>
          <div>
            <MessageBox>
              Click “Next” when you are ready to continue.
            </MessageBox>
          </div>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

export default Introduction;
