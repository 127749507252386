import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";

import { validateZipApi } from "src/apiService";
import Questionnaire from "src/components/Questionnaire";
import {
  QuestionFormProps,
  singleQuestions,
} from "src/interfaces/optimizedPlanBuild.interface";
import { QuestionForm } from "src/interfaces/questionnaire.interface";
import { validateZip } from "src/utils";
// import CenterContainer from "../PlanBuilder/Components/CenterContainer";

const Moving = ({
  planSetter,
  planData,
  goToNext,
  nextClicked,
  errors,
  setErrors,
}: QuestionFormProps) => {
  const [nextClick] = useState(nextClicked);
  const questionData: QuestionForm = singleQuestions.moving;

  const startingValues = cloneDeep(questionData.emptyValue);
  for (const key in questionData.emptyValue) {
    const savedData = planData[key as keyof typeof planData];
    if (savedData && savedData !== "n") {
      const question = questionData.fields.find(
        (question) => question.field === key
      );
      if (question && question.enabled) {
        const enablers = question.enabled;
        enablers.forEach((enabler) => {
          startingValues[enabler] = "y";
        });
      }
      startingValues[key] = savedData;
    }
  }
  if (!startingValues.id) {
    startingValues.id = Date.now();
  }
  const [values, setValues] = useState<any>(startingValues);

  const validate = async (input: string) => {
    let isValid = true;
    // check the format (cheap check)
    let zipError = !validateZip(input);
    if (!zipError) {
      // check the zip (round-trip check to API)
      zipError = !(await validateZipApi(input));
    }
    if (zipError) {
      isValid = false;
    }
    setErrors((current) => {
      const newErrors = new Set(current);
      if (zipError) {
        newErrors.add("move_zip_code");
      } else {
        newErrors.delete("move_zip_code");
      }
      if (!values.move_date) {
        newErrors.add("move_date");
        isValid = false;
      } else {
        newErrors.delete("move_date");
      }
      return newErrors;
    });
    return isValid;
  };

  const handleChange = (newValues: any) => {
    updatePlanData(newValues);
    setValues(newValues);
    setErrors(new Set([]));
  };

  useEffect(() => {
    if (nextClicked !== nextClick) {
      const promise =
        planData.will_move === "y"
          ? validate(planData.move_zip_code)
          : Promise.resolve(true);
      promise.then((isValid: boolean) => {
        if (isValid) {
          if (!planData.will_move) {
            planSetter({ ...planData, will_move: "n" });
          }
          goToNext();
        }
      });
    }
  }, [nextClicked]);

  const updatePlanData = (changedValues: any) => {
    const newPlan = { ...planData };
    if (changedValues.will_move === "n") {
      //  if preReq is n then set will_nonprofit to n
      newPlan.will_move = "n";
      newPlan.move_date = "";
      newPlan.move_zip_code = "";
    } else if (changedValues.will_move === "y") {
      // if preReq is y then update will_nonprofit accordingly
      newPlan.will_move = changedValues.will_move;
      newPlan.move_date = changedValues.move_date;
      newPlan.move_zip_code = "" + changedValues.move_zip_code;
    }
    planSetter(newPlan);
  };

  return (
    <Questionnaire
      questions={questionData}
      values={values}
      onChange={handleChange}
      errors={errors}
      setErrors={setErrors}
    />
  );
};

export default Moving;
