import * as React from "react";
import DashboardLayout from "src/layouts/DashboardLayout";
import { Content } from "./Content";
import "src/pages/Dashboard/MoneyDetail/NetWealth/NWPage.css";

const NetWealth = () => {
  return (
    <DashboardLayout pageTitle="Net Wealth">
      <Content />
    </DashboardLayout>
  );
};

export default NetWealth;
