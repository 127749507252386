import * as React from "react";
import DashboardLayout from "src/layouts/DashboardLayout";
import Content from "./Content";

const Accounts = () => {
  return (
    <DashboardLayout pageTitle="Accounts">
      <Content />
    </DashboardLayout>
  );
};

export default Accounts;
