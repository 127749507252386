import { useState, createContext, useContext, useCallback } from "react";

interface CustomFlyout {
  component: React.ComponentType<any> | ((props: any) => JSX.Element);
  title?: string;
  [key: string]: any;
  props?: any;
}

interface FlyoutContextProps {
  isFlyoutOpen: boolean;
  infoType: string;
  toggleFlyout: (type: string) => void;
  closeFlyout: () => void;
  openCustomFlyout: (data: CustomFlyout) => void;
  customFlyout: Partial<CustomFlyout>;
}

const FlyoutContext = createContext<FlyoutContextProps>({
  isFlyoutOpen: false,
  infoType: "",
  toggleFlyout: () => {
    throw new Error("Function not implemented.");
  },
  closeFlyout: () => {
    throw new Error("Function not implemented.");
  },
  openCustomFlyout: () => {
    throw new Error("Function not implemented.");
  },
  customFlyout: {},
});

export const useFlyout = () => useContext(FlyoutContext);

interface FlyoutProviderProps {
  children: React.ReactNode;
}

export const FlyoutProvider = ({ children }: FlyoutProviderProps) => {
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);
  const [infoType, setInfoType] = useState("");
  const [customFlyout, setCustomFlyout] = useState<Partial<CustomFlyout>>({});

  {isFlyoutOpen && customFlyout.component && (
    <>
      <customFlyout.component key={Date.now()} {...customFlyout.props} />
    </>
  )}
  
  
  const openCustomFlyout = (data: CustomFlyout) => {
    setCustomFlyout(data);
    setIsFlyoutOpen(true);
  };

  const toggleFlyout = useCallback(
    (type: string) => {
      setInfoType(type);
      setIsFlyoutOpen(!isFlyoutOpen);
    },
    [isFlyoutOpen]
  );

  const closeFlyout = useCallback(() => {
    setIsFlyoutOpen(false);
    setCustomFlyout({});
  }, []);

  return (
    <FlyoutContext.Provider
      value={{
        isFlyoutOpen,
        infoType,
        toggleFlyout,
        closeFlyout,
        openCustomFlyout,
        customFlyout,
      }}
    >
      {children}
    </FlyoutContext.Provider>
  );
};
