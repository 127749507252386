import {
  ASSET_TYPES,
  RepaymentPlan,
  EXPENSE_TYPES,
  INCOME_TYPES,
  Who,
  YesNo,
} from "./common";
import { LifeEvent } from "./lifeEvents.interface";
import { OptimizedPlan } from "./optimizedPlanBuild.interface";

export interface RetirementEntry {
  who: Who;
  has_401k: YesNo;
  has_roth_401k: YesNo;
  match_limit: number;
  match_percent: number;
  additional_limit: number;
  additional_percent: number;
  traditional_limit: number;
  nonelective_limit: number;
  nonelective_traditional_limit: number;
  student_loan_match: YesNo;
}

export interface StudentLoan {
  strategy: "high_interest_rate" | "low_balance";
  who: Who;
  repayplan: RepaymentPlan;
  idroption: "standard" | "pslf" | "payoff";
  perkinscancel: boolean;
  start: string;
}

export const ASSET_ALLOCATIONS = {
  cash_value: {
    icon: "fb-purchase",
    key: "cash_value",
    label: "Savings/checking contributions",
    buttonLabel: "Savings/Checking",
    debt: false,
    type: "simple",
    category: "asset",
  },
  "401k_value": {
    icon: "fb-purchase",
    key: "401k_value",
    label: "401(k)/403(b)/457",
    buttonLabel: "401(k)/403(b)/457",
    debt: false,
    type: "401k",
    category: "asset",
  },
  roth_401k_value: {
    icon: "fb-purchase",
    key: "roth_401k_value",
    label: "Roth 401(k)",
    buttonLabel: "Roth 401(k)",
    debt: false,
    type: "401k",
    category: "asset",
  },
  roth_ira_value: {
    icon: "fb-purchase",
    key: "roth_ira_value",
    label: "Roth IRA",
    buttonLabel: "Roth IRA",
    debt: false,
    type: "ira",
    category: "asset",
  },
  ira_value: {
    icon: "fb-purchase",
    key: "ira_value",
    label: "Traditional IRA",
    buttonLabel: "Traditional/Simple/SEP IRA",
    debt: false,
    type: "ira",
    category: "asset",
  },
  hsa_value: {
    icon: "fb-purchase",
    key: "hsa_value",
    label: "Heath savings account",
    buttonLabel: "HSA",
    debt: false,
    type: "simple",
    category: "asset",
  },
  other_retirement_value: {
    icon: "fb-purchase",
    key: "other_retirement_value",
    label: "Other Retirement",
    buttonLabel: "Other Retirement Savings",
    debt: false,
    type: "simple",
    category: "asset",
  },
  other_assets_value: {
    icon: "fb-purchase",
    key: "other_assets_value",
    label: "Other Assets",
    buttonLabel: "Other Asset",
    debt: false,
    type: "simple",
    category: "asset",
  },
  other_investments_value: {
    icon: "fb-purchase",
    key: "other_investments_value",
    label: "Other Investments",
    buttonLabel: "Other Investment",
    debt: false,
    type: "simple",
    category: "asset",
  },
};

export const FUNDING_CATEGORIES = {
  income: {
    icon: "fb-money",
    key: "income",
    label: "Add Income",
    buttonLabel: "Add Income",
  },
  fed_loan: {
    icon: "fb-purchase",
    key: "fed_loan",
    label: "Federal Student Loans",
    buttonLabel: "Federal Student Loans",
  },
  priv_loan: {
    icon: "fb-purchase",
    key: "priv_loan",
    label: "Private Student Loans",
    buttonLabel: "Private Student Loans",
  },
  parent_loan: {
    icon: "fb-purchase",
    key: "parent_loan",
    label: "Parent Plus Loans",
    buttonLabel: "Parent Plus Loans",
  },
  grant: {
    icon: "fb-purchase",
    key: "grant",
    label: "Scholarships/Grants/\nFamily Aid/Gifts/\n529/Coverdell ESA",
    buttonLabel: "Scholarships/Grants/Family Aid/Gifts/529 Plan/Coverdell ESA",
  },
  funding: {
    icon: "fb-purchase",
    key: "funding",
    label: "Other Funding",
    buttonLabel: "Other Funding",
  },
  assets: {
    icon: "fb-purchase",
    key: "assets",
    label: "Use Assets I Currently Have",
    buttonLabel:
      "Use Assets I Currently Have (e.g. Cash, liquidate 401(k), etc.)",
  },
};

export const DEBT_ALLOCATIONS = {
  fed_loan: {
    icon: "fb-purchase",
    key: "fed_loan",
    label: "Federal Student Loan",
    buttonLabel: "Federal Student Loan",
    category: "debt",
  },
  perkins_loan: {
    icon: "fb-purchase",
    key: "perkins_loan",
    label: "Perkins Student Loan",
    buttonLabel: "Perkins Student Loan",
    category: "debt",
  },
  priv_loan: {
    icon: "fb-purchase",
    key: "priv_loan",
    label: "Private Student Loan",
    buttonLabel: "Private Student Loan",
    category: "debt",
  },
  credit_card: {
    icon: "fb-purchase",
    key: "credit_card",
    label: "Credit Card",
    buttonLabel: "Credit Card",
    category: "debt",
  },
  auto_loan: {
    icon: "fb-purchase",
    key: "auto_loan",
    label: "Auto Loan",
    buttonLabel: "Auto Loan",
    category: "debt",
  },
  home_loan: {
    icon: "fb-purchase",
    key: "home_loan",
    label: "Home Mortgage",
    buttonLabel: "Home Mortgage",
    category: "debt",
  },
  property_loan: {
    icon: "fb-purchase",
    key: "property_loan",
    label: "Rental Property Mortgage",
    buttonLabel: "Rental Mortgage",
    category: "debt",
  },
  personal_loan: {
    icon: "fb-purchase",
    key: "personal_loan",
    label: "Personal Loan",
    buttonLabel: "Personal Loan",
    category: "debt",
  },
  other_debt: {
    icon: "fb-purchase",
    key: "other_debt",
    label: "Other Debts/Liabilities",
    buttonLabel: "Other Debt",
    category: "debt",
  },
};

export const ALLOCATIONS_TYPES = {
  ...ASSET_ALLOCATIONS,
  ...DEBT_ALLOCATIONS,
  solo: null,
};

export const SPECIAL_ALLOCATIONS = [
  "401k_value",
  "roth_401k_value",
  "roth_ira_value",
  "ira_value",
  "fed_loan",
  // "perkins_loan",
  // "priv_loan",
];

export const FUNDING_INCOME_TYPES = {
  salary: "Salary",
  hourly: "Hourly Wage",
  commission: "Commission Income",
  bonus: "Bonus Income",
  stipend: "Stipend Income",
  interest: "Interest Income",
  dividend: "Dividend Income",
  rent: "Rental Income",
  side_hustle: "1099/Self-Employed/Side Hustle Income",
};

export const ONE_TIME_FUNDING_TYPES = {
  fed_loan: "Federal Loans",
  priv_loan: "Private Loans",
  parent_loan: "Parent Loans",
  grant: "Grants, Etc.",
  funding: "Other Funding",
};

export const EDUCATION_EXPENSE_TYPES = {
  tuition: "Tuition",
  books: "Books and Supplies",
  fees: "Fees",
  cost: "Other Education Expenses",
};

export const EDUCATION_FUNDING_AND_EXPENSES = {
  income: "Income",
  assets: "Asset Withdrawals",
  ...FUNDING_INCOME_TYPES,
  ...ONE_TIME_FUNDING_TYPES,
  ...EDUCATION_EXPENSE_TYPES,
  ...ASSET_TYPES,
};

interface SoloAllocation {
  who: any;
  "401k_value"?: number;
  roth_401k_value?: number;
  roth_ira_value?: number;
  ira_value?: number;
  fed_loan?: number;
}

export interface AssetAllocations {
  cash_value?: number;
  "401k_value"?: number;
  roth_401k_value?: number;
  roth_ira_value?: number;
  ira_value?: number;
  other_retirement_value?: number;
  other_investments_value?: number;
  hsa_value?: number;
}

export interface DebtAllocations {
  fed_loan?: number;
  perkins_loan?: number;
  priv_loan?: number;
  credit_card?: number;
  auto_loan?: number;
  home_loan?: number;
  property_loan?: number;
  personal_loan?: number;
  other_debt?: number;
}

export interface Allocations extends AssetAllocations, DebtAllocations {}

export interface PlanAllocations extends Allocations {
  solo: SoloAllocation[];
}

export interface Assumptions {
  income: number;
  savings: number;
  dividend: number;
  investments: number;
  property: number;
  auto_loan_rate: number;
  auto_loan_term: number;
  home_loan_rate: number;
  home_loan_term: number;
  property_loan_rate: number;
  property_loan_term: number;
  property_loan_down: number;
}

export interface EducationFunding {
  date?: string;
  type: keyof typeof EDUCATION_FUNDING_AND_EXPENSES;
  earning?: number;
  who?: string;
  payment?: number;
  repayment?: boolean;
  rate?: number;
  term?: number;
}

export interface Earning {
  type: keyof typeof INCOME_TYPES;
  earning: number;
  who: Who;
}

export interface Expense {
  type: keyof typeof EXPENSE_TYPES;
  payment: number;
}

export interface PlanListRecord {
  id: number;
  name: string;
  implemented: boolean;
  questionnaire?: boolean;
  messages?: boolean;
  progress?: number;
}

export interface PlanGoal {
  id: number;
  goaltype: string;
  account_id?: number;
  lifeevent_id?: number;
  who?: "applicant" | "spouse";
}

export interface Plan {
  id?: number;
  name: string;
  profile: {
    zip: string;
    married: boolean;
    spouseDOB: string;
    children: number[];
    has_hsa?: boolean;
    filing_jointly: boolean;
    priority?: string;
  };
  progress?: number;
  emergencymonths: number;
  retirement: RetirementEntry[];
  studentloan: StudentLoan[];
  incomes: Earning[];
  education?: EducationFunding[];
  expenses: Expense[];
  risks: Expense[];
  allocations: PlanAllocations[];
  lifeevents: LifeEvent[];
  goals: PlanGoal[];
  assumptions: Assumptions;
  messages?: MessageCode[];
  questionnaire?: OptimizedPlan;
}

export interface MessageCode {
  id: string;
  who?: "applicant" | "spouse" | "both";
  months_to_save?: number;
  monthly_expenses?: number;
}

export interface PlanWithMessages {
  plan: Plan;
  messages: MessageCode[];
}
