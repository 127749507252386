import React, { useCallback, useEffect, useState, useRef } from "react";
import { debounce, map } from "lodash";

import {
  Box,
  Button,
  CardContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";

// TODO clean up jank and merge with MainCard

import Icon from "src/components/Icon";
import { NOT_APPLICABLE } from "src/constants";
import { mainCardStyles } from "./style";
import { formatWholeDollars } from "src/utils";
import { DollarTextField, PercentTextField } from "src/utils/inputMask";
import { SidebarContent } from "src/interfaces";
import { colors } from "src/theme";
import HelpToggle from "../HelpToggle";

interface IMainCard {
  addItems?: any;
  addLabel?: string;
  allowSelect?: boolean;
  defaultNewItem?: any;
  disableDelete?: boolean;
  enableDeleteFixed?: boolean;
  iconName?: string;
  loading?: boolean;
  title: string;
  noContainer?: boolean;
  hideTitle?: boolean;
  columns: Array<any>;
  data: Array<any>;
  className?: string;
  fixedRows?: Array<number>;
  hideHeaders?: boolean;
  onAdd?: VoidFunction;
  addTooltip?: string;
  disableAdd?: boolean;
  disableEditFields?: string[];
  onAddFromSelect?: (item: any, key?: any) => void;
  onCancelEdit?: VoidFunction;
  onDelete?: (item: any, index: number) => void;
  onEdit?: (item: any, index: number) => void;
  onSave: (item: any, update: any, index: number) => void;
  specialEdit?: {
    check: (item: any, index: number) => boolean;
    handle: (item: any, index: number) => void;
  };
  saveOnChange?: boolean;
  saveOnClose?: boolean;
  summaryRow?: any;
  disableSave?: boolean;
  secondaryStyle?: boolean;
  flat?: boolean;
  total?: number;
  help?: SidebarContent[];
  additionalText?: string;
  setDirtyFlag?: VoidFunction;
  displayWarnings?: boolean;
}

type MainCardProps = IMainCard;
const useStyles = makeStyles(mainCardStyles);

const BasicCard = ({
  addLabel,
  allowSelect,
  iconName,
  title,
  hideTitle,
  columns,
  data,
  disableDelete,
  enableDeleteFixed,
  fixedRows,
  // loading,
  className,
  addItems,
  hideHeaders,
  defaultNewItem,
  addTooltip,
  disableAdd,
  disableEditFields,
  onAdd,
  onAddFromSelect,
  onCancelEdit,
  onDelete,
  onSave,
  specialEdit,
  summaryRow,
  saveOnChange,
  saveOnClose,
  noContainer,
  flat,
  total,
  help,
  additionalText,
  secondaryStyle,
}: // setDirtyFlag,
MainCardProps) => {
  const styles = useStyles();

  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [changed, setChanged] = useState<boolean>(false);
  const [isNew, setNew] = useState<boolean>(false);
  const [newValues, setNewValues] = useState<any>({});
  const [anchorEl, setAnchorEl] = useState(null);
  // const [editableColumns, setEditableColumns] = useState<string[]>([]);
  const persistChanged = useRef(false);
  const persistIndex = useRef(-1);
  const persistItem = useRef(data[0] || null);
  const persistValues = useRef<any>({});

  const save = () => {
    setChanged(false);
    onSave(persistItem.current, persistValues.current, persistIndex.current);
  };

  const quickSave = useCallback(debounce(save, 800, { leading: false }), []);

  useEffect(() => {
    persistChanged.current = changed;
    // if (changed && setDirtyFlag) {
    //   setDirtyFlag();
    // }
  }, [changed]);

  useEffect(() => {
    if (saveOnClose) {
      save();
    }
  }, [saveOnClose]);

  useEffect(() => {
    persistIndex.current = selectedIndex;
    persistItem.current = data[selectedIndex] || null;
  }, [selectedIndex, data]);

  useEffect(() => {
    persistValues.current = newValues;
    if (saveOnChange && persistChanged.current) {
      quickSave();
    }
  }, [newValues]);

  useEffect(() => {
    return () => {
      onSelectOrSave(-1);
    };
  }, []);

  const openAddMenu = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeAddMenu = () => {
    setAnchorEl(null);
  };

  const handleAddItem = (item: any, key?: any) => {
    if (selectedIndex >= 0) {
      onSelectOrSave(selectedIndex);
    }
    setSelectedIndex(data.length);
    setNew(() => true);
    if (onAddFromSelect) {
      onAddFromSelect(item, key);
      setNewValues({ ...item });
      setChanged(false);
      closeAddMenu();
    } else if (onAdd) {
      if (defaultNewItem) {
        setNewValues({ ...defaultNewItem });
      }
      onAdd();
    }
  };

  const handleEnterKey = (e: React.KeyboardEvent<any>) => {
    if (e.key === "Enter" && changed) {
      save();
    }
  };

  const onSelectOrSave = (i: number) => {
    setNew(() => false);
    if (persistChanged.current) {
      save();
    } else {
      if (onCancelEdit) {
        onCancelEdit();
      }
    }
    if (i < 0) {
      return;
    }
    if (i === selectedIndex) {
      setNewValues({});
      setSelectedIndex(-1);
    } else {
      if (specialEdit && specialEdit.check(data[i], i)) {
        setSelectedIndex(-1);
        return specialEdit.handle(data[i], i);
      }
      setNewValues({ ...data[i] });
      const newEditableColumns: string[] = [];
      columns.forEach((column) => {
        if (
          column.type === "fixed" ||
          (column.formatter &&
            column.formatter(data[i][column.field]) === NOT_APPLICABLE) ||
          (column.type === "select" && !allowSelect) ||
          (disableEditFields && disableEditFields.indexOf(column.field) >= 0)
        ) {
          return true;
        }
        newEditableColumns.push(column.field);
      });
      // setEditableColumns(newEditableColumns);
      setSelectedIndex(i);
    }
  };

  const updateField = (field: string) => (e: React.ChangeEvent<any>) => {
    let value = e.target.value;
    if (value || newValues[field]) {
      setNew(false);
      setChanged(true);
    }
    if (!isNaN(+value)) {
      value = +value;
    }
    const update = { ...newValues, [field]: value };
    if (field === "annual") {
      update["monthly"] = Math.floor(value / 12);
      if (total) {
        update.percent = (value / total) * 100;
      }
    } else if (field === "monthly") {
      const annual = Math.floor(value * 12);
      update["annual"] = annual;
      // only update calculations for percents if the total has been provided for the formula
      if (total) {
        update.percent = (annual / total) * 100;
      }
    } else if (field === "percent" && total) {
      const annual = total * value * 0.01;
      update["annual"] = annual;
      update["monthly"] = Math.floor(annual / 12);
    }
    setNewValues(update);
  };

  const renderCell = (
    column: any,
    row: any,
    values: any,
    i: number,
    columnIndex: number,
    enableEdit: boolean
  ) => {
    // let enableEdit = selectedIndex === i && column.type !== "fixed" &&
    //   (!disableEditFields || disableEditFields.indexOf(column.field) < 0);
    let renderValue = values[column.field];
    if (values[`${column.field}Label`]) {
      renderValue = values[`${column.field}Label`];
    } else if (values[`${column.field}Items`]) {
      renderValue = column.items[values[column.field]];
    }
    if (column.formatter) {
      const formattedValue = column.formatter(renderValue);
      if (formattedValue === "N/A" && enableEdit) {
        renderValue = 0;
      } else {
        renderValue = formattedValue;
      }
    }
    let plainCell = <span>{renderValue}</span>;
    if (
      !columnIndex &&
      ((typeof row.min_annual === "number" && row.annual < row.min_annual) ||
        (typeof row.max_annual === "number" && row.annual > row.max_annual))
    ) {
      plainCell = (
        <Box className="w-full h-full flex justify-between items-center">
          {renderValue}
          <Tooltip title="The allocated amount needs attention. Please correct accordingly.">
            <span>
              <Icon
                iconName="fa-triangle-exclamation"
                style={{ color: colors.error }}
              />
            </span>
          </Tooltip>
        </Box>
      );
    }
    if (
      !enableEdit ||
      renderValue === "N/A" ||
      (column.type === "select" && !(allowSelect || row.id < 0))
    ) {
      return plainCell;
    }
    if (enableEdit) {
      if (column.type === "select" && (allowSelect || row.id < 0)) {
        let items: any = column.items;
        if (items instanceof Function) {
          items = items(row);
        }
        // const children = items ?
        //       map(items, (label, value) => {
        //         const isArray = Array.isArray(items);
        //         let renderLabel = label;
        //         if (column.selectLabelFormat) {
        //           renderLabel = column.selectLabelFormat(values, label);
        //         }
        //         return (
        //           <MenuItem
        //             key={isArray ? label : value}
        //             value={isArray ? label : value}
        //           >
        //             {renderLabel}
        //           </MenuItem>
        //         );
        //       }) : [];
        const children = items
          ? map(items, (label, value) => {
              const isArray = Array.isArray(items);
              let renderLabel = label;
              if (column.selectLabelFormat) {
                renderLabel = column.selectLabelFormat(values, label);
              }
              return (
                <option
                  key={isArray ? label : value}
                  value={isArray ? label : value}
                >
                  {renderLabel}
                </option>
              );
            })
          : [];
        console.log({ children });
        return (
          <Select
            native
            value={values[column.field] || ""}
            className="select"
            variant="outlined"
            onChange={updateField(column.field)}
            margin="dense"
            style={{ width: "100%", height: 28 }}
            onOpen={(e) => console.log({ onOpen: e })}
          >
            {children}
          </Select>
        );
      }
      if (column.type === "number") {
        const min = row[`min_${column.field}`];
        const max = row[`max_${column.field}`];
        return (
          <Box>
            <DollarTextField
              className="input"
              placeholder="$0"
              onChange={updateField(column.field)}
              onKeyDown={handleEnterKey}
              max={typeof max === "number" ? Math.floor(max) : undefined}
              value={
                typeof values[column.field] === "number"
                  ? values[column.field] || ""
                  : ""
              }
            />
            {typeof min === "number" && (
              <Typography className="min" variant="caption">
                Min: {formatWholeDollars(Math.ceil(min))}
              </Typography>
            )}
            {typeof max === "number" && (
              <Typography className="min" variant="caption">
                Max: {formatWholeDollars(Math.floor(max))}
              </Typography>
            )}
          </Box>
        );
      }
      if (column.type === "plainNumber") {
        return (
          <TextField
            className="input"
            onChange={updateField(column.field)}
            onKeyDown={handleEnterKey}
            value={"" + values[column.field] || ""}
          />
        );
      }
      if (column.type === "percent") {
        return (
          <Box>
            <PercentTextField
              className="input"
              placeholder="%"
              onChange={updateField(column.field)}
              onKeyDown={handleEnterKey}
              value={
                typeof values[column.field] === "number"
                  ? values[column.field]
                  : ""
              }
            />
            {typeof row.min === "number" && (
              <Typography className="min" variant="caption">
                Min: {(Math.ceil(row.min * 100) / 100).toFixed(2)}%
              </Typography>
            )}
            {typeof row.max === "number" && (
              <Typography className="min" variant="caption">
                Max: {(Math.floor(row.max * 100) / 100).toFixed(2)}%
              </Typography>
            )}
          </Box>
        );
      }
      if (column.type === "fixed") {
        return plainCell;
      }
    }
  };

  const renderAddButton = () => {
    const disabled = disableAdd || isNew;
    const button = addItems ? (
      <>
        <Button
          aria-controls={`add-${title}-menu`}
          aria-haspopup="true"
          variant="outlined"
          className={styles.button}
          disabled={disabled}
          color="primary"
          endIcon={<Icon iconName="fb-add-alt" />}
          onClick={openAddMenu}
        >
          {addLabel || `Add ${title}`}
        </Button>
        <Menu
          id={`add-${title}-menu`}
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={closeAddMenu}
        >
          {map(addItems, (item: any, key: any) => (
            <MenuItem key={key} onClick={() => handleAddItem(item, key)}>
              {typeof item === "string" ? item : item.label}
            </MenuItem>
          ))}
        </Menu>
      </>
    ) : (
      <Button
        variant="outlined"
        disabled={disabled}
        className={styles.button}
        color="primary"
        endIcon={<Icon iconName="fb-add-alt" />}
        onClick={handleAddItem}
      >
        {addLabel || `Add ${title}`}
      </Button>
    );
    if (addTooltip) {
      return (
        <Tooltip title={addTooltip}>
          <span>{button}</span>
        </Tooltip>
      );
    }
    return button;
  };

  const renderEditButton = (i: number) => {
    return (
      <Button
        className="text-sm"
        color="primary"
        onClick={() => onSelectOrSave(i)}
      >
        {selectedIndex === i ? "Done" : "Edit"}
      </Button>
    );
  };

  return (
    <Paper
      elevation={flat ? 0 : 1}
      className={
        className
          ? `${styles.root} ${className}`
          : `${styles.root} ${noContainer ? styles.noContainer : ""}`
      }
    >
      <CardContent className={styles.content}>
        <Box className={styles.container}>
          {!hideTitle && (
            <Box className="flex mb-4">
              {iconName && (
                <Icon
                  iconName={iconName}
                  className={secondaryStyle ? styles.icon2 : styles.icon}
                />
              )}
              <Typography
                className={secondaryStyle ? styles.title2 : styles.title}
              >
                {title}
              </Typography>
              {!!help && <HelpToggle content={help} />}
            </Box>
          )}
          {additionalText && (
            <Box className="mb-5">
              <Typography className="text-sm">{additionalText}</Typography>
            </Box>
          )}
          <List className={styles.table}>
            {!hideHeaders && (
              <ListItem className={styles.header}>
                <Box className={styles.cellPrefix}></Box>
                {columns.map((column, i) => (
                  <Box
                    key={i}
                    style={{
                      width: column.width || `${100 / columns.length}%`,
                    }}
                  >
                    {column.label}
                  </Box>
                ))}
                <Box className={styles.cellSuffix} />
              </ListItem>
            )}
            <List className={styles.table}>
              {data.map((row, i) => {
                const values: any =
                  changed && selectedIndex === i
                    ? { ...row, ...newValues }
                    : row;
                const selected = selectedIndex === i;
                const isEditable = !fixedRows || fixedRows.indexOf(i) < 0;
                const enableEdit = selectedIndex === i && isEditable;
                const className = clsx(
                  styles.row,
                  isEditable && "edit",
                  selected && "selected",
                  selected &&
                    (typeof row.min === "number" ||
                      typeof row.max === "number") &&
                    "taller"
                );
                return (
                  <ListItem
                    key={JSON.stringify(row)}
                    className={className}
                    style={{ cursor: "initial" }}
                  >
                    <Box
                      className={styles.cellPrefix}
                      onClick={() => {
                        if (!enableEdit) {
                          onSelectOrSave(i);
                        }
                      }}
                    >
                      {isEditable && renderEditButton(i)}
                    </Box>
                    {columns.map((column, columnIndex) => {
                      return (
                        <Box
                          className={styles.cell}
                          key={column.field}
                          style={{
                            width: column.width || `${100 / columns.length}%`,
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            if (!enableEdit) {
                              onSelectOrSave(i);
                            }
                          }}
                        >
                          {renderCell(
                            column,
                            row,
                            values,
                            i,
                            columnIndex,
                            enableEdit
                          )}
                        </Box>
                      );
                    })}
                    <Box className={styles.cellSuffix}>
                      {!disableDelete &&
                        (!fixedRows ||
                          fixedRows.indexOf(i) < 0 ||
                          enableDeleteFixed) && (
                          <IconButton
                            color="primary"
                            onClick={() => {
                              if (selectedIndex === i && enableEdit) {
                                setSelectedIndex(-1);
                                setNew(false);
                              }
                              return onDelete ? onDelete(row, i) : null;
                            }}
                          >
                            <Icon iconName="fb-trash-can" />
                          </IconButton>
                        )}
                    </Box>
                  </ListItem>
                );
              })}
            </List>
          </List>
          {!!summaryRow && (
            <>
              <Divider className="mt-4" />
              <Box className={styles.summaryRow}>
                <Box className={styles.cellPrefix} />
                {columns.map((column, i) => {
                  let value = summaryRow[column.field] || "";
                  if (column.formatter) {
                    if (!value) {
                      value = 0;
                    }
                    value = column.formatter(value);
                  }
                  const style: any = {
                    width: column.width || `${100 / columns.length}%`,
                  };
                  return (
                    <Box
                      className={`${styles.cell} summary`}
                      key={column.field}
                      style={style}
                    >
                      {!i ? (
                        <Box style={{ marginLeft: -35 }}>{value}</Box>
                      ) : (
                        value
                      )}
                    </Box>
                  );
                })}
                <Box className={styles.cellSuffix} />
              </Box>
            </>
          )}
          <Grid container spacing={2} className="mt-0">
            <Grid item xs={12}>
              {renderAddButton()}
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Paper>
  );
};

export default BasicCard;
