import React from "react";
import { useSelector } from "react-redux";

import DashboardLayout from "src/layouts/DashboardLayout";
import HomeNoPlan from "./HomeNoPlan";
import HomePlan from "./HomePlan";
import { getHasPlan } from "src/store/system/selector";

const HomeContainer = () => {
  const hasPlan = useSelector(getHasPlan);

  return (
    <DashboardLayout pageTitle="Plans">
      {hasPlan ? <HomePlan /> : <HomeNoPlan />}
    </DashboardLayout>
  );
};

export default HomeContainer;
