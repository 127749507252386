import React from "react";
import { useSelector } from "react-redux";

import ActionItems from "./Blocks/ActionItems";
import PlanSummaryChart from "./Blocks/PlanSummaryChart";
import IdrSolutionBlock from "./Blocks/IdrSolutionBlock";
import { SPECIAL_REPAYMENTS } from "src/interfaces";
import LatestPosts from "./Blocks/LatestPosts";
import { getProfile, getSpouseProfile } from "src/store/profileBuild/selector";

export const HomeNoPlan = () => {
  const profile = useSelector(getProfile);
  const spouseProfile = useSelector(getSpouseProfile);

  const hasIdr =
    SPECIAL_REPAYMENTS.indexOf(profile?.fed_repayment_plan || "") >= 0 ||
    SPECIAL_REPAYMENTS.indexOf(spouseProfile?.fed_repayment_plan || "") >= 0;

  return (
    <>
      <div className="card">
        <PlanSummaryChart />
      </div>
      <div className="card">
        <ActionItems />
      </div>
      <div className="card">
        <LatestPosts />
      </div>
      {hasIdr && (
        <div className="card">
          <IdrSolutionBlock />
        </div>
      )}
    </>
  );
};

export default HomeNoPlan;
